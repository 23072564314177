import React from 'react';
import './status-label.css';

const StatusLabelRecon = props => {

    const { type, label, style } = props;

    return (
        <div className={`status-label status-label-${type}`} style={style}>
            <label className={`status-label-recon-text`}>{label}</label>
        </div>
    )
}

export default StatusLabelRecon;