import React, {Component} from "react";
import {connect} from "react-redux";
import Alert from "../../../components/alert/Alert";
import Loader from "../../../components/loader/Loader";
import {Card, FormControlLabel} from "@mui/material";
import Switch from '@mui/material/Switch';
import {setImportFeatures, setIntegrateFeatures} from "../../../redux/actions/other";

class AksesMenu extends Component {
  state = {
    loading: false,
    alert: {
      ...alert,
      show: false,
      // message: response.message,
      isError: false
    }
  }

  changeImport = async (value) => {
    this.setState({loading: true});
    await this.props.onSetImport(value);
    this.setState({loading: false});
  }

  changeIntegrate = async (value) => {
    this.setState({loading: true});
    await this.props.onSetIntegrate(value);
    this.setState({loading: false});
  }

  render() {
    const {features} = this.props?.profile;
    const statusImport = features.find(x => x.name === 'FEATURE_IMPORT_STATEMENT');
    const statusIntegrate = features.find(x => x.name === 'FEATURE_INTEGRATE_STATEMENT_BY_API');
    return (
      <div className="col-12" style={{marginBottom: 20}}>
        <Loader show={this.state.loading}/>
        <Alert
          show={this.state.alert.show}
          message={this.state.alert.message}
          isError={this.state.alert.isError}
          modalClosed={() => this.setState({alert: {...alert, show: false}})}
        />
        <div className="row">
          <div className="col-8 col-sm-12">
            <Card>
              <div className="col-12" style={{paddingTop: 34, paddingBottom: 20}}>
                <h4 style={{marginBottom: 20, fontSize: '18px', fontFamily: 'Inter'}}>Unggah Statement</h4>
                <div style={{paddingLeft: 10, marginTop: 34}}>
                  <div>
                    <FormControlLabel
                      value="import"
                      control={
                        <Switch
                          value={statusImport?.is_allow ?? false}
                          checked={statusImport?.is_allow ?? false}
                          onChange={(e, value) => this.changeImport(value)}
                          sx={{
                            width: 40,
                            height: 20,
                            padding: 0,
                            marginRight: 2,
                            '& .MuiSwitch-switchBase': {
                              padding: 0,
                              margin: 0.2,
                              transitionDuration: '300ms',
                              '&.Mui-checked': {
                                transform: 'translateX(19px)',
                                color: '#fff',
                                '& + .MuiSwitch-track': {
                                  backgroundColor: '#667AB3',
                                  opacity: 1,
                                  border: 0,
                                },
                              },
                            },
                            '& .MuiSwitch-thumb': {
                              boxSizing: 'border-box',
                              width: 17,
                              height: 17,
                            },
                            '& .MuiSwitch-track': {
                              borderRadius: 26 / 2,
                              backgroundColor: 'gray',
                              opacity: 1,
                            },
                          }}
                        />
                      }
                      label="Import"
                      labelPlacement="end"
                    />
                  </div>
                  <div style={{marginTop: 20}}>
                    <FormControlLabel
                      value="integrasi"
                      control={
                        <Switch
                          onChange={(e, value) => this.changeIntegrate(value)}
                          value={statusIntegrate?.is_allow ?? false}
                          checked={statusIntegrate?.is_allow ?? false}
                          sx={{
                            width: 40,
                            height: 20,
                            padding: 0,
                            marginRight: 2,
                            '& .MuiSwitch-switchBase': {
                              padding: 0,
                              margin: 0.2,
                              transitionDuration: '300ms',
                              '&.Mui-checked': {
                                transform: 'translateX(19px)',
                                color: '#fff',
                                '& + .MuiSwitch-track': {
                                  backgroundColor: '#667AB3',
                                  opacity: 1,
                                  border: 0,
                                },
                              },
                            },
                            '& .MuiSwitch-thumb': {
                              boxSizing: 'border-box',
                              width: 17,
                              height: 17,
                            },
                            '& .MuiSwitch-track': {
                              borderRadius: 26 / 2,
                              backgroundColor: 'gray',
                              opacity: 1,
                            },
                          }}
                        />
                      }
                      label="Integrasi"
                      labelPlacement="end"
                    />
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.auth.profile
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSetImport: (value) => dispatch(setImportFeatures(value)),
    onSetIntegrate: (value) => dispatch(setIntegrateFeatures(value))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AksesMenu);
