import React, { Component } from 'react';
import { connect } from 'react-redux';
import Table from '../../../components/table/Table';
import Loader from '../../../components/loader/Loader';
import Button from '../../../components/button/Button';
import Dropdown from '../../../components/dropdown/Dropdown';
import { getUserList, getListRole, deleteUser } from '../../../redux/actions';
import { formatDisplayDate } from '../../../helpers/functionFormat';
import Alert from '../../../components/alert/Alert';
import { ReactComponent as DeleteSvg } from '../../../assets/icons/trash.svg';
import { ReactComponent as EditSvg } from '../../../assets/icons/pencil.svg';
// import user from '../../../assets/JsonData/user-list.json';

class UserList extends Component {
    state = {
        role: { value: null, name: 'Peran: Semua' },
        tableConfig: {
            search: '',
            page: 1,
            sort: 'name',
            limit: 10,
        },
        loading: false,
        alert: {
            show: false,
            message: '',
            isError: false,
        },
    };

    componentDidMount() {
        this.onGetUserList(
            this.state.tableConfig.page,
            this.state.tableConfig.limit,
            this.state.tableConfig.search,
            this.state.tableConfig.sort,
            this.state.role.value ? parseInt(this.state.role.value) : null
        );
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.tableConfig !== this.state.tableConfig) {
            this.onGetUserList(
                this.state.tableConfig.page,
                this.state.tableConfig.limit,
                this.state.tableConfig.search,
                this.state.tableConfig.sort,
                this.state.role.value ? parseInt(this.state.role.value) : null
            );
        } else if (prevState.role !== this.state.role) {
            this.setState((prevState) => {
                return {
                    ...prevState,
                    loading: true,
                    tableConfig: {
                        ...prevState.tableConfig,
                        page: 1,
                        search: '',
                    },
                };
            });
        }
    }

    formatTable = (data) => {
        const newUserList = data.map((item) => {
            return {
                id: item.user_id,
                user: item.name,
                peran: item.role_name,
                email: item.email,
                telp: item.phone,
                lastUpdate: formatDisplayDate(item.created_at),
                // action: {
                //     name: 'delete',
                //     onHandleClick: (userId) => this.handleDeleteAction(userId),
                // },
                action:(
                  <div style={{alignItems: "center", justifyItems: "center", textAlign: 'center'}}>
                      <div>
                          <Button
                            className="action_deleteUser"
                            title={<DeleteSvg />}
                            onClick={() => this.handleDeleteAction(item?.user_id)}
                          />
                          <Button
                            style={{marginLeft: 20, paddingTop: 10, paddingBottom: 10}}
                            className="action_editUser"
                            title={<EditSvg />}
                            onClick={() => this.props.history.push({
                                key: 'edit-user',
                                pathname: `/edit-user`,
                                state: {type: 'Edit', data: item}
                            })}
                          />
                      </div>
                  </div>
                ),
            };
        });

        return newUserList;
    };

    handleDeleteAction = async (userId) => {
        console.log('userId', userId)
        this.setState({
            alert: {
                ...alert,
                show: true,
                message: 'Apa anda yakin ingin menghapus user ini ?',
                noImg: true,
                buttonText: 'Hapus',
                buttonText2: 'Batal',
                onBtnPress: () => this.onDeleteUser(userId),
            },
        });
    };

    onResetData = async () => {
        await this.setState((prevState) => ({
            tableConfig: {
                ...prevState.tableConfig,
                page: 1,
                limit: 10,
                search: '',
            },
        }));
        this.setState({
            alert: { ...alert, show: false },
        });
    };

    onDeleteUser = async (userId) => {
        this.setState({
            alert: {
                ...alert,
                show: false,
            },
            loading: true,
        });

        let response = await this.props.onDeleteUser(userId);

        this.setState({
            loading: false,
            alert: {
                ...alert,
                show: true,
                message: response.message,
                onBackPress: () => this.onResetData(),
                onBtnPress: () => this.onResetData(),
            },
        });
    };

    onGetUserList = async (page, limit, search, sort, roleId) => {
        // this.setState({loading: true});
        try {
            await this.props.onGetListUser(page, limit, search, sort, roleId);
            await this.props.onGetListRole();
            this.setState({ loading: false, alert: false });
        } catch (error) {
            this.setState({ loading: false });
            this.setState({
                alert: {
                    ...alert,
                    show: true,
                    message: error.message,
                    isError: true,
                },
            });
        }
    };

    onChangeTable = (identifier, value) => {
        this.setState((prevState) => {
            let page =
                identifier === 'page' ? value : prevState.tableConfig.page;
            page = identifier === 'search' ? 1 : page;
            return {
                ...prevState,
                tableConfig: {
                    ...prevState.tableConfig,
                    [identifier]: value,
                    page: page,
                },
            };
        });
    };

    formatDropdownRole = (data) => {
        let listRole = [{ value: 'all', name: 'Peran: Semua' }];
        if (!data) {
            return listRole;
        }
        const roles = data.map((role) => {
            return {
                value: role.role_id,
                name: `Peran: ${role.name}`,
            };
        });
        return [...listRole, ...roles];
    };

    render() {
        const columns = [
            {
                name: 'Nama User',
                style: { width: 250 },
            },
            {
                name: 'Peran',
                style: { width: 200 },
            },
            {
                name: 'Email',
                style: { width: 350 },
            },
            {
                name: 'Telepon',
                style: { width: 150 },
            },
            {
                name: 'Terakhir Diperbarui',
                style: { width: 200 },
            },
            {
                name: 'Action',
                style: { width: 50 },
            },
        ];

        return (
            <div className='col-12'>
                <Loader show={this.state.loading} />
                <Alert
                    show={this.state.alert.show}
                    isError={this.state.alert.isError}
                    noImg={this.state.alert.noImg}
                    message={this.state.alert.message}
                    buttonText={this.state.alert.buttonText}
                    buttonText2={this.state.alert.buttonText2}
                    onBtnPress={this.state.alert.onBtnPress}
                    onBackPress={this.state.alert.onBackPress}
                    modalClosed={() =>
                        this.setState({
                            alert: { ...alert, show: false },
                        })
                    }
                />
                <div className='row'>
                    <div className='col-12'>
                        <Table
                            columns={columns}
                            data={this.formatTable(this.props.userData.data)}
                            useHeader
                            useFooter
                            rightHeaderComponent={
                                <div style={{ display: 'flex' }}>
                                    <Dropdown
                                        style={{ width: '210px' }}
                                        value={this.state.role}
                                        // options={[{ value: 'all', name: 'Peran: Semua'}]}
                                        options={this.formatDropdownRole(
                                            this.props.roleData.data
                                        )}
                                        onChange={(value) =>
                                            this.setState({ role: value })
                                        }
                                    />
                                    <Button
                                        className='action_addUser'
                                        style={{
                                            padding: '12px 20px 12px 20px',
                                            marginLeft: '10px',
                                        }}
                                        title='Tambah User'
                                        onClick={() =>
                                            this.props.history.push('/add-user')
                                        }
                                    />
                                </div>
                            }
                            tableConfig={this.state.tableConfig}
                            onChange={this.onChangeTable}
                            meta={this.props.userData.meta}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userData: state.user.user,
        roleData: state.user.role,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetListUser: (page, limit, keyword, sort, roleId) =>
            dispatch(getUserList(page, limit, keyword, sort, roleId)),
        onGetListRole: () => dispatch(getListRole()),
        onDeleteUser: (UID) => dispatch(deleteUser(UID)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
