//config
export const CHANGE_PAGE = 'CHANGE_PAGE';
export const DEFAULT_PAGE = 'DEFAULT_PAGE';

//auth
export const AUTHENTICATE = 'AUTHENTICATE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const CHANGE_FIRST_LOGGEDIN = 'CHANGE_FIRST_LOGGEDIN';
export const LOGOUT = 'LOGOUT';

//dashboard
export const GET_DASHBOARD = 'GET_DASHBOARD';

//atm
export const GET_LIST_ATM = 'GET_LIST_ATM';
export const GET_LIST_ATM_TRANSACTIONS = 'GET_LIST_ATM_TRANSACTIONS';

//replenishment
export const GET_LIST_ALL_REPLENISHMENT = 'GET_LIST_ALL_REPLENISHMENT';
export const GET_LIST_INISIASIULANG = 'GET_LIST_INISIASIULANG';
export const GET_LIST_REKONSILIASI = 'GET_LIST_REKONSILIASI';
export const GET_LIST_SELISIH = 'GET_LIST_SELISIH';

//user
export const GET_LIST_AUDIT = 'GET_LIST_AUDIT';
export const GET_LIST_USER = 'GET_LIST_USER';
export const GET_LIST_ROLE = 'GET_LIST_ROLE';

export const GET_LIST_BRANCH = 'GET_LIST_BRANCH';
export const GET_ALL_BRANCH = 'GET_ALL_BRANCH';
export const GET_LIST_DENOMINASI = 'GET_LIST_DENOMINASI';
export const GET_LIST_BRANCH_TREE = 'GET_LIST_BRANCH_TREE';


