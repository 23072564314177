import React, { Component } from 'react';
import { connect } from 'react-redux';
import './informasi-akun.css';
import TextInput from '../../../components/textInput/TextInput';
import Button from '../../../components/button/Button';
import Card from '../../../components/card/Card';
import Loader from '../../../components/loader/Loader';
import Alert from '../../../components/alert/Alert';
import validationInput from '../../../helpers/validationInput';
import { changePassword, changeProfile } from '../../../redux/actions';


class InformasiAkun extends Component {

    state = {
        name: '',
        email: '',
        phone: '',
        oldPassword: {
            value: '',
            label: 'Kata Sandi Lama',
            valid: false,
            touched: false,
            isShow: false,
            error: '',
            validation: {
                isRequired: true
            }
        },
        newPassword: {
            value: '',
            label: 'Kata Sandi Baru',
            valid: false,
            touched: false,
            isShow: false,
            error: '',
            validation: {
                isRequired: true,
                minLength: 8,
                upperCaseRequired: true,
                lowerCaseRequired: true,
                numberRequired: true,
                symbolRequired: true
            }
        },
        confirmNewPassword: {
            value: '',
            label: 'Konfirmasi Kata Sandi Baru',
            valid: false,
            touched: false,
            isShow: false,
            error: '',
            validation: {
                isRequired: true,
                equalTo: true
            }
        },
        loading: false,
        alert: {
            show: false,
            message: '',
            isError: false
        }
    }

    componentDidMount(){
        this.setState({
            name: this.props.profile.name,
            email: this.props.profile.email,
            phone: this.props.profile.phone,
        })
    }

    onChangeProfile = async () => {
        this.setState({loading: true});
        try{
            const response = await this.props.onChangeProfile(
                this.state.name, 
                this.state.email, 
                this.state.phone
            );
            this.setState({
                loading: false,
                alert: {
                    ...alert,
                    show: true,
                    message: response.message,
                    isError: false
                }
            })
        }catch(error){
            this.setState({
                name: this.props.profile.name,
                email: this.props.profile.email,
                phone: this.props.profile.phone,
                loading: false,
                alert: {
                    ...alert,
                    show: true,
                    message: error.message,
                    isError: true
                }
            })
        }
    }

    onChangePassword = async () => {
        this.setState({loading: true});
        try{
            const response = await this.props.onChangePassword(
                this.state.oldPassword.value, 
                this.state.newPassword.value, 
                this.state.confirmNewPassword.value
            );
            this.setState({
                oldPassword: {
                    value: '',
                    label: 'Kata Sandi Lama',
                    valid: false,
                    touched: false,
                    isShow: false,
                    error: '',
                    validation: {
                        isRequired: true
                    }
                },
                newPassword: {
                    value: '',
                    label: 'Kata Sandi Baru',
                    valid: false,
                    touched: false,
                    isShow: false,
                    error: '',
                    validation: {
                        isRequired: true,
                        minLength: 8,
                        upperCaseRequired: true,
                        lowerCaseRequired: true,
                        numberRequired: true,
                        symbolRequired: true
                    }
                },
                confirmNewPassword: {
                    value: '',
                    label: 'Konfirmasi Kata Sandi Baru',
                    valid: false,
                    touched: false,
                    isShow: false,
                    error: '',
                    validation: {
                        isRequired: true,
                        equalTo: true
                    }
                },
                loading: false,
                alert: {
                    ...alert,
                    show: true,
                    message: response.message,
                    isError: false
                }
            })
        }catch(error){
            this.setState({
                loading: false,
                alert: {
                    ...alert,
                    show: true,
                    message: error.message,
                    isError: true
                }
            })
        }
    }

    onChangeInput = (value, identifier, equalObject, targetEqualId) => {
        const validation = validationInput(value, this.state[identifier].validation, equalObject);
        this.setState({
            [identifier]: {
                ...this.state[identifier],
                valid: validation.valid,
                touched: true,
                error: validation.messageError,
                value: value
            }
        })
        if(targetEqualId && this.state[targetEqualId].touched){
            let object = {
                ...this.state[identifier],
                value: value
            }
            const validationTargetEqual = validationInput(
                this.state[targetEqualId].value, 
                this.state[targetEqualId].validation, 
                object
            );
            this.setState({
                [targetEqualId]: {
                    ...this.state[targetEqualId],
                    valid: validationTargetEqual.valid,
                    error: validationTargetEqual.messageError
                }
            })
        }
    }

    render(){
        return (
            <div className="col-12" style={{marginBottom: 20}}>
                <Loader show={this.state.loading}/>
                <Alert 
                    show={this.state.alert.show}
                    message={this.state.alert.message}
                    isError={this.state.alert.isError}
                    modalClosed={() => this.setState({alert: {...alert, show: false}})}
                />
                <div className="row">
                    <div className="col-8 col-sm-12">
                        <Card>
                            <div className="col-12">
                                <h4 style={{marginBottom: 20, fontSize: '18px', fontFamily: 'Inter'}}>Informasi Akun</h4>
                                <TextInput 
                                    className="input_nama"
                                    label="Nama Lengkap"
                                    value={this.state.name}
                                    onChange={(e) => this.setState({name: e.target.value})}
                                    style={{fontSize: '16px', paddingTop: '2px', paddingBottom: '2px'}}
                                />
                                {/* <div style={{border: '1px solid #E4E7EB', borderRadius: 6}} /> */}
                                <div className="row" style={{marginTop: 20}}>
                                    <div className="col-6">
                                        <TextInput 
                                            className="input_email"
                                            label="Email"
                                            readOnly
                                            value={this.state.email}
                                            style={{fontSize: '16px', paddingTop: '2px', paddingBottom: '2px'}}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <TextInput 
                                            className="input_noHp"
                                            label="Nomor Telepon"
                                            type="number"
                                            value={this.state.phone}
                                            onChange={(e) => this.setState({phone: e.target.value})}
                                            style={{fontSize: '16px', paddingTop: '2px', paddingBottom: '2px'}}
                                        />
                                    </div>
                                    <div className="col-6" style={{marginTop: 10, marginBottom: 20}}>
                                        <Button 
                                            className="btn_simpanInformasiAkun"
                                            title="Simpan Perubahan"
                                            onClick={this.onChangeProfile}
                                        />
                                    </div>
                                    
                                </div>
                                <div style={{borderBottom: '1px solid #E4E7EB'}}/>
                            </div>
                            <div className="col-12">
                                <h4 style={{marginBottom: 20, marginTop: 20, fontSize: '18px', fontFamily: 'Inter'}}>Ubah Kata Sandi</h4>
                                <TextInput 
                                    className="input_oldPassword"
                                    label={this.state.oldPassword.label}
                                    placeholder="Kata Sandi Lama"
                                    type={this.state.oldPassword.isShow? "default" : "password"}
                                    value={this.state.oldPassword.value}
                                    valid={this.state.oldPassword.valid}
                                    touched={this.state.oldPassword.touched}
                                    messageerror={this.state.oldPassword.error}
                                    onChange={(e) => this.onChangeInput(e.target.value, 'oldPassword', null, null)}
                                    style={{fontSize: '16px', paddingTop: '2px', paddingBottom: '2px'}}
                                    righticon={(
                                        <div 
                                            className="textinput-hide-show-btn"
                                            onClick={() => this.setState({oldPassword: { 
                                                ...this.state.oldPassword, 
                                                isShow: !this.state.oldPassword.isShow
                                            }})}>
                                            {this.state.oldPassword.isShow? 'hide' : 'show'}
                                        </div>
                                    )}
                                />
                                <div className="row" style={{marginTop: 20}}>
                                    <div className="col-6">
                                        <TextInput 
                                            className="input_newPassword"
                                            label={this.state.newPassword.label}
                                            placeholder="Kata Sandi Baru"
                                            type={this.state.newPassword.isShow? "default" : "password"}
                                            value={this.state.newPassword.value}
                                            valid={this.state.newPassword.valid}
                                            touched={this.state.newPassword.touched}
                                            messageerror={this.state.newPassword.error}
                                            onChange={(e) => this.onChangeInput(e.target.value, 'newPassword', null, 'confirmNewPassword')}
                                            style={{fontSize: '16px', paddingTop: '2px', paddingBottom: '2px'}}
                                            righticon={(
                                                <div 
                                                    className="textinput-hide-show-btn"
                                                    onClick={() => this.setState({newPassword: { 
                                                        ...this.state.newPassword, 
                                                        isShow: !this.state.newPassword.isShow
                                                    }})}>
                                                    {this.state.newPassword.isShow? 'hide' : 'show'}
                                                </div>
                                            )}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <TextInput 
                                            className="input_konfirmasiNewPassword"
                                            label={this.state.confirmNewPassword.label}
                                            placeholder="Konfirmasi Kata Sandi Baru"
                                            type={this.state.confirmNewPassword.isShow? "default" : "password"}
                                            value={this.state.confirmNewPassword.value}
                                            valid={this.state.confirmNewPassword.valid}
                                            touched={this.state.confirmNewPassword.touched}
                                            messageerror={this.state.confirmNewPassword.error}
                                            onChange={(e) => this.onChangeInput(e.target.value, 'confirmNewPassword', this.state.newPassword, null)}
                                            style={{fontSize: '16px', paddingTop: '2px', paddingBottom: '2px'}}
                                            righticon={(
                                                <div 
                                                    className="textinput-hide-show-btn"
                                                    onClick={() => this.setState({confirmNewPassword: { 
                                                        ...this.state.confirmNewPassword, 
                                                        isShow: !this.state.confirmNewPassword.isShow
                                                    }})}>
                                                    {this.state.confirmNewPassword.isShow? 'hide' : 'show'}
                                                </div>
                                            )}
                                        />
                                    </div>
                                    <div className="col-6" style={{marginTop: 10, marginBottom: 10}}>
                                        <Button 
                                            disabled={!this.state.oldPassword.valid || !this.state.newPassword.valid 
                                                || !this.state.confirmNewPassword.valid}
                                            className="btn_simpanUbahKataSandi"
                                            title="Ubah Kata Sandi"
                                            onClick={this.onChangePassword}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onChangeProfile: (name, email, phone) => dispatch(changeProfile(name, email, phone)),
        onChangePassword: (oldPassword, password, confirmPassword) => dispatch(changePassword(oldPassword, password, confirmPassword))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InformasiAkun);