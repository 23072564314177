import React, { Component } from 'react';
import './detail-rekonsiliasi.css';
import Card from '../../../components/card/Card';
import StatusLabel from '../../../components/statuslabel/StatusLabel';
import Modal from '../../../components/modal/Modal';
import { connect } from 'react-redux';
import CatridgeRekonsiliasi from '../../../components/catridge/CatridgeRekonsiliasi';
import Loader from '../../../components/loader/Loader';
import {
  detailReplenishment,
  approveReconsiliasi,
  markDoneReplenishment,
} from '../../../redux/actions';
import denominasiList from '../../../assets/JsonData/denominasi-list.json';
import {
  formatUTCDisplayDate,
  formatRupiah,
  getFilenameFromUrl,
} from '../../../helpers/functionFormat';
import CatridgeModal from '../../../components/catridge/CatridgeModal';
import { tableFunction } from '../../../helpers/functionEJ';
import Button from '../../../components/button/Button';
import Alert from '../../../components/alert/Alert';

class DetailRekonsiliasi extends Component {
  state = {
    id: '',
    atm: {
      id: '',
      name: '',
      key: '',
      label: '',
      location: '',
    },
    userResponsible: {
      id: '',
      name: '-',
    },
    userChecked: {
      id: '',
      name: '-',
    },
    tglInisiasi: new Date(),
    tglLapor: new Date(),
    saldoAwal: '0',
    saldoIsi: '0',
    saldoEJ: '0',
    saldoAkhir: '0',
    selisih: '0',
    catridges: [],
    total: '0',
    status: '',
    catatan: '-',
    fotoBukti: null,
    fotoKerja: null,

    loading: false,
    alert: {
      show: false,
      message: '',
      isError: false,
      onBtnPress: null,
      onBackPress: null,
    },
    showModal: false,
    modal: {
      show: false,
      useHeader: false,
      title: null,
      body: null,
    },
  };

  componentDidMount() {
    this.loadDetailReplenishment(this.props.match.params.id);
  }

  loadDetailReplenishment = async (id, status) => {
    this.setState({ loading: true });
    try {
      let deno = {};
      for (let i = 0; i < denominasiList.length; i++) {
        deno = {
          ...deno,
          [denominasiList[i].denominator_id]: {
            ...denominasiList[i],
          },
        };
      }
      const response = await this.props.onGetReplenishment(id);
      const responseCatridges = Array.isArray(
        response.data.replenishment_cartridges
      )
        ? response.data.replenishment_cartridges
        : [];

      let catridge = {};
      for (let i = 0; i < responseCatridges.length; i++) {
        let denoList = [];
        for (
          let d = 0;
          d < responseCatridges[i]?.replenishment_cartridge_details?.length;
          d++
        ) {
          denoList.push({
            ...responseCatridges[i]?.replenishment_cartridge_details[d],
            denoValue:
              deno[
                responseCatridges[i]?.replenishment_cartridge_details[d]
                  .denominator_id
              ].value,
          });
        }

        catridge = {
          ...catridge,
          [responseCatridges[i]?.serial_number
            ? responseCatridges[i]?.serial_number
            : '0']: {
            ...catridge[
              responseCatridges[i]?.serial_number
                ? responseCatridges[i]?.serial_number
                : '0'
            ],
            [responseCatridges[i].type]: {
              ...responseCatridges[i],
              replenishment_cartridge_details: denoList,
            },
          },
        };
      }

      let catridges = Object.keys(catridge).map((item) => {
        return catridge[item];
      });

      this.setState({
        id: response.data.replenishment_id,
        atm: {
          id: response.data.atm.atm_id,
          name: response.data.atm.name,
          key: response.data.atm.atm_key,
          label: response.data.atm.atm_label,
          location: response.data.atm.location,
        },
        userResponsible: {
          id: response.data.atm.updated_by,
          name: response.data.pic.name,
        },
        userChecked: {
          id: response.data.pic.created_by,
          name: response.data.pic.name,
        },
        tglInisiasi: response.data.replenishment_datetime,
        tglLapor: response.data.updated_at,
        saldoAwal: response.data.beginning_balance,
        saldoIsi: response.data.replenishment_balance,
        saldoEJ: response.data.ej_balance,
        saldoAkhir: response.data.return_balance,
        selisih: response.data.difference_balance,
        catridges: catridges,
        status: status ? status : response.data.status,
        catatan: response.data.notes,
        fotoBukti: response.data.bukti_foto_layar_image_url,
        fotoKerja: response.data.surat_kerja_image_url,
        loading: false,
      });
    } catch (error) {
      this.setState({
        loading: false,
        alert: {
          ...alert,
          show: true,
          message: error.message,
          isError: true,
        },
      });
    }
  };

  onApprove = async () => {
    this.setState({ loading: true });
    try {
      await this.props.onApproveReconsiliasi(this.state.id);
      this.setState({
        loading: false,
        alert: {
          ...alert,
          show: true,
          message: 'Sukses',
          isError: false,
          onBtnPress: () => {
            this.setState({ alert: { ...this.state.alert, show: false } });
            this.loadDetailReplenishment(this.state.id);
          },
          onBackPress: () => {
            this.setState({ alert: { ...this.state.alert, show: false } });
            this.loadDetailReplenishment(this.state.id);
          },
        },
      });
    } catch (error) {
      this.setState({
        loading: false,
        alert: {
          ...alert,
          show: true,
          message: error.message,
          isError: true,
        },
      });
    }
  };

  onMarkDone = async () => {
    this.setState({ loading: true });
    try {
      await this.props.onMarkDoneReplenishment(this.state.id);
      this.setState({
        loading: false,
        alert: {
          ...alert,
          show: true,
          message: 'response.message',
          isError: false,
          onBtnPress: () => {
            this.setState({ alert: { ...this.state.alert, show: false } });
            this.loadDetailReplenishment(this.state.id);
          },
          onBackPress: () => {
            this.setState({ alert: { ...this.state.alert, show: false } });
            this.loadDetailReplenishment(this.state.id);
          },
        },
      });
    } catch (error) {
      this.setState({
        loading: false,
        alert: {
          ...alert,
          show: true,
          message: error.message,
          isError: true,
        },
      });
    }
  };

  openModalRingkasan = () => {
    const body = (
      <div className='row'>
        <div className='col-12'>
          {this.state.catridges.map((catridge, idx) => (
            <CatridgeModal key={idx.toString()} item={catridge['IN']} />
          ))}
        </div>
      </div>
    );

    this.setState({
      modal: {
        ...this.state.modal,
        show: true,
        useHeader: true,
        title: 'Rincian Inisiasi Isi Ulang',
        body: body,
      },
    });
  };

  openModalPhoto = (altName, image) => {
    const body = (
      <img
        alt={altName}
        style={{ width: '100%', height: '100%' }}
        src={
          image ? image : require('../../../assets/images/example-photo.png')
        }
      />
    );
    this.setState({
      modal: {
        ...this.state.modal,
        show: true,
        useHeader: false,
        title: null,
        body: body,
      },
    });
  };

  render() {
    return (
      <div>
        <Loader show={this.state.loading} />
        <Alert
          show={this.state.alert.show}
          message={this.state.alert.message}
          isError={this.state.alert.isError}
          onBtnPress={this.state.alert.onBtnPress}
          onBackPress={this.state.alert.onBackPress}
          modalClosed={() =>
            this.setState({ alert: { ...alert, show: false } })
          }
        />
        <Modal
          useHeader={this.state.modal.useHeader}
          show={this.state.modal.show}
          modalClosed={() =>
            this.setState((prevState) => {
              return { ...prevState.modal, modal: false };
            })
          }
          title={this.state.modal.title}
          body={this.state.modal.body}
        />
        <h2 className='page-header header-detail-rekonsiliasi'>
          Detail Rekonsiliasi
        </h2>
        <div className='row container-detail-rekonsiliasi'>
          <div className='col-8 col-sm-12'>
            <Card className='card-detail-rekonsiliasi'>
              <div className='row info-detail-rekonsiliasi'>
                <div className='col-4 col-md-12'>
                  <label className='label-detail-rekonsiliasi'>
                    ID Replenishment
                  </label>
                </div>
                <div className='col-8 col-md-12 col-sm-12'>
                  <label className='value-detail-rekonsiliasi'>
                    {this.state.id}
                  </label>
                </div>
              </div>
              <div className='row info-detail-rekonsiliasi'>
                <div className='col-4 col-md-12 col-sm-12'>
                  <label className='label-detail-rekonsiliasi'>
                    Tgl. Inisiasi
                  </label>
                </div>
                <div className='col-8 col-md-12 col-sm-12'>
                  <label className='value-detail-rekonsiliasi'>
                    {formatUTCDisplayDate(this.state.tglInisiasi)}
                  </label>
                </div>
              </div>
              <div className='row info-detail-rekonsiliasi'>
                <div className='col-4 col-md-12 col-sm-12'>
                  <label className='label-detail-rekonsiliasi'>
                    Penanggung Jawab
                  </label>
                </div>
                <div className='col-8 col-md-12 col-sm-12'>
                  <label className='value-detail-rekonsiliasi'>
                    {this.state.userResponsible.name}
                  </label>
                </div>
              </div>
              <div className='row info-detail-rekonsiliasi'>
                <div className='col-4 col-md-12 col-sm-12'>
                  <label className='label-detail-rekonsiliasi'>ATM</label>
                </div>
                <div className='col-8 col-md-12 col-sm-12'>
                  <label className='value-detail-rekonsiliasi'>
                    {this.state.atm.name}
                  </label>
                </div>
              </div>
              {this.state.atm.key && (
                <div className='row info-detail-rekonsiliasi'>
                  <div className='col-4 col-md-12 col-sm-12'>
                    <label className='label-detail-rekonsiliasi'>
                      IP Address ATM
                    </label>
                  </div>
                  <div className='col-8 col-md-12 col-sm-12'>
                    <label className='value-detail-rekonsiliasi'>
                      {this.state.atm.key}
                    </label>
                  </div>
                </div>
              )}
              <div className='hr-detail-rekonsiliasi' />
              <div className='row info-detail-rekonsiliasi'>
                <div className='col-4 col-md-12 col-sm-12'>
                  <label className='label-detail-rekonsiliasi'>
                    Saldo Awal
                  </label>
                </div>
                <div className='col-8 col-md-12 col-sm-12'>
                  <label className='value-detail-rekonsiliasi'>
                    {formatRupiah(this.state.saldoAwal, '.')}
                  </label>
                </div>
              </div>
              <div className='row info-detail-rekonsiliasi'>
                <div className='col-4 col-md-12 col-sm-12'>
                  <label className='label-detail-rekonsiliasi'>
                    Saldo Isi Ulang
                  </label>
                </div>
                <div className='col-8 col-md-12 col-sm-12'>
                  <label className='value-detail-rekonsiliasi'>
                    {formatRupiah(this.state.saldoIsi, '.')}
                  </label>
                  <label
                    onClick={this.openModalRingkasan}
                    className='value-detail-rekonsiliasi a-label-detail-rekonsiliasi'
                  >
                    Lihat Ringkasan
                  </label>
                </div>
              </div>
              <div className='row info-detail-rekonsiliasi'>
                <div className='col-4 col-md-12 col-sm-12'>
                  <label className='label-detail-rekonsiliasi'>
                    Saldo Jurnal Elektronik
                  </label>
                </div>
                <div className='col-8 col-md-12 col-sm-12'>
                  <label className='value-detail-rekonsiliasi'>
                    {formatRupiah(this.state.saldoEJ, '.')}
                  </label>
                </div>
              </div>
              <div className='row info-detail-rekonsiliasi'>
                <div className='col-4 col-md-12 col-sm-12'>
                  <label className='label-detail-rekonsiliasi'>
                    Saldo Akhir Cek Fisik
                  </label>
                </div>
                <div className='col-8 col-md-12 col-sm-12'>
                  <label className='value-detail-rekonsiliasi'>
                    {formatRupiah(this.state.saldoAkhir, '.')}
                  </label>
                </div>
              </div>
              <div className='row info-detail-rekonsiliasi'>
                <div className='col-4 col-md-12 col-sm-12'>
                  <label className='label-detail-rekonsiliasi'>Selisih</label>
                </div>
                <div className='col-8 col-md-12 col-sm-12'>
                  <label className='value-detail-rekonsiliasi text-red-detail-rekonsiliasi'>
                    {formatRupiah(
                      this.state.selisih ? this.state.selisih : 0,
                      '.'
                    )}
                  </label>
                </div>
              </div>
            </Card>
          </div>
          <div className='col-4 col-sm-12'>
            <Card className='card-detail-rekonsiliasi'>
              <div className='info-right-detail-rekonsiliasi'>
                <div className='label-detail-rekonsiliasi'>
                  Status Rekonsiliasi
                </div>
                {this.state.status ? (
                  tableFunction.checkingStatus(this.state.status)
                ) : (
                  <StatusLabel type='process' label='Diperiksa' />
                )}
              </div>
              <div className='info-right-detail-rekonsiliasi'>
                <div className='label-detail-rekonsiliasi'>Tanggal Lapor</div>
                <div className='value-detail-rekonsiliasi'>
                  {formatUTCDisplayDate(this.state.tglLapor)}
                </div>
              </div>
              <div className='info-right-detail-rekonsiliasi'>
                <div className='label-detail-rekonsiliasi'>Diperiksa Oleh</div>
                <div className='value-detail-rekonsiliasi'>
                  {this.state.userChecked.name}
                </div>
              </div>
            </Card>
          </div>
        </div>

        <div className='row container-detail-rekonsiliasi'>
          <div className='col-12'>
            <Card className='card-detail-rekonsiliasi'>
              {this?.state?.catridges.map((item, idx) => (
                <CatridgeRekonsiliasi
                  key={idx.toString()}
                  item={item}
                  index={idx + 1}
                  isLast={
                    this.state?.catridges && this?.state?.catridges?.length
                      ? this?.state?.catridges?.length
                      : 0 === idx + 1
                  }
                />
              ))}
            </Card>
          </div>
        </div>
        <div className='row container-detail-rekonsiliasi'>
          <div className='col-12'>
            <Card className='card-detail-rekonsiliasi'>
              <h4 className='header-detail-rekonsiliasi'>Dokumen</h4>

              <div className='row' style={{ marginBottom: 10 }}>
                <div className='col-3 col-sm-12'>
                  <label className='label-detail-rekonsiliasi'>
                    Bukti Foto Layar ATM
                  </label>
                </div>
                <div className='col-9 col-sm-12'>
                  <label className='value-detail-rekonsiliasi'>
                    {this.state.fotoBukti
                      ? getFilenameFromUrl(this.state.fotoBukti)
                      : 'Kosong'}
                  </label>
                  <label
                    className='value-detail-rekonsiliasi a-label-detail-rekonsiliasi'
                    onClick={() =>
                      this.openModalPhoto(
                        'bukti_layar_atm',
                        this.state.fotoBukti
                      )
                    }
                  >
                    Lihat
                  </label>
                </div>
              </div>
              <div className='row' style={{ marginBottom: 10 }}>
                <div className='col-3 col-sm-12'>
                  <label className='label-detail-rekonsiliasi'>
                    Foto Surat Kerja
                  </label>
                </div>
                <div className='col-9 col-sm-12'>
                  <label className='value-detail-rekonsiliasi'>
                    {this.state.fotoKerja
                      ? getFilenameFromUrl(this.state.fotoKerja)
                      : 'Kosong'}
                  </label>
                  <label
                    className='value-detail-rekonsiliasi a-label-detail-rekonsiliasi'
                    onClick={() =>
                      this.openModalPhoto(
                        'foto_surat_kerja',
                        this.state.fotoKerja
                      )
                    }
                  >
                    Lihat
                  </label>
                </div>
              </div>
            </Card>
          </div>
        </div>
        <div className='row container-detail-rekonsiliasi'>
          <div className='col-12'>
            <Card className='card-detail-rekonsiliasi'>
              <div className='row'>
                <div className='col-12'>
                  <div className='label-detail-rekonsiliasi'>Catatan</div>
                </div>
                <div className='col-12'>
                  <div className='value-detail-rekonsiliasi'>
                    {this.state.catatan ? this.state.catatan : '-'}
                  </div>
                </div>
              </div>
            </Card>
            {this.props.authUser.profile.roleId === 1 &&
              this.state.status === 'REPLENISHMENT_ST_03' && (
                <div className='row container-detail-replenishment'>
                  <div className='col-12' style={{ alignItems: 'flex-end' }}>
                    <div
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <Button
                        className='submit'
                        title='Setuju'
                        onClick={this.onApprove}
                      />
                    </div>
                  </div>
                </div>
              )}
            {this.props.authUser.profile.roleId === 1 &&
              this.state.status === 'REPLENISHMENT_ST_06' && (
                <div className='row container-detail-replenishment'>
                  <div className='col-12' style={{ alignItems: 'flex-end' }}>
                    <div
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <Button
                        className='submit'
                        title='Mark As Done'
                        onClick={this.onMarkDone}
                      />
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authUser: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetReplenishment: (id) => dispatch(detailReplenishment(id)),
    onApproveReconsiliasi: (id) => dispatch(approveReconsiliasi(id)),
    onMarkDoneReplenishment: (id) => dispatch(markDoneReplenishment(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailRekonsiliasi);
