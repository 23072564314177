import React, { Component } from 'react';
import TextInput from '../../../components/textInput/TextInput';
import Button from '../../../components/button/Button';
import Card from '../../../components/card/Card';

class Sistem extends Component {
    render(){
        return (
            <div className="col-12" style={{marginBottom: 20}}>
                <div className="row">
                    <div className="col-8 col-sm-12">
                        <Card>
                            <div className="col-12">
                                <h4 style={{marginBottom: 20, fontSize: '18px', fontFamily: 'Inter'}}>Sistem</h4>
                                <TextInput 
                                    className="input_waktuSinkronisasi "
                                    label="Waktu Sinkronasi"
                                    placeholder="Set waktu sinkronasi"
                                    style={{fontSize: '16px', paddingTop: '2px', paddingBottom: '2px'}}
                                />
                                <Button 
                                    className="btn_simpanPerubahanWaktu"
                                    style={{marginTop: 10}}
                                    title="Simpan Perubahan"
                                />
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        )
    }
}

export default Sistem;