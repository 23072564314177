import React from 'react';
import './warning.css';
import Button from '../button/Button';

const Warning = (props) => {
  const {
    show,
    modalClosed,
    message1,
    message2,
    buttonText,
    buttonText2,
    onBtnPress,
    onBackPress,
  } = props;

  const handleOnBackPress = (event) => {
    event.preventDefault();
    modalClosed();
  };

  return (
    <div
      onClick={onBackPress ? onBackPress : handleOnBackPress}
      className={`alert-container ${show ? 'active' : ''}`}
    >
      <div onClick={(e) => e.stopPropagation()} className='alert-content'>
          <img
            className='alert-image'
            alt='Alert Info'
            src={require('../../assets/images/question-image.png')}
          />
        <div className='alert-message'>{message1}</div>
        <div className='alert-message'>{message2}</div>
        <div className='alert-button'>
          {onBtnPress && modalClosed && buttonText2 && (
            <Button
              className='btn-default'
              title={buttonText2 ? buttonText2 : 'Cancel'}
              onClick={modalClosed}
            />
          )}
          <Button
            title={buttonText ? buttonText : 'OK'}
            onClick={onBtnPress ? onBtnPress : modalClosed}
          />
        </div>
      </div>
    </div>
  );
};

export default Warning;
