import * as actionTypes from "../actionTypes";

const initialState = {
  branch: [],
  branchAll: [],
  branchTree: [],
  meta: {
    pagination: 1,
    total: 0,
    limit: 10,
    page: 1
  }
}

const branchReducer = (state = initialState, action) => {
  switch(action.type){
    case actionTypes.GET_LIST_BRANCH: {
      return {
        ...state,
        branch: action.branch,
        meta: action.meta
      }
    }
    case actionTypes.GET_ALL_BRANCH: {
      return {
        ...state,
        branchAll: action.branchAll,
      }
    }
    case actionTypes.GET_LIST_BRANCH_TREE: {
      return {
        ...state,
        branchTree: action.branchTree,
      }
    }
    default: {
      return state;
    }
  }
}

export default branchReducer;

