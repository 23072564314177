import React from 'react';
import './modal.css';
import { ReactComponent as CloseSvg } from '../../assets/icons/close.svg';

const Modal = props => {

    const { show, modalClosed, useHeader, title, body, style, className, styleHeader } = props;

    let newClassName = className? className : "modal-content";

    const onBackPress = (event) => {
        event.preventDefault();
        modalClosed();
    }

    return (
        <div onClick={onBackPress} className={`modal ${show? 'active' : ''}`}>
            <div onClick={(e) => e.stopPropagation()} className={newClassName} style={style}>
                {useHeader && <div className="modal-header" style={styleHeader}>
                    <label className="modal-title">{title}</label>
                    <CloseSvg className="modal-close" onClick={onBackPress} />
                </div>}
                <div className="modal-body">
                    {body? body: props.children}
                </div>
            </div>
        </div>
    )
}

export default Modal;
