import { combineReducers } from 'redux';

import configReducer from './config';
import authReducer from './auth';
import dashboardReducer from './dashboard';
import atmReducer from './atm';
import replenishReducer from './replesnishment';
import userReducer from './user';
import branchReducer from './branch';
import denominasiReducer from './denominasi';

const reducers = combineReducers({
    config: configReducer,
    auth: authReducer,
    dashboard: dashboardReducer,
    atm: atmReducer,
    replenish: replenishReducer,
    user: userReducer,
    branch: branchReducer,
    denominasi: denominasiReducer
})

export default reducers;
