import * as actionTypes from '../actionTypes';

export const initialState = {
    url: process.env.REACT_APP_BASE_URL,
    pages: {
        dashboard: 'atm',
        konfigurasi: 'akun',
        master: 'user'
    }
}

const configReducer = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.CHANGE_PAGE: {
            return {
                ...state,
                pages: {
                    ...state.pages,
                    [action.key]: action.page
                }
            }
        }
        case actionTypes.DEFAULT_PAGE: {
            return {
                ...state,
                pages: {
                    ...initialState.pages
                }
            }
        }
        case actionTypes.AUTHENTICATE: {
            return {
                ...state,
                pages: {
                    ...initialState.pages
                }
            }
        }
        case actionTypes.LOGOUT: {
            return {
                ...state,
                pages: {
                    ...initialState.pages
                }
            }
        }
        default: {
            return state;
        }
    }
}

export default configReducer;