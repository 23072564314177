import React from 'react';
import './page-404.css';

const Page404 = props => {
    return (
        <div className="row container404">
            <div className="card-container404">
                <label className="text404">404</label>
                <label className="not-found-text404">Tidak Ditemukan!</label>
                <span className="description404">Oops! Maaf halaman ini tidak tersedia</span>
                <button className="btn404" onClick={() => props.history.goBack()}>Kembali</button>
            </div>
        </div>
    )
}

export default Page404;