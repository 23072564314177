import React, { Component } from 'react';
import Button from '../../../components/button/Button';
import Table from '../../../components/table/Table';
import peran from '../../../assets/JsonData/peran-list.json';

class Peran extends Component {

    _isMounted;

    state = {
        peranList: []
    }

    componentDidMount(){
        const newPeranList = peran.map(item => {
            return {
                id: item.id,
                name: item.name,
                hakAkses: item.hakAkses,
                aksi: (
                    <div style={{textAlign: 'center'}}>
                        <Button 
                            className="btn-default"
                            title="Atur"
                        />
                    </div>
                )
            }
        })

        this.setState({ peranList: newPeranList })
    }

    render(){
        const columns = [{
            name: 'Nama Peran',
            style: { width: 250 }
        }, {
            name: 'Hak Akses',
            style: { width: 800 }
        }, {
            name: 'Aksi',
            style: { width: 100, textAlign: 'center' }
        }]

        return (
            <div className="col-12">
                <div className="row" style={{display: 'flex', alignItems: 'center'}}>
                    <div className="col-6">
                        <h4>Peran</h4>
                    </div>
                    <div className="col-6" style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button 
                            style={{padding: '10px 28px 10px 28px'}}
                            title="Tambah Peran"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Table 
                            columns={columns}
                            data={this.state.peranList}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default Peran;