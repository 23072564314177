import React from 'react';
import './card.css';

const Card = props => {

    const { children, style, className, useHeader, headerTitle, color } = props;

    if(useHeader){
        return (
            <div className={`card-item-header`} style={style}>
                <div className={`card-header card-header-${color}`}>
                    <label className={`card-header-title card-header-title-${color}`}>{headerTitle}</label>
                </div>
                <div className={`card-body ${className}`}>
                    {children}
                </div>
            </div>
        )
    }

    return (
        <div className={`card-item ${className}`} style={style}>
            {children}
        </div>
    )
}

export default Card;