import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import './login.css';
import { ReactComponent as MailSvg } from '../../../assets/icons/mail.svg';
import { ReactComponent as LockSvg } from '../../../assets/icons/lock.svg';
import { login } from '../../../redux/actions';
import Button from '../../../components/button/Button';
// import Checkbox from '../../../components/checkbox/Checkbox';
import Loader from '../../../components/loader/Loader';
import Alert from '../../../components/alert/Alert';

class Login extends Component {
  _mounted = true;

  state = {
    email: '',
    password: '',
    isShowPassword: false,
    rememberMe: false,
    loading: false,
    alert: {
      show: false,
      message: '',
      isError: false,
    },
  };

  componentWillUnmount() {
    this._mounted = false;
  }

  onSubmitHandler = async () => {
    this.setState({ loading: true });
    try {
      const profile = await this.props.onLogin(
        this.state.email,
        this.state.password,
        this.state.rememberMe
      );
      if (this._mounted) {
        this.setState({ loading: false });
      }
      if (profile.roleId === 3) {
        this.props.history.replace('/configuration');
      } else {
        this.props.history.replace('/dashboard');
      }
    } catch (error) {
      this.setState({
        loading: false,
        alert: {
          ...alert,
          show: true,
          message: error.message,
          isError: true,
        },
      });
    }
  };

  render() {
    return (
      <div className='container-login'>
        <Loader show={this.state.loading} />
        <Alert
          show={this.state.alert.show}
          isError={this.state.alert.isError}
          message={this.state.alert.message}
          modalClosed={() =>
            this.setState({ alert: { ...alert, show: false } })
          }
        />
        <div className='logo-container-login'></div>
        <div className='row col-4 col-xm-5 col-xs-6 col-md-6 col-sm-10 card-login'>
          <img
            className='logo-login'
            src={require('../../../assets/images/company-logo_2.png')}
            alt='Sample Bank'
          />
          <div className='title-login'>Login ke akun Anda</div>
          <span className='subtitle-login'>
            Selamat Datang! Silakan lengkapi data Anda
          </span>
          <div className='input-container-login'>
            <div className='icon-container-login'>
              <MailSvg />
            </div>
            <input
              className='input-text-login input_email'
              placeholder='Email'
              type='email'
              value={this.state.email}
              onChange={(e) => this.setState({ email: e.target.value })}
            />
          </div>
          <div className='input-container-login'>
            <div className='icon-container-login'>
              <LockSvg />
            </div>

            <input
              className='input-text-login input_password'
              placeholder='Password'
              type={this.state.isShowPassword ? 'default' : 'password'}
              value={this.state.password}
              onChange={(e) => this.setState({ password: e.target.value })}
            />
            <div className='container-hide-show-login'>
              <div
                className='hide-show-login'
                onClick={() =>
                  this.setState({ isShowPassword: !this.state.isShowPassword })
                }
              >
                {this.state.isShowPassword ? 'Hide' : 'Show'}
              </div>
            </div>
          </div>
          {/* <Checkbox 
                        className="checkbox_remember"
                        style={{marginBottom: '15px'}}
                        label="Ingat Saya"
                        checked={this.state.rememberMe}
                        onChange={() => this.setState(prevState =>{ return {rememberMe: !prevState.rememberMe}})}
                    /> */}
          {/* <Link to="/forgot-password" className="btn_forgotpassword">
                        <span className="forgot-password-login">Lupa Password?</span>
                    </Link> */}
          <Button
            style={{ padding: '12px 0' }}
            className='btn-login btn_masuk'
            title='Masuk'
            onClick={this.onSubmitHandler}
            disabled={!this.state.password || !this.state.email}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogin: (email, password) => dispatch(login(email, password)),
  };
};

export default connect(null, mapDispatchToProps)(Login);
