import {useCollapse} from 'react-collapsed';
import React, {useEffect} from "react";
import { ReactComponent as Up } from '../assets/icons/curve-up.svg';
import { ReactComponent as Down } from '../assets/icons/curve-down.svg';
import { ReactComponent as Trash } from '../assets/icons/trash-icon.svg';
import { ReactComponent as Pencil } from '../assets/icons/pencil-icon.svg';
import { ReactComponent as Plus } from '../assets/icons/plus.svg';
const Collapsible = ({header = 'Title', isEdit = false, child = [], openModal, key, item}) => {
  const { getCollapseProps, isExpanded, setExpanded } = useCollapse();

  useEffect(() => {
    setExpanded(false);
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="collapsible" style={{cursor: "pointer", marginBottom: 10}} key={key}>
      <div className="header">
        <div style={{flexDirection: 'row', flex: 1, display: "flex"}}>
          <div onClick={() => setExpanded(!isExpanded)}>
            {isExpanded ? <Up style={{marginRight: 10}} /> : <Down style={{marginRight: 10}} />}
          </div>
          <div onClick={() => setExpanded(!isExpanded)}>
            <span style={{marginTop: -20}}>{header}</span>
          </div>
          {isEdit && (
            <div style={{marginLeft: 25, flexDirection: "row", display: "flex", alignItems: "center"}}>
              <Plus
                style={{marginRight: 12}}
                onClick={() => {
                  openModal('Tambah', item);
                }}
                color="#9AA2B1"
              />
              <Pencil
                style={{marginRight: 12}}
                onClick={() => openModal('Edit', item)}
              />
              <Trash
                onClick={() => {
                  openModal('Delete', item);
                }}
              />
            </div>
          )}
        </div>
      </div>
      <div {...getCollapseProps()} style={{marginLeft: 30}}>
        {isExpanded && child?.length > 0 && (
          <div className="content" style={{marginBottom: 20, marginTop: 5}}>
            {child?.map((v, i) => {
              return(
              <Collapsible header={v?.name} item={v}  isEdit={isEdit} child={v?.child} openModal={openModal} />
              )
            })}
          </div>
        )}
      </div>
    </div>
  );

}

export default Collapsible;
