import React from 'react';
import './catridge-modal.css';
import Card from '../card/Card';
import { formatRupiah } from '../../helpers/functionFormat';

const CatridgeModal = props => {

    const { item } = props;

    return (
        <Card className="catridge-modal">
            <div className="row">
                <div className="col-6 col-sm-12 nomorseri-catridge-modal">
                    <div className="label-catridge-modal">Nomor Seri</div>
                    <div className="value-catridge-modal">{item?.serial_number}</div>
                </div>
                <div className="col-6 col-sm-12">
                    <div className="label-catridge-modal ">Denominasi</div>
                    {item?.replenishment_cartridge_details?.map((deno, idx) => (
                        <div className="row" key={idx.toString()}>
                            <div className="col-6">
                                <label className="value-catridge-modal">{formatRupiah(deno.denoValue, '.')} x</label>
                            </div>
                            <div className="col-6" style={{textAlign: 'right'}}>
                                <label className="label-catridge-modal ">{deno.value} Lembar</label>
                            </div>
                        </div>
                    ))}
                    <div className="hr-detail-rekonsiliasi" />
                    <div className="row">
                        <div className="col-4">
                            <label className="value-catridge-modal">Subtotal</label>
                        </div>
                        <div className="col-8" style={{textAlign: 'right'}}>
                            <label className="label-catridge-modal ">{item?.sub_total ? formatRupiah(item?.sub_total, '.') : 0}</label>
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    )
}

export default CatridgeModal;
