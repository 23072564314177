import React, { useRef } from 'react';
import './dropzone-doc.css';
import Button from '../button/Button';

const DropZoneDoc = props => {

    const { file, onChangeFile, label, labelStyle, containerStyle, className } = props;

    const inputRef = useRef();

    const dragOver = (e) => {
        e.preventDefault();
    }
    
    const dragEnter = (e) => {
        e.preventDefault();
    }
    
    const dragLeave = (e) => {
        e.preventDefault();
    }
    
    const fileDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        onChangeFile(files[0]);
    }

    return (
        <div className="dropzone-doc">
            <div className="label-dropzone" style={labelStyle}>{label}</div>
            <input 
                className={`dropzone-input ${className}`}
                type='file' 
                id='file' 
                ref={inputRef} 
                style={{display: 'none'}} 
                value={""}
                onChange={(e) => onChangeFile(e.target.files[0])}
            />
            <div
                className="container-dropzone-doc"
                style={containerStyle}
                onDragOver={dragOver}
                onDragEnter={dragEnter}
                onDragLeave={dragLeave}
                onDrop={fileDrop}
                onClick={() => inputRef.current.click()}
            >
                <Button 
                    className="btn-default"
                    title="Cari File"
                    style={{margin: '10px 20px'}}
                />
                {file && <div className="namefile-dropzone">{file.name}</div>}
            </div>
        </div>
    )
}

export default DropZoneDoc;