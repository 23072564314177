import React, { Component } from 'react';
import { connect } from 'react-redux';

import Card from '../../../components/card/Card';
import Dropdown from '../../../components/dropdown/Dropdown';
import Table from '../../../components/table/Table';
import { getListAtmTransactions } from '../../../redux/actions';
import {
  formatRupiah,
  formatUTCDisplayDate,
  formatRequestDateOnly,
} from '../../../helpers/functionFormat';
import DatePicker from '../../../components/datepicker/DatePicker';
import Loader from '../../../components/loader/Loader';

class DetailFileRekon extends Component {
  state = {
    date: new Date(),
    sourceType: { value: 'EJ', name: 'EJ' },
    atmId: null,
    atmName: null,
    // replenishmentId: null,
    transactions: [],
    loading: false,
  };

  componentDidMount() {
    if (this.props.location && this.props.location.state) {
      this.setState({
        // replenishmentId: this.props.location.state.id,
        atmId: this.props.location.state.atmId,
        atmName: this.props.location.state.atmName,
        date: new Date(),
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.date !== this.state.date ||
      prevState.sourceType.value !== this.state.sourceType.value ||
      prevState.atmId !== this.state.atmId
    ) {
      this.loadTransactions(
        this.state.atmId,
        this.state.sourceType.value,
        this.state.date
      );
    }
  }

  formatTable = (data) => {
    const newInisiasiList = data.map((item) => {
      return {
        // id: item.transaction_id,
        nomorResi: item.resi_number,
        dateTime: formatUTCDisplayDate(item.date_time),
        total: formatRupiah(item.total, '.'),
        type: item.type,
        fileId: '#' + item.transaction_id,
        lineNumber: <div style={{ textAlign: 'center' }}>16</div>,
      };
    });

    return newInisiasiList;
  };

  loadTransactions = async (id, sourceType, date) => {
    this.setState({ loading: true });
    try {
      const data = await this.props.onGetListAtmTransactions(
        id,
        sourceType,
        formatRequestDateOnly(date)
      );
      this.setState({ transactions: data ? data : [], loading: false });
    } catch (error) {
      this.setState({ loading: false });
    }
  };

  render() {
    const columns = [
      {
        name: 'Nomor Resi',
        style: { width: '18%' },
      },
      {
        name: 'Tanggal',
        style: { width: '18%' },
      },
      {
        name: 'Jumlah',
        style: { width: '20%' },
      },
      {
        name: 'Tipe Transaksi',
        style: { width: '16%' },
      },
      {
        name: 'File ID',
        style: { width: '16%' },
      },
      {
        name: 'Line Number',
        style: { width: 200, textAlign: 'center' },
      },
    ];

    return (
      <div>
        <Loader show={this.state.loading} />
        <h2 className='page-header header-detail-rekonsiliasi'>
          Detail File Rekon EJ
        </h2>
        <div className='row container-detail-rekonsiliasi'>
          <div className='col-8 col-sm-12'>
            <Card className='card-detail-rekonsiliasi'>
              {/* <div className="row info-detail-rekonsiliasi">
                                <div className="col-4 col-md-12">
                                    <label className="label-detail-rekonsiliasi">ID Replenishment</label>
                                </div>
                                <div className="col-8 col-md-12 col-sm-12">
                                    <label className="value-detail-rekonsiliasi">{this.state.replenishmentId}</label>
                                </div>
                            </div> */}
              <div className='row info-detail-rekonsiliasi'>
                <div className='col-4 col-md-12 col-sm-12'>
                  <label className='label-detail-rekonsiliasi'>ATM</label>
                </div>
                <div className='col-8 col-md-12 col-sm-12'>
                  <label className='value-detail-rekonsiliasi'>{`${this.state.atmId} - ${this.state.atmName}`}</label>
                </div>
              </div>
            </Card>
          </div>
        </div>
        <div className='row'>
          <div className='col-12' style={{ display: 'flex' }}>
            <div style={{ marginRight: 20 }}>
              <div
                className='label-detail-rekonsiliasi'
                style={{ marginBottom: 5 }}
              >
                Tipe Dokumen
              </div>
              <Dropdown
                style={{ width: '200px' }}
                styleValue={{ paddingTop: 8, paddingBottom: 8 }}
                value={this.state.sourceType}
                options={[
                  { value: 'EJ', name: 'EJ' },
                  { value: 'SWITCHING', name: 'Switching' },
                  { value: 'CORE', name: 'Core Banking' },
                ]}
                onChange={(item) => this.setState({ sourceType: item })}
              />
            </div>
            <div>
              <div
                className='label-detail-rekonsiliasi'
                style={{ marginBottom: 5 }}
              >
                Tanggal
              </div>
              <div style={{ width: '220px' }}>
                <DatePicker
                  label='Tanggal Isi Ulang'
                  date={this.state.date}
                  onchangeDate={(date) => this.setState({ date: date })}
                  customInputStyle={{ paddingTop: '8px', paddingBottom: '8px' }}
                />
              </div>
              {/* <Dropdown 
                                style={{width: '200px'}}
                                value={{ value: 'all', name: '02 Feb 2022'}}
                                options={[]}
                                onChange={(item) => {}}
                            /> */}
            </div>
          </div>
          <div className='col-12'>
            <h4
              className='page-header header-detail-rekonsiliasi'
              style={{ marginTop: 15, marginBottom: 15 }}
            >
              Daftar Transaksi
            </h4>
            <Table
              columns={columns}
              data={this.formatTable(this.state.transactions)}
              // data={this.formatTable(this.props.inisiasiData.data)}
              // tableConfig={this.state.tableConfig}
              // onChange={(identifier, value) => tableFunction.onChangeTable(this, identifier, value)}
              // meta={this.props.inisiasiData.meta}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onGetListAtmTransactions: (id, sourceType, date) =>
      dispatch(getListAtmTransactions(id, sourceType, date)),
  };
};

export default connect(null, mapDispatchToProps)(DetailFileRekon);
