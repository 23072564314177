import React from 'react';
import './loader.css';

const Loader = props => {

    let visibleClassName = props.show? "spinner-loader-enabled" : "spinner-loader-disabled"

    return (
        <div className={`spinner-loader-container ${visibleClassName}`}>
            <div className="spinner-loader" />
        </div>
    )
}

export default Loader;