import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ButtonTab from '../../../components/buttontab/ButtonTab';
import InformasiAkun from './InformasiAkun';
import Sistem from './Sistem';
import PengaturanNotifikasi from './PengaturanNotifikasi';
import Peran from './Peran';
import Audit from './Audit';
import AksesMenu from "./AksesMenu";
import { changePage, changeFirstLoggedIn } from '../../../redux/actions';

const Konfigurasi = props => {

    const dispatch = useDispatch();
    const screen = useSelector(state => state.config.pages.konfigurasi);
    const isFirstLoggedIn = useSelector(state => state.auth.isFirstLoggedIn);

    useEffect(() => {
        if(isFirstLoggedIn){
            dispatch(changeFirstLoggedIn())
        }
    }, [isFirstLoggedIn, dispatch])

    let page = <InformasiAkun {...props} />;
    switch(screen){
        case 'akun': {
            page = <InformasiAkun {...props} />;
            break;
        }
        case 'sistem': {
            page = <Sistem {...props} />;
            break;
        }
        case 'notifikasi': {
            page = <PengaturanNotifikasi {...props} />;
            break;
        }
        case 'peran': {
            page = <Peran {...props} />;
            break;
        }
        case 'audit': {
            page = <Audit {...props} />;
            break;
        }
        case 'aksesMenu': {
            page = <AksesMenu {...props} />;
            break;
        }
        default: {
            page = <InformasiAkun {...props} />;
            break;
        }
    }

    return (
        <div>
            <h2 className="page-header" style={{marginBottom: 20}}>Konfigurasi</h2>
            <div className="row">
                <div className="col-12">
                    <div style={{paddingBottom: 10, borderBottom: '1px solid #E4E7EB', marginBottom: 20}}>
                        <ButtonTab
                            className="menu_akun"
                            style={{marginRight: 10}}
                            title="Akun"
                            active={screen === 'akun'}
                            onPress={() => dispatch(changePage('konfigurasi', 'akun'))}
                        />
                        {/* <ButtonTab
                            className="menu_system"
                            style={{marginRight: 10}}
                            title="Sistem"
                            active={screen === 'sistem'}
                            onPress={() => dispatch(changePage('konfigurasi', 'sistem'))}
                        />
                        <ButtonTab
                            className="menu_pengaturanNotif"
                            style={{marginRight: 10}}
                            title="Pengaturan Notifikasi"
                            active={screen === 'notifikasi'}
                            onPress={() => dispatch(changePage('konfigurasi', 'notifikasi'))}
                        /> */}
                        {/* <ButtonTab
                            className="menu_peran"
                            style={{marginRight: 10}}
                            title="Peran"
                            active={screen === 'peran'}
                            onPress={() => dispatch(changePage('konfigurasi', 'peran'))}
                        /> */}
                        <ButtonTab
                            className="menu_audit"
                            style={{marginRight: 10}}
                            title="Audit Trail"
                            active={screen === 'audit'}
                            onPress={() => dispatch(changePage('konfigurasi', 'audit'))}
                        />
                        <ButtonTab
                          className="menu_akses"
                          style={{marginRight: 10}}
                          title="Akses Menu"
                          active={screen === 'aksesMenu'}
                          onPress={() => dispatch(changePage('konfigurasi', 'aksesMenu'))}
                        />
                    </div>
                </div>
                {page}
            </div>
        </div>
    )
}

export default Konfigurasi;
