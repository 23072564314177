import React from 'react'
import { Link } from 'react-router-dom';
import './topnav.css';
// import notifications from '../../assets/JsonData/notification.json';
import DropdownUser from '../dropdown/DropdownUser';
// import DropdownNotif from '../dropdown/DropdownNotif';
import { ReactComponent as MenuSvg } from '../../assets/icons/menu.svg';
// import { ReactComponent as BellSvg } from '../../assets/icons/bell.svg';

// const renderNotificationItem = (item, index) => (
//     <div className="notification-item" key={index}>
//         <i className={item.icon}></i>
//         <span>{item.content}</span>
//     </div>
// )

const Topnav = props => {

    const { profile } = props;

    const userMenu = [
        {
            "icon": "bx bx-user",
            "content": "Atur Akun",
            "onPress": profile.roleId === 3? "/configuration" : "/profile"
        },
        {
            "icon": "bx bx-log-out-circle bx-rotate-180",
            "content": "Logout",
            "onPress": "/logout"
        }
    ]

    const renderUserMenu = (item, index) => (
        <Link to={item.onPress} key={index}>
            <div className="menu-item">
                <span className="menu-item-name">{item.content}</span>
            </div>
        </Link>
    )
    return (
        <div className='topnav'>
            <div className="topnav__left">
                <MenuSvg 
                    onClick={props.onToggleDrawer}
                />
            </div>
            <div className="topnav__right">
                {/* <div className="topnav__right-item">
                    <DropdownNotif
                        icon={<BellSvg stroke="#687083"/>}
                        contentData={notifications}
                        renderItems={(item, index) => renderNotificationItem(item, index)}
                        renderFooter={() => <Link to='/'>View All</Link>}
                    />
                </div> */}
                <div className="topnav__right-item">
                    {/* dropdown here */}
                    <DropdownUser
                        customToggle={() => (
                            <div className="topnav__right-user">
                                <div className="topnav__right-user__image">
                                    <img src={require('../../assets/images/user.png')} style={{background: 'red'}} alt="" />
                                </div>
                                <div className="topnav_user">
                                    <div className="topnav_user_name">
                                        {profile.name}
                                    </div>
                                    <span className="topnav_user_role">{profile.role}</span>
                                </div>
                            </div>
                        )}
                        contentData={userMenu}
                        renderItems={(item, index) => renderUserMenu(item, index)}
                    />
                </div>
            </div>
        </div>
    )
}

export default Topnav
