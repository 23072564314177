import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import { CSVLink } from 'react-csv';
import './rekonsiliasi.css';

import Table from '../../../components/table/Table';
// import Dropdown from '../../../components/dropdown/Dropdown';
import Button from '../../../components/button/Button';
// import rekonsiliasi from '../../../assets/JsonData/rekonsiliasi-list.json';
// import StatusLabel from '../../../components/statuslabel/StatusLabel';
import Loader from '../../../components/loader/Loader';
import Modal from '../../../components/modal/Modal';
import DatePicker from '../../../components/datepicker/DatePicker';
import { connect } from 'react-redux';
import {
  getListRekonsiliasi,
  downloadReplenishment,
} from '../../../redux/actions';
import {
  formatRupiah,
  formatUTCDisplayDate,
  formatRequestDownloadDate,
} from '../../../helpers/functionFormat';
import { modalDateFunction, tableFunction } from '../../../helpers/functionEJ';
// import DropdownInsideTable from '../../../components/dropdown/DropdownInsideTable';

class Rekonsiliasi extends Component {
  _isMounted;

  state = {
    loading: true,
    isOpen: false,
    tableConfig: {
      search: '',
      page: 1,
      sort: 'name',
      limit: 10,
    },
    modalDate: {
      show: false,
      useHeader: false,
      title: 'Unduh Laporan',
      // body: null,
      dateRange: [null, null],
    },
    downloadData: [],
  };

  componentDidMount() {
    // this.loadRekonsiliasiList(
    //   this.state.tableConfig.page,
    //   this.state.tableConfig.limit,
    //   this.state.tableConfig.search,
    //   this.state.tableConfig.sort
    // );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.tableConfig !== this.state.tableConfig) {
      this.loadRekonsiliasiList(
        this.state.tableConfig.page,
        this.state.tableConfig.limit,
        this.state.tableConfig.search,
        this.state.tableConfig.sort
      );
    }
  }

  loadRekonsiliasiList = async (page, limit, search, sort) => {
    this.setState({loading: true});
    try {
      const {branchId} = this.props?.profile;
      await this.props.onGetListRekonsiliasi(page, limit, search, sort, null, branchId);
      this.setState({ loading: false });
    } catch (error) {
      this.setState({
        loading: false,
        alert: {
          ...alert,
          show: true,
          message: error.message,
          isError: true,
        },
      });
    }
  };

  formatTable = (data) => {
    const newRekonsiliasiList = data.map((item) => {
      return {
        id: item.replenishment_id,
        idComponent: (
          <Link to={`/rekonsiliasi/${item.replenishment_id}`}>
            <div style={{ color: '#4991F2' }}>{item.replenishment_id}</div>
          </Link>
        ),
        atm: item.atm.name,
        status: tableFunction.checkingStatus(item.status),
        tgl: formatUTCDisplayDate(item.updated_at),
        saldoAwal: formatRupiah(item.beginning_balance, '.'),
        saldoIsi: formatRupiah(item.replenishment_balance, '.'),
        saldoEJ: formatRupiah(item.ej_balance, '.'),
        saldoAkhir: formatRupiah(item.return_balance, '.'),
        selisih: (
          <div className='selisih-dashboard'>
            {formatRupiah(item.difference_balance, '.')}
          </div>
        ),
        // aksi: (
        //     <div style={{display: 'flex', alignItems: 'center', justifyContent:'center', position: 'relative'}}>
        //         <DropdownInsideTable
        //             // style={{width: '150px'}}
        //             styleValue={{paddingTop: 8, paddingBottom: 8}}
        //             value={{ value: 'Aksi', name: 'Aksi'}}
        //             options={[{
        //                 value: 'EJ',
        //                 name: 'Rekonsiliasi File',
        //                 onPress: () => this.props.history.push({
        //                     key: `rekonsiliasi-document-${item.replenishment_id}`,
        //                     pathname: `/rekonsiliasi-document/${item.replenishment_id}`,
        //                     state: {
        //                         id: item.replenishment_id,
        //                         atmId: item.atm.atm_id,
        //                         atmName: item.atm.name
        //                     }
        //                 })
        //             }, {
        //                 value: 'SWITCHING',
        //                 name: 'Daftar Transaksi',
        //                 onPress: () => this.props.history.push({
        //                     key: `detail-file-rekon-${item.replenishment_id}`,
        //                     pathname: `/detail-file-rekon/${item.replenishment_id}`,
        //                     state: {
        //                         id: item.replenishment_id,
        //                         atmId: item.atm.atm_id,
        //                         atmName: item.atm.name
        //                     }
        //                 })
        //             }]}
        //             // onChange={item => {}}
        //         />
        //     </div>
        // )
      };
    });
    return newRekonsiliasiList;
  };

  // onChangeTable = (identifier, value) => {
  //     this.setState(prevState => {
  //         let page = identifier === 'page'? value : prevState.tableConfig.page;
  //         page = identifier === 'search'? 1 : page;
  //         return {
  //             ...prevState,
  //             tableConfig: {
  //                 ...prevState.tableConfig,
  //                 [identifier]: value,
  //                 page: page
  //             }
  //         }
  //     })
  // }

  onDownloadReplenishment = async () => {
    this.setState({
      modalDate: { ...this.state.modalDate, show: false },
      loading: true,
    });
    try {
      const response = await this.props.onDownloadReplenishment(
        '',
        'replenishment_id',
        'REKONSILIASI',
        this.state.modalDate.dateRange[0]
          ? formatRequestDownloadDate(this.state.modalDate.dateRange[0])
          : null,
        this.state.modalDate.dateRange[1]
          ? formatRequestDownloadDate(this.state.modalDate.dateRange[1])
          : null,
        null
      );
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', 'Laporan Rekonsiliasi.xlsx');
      document.body.appendChild(fileLink);
      fileLink.click();
      fileLink.remove();
      setTimeout(() => {
        this.setState({
          loading: false,
        });
      }, 2000);
      // const data = response.data.map(item => {
      //     return {
      //         id: item.replenishment_id,
      //         atm: item.atm.name,
      //         status: item.status_desc.toLocaleUpperCase(),
      //         // tglInisiasi: formatUTCDisplayDate(item.replenishment_datetime),
      //         tglLapor: formatUTCDisplayDate(item.updated_at),
      //         saldoAwal: formatRupiah(item.beginning_balance, "."),
      //         saldoIsi: formatRupiah(item.replenishment_balance, "."),
      //         saldoEJ: formatRupiah(item.ej_balance, "."),
      //         saldoAkhir: formatRupiah(item.return_balance, "."),
      //         selisih: formatRupiah(item.difference_balance, ".")
      //     }
      // })

      // this.setState({loading: false, downloadData: data});
      // this.csvLink.link.click();
    } catch (error) {
      this.setState({
        loading: false,
      });
    }
  };

  render() {
    const columns = [
      {
        name: 'ID Replenishment',
        style: { width: 100 },
      },
      {
        name: 'ATM',
        style: { width: 400 },
      },
      {
        name: 'Status Rekonsiliasi',
        style: { width: 200 },
      },
      {
        name: 'Tgl. Lapor',
        style: { width: 150 },
      },
      {
        name: 'Saldo Awal',
        style: { width: 100 },
      },
      {
        name: 'Saldo Isi Ulang',
        style: { width: 100 },
      },
      {
        name: 'Saldo  EJ',
        style: { width: 100 },
      },
      {
        name: 'Saldo Akhir',
        style: { width: 100 },
      },
      {
        name: 'Selisih',
        style: { width: 100 },
      },
    ];

    // const headers = [
    //     { label: "ID Replenishment", key: "id" },
    //     { label: "ATM", key: "atm" },
    //     { label: "Status Rekonsiliasi", key: "status" },
    //     // { label: "Tanggal Inisiasi Ulang", key: "tglInisiasi" },
    //     { label: "Tanggal Lapor", key: "tglLapor" },
    //     { label: "Saldo Awal", key: "saldoAwal" },
    //     { label: "Saldo Isi Ulang", key: "saldoIsi" },
    //     { label: "Saldo EJ", key: "saldoEJ" },
    //     { label: "Saldo Akhir", key: "saldoAkhir" },
    //     { label: "Selisih", key: "selisih" }
    // ]

    return (
      <div style={{ marginBottom: '150px' }}>
        <Loader show={this.state.loading} />
        <Modal
          useHeader={this.state.modalDate.useHeader}
          show={this.state.modalDate.show}
          modalClosed={() =>
            this.setState((prevState) => {
              return {
                ...prevState,
                modalDate: {
                  ...prevState.modalDate,
                  show: false,
                },
              };
            })
          }
          title={this.state.modalDate.title}
          style={{ top: 100, overflow: 'visible' }}
          className='modal-content-date col-25 col-md-4 col-sm-6 col-xm-4 col-xs-4'
        >
          <div className='row'>
            <div className='col-12'>
              <DatePicker
                selectsRange={true}
                date={this.state.modalDate.dateRange}
                onchangeDate={(date) =>
                  modalDateFunction.onchangeRangeDate(this, 'modalDate', date)
                }
                label='Semua Tanggal'
              />
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  title={this.state.loading ? 'Mengunduh...' : 'Unduh'}
                  style={{ background: '#111827', marginTop: '20px' }}
                  onClick={this.onDownloadReplenishment}
                />
              </div>
            </div>
          </div>
        </Modal>

        <h2 className='page-header'>Rekonsiliasi</h2>
        {/* <CSVLink
                    ref={ref => this.csvLink = ref}
                    style={{display: 'none'}}
                    data={this.state.downloadData}
                    headers={headers}
                    filename={"Laporan Rekonsiliasi.csv"}
                /> */}
        <div className='row'>
          <div className='col-12'>
            <Table
              columns={columns}
              data={this.formatTable(this.props.rekonsiliasiData.data)}
              useHeader={true}
              useFooter={true}
              rightHeaderComponent={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {/* <div style={{marginRight: 10}}>
                                        <Dropdown
                                            style={{width: '150px'}}
                                            value={{ value: 'all', name: 'ATM: Semua'}}
                                            options={[{ value: 'all', name: 'ATM: Semua'}]}
                                            onChange={() => {}}
                                        />
                                    </div>
                                    <div>
                                        <Dropdown
                                            style={{width: '170px'}}
                                            value={{ value: 'all', name: '7 hari terakhir'}}
                                            options={[{ value: 'all', name: '7 hari terakhir'}]}
                                            onChange={() => {}}
                                        />
                                    </div> */}
                  <Button
                    className='action_unduhLaporan'
                    style={{ padding: '12px 20px', marginLeft: 10 }}
                    title='Unduh Laporan'
                    onClick={() =>
                      modalDateFunction.openModalDate(this, 'modalDate')
                    }
                  />
                </div>
              }
              // styleTable={{overflowY: 'auto'}}
              // stickyRight={[9]}
              // stickyBodyRight={['aksi']}
              tableConfig={this.state.tableConfig}
              onChange={(identifier, value) =>
                tableFunction.onChangeTable(this, identifier, value)
              }
              meta={this.props.rekonsiliasiData.meta}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rekonsiliasiData: state.replenish.rekonsiliasi,
    profile: state.auth.profile
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetListRekonsiliasi: (page, limit, keyword, sort, atmId, branchId) =>
      dispatch(
        getListRekonsiliasi(page, limit, keyword, sort, null, null, atmId, branchId)
      ),
    onDownloadReplenishment: (
      keyword,
      sort,
      category,
      startDate,
      endDate,
      atmId
    ) =>
      dispatch(
        downloadReplenishment(
          keyword,
          sort,
          category,
          startDate,
          endDate,
          atmId
        )
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Rekonsiliasi);
