import * as actionTypes from "../actionTypes";

const initialState = {
    atm: [],
    transactions: [],
    meta: {
        pagination: 1,
        total: 0,
        limit: 10,
        page: 1
    }
}

const atmReducer = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.GET_LIST_ATM: {
            return {
                ...state,
                atm: action.atm,
                meta: action.meta
            }
        }
        case actionTypes.GET_LIST_ATM_TRANSACTIONS: {
            return {
                ...state,
                transactions: action.data
            }
        }
        default: {
            return state;
        }
    }
}

export default atmReducer;

