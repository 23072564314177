import React, { useEffect, useState } from "react";
import "./table.css";
import TextInput from "../textInput/TextInput";
import { ReactComponent as DeleteSvg } from "../../assets/icons/trash.svg";
import { ReactComponent as SearchSvg } from "../../assets/icons/search.svg";
import { ReactComponent as ArrowRightSSvg } from "../../assets/icons/arrow-right.svg";
import { ReactComponent as ArrowLeftSvg } from "../../assets/icons/arrow-left.svg";
import Dropdown from "../dropdown/Dropdown";
import Button from "../button/Button";

const Table = (props) => {
  const [searchValue, setSearchValue] = useState("");
  const {
    tableConfig,
    meta,
    onChange = () => console.log("onChange"),
    columns,
    data,
    rightHeaderComponent,
    useHeader,
    useFooter,
    stickyLeft,
    stickyBodyLeft,
    stickyRight,
    stickyBodyRight,
    styleTable,
    SortingComponent = "",
  } = props;

  const limitOptions = [
    { name: "5", value: 5 },
    { name: "10", value: 10 },

    { name: "50", value: 50 },
    { name: "100", value: 100 },
  ];

  const getInfoPagination = (meta) => {
    let offset =
      meta.total !== 0 ? (meta.page - 1) * meta.limit + 1 : meta.total;
    let limit = (meta.page - 1) * meta.limit + meta.limit;
    let limitOffset = meta.total > limit ? limit : meta.total;
    return `Menampilkan ${offset} - ${limitOffset} dari ${meta.total}`;
  };

  useEffect(() => {
    const typingTimeout = setTimeout(() => {
      onChange("search", searchValue);
    }, 800);
    return () => clearTimeout(typingTimeout);
  }, [searchValue]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="table-container">
      {useHeader && (
        <div className="table-header">
          <TextInput
            containerclassname="search"
            lefticon={<SearchSvg />}
            placeholder="Pencarian"
            style={{
              paddingTop: 3,
              paddingBottom: 3,
              fontSize: "16px",
            }}
            value={searchValue}
            onChange={
              onChange
                ? (e) => {
                    setSearchValue(e.target.value);
                  }
                : () => {}
            }
          />
          {rightHeaderComponent ? rightHeaderComponent : <div></div>}
        </div>
      )}
      <table className="styled-table" style={styleTable}>
        <thead>
          <tr>
            {columns.map((item, index) => {
              // console.log('item', item.name === 'Nomor Resi');
              let showClick = item.onClick ? "table-header-th-onclick" : "";
              if (stickyLeft && stickyLeft.includes(index)) {
                return (
                  <th
                    className={`table-sticky-header-left ${showClick}`}
                    key={item.name}
                    style={item.style}
                    onClick={item.onClick}
                  >
                    {item.name}
                  </th>
                );
              }
              if (stickyRight && stickyRight.includes(index)) {
                return (
                  <th
                    className={`table-sticky-header-right ${showClick}`}
                    key={item.name}
                    style={item.style}
                    onClick={item.onClick}
                  >
                    {item.name}
                  </th>
                );
              }

              return (
                <th
                  className={`table-header-th ${showClick}`}
                  key={item.name}
                  style={item.style}
                  onClick={item.onClick}
                >
                  {item.name}
                  {item.name === "Nomor Resi" && SortingComponent}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data?.length !== 0 ? (
            data.map((item) => (
              <tr key={item.id}>
                {Object.keys(item).map((key) => {
                  if (key !== "id") {
                    if (stickyBodyLeft && stickyBodyLeft.includes(key)) {
                      return (
                        <th
                          className="table-sticky-body-left"
                          key={key}
                          style={item.style}
                        >
                          {item[key]}
                        </th>
                      );
                    }
                    if (stickyBodyRight && stickyBodyRight.includes(key)) {
                      return (
                        <th
                          className="table-sticky-body-right"
                          key={key}
                          style={item.style}
                        >
                          {item[key]}
                        </th>
                      );
                    }

                    if (
                      typeof item[key] === "object" &&
                      item[key].name === "delete"
                    ) {
                      return (
                        <td key={key}>
                          <Button
                            className="action_addUser"
                            style={{
                              width: 40,
                              height: 42,
                              padding: 8,
                              marginLeft: 4,
                            }}
                            title={
                              <DeleteSvg
                                style={{
                                  width: "20px",
                                }}
                              />
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              item[key].onHandleClick(item.id);
                            }}
                          />
                        </td>
                      );
                    } else return <td key={key}>{item[key]}</td>;
                  }
                  return null;
                })}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={columns.length} style={{ textAlign: "center" }}>
                Tidak ada data
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {useFooter && (
        <div className="table-footer col-12">
          <div className="table-limit-number">
            <div className="table-limit-label">Item per halaman</div>
            <Dropdown
              style={{ width: "80px" }}
              value={{
                name: tableConfig ? tableConfig.limit : "10",
                value: tableConfig ? tableConfig.limit : 10,
              }}
              onChange={onChange ? (e) => onChange("limit", e.value) : null}
              options={limitOptions}
            />
          </div>
          {meta && (
            <div className="table-pagination">
              <div className="table-limit-label">{getInfoPagination(meta)}</div>
              <ArrowLeftSvg
                className="page-arrow-btn page-arrow-btn-left"
                stroke={meta.page === 1 ? "#D1D5DC" : "#667AB3"}
                onClick={
                  meta.page === 1 ? null : () => onChange("page", meta.page - 1)
                }
                width={25}
                height={25}
              />
              <ArrowRightSSvg
                className="page-arrow-btn"
                stroke={meta.page === meta.pagination ? "#D1D5DC" : "#667AB3"}
                onClick={
                  meta.page === meta.pagination
                    ? null
                    : () => onChange("page", meta.page + 1)
                }
                width={25}
                height={25}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Table;
