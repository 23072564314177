import React, { Component } from 'react';
import './detail-replenishment.css';
import Card from '../../../components/card/Card';
import StatusLabel from '../../../components/statuslabel/StatusLabel';
import { formatDisplayDate, formatRupiah } from '../../../helpers/functionFormat';
import { connect } from 'react-redux';
import { detailReplenishment, approvalReplenisment, rejectReplenishment } from '../../../redux/actions';
import Loader from '../../../components/loader/Loader';
import Alert from '../../../components/alert/Alert';
import denominasiList from '../../../assets/JsonData/denominasi-list.json';
import Button from '../../../components/button/Button';
import { tableFunction } from '../../../helpers/functionEJ';

class DetailReplenishment extends Component {

    state = {
        id: '',
        atm: {
            id: '',
            name: '',
            key: '',
            label: '',
            location: ''
        },
        userResponsible: {
            id: '',
            name: '-'
        },
        userChecked: {
            id: '',
            name: '-'
        },
        tglInisiasi: new Date(),
        saldoAwal: '0',
        saldoIsi: '0',
        catridges: [],
        status: '',
        catatan: '-',
        loading: false,
        total: '0',
        alert: {
            show: false,
            message: '',
            isError: false
        }
    }

    componentDidMount(){
        if(this.props.location && this.props.location.state){
            this.setState({
                id: this.props.location.state.replenishment_id,
                atm: {
                    id: this.props.location.state.atm.atm_id,
                    name: this.props.location.state.atm.name,
                    key: this.props.location.state.atm.atm_key,
                    label: this.props.location.state.atm.atm_label,
                    location: this.props.location.state.atm.location
                },
                userResponsible: {
                    id: this.props.location.state.atm.updated_by,
                    name: '-'
                },
                userChecked: {
                    id: '',
                    name: '-'
                },
                tglInisiasi: this.props.location.state.created_at,
                saldoAwal: this.props.location.state.beginning_balance,
                saldoIsi: this.props.location.state.replenishment_balance,
                catridges: [],
                status: '',
                catatan: '-'
            })
        }
        this.loadDetailReplenishment(this.props.match.params.id);
    }

    loadDetailReplenishment = async (id) => {
        this.setState({loading: true});
        try{
            const response = await this.props.onGetReplenishment(id);
            // console.log(response);

            this.setState({
                id: response.data.replenishment_id,
                atm: {
                    id: response.data.atm.atm_id,
                    name: response.data.atm.name,
                    key: response.data.atm.atm_key,
                    label: response.data.atm.atm_label,
                    location: response.data.atm.location
                },
                userResponsible: {
                    id: response.data.atm.updated_by,
                    name: response.data.pic.name
                },
                userChecked: {
                    id: response.data.pic.created_by,
                    name: response.data.pic.name
                },
                tglInisiasi: response.data.replenishment_datetime,
                saldoAwal: response.data.beginning_balance,
                saldoIsi: response.data.replenishment_balance,
                catridges: response.data.replenishment_cartridges,
                status: response.data.status,
                catatan: response.data.notes? response.data.notes : "-",
                loading: false
            });
        }catch(error){
            this.setState({loading: false});
        }
    }

    onApproveReplenishment = async () => {
        this.setState({loading: true});
        try{
            const response = await this.props.onApproveReplenishment(this.state.id);
            this.setState({
                loading: false,
                alert: {
                    ...alert,
                    show: true,
                    message: response.message,
                    isError: false,
                    onBtnPress: () => this.props.history.goBack(),
                    onBackPress: () => this.props.history.goBack()
                }
            })
        }catch(error){
            this.setState({
                loading: false,
                alert: {
                    ...alert,
                    show: true,
                    message: error.message,
                    isError: true
                }
            })
        }
    }

    onRejectReplenishment = async () => {
        this.setState({loading: true});
        try{
            const response = await this.props.onRejectReplenishment(this.state.id);
            this.setState({
                loading: false,
                alert: {
                    ...alert,
                    show: true,
                    message: response.message,
                    isError: false,
                    onBtnPress: () => this.props.history.goBack(),
                    onBackPress: () => this.props.history.goBack()
                }
            })
        }catch(error){
            this.setState({
                loading: false,
                alert: {
                    ...alert,
                    show: true,
                    message: error.message,
                    isError: true
                }
            })
        }
    }

    render() {
        return (
            <div>
                <Loader show={this.state.loading}/>
                <Alert
                    show={this.state.alert.show}
                    message={this.state.alert.message}
                    isError={this.state.alert.isError}
                    modalClosed={() => this.setState({alert: {...alert, show: false}})}
                />
                <h2 className="page-header header-detail-replenishment">Detail Replenishment</h2>
                <div className="row container-detail-replenishment">
                    <div className="col-8">
                        <Card className="card-detail-replenishment">
                            <div className="row info-detail-replenishment">
                                <div className="col-4">
                                    <label className="label-detail-replenishment">ID Replenishment</label>
                                </div>
                                <div className="col-8">
                                    <label className="value-detail-replenishment">{this.state.id}</label>
                                </div>
                            </div>
                            <div className="row info-detail-replenishment">
                                <div className="col-4">
                                    <label className="label-detail-replenishment">Tgl. Inisiasi</label>
                                </div>
                                <div className="col-8">
                                    <label className="value-detail-replenishment">{formatDisplayDate(this.state.tglInisiasi)}</label>
                                </div>
                            </div>
                            <div className="row info-detail-replenishment">
                                <div className="col-4">
                                    <label className="label-detail-replenishment">ATM</label>
                                </div>
                                <div className="col-8">
                                    <label className="value-detail-replenishment">{this.state.atm.name}</label>
                                </div>
                            </div>
                            {this.state.atm.key && <div className="row info-detail-rekonsiliasi">
                                <div className="col-4 col-md-12 col-sm-12">
                                    <label className="label-detail-rekonsiliasi">IP Address ATM</label>
                                </div>
                                <div className="col-8 col-md-12 col-sm-12">
                                    <label className="value-detail-rekonsiliasi">{this.state.atm.key}</label>
                                </div>
                            </div>}
                            <div className="row info-detail-replenishment">
                                <div className="col-4">
                                    <label className="label-detail-replenishment">Penanggung Jawab</label>
                                </div>
                                <div className="col-8">
                                    <label className="value-detail-replenishment">{this.state.userResponsible.name}</label>
                                </div>
                            </div>
                            <div className="hr-detail-replenishment" />
                            <div className="row info-detail-replenishment">
                                <div className="col-4">
                                    <label className="label-detail-replenishment">Saldo Awal</label>
                                </div>
                                <div className="col-8">
                                    <label className="value-detail-replenishment">{formatRupiah(this.state.saldoAwal, '.')}</label>
                                </div>
                            </div>
                            <div className="row info-detail-replenishment">
                                <div className="col-4">
                                    <label className="label-detail-replenishment">Saldo Isi Ulang</label>
                                </div>
                                <div className="col-8">
                                    <label className="value-detail-replenishment">{formatRupiah(this.state.saldoIsi, '.')}</label>
                                    {/* <label className="value-detail-replenishment a-label-detail-replenishment">Lihat Ringkasan</label> */}
                                </div>
                            </div>
                            <div className="hr-detail-replenishment" />
                            {this.state.catridges.map((catridge, index) => (
                                <div className="row container-detail-replenishment" key={catridge.replenishment_cartridge_id}>
                                    <div className="col-12">
                                        <Card className="card-detail-replenishment">
                                            <h4 className="header-detail-replenishment">Catridge: {index+1}</h4>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="label-detail-replenishment">Nomor Seri</div>
                                                    <div className="value-detail-replenishment">{catridge?.serial_number}</div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="label-detail-replenishment">Denominasi</div>
                                                    {catridge?.replenishment_cartridge_details?.map(detail => {
                                                        let deno = denominasiList.find(item => item.denominator_id === detail.denominator_id);
                                                        return (
                                                            <div className="row" key={detail.replenishment_cartridge_detail_id}>
                                                                <div className="col-6">
                                                                    <label className="value-detail-replenishment">{deno? formatRupiah(deno.value, '.') : "Rp 100.000"} x</label>
                                                                </div>
                                                                <div className="col-6" style={{textAlign: 'right'}}>
                                                                    <label className="label-detail-replenishment">{detail.value} Lembar</label>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                    <div className="hr-detail-replenishment" />
                                                    <div className="row">
                                                        <div className="col-4">
                                                            <label className="value-detail-replenishment">Subtotal</label>
                                                        </div>
                                                        <div className="col-8" style={{textAlign: 'right'}}>
                                                            <label className="label-detail-replenishment">{catridge?.sub_total ? formatRupiah(catridge?.sub_total, '.') : 0}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                </div>

                            ))}
                            <div className="row container-detail-replenishment">
                                <div className="col-6">
                                    <label className="total-detail-replanishment">Total Isi Ulang</label>
                                </div>
                                <div className="col-6" style={{textAlign: 'right'}}>
                                    <label className="total-detail-replanishment">{formatRupiah(this.state.saldoIsi, '.')}</label>
                                </div>
                            </div>
                        </Card>
                        {this.props.profile.roleId === 1 && this.state.status === "REPLENISHMENT_ST_01" && <div className="container-button-detail-replanishment">
                            <Button
                                className="button-detail-replanishment btn-default action_tolak"
                                title="Tolak"
                                onClick={this.onRejectReplenishment}
                            />
                            <Button
                                className="button-detail-replanishment action_setuju"
                                title="Setujui"
                                onClick={this.onApproveReplenishment}
                            />
                        </div>}
                    </div>
                    <div className="col-4">
                        <Card className="card-detail-replenishment">
                            <div className="info-right-detail-replenishment">
                                <div className="label-detail-replenishment">Status Rekonsiliasi</div>
                                {this.state.status? tableFunction.checkingStatus(this.state.status)  : <StatusLabel type="process" label="Diperiksa"/>}
                            </div>
                            {/* <div className="info-right-detail-replenishment">
                                <div className="label-detail-replenishment">Tanggal Lapor</div>
                                <div className="value-detail-replenishment">01/02/2021, 09.30</div>
                            </div> */}
                            <div className="info-right-detail-replenishment">
                                <div className="label-detail-replenishment">Diperiksa Oleh</div>
                                <div className="value-detail-replenishment">{this.state.userChecked.name}</div>
                            </div>
                            <div className="info-right-detail-replenishment">
                                <div className="label-detail-replenishment">Catatan</div>
                                <div className="value-detail-replenishment">{this.state.catatan}</div>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onGetReplenishment: (id) => dispatch(detailReplenishment(id)),
        onApproveReplenishment: (id) => dispatch(approvalReplenisment(id)),
        onRejectReplenishment: (id) => dispatch(rejectReplenishment(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailReplenishment);
