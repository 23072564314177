import * as actionTypes from "../actionTypes";

const initialState = {
    atm: 0,
    iniasiUlang: 0,
    rekonsiliasi: 0,
    selisih: 0,
    inactiveAtm: 0
}

const dashboardReducer = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.GET_DASHBOARD: {
            return {
                ...state,
                atm: action.atm,
                iniasiUlang: action.iniasiUlang,
                rekonsiliasi: action.rekonsiliasi,
                selisih: action.selisih,
                inactiveAtm: action.inactiveAtm
            }
        }
        default: {
            return state;
        }
    }
}

export default dashboardReducer;
