import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter } from 'react-router-dom'

import { store, persistor } from './redux';
import Router from './routes';
import packageJson from '../package.json';

function App() {
  console.log("v"+packageJson.version);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
