import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '../../../components/button/Button';
import Table from '../../../components/table/Table';
// import Dropdown from '../../../components/dropdown/Dropdown';
import { getATMList } from '../../../redux/actions';
import Loader from '../../../components/loader/Loader';
import Alert from '../../../components/alert/Alert';

class ATM extends Component {

    _isMounted = true;

    state = {
        tableConfig: {
            search: '',
            page: 1,
            sort: 'atm_id',
            limit: 10
        },
        loading: false,
        alert: {
            show: false,
            message: '',
            isError: false
        }
    }

    componentDidMount(){
        this.loadATMList(
            this.state.tableConfig.page,
            this.state.tableConfig.limit,
            this.state.tableConfig.search,
            this.state.tableConfig.sort
        )
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.tableConfig !== this.state.tableConfig) {
            this.loadATMList(
                this.state.tableConfig.page,
                this.state.tableConfig.limit,
                this.state.tableConfig.search,
                this.state.tableConfig.sort
            )
        }
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    loadATMList = async (page, limit, search, sort) => {
        // if(this._isMounted){
        //     this.setState({loading: true});
        // }
        try{
            await this.props.getATMList(page, limit, search, sort);
            if(this._isMounted){
                this.setState({loading: false})
            }
        }catch(error){
            if(this._isMounted){
                this.setState({
                    loading: false,
                    alert: {
                        ...alert,
                        show: true,
                        message: error.message,
                        isError: true
                    }
                })
            }
        }
    }

    formatTable = (data) => {
        const newAtmList = data.map(item => {
            // console.log(item)
            return {
                id: item.id,
                idComponent: item.id,
                atm_label: item.atm_label,
                name: item.name,
                location: item.location,
                merk: item.merk? item.merk : "-",
                aksi: (
                    <Button
                        className="btn-default"
                        title="Lihat Detail"
                        onClick={() => this.props.history.push({
                            key: 'atm-' + item.id,
                            pathname: `/atm/${item.id}`,
                            state: {
                                id: item.id,
                                atm_label: item.atm_label,
                                name: item.name,
                                location: item.location,
                                merk: item.merk? item.merk : "-",
                                description: item.description,
                                saldoAktif: item.balance,
                                lastIsiUlang: '1 feb 2021',
                            }
                        })}
                    />
                )
            }
        })

        return newAtmList;
    }

    onChangeTable = (identifier, value) => {
        if(this._isMounted){
            this.setState(prevState => {
                let page = identifier === 'page'? value : prevState.tableConfig.page;
                page = identifier === 'search'? 1 : page;
                return {
                    ...prevState,
                    tableConfig: {
                        ...prevState.tableConfig,
                        [identifier]: value,
                        page: page
                    }
                }
            })
        }
    }

    render(){
        const columns = [{
            name: 'ID ATM',
            style: { width: 150 },
            onClick: () => this.setState(prevState => {
                return {
                    ...prevState,
                    tableConfig: {
                        ...prevState.tableConfig,
                        sort: prevState.tableConfig.sort === 'atm_id'? '-atm_id' : 'atm_id'
                    }
                }
            })
        }, {
            name: 'Terminal ID',
            style: { width: 150 },
            onClick: () => this.setState(prevState => {
                return {
                    ...prevState,
                    tableConfig: {
                        ...prevState.tableConfig,
                        sort: prevState.tableConfig.sort === 'atm_label'? '-atm_label' : 'atm_label'
                    }
                }
            })
        }, {
            name: 'ATM',
            style: { width: 300 },
            onClick: () => this.setState(prevState => {
                return {
                    ...prevState,
                    tableConfig: {
                        ...prevState.tableConfig,
                        sort: prevState.tableConfig.sort === 'name'? '-name' : 'name'
                    }
                }
            })
        }, {
            name: 'Lokasi',
            style: { width: 350 },
            onClick: () => this.setState(prevState => {
                return {
                    ...prevState,
                    tableConfig: {
                        ...prevState.tableConfig,
                        sort: prevState.tableConfig.sort === 'location'? '-location' : 'location'
                    }
                }
            })
        }, {
            name: 'Merek',
            style: { width: 200 },
            onClick: () => this.setState(prevState => {
                return {
                    ...prevState,
                    tableConfig: {
                        ...prevState.tableConfig,
                        sort: prevState.tableConfig.sort === 'merk'? '-merk' : 'merk'
                    }
                }
            })
        }, {
            name: 'Aksi',
            style: { width: 150, textAlign: 'center' }
        }]

        return (
            <div className="col-12">
                <Loader show={this.state.loading}/>
                <Alert
                    show={this.state.alert.show}
                    message={this.state.alert.message}
                    isError={this.state.alert.isError}
                    modalClosed={() => this.setState({alert: {...alert, show: false}})}
                />
                <div className="row">
                    <div className="col-12">
                        <Table
                            columns={columns}
                            data={this.formatTable(this.props.atmData.atm)}
                            useHeader
                            useFooter
                            rightHeaderComponent={(
                                <div style={{display: 'flex'}}>
                                    {/* <Dropdown
                                        style={{width: '170px'}}
                                        value={{ value: 'all', name: 'Lokasi: Semua'}}
                                        options={[{ value: 'all', name: 'Lokasi: Semua'}]}
                                        onChange={() => {}}
                                    /> */}
                                    <Button
                                        className="action_addAtm"
                                        style={{padding: '12px 20px 12px 20px', marginLeft: '10px'}}
                                        title="Tambah ATM"
                                        onClick={() => this.props.history.push('/add-atm')}
                                    />
                                </div>
                            )}
                            tableConfig={this.state.tableConfig}
                            onChange={this.onChangeTable}
                            meta={this.props.atmData.meta}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        atmData: state.atm
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getATMList: (page, limit, keyword, sort) => dispatch(getATMList(page, limit, keyword, sort))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ATM);
