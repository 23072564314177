import * as actionTypes from "../actionTypes";

const initialState = {
  denominasi: [
    {
      denominator_id: 1,
      value: 50000,
      description: "LIMA PULUH RIBU RUPIAH"
    },
    {
      denominator_id: 2,
      value: 100000,
      description: "SERATUS RIBU RUPIAH"
    }
  ],
  meta: {
    pagination: 1,
    total: 0,
    limit: 10,
    page: 1
  }
}

const denominasiReducer = (state = initialState, action) => {
  switch(action.type){
    case actionTypes.GET_LIST_DENOMINASI: {
      return {
        ...state,
        denominasi: action.denominasi,
        meta: action.meta
      }
    }
    default: {
      return state;
    }
  }
}

export default denominasiReducer;

