import React, { useState, useRef } from 'react';
import './dropzone.css';
import { ReactComponent as UploadSvg } from '../../assets/icons/cloud-upload.svg';
import { ReactComponent as FileSvg } from '../../assets/icons/file-text.svg';
import Modal from '../modal/Modal';

const DropZone = props => {

    const { file, onChangeFile, label, labelStyle, containerStyle, className } = props;

    const [modal, setModal] = useState({
        show: false,
        body: null
    })

    const inputRef = useRef();

    const dragOver = (e) => {
        e.preventDefault();
    }
    
    const dragEnter = (e) => {
        e.preventDefault();
    }
    
    const dragLeave = (e) => {
        e.preventDefault();
    }
    
    const fileDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        onChangeFile(files[0]);
    }

    const onViewImage = async (file) => {
        try{
            const image = await convertBase64(file);
            const body = <img 
                alt={`upload ${label}`} 
                style={{width: '100%', height: '100%'}} 
                src={image} 
            />;
            setModal({
                show: true,
                body:  body
            })
        }catch(error){
            console.log(error);
        }
    }

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    return (
        <div className="dropzone">
            <Modal 
                show={modal.show}
                modalClosed={() => setModal({...modal, show: false})}
                body={modal.body}
            />
            <div className="label-dropzone" style={labelStyle}>{label}</div>
            <input 
                className={`dropzone-input ${className}`}
                type='file' 
                id='file' 
                ref={inputRef} 
                style={{display: 'none'}} 
                value={""}
                onChange={(e) => onChangeFile(e.target.files[0])}
            />
            <div
                className={`container-dropzone ${file? "" : "container-dropzone-nofile"}`}
                style={containerStyle}
                onDragOver={dragOver}
                onDragEnter={dragEnter}
                onDragLeave={dragLeave}
                onDrop={fileDrop}
                onClick={!file? () => inputRef.current.click() : () => {}}
            >
                {file? (
                    <>
                        <div className="file-dropzone">
                            <FileSvg width="40" height="40"/>
                            <div className="typefile-dropzone">{file.name.split('.').pop()}</div>
                        </div>
                        <div className="namefile-dropzone">{file.name}</div>
                        <div className="sizefile-dropzone">{Math.round(file.size * 0.001)} KB</div>
                        <div style={{display: 'flex'}}>
                            {(file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg") && (
                                <div className="viewfile-dropzone" onClick={() => onViewImage(file)}>Lihat</div>
                            )}
                            <div className="deletefile-dropzone" onClick={() => onChangeFile(null)}>Hapus</div>
                        </div>
                    </>
                ) : (
                    <>
                        <UploadSvg className="icon-dropzone"/>
                        <label className="label-container-dropzone">Cari File<label style={{color: '#687083'}}>, Letakkan dokumen disini</label></label>
                    </>
                )}
                
            </div>
        </div>
    )
}

export default DropZone;