import React, { Component } from 'react';
import './forgot-password.css';
import { ReactComponent as MailSvg } from '../../../assets/icons/mail.svg';
import Button from '../../../components/button/Button';

class ForgotPassword extends Component {

    state = {
        email: ''
    }

    onSubmitHandler = () => {
        this.props.history.replace('/sent-email');
    }
    
    render() {
        return (
            <div className="container-forgot-password">
                <div className="logo-container-forgot-password">
                    <img 
                        className="logo-forgot-password"
                        src={require('../../../assets/images/company-logo_2.png')} 
                        alt="Sample Bank" 
                    />
                </div>
                <div className="col-4 col-xm-5 col-xs-6 col-md-6 col-sm-10 card-forgot-password">
                    <div className="title-forgot-password">Lupa Kata Sandi</div>
                    <span className="subtitle-forgot-password">Masukkan e-mail atau nomor HP yang terdaftar. Kami akan mengirimkan kode verifikasi untuk atur ulang kata sandi.</span>
                    <div className="input-container-forgot-password">
                        <div className="icon-container-forgot-password">
                            <MailSvg />
                        </div>
                        <input 
                            className="input-text-forgot-password input_email"
                            placeholder="Email"
                            type="email"
                            value={this.state.email}
                            onChange={(e) => this.setState({email: e.target.value})}
                        />
                    </div>
                    <Button 
                        style={{background: '#111827'}}
                        className="btn-forgot-password btn_lanjutkan"
                        title="Lanjutkan"
                        onClick={this.onSubmitHandler}
                    />
                </div>
            </div>
        )
    }
}

export default ForgotPassword;