const monthsArray = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const monthsArrayLong = [
  "Januari",
  "Februari",
  "Maret",
  "April",
  "Mei",
  "Juni",
  "July",
  "Agustus",
  "September",
  "Oktober",
  "November",
  "Desember",
];

export const formatRupiah = (number, separator, symbol = "Rp") => {
  let newNumber = number.toString();
  let isMinus = newNumber[0] === "-";
  let nNumber = isMinus ? newNumber.substr(1, newNumber.length - 1) : newNumber;
  nNumber = Math.round(nNumber);
  let numberString = nNumber.toString();
  // let decimal = numberString.split('.')[1]? (numberString.split('.')[1] === "00"? null : numberString.split('.')[1]) : null;
  numberString = numberString.split(".")[0];
  let sisa = numberString.length % 3;
  let currency = numberString.substr(0, sisa);
  let ribuan = numberString.substr(sisa).match(/\d{3}/g);

  if (ribuan) {
    let separate = sisa ? separator : "";
    currency += separate + ribuan.join(separator);
  }

  return `${isMinus ? "- " : ""}${symbol} ${currency}`;
};

export const formatDisplayDate = (nDate) => {
  const date = new Date(nDate);

  let day = date.getUTCDate().toString();
  let month = (date.getUTCMonth() + 1).toString();
  day = day.length > 1 ? day : "0" + day;
  month = month.length > 1 ? month : "0" + month;

  return `${day}/${month}/${date.getUTCFullYear()}`;
};

export const formatUTCDisplayDate = (nDate) => {
  const dateUTC = new Date(nDate).toUTCString().split(" ");

  let day = dateUTC[1];
  let month = (
    parseInt(monthsArray.findIndex((mon) => mon === dateUTC[2])) + 1
  ).toString();
  day = day.length > 1 ? day : "0" + day;
  month = month.length > 1 ? month : "0" + month;

  return `${day}/${month}/${dateUTC[3]}`;
};

export const formatDisplayDateOnly = (nDate) => {
  const date = new Date(nDate);
  let day = date.getDate().toString();
  let month = (date.getMonth() + 1).toString();
  day = day.length > 1 ? day : "0" + day;
  month = month.length > 1 ? month : "0" + month;
  return `${day}/${month}/${date.getFullYear()}`;
};

export const formatRequestDateV2 = (nDate) => {
  // const date = new Date(nDate);
  const date2 = new Date(nDate).toUTCString().split(" ");

  let day = date2[1];
  let month = parseInt(
    monthsArray.findIndex((mon) => mon === date2[2])
  ).toString();
  let year = date2[3];

  let time = date2[4];

  day = day.length > 1 ? day : "0" + day;
  month = monthsArrayLong[month];

  return `${day} ${month} ${year}, ${time}`;
  // return `${day} ${month} ${year}, ${hour}:${minute}:${second}`;
};

export const formatRequestDate = (nDate) => {
  const date = new Date(nDate);
  let day = date.getDate().toString();
  let month = (date.getMonth() + 1).toString();
  let hour = date.getHours().toString();
  let minute = date.getMinutes().toString();
  day = day.length > 1 ? day : "0" + day;
  month = month.length > 1 ? month : "0" + month;
  hour = hour.length > 1 ? hour : "0" + hour;
  minute = minute.length > 1 ? minute : "0" + minute;
  return `${date.getFullYear()}-${month}-${day} ${hour}:${minute}`;
};

export const formatRequestDownloadDate = (nDate) => {
  const date = new Date(nDate);
  let day = date.getDate().toString();
  let month = (date.getMonth() + 1).toString();
  day = day.length > 1 ? day : "0" + day;
  month = month.length > 1 ? month : "0" + month;
  return `${day}/${month}/${date.getFullYear()}`;
  // return `${date.getFullYear()}-${month}-${day} ${hour}:${minute}`;
};

export const formatRequestDateOnly = (nDate) => {
  const date = new Date(nDate);
  let day = date.getDate().toString();
  let month = (date.getMonth() + 1).toString();
  day = day.length > 1 ? day : "0" + day;
  month = month.length > 1 ? month : "0" + month;
  return `${date.getFullYear()}-${month}-${day}`;
};

export const formatRequestDateTimeUTC = (nDate) => {
  const date = new Date(nDate);

  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  const seconds = String(date.getUTCSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const getFilenameFromUrl = (url) => {
  return url.split("/").pop().split("#")[0].split("?")[0];
};

export const formatRequestDateRecon = (nDate) => {
  const date = new Date(nDate);
  let day = date.getDate().toString();
  let month = monthsArray[date.getMonth()];
  day = day.length > 1 ? day : "0" + day;
  month = month.length > 1 ? month : "0" + month;
  return `${day}-${month}-${date.getFullYear()}`;
};

export const formatRequestDateReconUTC = (nDate) => {
  const date = new Date(nDate).toUTCString().split(" ");
  let day = date[1].toString();
  let month = date[2].toString();
  return `${day}-${month}-${date[3]}`;
};
export const formatRequestDownloadDatew = (nDate) => {
  const date = new Date(nDate);
  let day = date.getDate().toString();
  let month = (date.getMonth() + 1).toString();
  day = day.length > 1 ? day : "0" + day;
  month = month.length > 1 ? month : "0" + month;
  return `${day}/${month}/${date.getFullYear()}`;
  // return `${date.getFullYear()}-${month}-${day} ${hour}:${minute}`;
};

export const capitalizeString = (text) => {
  const arr = text.split(" ");
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
  }
  return arr.join(" ");
};
