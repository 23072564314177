import React from 'react';
import ReactDatePicker from 'react-datepicker';
import './datepicker.css';
import { formatDisplayDateOnly } from '../../helpers/functionFormat';

const DatePicker = props => {

    const { selectsRange, date, onchangeDate, label, customInputStyle } = props;

    return (
        <ReactDatePicker
            customInput={(
                <div className="custom-input-date-picker" style={customInputStyle}>
                    {selectsRange?
                        `${date[0]? formatDisplayDateOnly(date[0]) : label} ${date[0] && date[1]? " - " + formatDisplayDateOnly(date[1]) : " "}`
                    : date? formatDisplayDateOnly(date) : <div className="custom-label-input-date-picker">{label}</div>}
                </div>
            )}
            selected={selectsRange? null : date}
            selectsRange={selectsRange}
            startDate={selectsRange? date[0] : null}
            endDate={selectsRange? date[1] : null}
            onChange={onchangeDate}
        />
    )
}

export default DatePicker;