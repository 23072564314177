import React, {Component} from 'react';
import Table from '../../../components/table/Table';
import Button from '../../../components/button/Button';
import {connect} from 'react-redux';
import {getATMList} from '../../../redux/actions';
import {formatRupiah} from '../../../helpers/functionFormat';
import {tableFunction} from '../../../helpers/functionEJ';
import Loader from "../../../components/loader/Loader";
// import { ReactComponent as FolderSvg } from '../../../assets/icons/folder.svg';
// import { ReactComponent as FileTextSvg } from '../../../assets/icons/file-text2.svg';


class ATM extends Component {

    _isMounted;

    state = {
        tableConfig: {
            search: '',
            page: 1,
            sort: 'name',
            limit: 10
        },
        alert: {
            show: false,
            message: '',
            isError: false
        },
        loading: true,
    }

    componentDidMount(){
        // this.loadATMList(
        //     this.state.tableConfig.page,
        //     this.state.tableConfig.limit,
        //     this.state.tableConfig.search,
        //     this.state.tableConfig.sort
        // )
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.tableConfig !== this.state.tableConfig) {
            this.loadATMList(
                this.state.tableConfig.page,
                this.state.tableConfig.limit,
                this.state.tableConfig.search,
                this.state.tableConfig.sort
            )
        }
    }

    loadATMList = async (page, limit, search, sort) => {
        this.setState({loading: true});
        try{
            const {branchId} = this.props?.profile;
            await this.props.getATMList(page, limit, search, sort, branchId);
            this.setState({loading: false});
        }catch(error){
            this.setState({loading: false});
            this.setState({
                alert: {
                    ...alert,
                    show: true,
                    message: error.message,
                    isError: true
                }
            })
        }
    }

    formatTable = (data) => {
        return data.map(item => {
            if (this.props.profile.roleId === 1) {
                return {
                    id: item.id,
                    // aksi: (
                    //     <div style={{display: 'flex', justifyContent: 'center'}}>
                    //         <div
                    //             style={{marginRight: '10px'}}
                    //             className="dashboard-action-icon"
                    //             title="Daftar Transaksi"
                    //             onClick={() => this.props.history.push({
                    //                     key: `rekonsiliasi-document-${item.id}`,
                    //                     pathname: `/rekonsiliasi-document/${item.id}`,
                    //                     state: {
                    //                         atmId: item.id,
                    //                         atmName: item.name
                    //                     }
                    //             })}
                    //         >
                    //             <FolderSvg
                    //                 stroke="white"
                    //                 width="20px"
                    //                 height="20px"
                    //             />
                    //         </div>
                    //         <div
                    //             className="dashboard-action-icon"
                    //             title="Rekonsiliasi File"
                    //             onClick={() => this.props.history.push({
                    //                 key: `detail-file-rekon-${item.id}`,
                    //                 pathname: `/detail-file-rekon/${item.id}`,
                    //                 state: {
                    //                     atmId: item.id,
                    //                     atmName: item.name
                    //                 }
                    //             })}
                    //         >
                    //             <FileTextSvg
                    //                 stroke="white"
                    //                 width="20px"
                    //                 height="20px"
                    //             />
                    //         </div>
                    //     </div>
                    // ),
                    idComponent: (
                      <label
                        className="id-dashboard"
                        onClick={() => this.props.history.push({
                            key: `atm-${item.id}`,
                            pathname: `/atm/${item.id}`,
                            state: {...item}
                        })}
                      >
                          <div style={{color: '#4991F2'}}>
                              {item.id}
                          </div>
                      </label>
                    ),
                    terminalId: (
                      <div style={{color: '#4991F2'}}>
                          {item?.atm_label}
                      </div>
                    ),
                    name: item.name,
                    location: item.location,
                    saldo: formatRupiah(item.saldo, '.')
                }
            } else {
                return {
                    id: item.id,
                    viewDetail: this.props.profile.roleId === 1 ? null : (
                      <Button
                        className="btn-primary action_isiUlang"
                        title="Inisiasi Isi Ulang"
                        onClick={() => this.props.history.push({
                            key: 'inisiasi-ulang-' + item.id,
                            pathname: `/inisiasi-ulang/form-inisiasi-ulang`,
                            state: {
                                atmId: item.id,
                                atmName: item.name,
                                atmLocation: item.location,
                                saldo: item.saldo
                            }
                        })}
                      />
                    ),
                    idComponent: (
                      <label
                        className="id-dashboard"
                        onClick={() => this.props.history.push({
                            key: `atm-${item.id}`,
                            pathname: `/atm/${item.id}`,
                            state: {...item}
                        })}
                      >
                          <div style={{color: '#4991F2'}}>
                              {item.id}
                          </div>
                      </label>
                    ),
                    name: item.name,
                    location: item.location,
                    saldo: formatRupiah(item.saldo, '.')
                }
            }

        });
    }

    render(){
        let columns = [];
        if(this.props.profile.roleId === 2){
            columns.push({
                name: "Aksi",
                style: { width: 100, textAlign: 'center' }
            })
        }

        columns = [...[{
            name: "ID ATM",
            style: { width: 100 },
            onClick: () => this.setState(prevState => ({
                tableConfig: {...prevState.tableConfig, sort: 'atm_id'}
            }))
        }, {
            name: "Terminal ID",
            style: { width: 100 }
        }, {
            name: "ATM",
            style: { width: '30%' }
        }, {
            name: "Lokasi",
            style: { width: 550 }
        }, {
            name: "Saldo Awal",
            style: { width: 350 }
        }]]

        return (
            <div className="row">
                <Loader show={this.state.loading}/>
                <div className="col-12">
                    <h3 className="title-dashboard">Total ATM</h3>
                    <Table
                        columns={columns}
                        data={this.formatTable(this.props.atmData.atm)}
                        useHeader={true}
                        useFooter={true}
                        rightHeaderComponent={(
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                {/* <Dropdown
                                    style={{width: '150px'}}
                                    value={{ value: 'all', name: 'ATM: Semua'}}
                                    options={[{ value: 'all', name: 'ATM: Semua'}]}
                                    onChange={() => {}}
                                /> */}
                                {/* <Button
                                    className="action_unduhLaporan"
                                    style={{padding: '12px 20px', marginLeft: 10}}
                                    title="Unduh Laporan"
                                /> */}
                            </div>
                        )}
                        tableConfig={this.state.tableConfig}
                        onChange={(identifier, value) => tableFunction.onChangeTable(this, identifier, value)}
                        meta={this.props.atmData.meta}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        atmData: state.atm,
        profile: state.auth.profile
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getATMList: (page, limit, keyword, sort, branchId) => dispatch(getATMList(page, limit, keyword, sort, branchId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ATM);
