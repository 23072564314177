import StatusLabel from '../components/statuslabel/StatusLabel';
import StatusLabelRecon from '../components/statuslabel/StatusLabelRecon';
import { capitalizeString } from './functionFormat';

export const tableFunction = {
  onChangeTable: (that, identifier, value) => {
    that.setState((prevState) => {
      let page = identifier === 'page' ? value : prevState.tableConfig.page;
      page = identifier === 'search' ? 1 : page;
      return {
        ...prevState,
        tableConfig: {
          ...prevState.tableConfig,
          [identifier]: value,
          page: page,
        },
      };
    });
  },

  checkingStatus: (status) => {
    let data = status;
    switch (status.toUpperCase()) {
      case 'REPLENISHMENT_ST_01': {
        data = <StatusLabel type='process' label={'Inisiasi'} />;
        break;
      }
      case 'REPLENISHMENT_ST_02': {
        data = <StatusLabel type='success' label={'Inisiasi Disetujui'} />;
        break;
      }
      case 'REPLENISHMENT_ST_03': {
        data = <StatusLabel type='process' label={'Rekonsiliasi'} />;
        break;
      }
      case 'REPLENISHMENT_ST_04': {
        data = <StatusLabel type='process' label={'Rekonsiliasi Disetujui'} />;
        break;
      }
      case 'REPLENISHMENT_ST_05': {
        data = <StatusLabel type='success' label={'Rekonsiliasi Sukses'} />;
        break;
      }
      case 'REPLENISHMENT_ST_06': {
        data = <StatusLabel type='error' label={'Kasus Selisih'} />;
        break;
      }
      case 'REPLENISHMENT_ST_07': {
        data = <StatusLabel type='success' label={'Selisih Selesai'} />;
        break;
      }
      case 'REPLENISHMENT_ST_08': {
        data = <StatusLabel type='error' label={'Ditolak'} />;
        break;
      }
      default: {
        data = status;
        break;
      }
    }
    return data;
  },

  checkingPembeda: (data, style) => {
    if (!data) return;
    return (
      <div
        style={style ? style : { display: 'flex', justifyContent: 'center' }}
      >
        {data.map((item) => (
          <StatusLabelRecon
            style={{ marginLeft: '2px', marginRight: '2px' }}
            key={item}
            type='process'
            label={capitalizeString(item)}
          />
        ))}
      </div>
    );
  },
};

export const modalDateFunction = {
  openModalDate: (that, identifier) => {
    that.setState({
      [identifier]: {
        ...that.state[identifier],
        show: true,
        useHeader: true,
        dateRange: [null, null],
      },
    });
  },
  onchangeRangeDate: (that, identifier, value) => {
    that.setState((prevState) => {
      return {
        ...prevState,
        [identifier]: {
          ...prevState[identifier],
          dateRange: value,
        },
      };
    });
  },
  closeModalDate: (that, identifier) => {
    that.setState((prevState) => {
      return {
        ...prevState,
        [identifier]: {
          ...prevState[identifier],
          show: false,
        },
      };
    });
  },
};

export const dropdownFunction = {
  listMonth: () => {
    return [
      // { value: '', name: 'Semua' },
      { value: '01', name: 'Januari' },
      { value: '02', name: 'Februari' },
      { value: '03', name: 'Maret' },
      { value: '04', name: 'April' },
      { value: '05', name: 'Mei' },
      { value: '06', name: 'Juni' },
      { value: '07', name: 'Juli' },
      { value: '08', name: 'Agustus' },
      { value: '09', name: 'September' },
      { value: '10', name: 'Oktober' },
      { value: '11', name: 'November' },
      { value: '12', name: 'Desember' },
    ];
  },
  listYear: () => {
    let year = new Date().getFullYear();
    let firstYear = year - 3;
    let years = [];
    // years.push({value: '', name: 'Semua'});
    for (let i = firstYear; i <= year; i++) {
      years.push({
        value: i.toString(),
        name: i.toString(),
      });
    }
    years.push({ value: (year + 1).toString(), name: (year + 1).toString() });
    return years;
  },
  getCurrentMonth: (listMonth) => {
    let date = new Date();
    return listMonth[date.getMonth()];
  },
  getCurrentYear: () => {
    let date = new Date();
    return {
      value: date.getFullYear().toString(),
      name: date.getFullYear().toString(),
    };
  },
};
