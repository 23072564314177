import React, { Component } from "react";
import "./rekonsiliasi-dokumen.css";

import { connect } from "react-redux";
import { formatRequestDateV2 } from "../../../helpers/functionFormat";
import Loader from "../../../components/loader/Loader";
import { ReactComponent as ArrowLeftSvg } from "../../../assets/icons/arrow-left.svg";
import Table from "../../../components/table/Table";
// import Dropdown from '../../../components/dropdown/Dropdown';
import {
  getReconFailed,
  getReconFailedByDatetime,
} from "../../../redux/actions/reconsiliation";
import Button from "../../../components/button/Button";
import { ReactComponent as EyeSvg } from "../../../assets/icons/eye-line.svg";
import { ReactComponent as PrintSvg } from "../../../assets/icons/print.svg";
import Modal from "../../../components/modal/Modal";
import { BlobProvider, Document, Page, Text, View } from "@react-pdf/renderer";

class RekonsiliasiDokumenFail extends Component {
  state = {
    date: new Date(),
    atmId: null,
    withDatetime: false,
    atmName: null,
    atmType: { value: "all", name: "Semua ATM" },
    atmList: [
      { value: "all", name: "Semua ATM" },
      { value: "bca", name: "BCA" },
      { value: "bri", name: "BRI" },
      { value: "cimb", name: "CIMB Niaga" },
    ],
    documents: [],
    loading: false,
    modal: {
      show: false,
      body: null,
    },
    tableConfig: {
      search: "",
      page: 1,
      sort: "datetime",
      limit: 10,
    },
    meta: null,
  };

  renderPDF = (data) => {
    return (
      <Document>
        <Page
          size="A4"
          style={{
            display: "flex",
            paddingVertical: 25,
            paddingHorizontal: 30,
          }}
        >
          <View>
            <Text style={{ fontSize: 9 }}>{data}</Text>
          </View>
        </Page>
      </Document>
    );
  };

  componentDidMount() {
    if (this.props.match && this.props.match.params) {
      const numberPattern = /\d+/;
      const atm_id = this.props.match.params.id.match(numberPattern);
      const searchParams = new URLSearchParams(window.location.href);
      const withDatetime = searchParams.get("with-datetime") === "true";

      this.setState({
        atmId: atm_id ? atm_id[0] : this.props.match.params.id,
        date: this.props.match.params.tgl,
        withDatetime: withDatetime,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.tableConfig !== this.state.tableConfig ||
      prevState.date !== this.state.date ||
      prevState.atmId !== this.state.atmId
    ) {
      this.loadDocuments(this.state.date, this.state.atmId);
    }
  }

  formatTable = (data) => {
    let newInisiasiList = [];
    if (data) {
      newInisiasiList = data.map((item) => {
        return {
          id: item.transaction_id,
          waktu: formatRequestDateV2(item.date_time),
          atm_label: item.atm_label,
          namaATM: item.atm_name,
          type: item.type,
          pesan: (
            <div>
              <Button
                style={{ padding: "2px 4px 1px 4px" }}
                title={<EyeSvg />}
                onClick={() => {
                  this.setState({ modal: { show: true, body: item.notes } });
                }}
              />
              <BlobProvider document={this.renderPDF(item.notes)}>
                {({ url }) => (
                  <Button
                    style={{ padding: "2px 4px 1px 4px", marginLeft: 10 }}
                    title={<PrintSvg style={{ height: 20, width: 20 }} />}
                    onClick={async () => {
                      const win = window.open(url, "_blank");
                      win.focus();
                    }}
                  />
                )}
              </BlobProvider>
            </div>
          ),
        };
      });
    }

    return newInisiasiList;
  };

  loadDocuments = async (date, atmId) => {
    this.setState({ loading: true });
    try {
      const data = this.state.withDatetime
        ? await this.props.onGetDocReconsiliationByDatetime(
            date,
            atmId,
            this.state.tableConfig.page,
            this.state.tableConfig.limit,
            this.state.tableConfig.sort
          )
        : await this.props.onGetDocReconsiliation(
            date,
            atmId,
            this.state.tableConfig.page
          );

      this.setState({
        loading: false,
        documents: data.data.transactions,
        meta: {
          ...data.meta,
          pagination: Math.ceil(data.meta.total / data.meta.limit),
        },
      });
    } catch (error) {
      this.setState({ loading: false });
    }
  };

  onChangeTable = (identifier, value) => {
    this.setState((prevState) => {
      let page = identifier === "page" ? value : prevState.tableConfig.page;
      page = identifier === "search" ? 1 : page;
      return {
        ...prevState,
        tableConfig: {
          ...prevState.tableConfig,
          [identifier]: value,
          page: page,
        },
      };
    });
  };

  // onChangeDropdown = (item) => {
  //   this.setState({ atmType: item });
  // };

  render() {
    const columns = [
      {
        name: "Waktu",
        style: { width: "15%" },
      },
      {
        name: "Terminal ID",
        style: { width: "15%" },
      },
      {
        name: "Nama ATM",
        style: { width: "15%" },
      },
      {
        name: "Tipe Transaksi",
        style: { width: "15%" },
      },
      {
        name: "Pesan",
        style: { width: "15%" },
      },
    ];

    return (
      <div>
        <Modal
          show={this.state.modal.show}
          useHeader={true}
          title={<h3>Pesan</h3>}
          style={{ maxHeight: '90%', maxWidth: '100%' }}
          styleHeader={{ border: 'none', marginBottom: 0 }}
          modalClosed={() =>
            this.setState((prevState) => {
              return {
                ...prevState,
                modal: {
                  ...prevState.modal,
                  show: false,
                },
              };
            })
          }
        >
          {/*<span dangerouslySetInnerHTML={{__html: this.state.modal.body}} />*/}
          <pre>{this.state.modal.body}</pre>
          {/*<div style={{flex: 1, marginTop: 20, textAlign: 'end'}}>*/}
          {/*  <Button title="Print" />*/}
          {/*</div>*/}
        </Modal>
        <Loader show={this.state.loading} />
        <div
          className="back-rekon-doc"
          onClick={() => this.props.history.goBack()}
        >
          <ArrowLeftSvg
            className="arrow-back-rekon-doc"
            stroke={"#667AB3"}
            width={20}
            height={20}
          />
          <div className="label-back-rekon-doc">Kembali</div>
        </div>
        <div className="row container-detail-rekonsiliasi">
          <div className="col-12">
            <div className="row" style={{ justifyContent: "space-between" }}>
              <div>
                <h2 className="title-rekon-doc" style={{ margin: 15 }}>
                  Transaksi Gagal
                </h2>
                <p style={{ marginLeft: 15 }}>
                  Berikut ini adalah daftar transaksi yg gagal dilakukan
                </p>
              </div>
              {/* <div style={{ marginTop: 15 }}>
                <Dropdown
                  style={{ width: '200px' }}
                  styleValue={{ paddingTop: 8, paddingBottom: 8 }}
                  value={this.state.atmType}
                  options={this.state.atmList}
                  onChange={(item) => this.onChangeDropdown(item)}
                />
              </div> */}
            </div>
            <Table
              columns={columns}
              data={this.formatTable(this.state.documents)}
              styleTable={{ display: "table" }}
              tableConfig={this.state.tableConfig}
              useFooter={this.state.documents}
              onChange={this.onChangeTable}
              meta={
                this.state.meta
                  ? this.state.meta.total
                    ? this.state.meta
                    : null
                  : null
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onGetDocReconsiliation: (date, atmId, page) =>
      dispatch(getReconFailed(date, atmId, page)),
    onGetDocReconsiliationByDatetime: (datetime, atmId, page, limit, sort) =>
      dispatch(getReconFailedByDatetime(datetime, atmId, page, limit, sort)),
  };
};

export default connect(null, mapDispatchToProps)(RekonsiliasiDokumenFail);
