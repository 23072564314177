import React, {Component} from 'react';
import {connect} from 'react-redux';
import {deleteBranch, getBranchList} from "../../../redux/actions";
import Button from "../../../components/button/Button";
import Loader from "../../../components/loader/Loader";
import Alert from "../../../components/alert/Alert";
import Table from "../../../components/table/Table";
import { ReactComponent as DeleteSvg } from '../../../assets/icons/trash.svg';
import { ReactComponent as EditSvg } from '../../../assets/icons/pencil.svg';
import Modal from "../../../components/modal/Modal";

class BranchList extends Component {
  _isMounted = true;
  state = {
    tableConfig: {
      search: '',
      page: 1,
      sort: 'name',
      limit: 10
    },
    modal: {
      show: false,
      body: null,
    },
    loading: false,
    alert: {
      show: false,
      message: '',
      isError: false
    }
  }

  componentDidMount(){
    this.loadBranchList(
      this.state.tableConfig.page,
      this.state.tableConfig.limit,
      this.state.tableConfig.search,
      this.state.tableConfig.sort
    )
  }

  loadBranchList = async (page, limit, search, sort) => {
    // if(this._isMounted){
    //     this.setState({loading: true});
    // }
    try{
      await this.props.getBranchList(page, limit, search, sort);
      if(this._isMounted){
        this.setState({loading: false})
      }
    }catch(error){
      if(this._isMounted){
        this.setState({
          loading: false,
          alert: {
            ...alert,
            show: true,
            message: error.message,
            isError: true
          }
        })
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.tableConfig !== this.state.tableConfig) {
      this.loadBranchList(
        this.state.tableConfig.page,
        this.state.tableConfig.limit,
        this.state.tableConfig.search,
        this.state.tableConfig.sort
      )
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  formatTable = (data) => {
    return data?.map(item => {
      // console.log(item)
      return {
        id: item?.id,
        idComponent: item.code,
        name: item.name,
        location: item.location,
        action:(
          <div style={{alignItems: "center", justifyItems: "center", textAlign: 'center'}}>
            <div>
            <Button
              className="action_addUser"
              title={<DeleteSvg />}
              onClick={() => {
                this.setState({modal: {show: true, body: item}})
              }}
            />
            <Button
              style={{marginLeft: 20, paddingTop: 10, paddingBottom: 10}}
              className="action_addUser"
              title={<EditSvg />}
              onClick={() => this.props.history.push({
                key: 'add-branch',
                pathname: `/add-branch`,
                state: {type: 'Edit'}
              })}
            />
            </div>
          </div>
        ),
      }
    });
  }

  onChangeTable = (identifier, value) => {
    if(this._isMounted){
      this.setState(prevState => {
        let page = identifier === 'page'? value : prevState.tableConfig.page;
        page = identifier === 'search'? 1 : page;
        return {
          ...prevState,
          tableConfig: {
            ...prevState.tableConfig,
            [identifier]: value,
            page: page
          }
        }
      })
    }
  }

  onDeleteBranch = async () => {
    this.setState({loading: true});
    try {
      const response = await this.props.onDeleteBranch(this.state?.modal?.body?.id);
      this.setState({
        loading: false,
        alert: {
          ...alert,
          show: true,
          message: response.message,
          isError: false,
          onBtnPress: () => this.props.history.goBack(),
          onBackPress: () => this.props.history.goBack(),
        },
      });
      this.setState({modal: {show: false, body: null}})
      this.loadBranchList(
        this.state.tableConfig.page,
        this.state.tableConfig.limit,
        this.state.tableConfig.search,
        this.state.tableConfig.sort
      )
    } catch (error) {
      this.setState({modal: {show: false, body: null}})
      this.setState({
        loading: false,
        alert: {
          ...alert,
          show: true,
          message: error.message,
          isError: true,
          onBtnPress: null,
          onBackPress: null,
        },
      });
    }
  }

  render() {
    const columns = [{
      name: 'Kode Cabang',
      style: { width: 150 },
      onClick: () => this.setState(prevState => {
        return {
          ...prevState,
          tableConfig: {
            ...prevState.tableConfig,
            sort: prevState.tableConfig.sort === 'location'? '-location' : 'location'
          }
        }
      })
    }, {
      name: 'Nama Cabang',
      style: { width: 200 },
      onClick: () => this.setState(prevState => {
        return {
          ...prevState,
          tableConfig: {
            ...prevState.tableConfig,
            sort: prevState.tableConfig.sort === 'merk'? '-merk' : 'merk'
          }
        }
      })
    },{
        name: 'Keterangan Lokasi',
        style: { width: 350 },
        onClick: () => this.setState(prevState => {
          return {
            ...prevState,
            tableConfig: {
              ...prevState.tableConfig,
              sort: prevState.tableConfig.sort === 'merk'? '-merk' : 'merk'
            }
          }
        })
      },{
      name: 'Aksi',
      style: { width: 100, textAlign: 'center' }
    }]
    return (
      <div className="col-12">
        <Loader show={this.state.loading}/>
        <Alert
          show={this.state.alert.show}
          message={this.state.alert.message}
          isError={this.state.alert.isError}
          modalClosed={() => this.setState({alert: {...alert, show: false}})}
        />
        <div className="row">
          <div className="col-12">
            <Table
              columns={columns}
              data={this?.formatTable(this?.props?.branchData?.branch) ?? []}
              useHeader
              useFooter
              rightHeaderComponent={(
                <div style={{display: 'flex'}}>
                  {/* <Dropdown
                                        style={{width: '170px'}}
                                        value={{ value: 'all', name: 'Lokasi: Semua'}}
                                        options={[{ value: 'all', name: 'Lokasi: Semua'}]}
                                        onChange={() => {}}
                                    /> */}
                  <Button
                    className="action_addAtm"
                    style={{padding: '12px 20px 12px 20px', marginLeft: '10px'}}
                    title="Tambah Cabang"
                    onClick={() => this?.props?.history?.push('/add-branch', {type: 'Tambah'})}
                  />
                </div>
              )}
              tableConfig={this.state.tableConfig}
              onChange={this.onChangeTable}
              meta={this?.props?.branchData?.meta}
            />
          </div>
        </div>
        <Modal
          show={this.state.modal.show}
          useHeader={false}
          style={{ maxHeight: '550px' }}
          styleHeader={{ border: 'none', marginBottom: 0 }}
          modalClosed={() => this.setState({modal: {show: false, body: null}})}
        >
          <div style={{paddingTop: 40, paddingBottom: 30, textAlign: 'center'}}>
            <h3>Apakah anda yakin akan menghapus "Cabang {this.state?.modal?.body?.name }" ?</h3>
            <div style={{ marginTop: 50, marginBottom: 50 }} />
            <Button className="btn-default" title={<span style={{color: '#667AB3'}}>Batal</span>} onClick={() => this.setState({modal: {show: false, body: null}})} />
            <Button style={{marginLeft: 20}} title="Hapus" onClick={this.onDeleteBranch} />
          </div>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    branchData: state.branch
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBranchList: (page, limit, keyword, sort) => dispatch(getBranchList(page, limit, keyword, sort)),
    onDeleteBranch: (id) => dispatch(deleteBranch(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BranchList);
