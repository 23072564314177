import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { logout } from '../../redux/actions/auth';

class Logout extends Component {

    _isMounted;

    componentDidMount() {
        this._isMounted = true;
        if(this._isMounted) {
            this.props.onLogout();
        }
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    render() {
        return <Redirect to="/login" />
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onLogout: () => dispatch(logout())
    }
}

export default connect(null, mapDispatchToProps)(Logout);