import React, { Component } from "react";
import { connect } from "react-redux";
import BarChart from "../../../components/charts/BarChart";
import Dropdown from "../../../components/dropdown/Dropdown";
import { DropdownSelect } from "../../../components/dropdownAutoComplete";
import Loader from "../../../components/loader/Loader";
import { dropdownFunction } from "../../../helpers/functionEJ";
import {
  formatRupiah,
  formatUTCDisplayDate,
} from "../../../helpers/functionFormat";
import { getAllATMList } from "../../../redux/actions";
import { getReconDocByDateRange } from "../../../redux/actions/reconsiliation";
import "./rekonsiliasi-dokumen.css";

class RekonsiliasiDokumen extends Component {
  state = {
    date: new Date(),
    month: dropdownFunction.getCurrentMonth(dropdownFunction.listMonth()),
    year: dropdownFunction.getCurrentYear(),
    totalAmount: "Rp 0",
    totalMissMatch: 0,
    missmatchData: [],
    atmId: null,
    atmLabel: null,
    atmName: null,
    atmList: [],
    documents: {
      transactions: [],
      is_matched: false,
      message: "Data Tidak Tersedia",
    },
    loading: false,
  };

  loadATMList = async () => {
    try {
      const response = await this.props.onGetATMList();
      this.setState({ atmList: response.atm });
    } catch (error) {
      console.error("Failed to load ATM list:", error);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      (prevState.month.value !== this.state.month.value ||
        prevState.year.value !== this.state.year.value ||
        prevState.atmLabel !== this.state.atmLabel) &&
      this.state.atmLabel
    ) {
      this.loadDocuments(
        this.state.month.value,
        this.state.year.value,
        this.state.atmLabel
      );
    }
  }

  componentDidMount() {
    this.loadATMList();
  }

  formatTable = (data) => {
    let newInisiasiList = [];
    if (data && data.transactions) {
      newInisiasiList = data.transactions.map((item) => {
        return {
          id: item.transaction_id,
          nomorResi: item.resi_number,
          dateTime: formatUTCDisplayDate(item.date_time),
          total: formatRupiah(item.total, "."),
          type: item.type,
          fileId: "#" + item.transaction_id,
          lineNumber: <div style={{ textAlign: "center" }}>16</div>,
        };
      });
    }

    return newInisiasiList;
  };

  separateDaysByInterval = (maxDays) => {
    const numRows = maxDays > 30 ? 7 : 6;
    const numCols = 5;
    const twoDimArray = [];

    for (let i = 0; i < numRows; i++) {
      const row = [];
      for (let j = 0; j < numCols; j++) {
        const value = i * numCols + j + 1;
        if (value <= maxDays) {
          row.push(value);
        }
      }
      twoDimArray.push(row);
    }

    return twoDimArray;
  };

  loadDocuments = async (month, year, atmLabel) => {
    const maxDays = new Date(
      parseInt(this.state.year.value),
      parseInt(this.state.month.value),
      0
    ).getDate();
    const separateDay = this.separateDaysByInterval(maxDays);
    let totalAmount = 0;
    let totalMissMatch = 0;
    let missmatchData = [];
    for (let i = 0; i < separateDay.length; i++) {
      this.setState({ loading: true });
      const start_date = `${year}-${month}-${separateDay[i][0]
        .toString()
        .padStart(2, "0")}`;
      const end_date = `${year}-${month}-${separateDay[i][
        separateDay[i].length - 1
      ]
        .toString()
        .padStart(2, "0")}`;
      try {
        const { branchId } = this.props?.profile;

        const data = await this.props.onGetDocReconsiliationByDateRange(
          start_date,
          end_date,
          branchId,
          atmLabel
        );
        totalAmount += data?.amount;
        totalMissMatch += data?.total;
        for (let j = 0; j < data?.mismatch_datas?.length; j++) {
          const item = data.mismatch_datas[j];
          missmatchData = [
            ...missmatchData,
            {
              label:
                item.day.toString().length > 1
                  ? item.day.toString()
                  : "0" + item.day,
              value: item.value,
            },
          ];
        }
        this.setState({
          loading: false,
          totalAmount: formatRupiah(totalAmount, "."),
          totalMissMatch: totalMissMatch,
          missmatchData: missmatchData,
        });
      } catch (error) {
        this.setState({ loading: false });
      }
    }
  };

  render() {
    const atmList = this.props?.atmData?.atm?.map(
      ({ id, account_no, atm_label, name }) => ({
        id,
        account_no,
        value: atm_label,
        name: `${atm_label} - ${name.trim()}`,
      })
    );
    return (
      <div style={{ marginBottom: 80 }}>
        <Loader show={this.state.loading} />
        <h2 className="page-header header-detail-rekonsiliasi">
          Rekonsiliasi Dokumen
        </h2>
        <div className="row">
          <div className="col-12" style={{ display: "flex" }}>
            <div style={{ marginRight: 20 }}>
              <div
                className="label-detail-rekonsiliasi"
                style={{ marginBottom: 5 }}
              >
                Bulan
              </div>
              <Dropdown
                style={{ width: "200px" }}
                styleValue={{ paddingTop: 8, paddingBottom: 8 }}
                value={this.state.month}
                options={dropdownFunction.listMonth()}
                onChange={(item) => this.setState({ month: item })}
              />
            </div>
            <div style={{ marginRight: 5 }}>
              <div
                className="label-detail-rekonsiliasi"
                style={{ marginBottom: 5 }}
              >
                Tahun
              </div>
              <Dropdown
                style={{ width: "200px" }}
                styleValue={{ paddingTop: 8, paddingBottom: 8 }}
                value={this.state.year}
                options={dropdownFunction.listYear()}
                onChange={(item) => this.setState({ year: item })}
              />
            </div>

            <div
              className="col-3 col-md-12 col-sm-12"
              style={{ marginRight: 20 }}
            >
              <div
                className="label-detail-rekonsiliasi"
                style={{ marginBottom: 5 }}
              >
                ATM
              </div>
              <DropdownSelect
                styleValue={{ paddingTop: 8, paddingBottom: 8 }}
                value={this.state.atm}
                options={atmList}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                } // eslint-disable-line no-shadow
                placeholder="Pilih ATM"
                noOptionsText="ATM tidak ditemukan"
                onChange={(e, v) => this.setState({ atmLabel: v?.value })}
              />
            </div>
          </div>
        </div>
        <div className="row" style={{ marginTop: "20px" }}>
          <div className="col-12" style={{ display: "flex" }}>
            <div style={{ marginRight: 20 }}>
              <div className="card-info-rekon-doc">
                <div className="title-info-rekon-doc">Total Missmatch</div>
                <div className="value-info-rekon-doc">
                  {this.state.totalMissMatch}
                </div>
              </div>
            </div>
            <div style={{ marginRight: 20 }}>
              <div className="card-info-rekon-doc">
                <div className="title-info-rekon-doc">Amount Missmatch</div>
                <div
                  className="amount-info-rekon-doc"
                  style={{
                    fontSize:
                      this.state.totalAmount.length > 10
                        ? (
                            (242 / this.state.totalAmount.length) *
                            1.4
                          ).toString() + "px"
                        : "25px",
                  }}
                >
                  {this.state.totalAmount}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <BarChart
              data={this?.state?.missmatchData}
              title="Data Missmatch per hari"
              color="#667AB3"
              labelX="Missmatch"
              tooltipLabel={(label) =>
                `${label ?? "-"} ${this?.state?.month?.name || "-"} ${
                  this?.state?.year?.name ?? "-"
                }`
              }
              onClick={(evt, elm) => {
                this.props.history.push({
                  key: `rekonsiliasi-dokumen-${this.state.year.value}-${
                    this.state.month.value
                  }-${this.state.missmatchData[elm[0].index].label}`,
                  pathname: `/rekonsiliasi-dokumen/${this.state.year.value}-${
                    this.state.month.value
                  }-${this.state.missmatchData[elm[0].index].label}`,
                  state: {
                    date: `${this.state.year.value}-${this.state.month.value}-${
                      this.state.missmatchData[elm[0].index].label
                    }`,
                    atmLabel: this.state.atmLabel,
                  },
                });
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.auth.profile,
    atmData: state?.atm,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetDocReconsiliationByDateRange: (
      startDate,
      endDate,
      branchId,
      atmLabel
    ) =>
      dispatch(getReconDocByDateRange(startDate, endDate, branchId, atmLabel)),
    onGetATMList: () => dispatch(getAllATMList()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RekonsiliasiDokumen);
