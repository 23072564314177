import * as actionTypes from "../actionTypes";

const initialState = {
    userId: null,
    token: null,
    profile: {
        userId: null,
        email: null,
        name: null,
        phone: null,
        roleId: null,
        role: null,
        photo: null,
        branches: [],
        branchId: '',
    },
    isFirstLoggedIn: false
}

const authReducer = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.AUTHENTICATE: {
            return {
                ...state,
                userId: action.userId,
                token: action.token,
                isFirstLoggedIn: true
            }
        }
        case actionTypes.UPDATE_PROFILE: {
            return {
                ...state,
                profile: action.profile
            }
        }
        case actionTypes.CHANGE_FIRST_LOGGEDIN: {
            return {
                ...state,
                isFirstLoggedIn: action.isFirstLoggedIn
            }
        }
        case actionTypes.LOGOUT: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}

export default authReducer;
