import React, { Component } from "react";
import { connect } from "react-redux";
import Loader from "../../../components/loader/Loader";
import { Card } from "@mui/material";
import { DropdownSelect } from "../../../components/dropdownAutoComplete";
import { getAllATMList } from "../../../redux/actions";
import DatePicker from "../../../components/datepicker/DatePicker";
import Button from "../../../components/button/Button";
import Alert from "../../../components/alert/Alert";
import { exportLogEJ } from "../../../redux/actions/other";

class ExportLogEJ extends Component {
  state = {
    loading: false,
    atm: null,
    date: null,
    alert: {
      show: false,
      message: "",
      isError: false,
      onBtnPress: null,
      onBackPress: null,
    },
  };

  componentDidMount() {
    this.loadATMList(1, 10, "", "atm_id");
  }

  loadATMList = async (page, limit, search, sort) => {
    try {
      await this.props.onGetATMList(page, limit, search, sort);
      if (this._isMounted) {
        this.setState({ loading: false });
      }
    } catch (error) {
      if (this._isMounted) {
        this.setState({
          loading: false,
          alert: {
            ...alert,
            show: true,
            message: error.message,
            isError: true,
          },
        });
      }
    }
  };

  onSubmit = async () => {
    try {
      this.setState({ loading: true });
      const response = await this.props.onExportLogEJ(
        this.state.date,
        this.state.atm
      );
      this.setState({
        alert: {
          ...alert,
          show: true,
          message: response?.message,
          isError: false,
        },
        loading: false,
      });
    } catch (error) {
      // this.setState({loading: false});
      this.setState({
        loading: false,
        alert: {
          ...alert,
          show: true,
          message: error.message,
          isError: true,
        },
      });
    }
  };

  render() {
    const atmList = this.props?.atmData?.atm?.map(
      ({ id, account_no, atm_label, name }) => ({
        id,
        account_no,
        value: atm_label,
        name: `${atm_label} - ${name.trim()}`,
      })
    );

    return (
      <div>
        <h2 className="page-header">Export EJ Log</h2>
        <Loader show={this.state.loading} />
        <Alert
          show={this.state.alert.show}
          message={this.state.alert.message}
          isError={this.state.alert.isError}
          modalClosed={() =>
            this.setState({ alert: { ...alert, show: false } })
          }
          onBtnPress={this.state.alert.onBtnPress}
          onBackPress={this.state.alert.onBackPress}
        />
        <div className="row container-detail-rekonsiliasi">
          <div className="col-8 col-md-12 col-sm-12">
            <Card
              className="card-detail-rekonsiliasi"
              style={{ paddingTop: 30, paddingBottom: 30 }}
            >
              <h3 style={{ marginBottom: 20 }}>Informasi EJ Log</h3>
              <div
                className="row info-detail-rekonsiliasi"
                style={{ paddingTop: "20px" }}
              >
                <div
                  className="col-6 col-md-12 col-sm-12"
                  style={{ marginBottom: "20px" }}
                >
                  <label className="label-detail-rekonsiliasi">Tanggal</label>
                  <div style={{ width: "100%", marginTop: "5px" }}>
                    <DatePicker
                      selectsRange={this.state.document === "SWITCHING"}
                      date={this.state.date}
                      onchangeDate={(date) => {
                        this.setState({ date: date });
                      }}
                      customInputStyle={{
                        paddingTop: "8px",
                        paddingBottom: "8px",
                      }}
                      label="Pilih Tanggal"
                    />
                  </div>
                </div>
                <div
                  className="col-6 col-md-12 col-sm-12"
                  style={{ marginBottom: "20px" }}
                >
                  <label className="label-detail-rekonsiliasi">Pilih ATM</label>
                  <div style={{ width: "100%", marginTop: "5px" }}>
                    <DropdownSelect
                      style={{ width: "280px" }}
                      styleValue={{ paddingTop: 8, paddingBottom: 8 }}
                      value={this.state.atm}
                      options={atmList}
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      } // eslint-disable-line no-shadow
                      placeholder="Pilih ATM"
                      noOptionsText="ATM tidak ditemukan"
                      onChange={(e, v) => this.setState({ atm: v })}
                    />
                  </div>
                </div>
              </div>
              <div className="hr-detail-replenishment" />
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  disabled={!this.state.date || !this.state.atm}
                  title="Export"
                  onClick={this.onSubmit}
                />
              </div>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    atmData: state.atm,
    profile: state.auth.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetATMList: () => dispatch(getAllATMList()),
    onExportLogEJ: (file, date, atm) => dispatch(exportLogEJ(file, date, atm)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportLogEJ);
