import React, { Component } from 'react';
import './sent-email.css';

class SentEmail extends Component {
    render() {
        return (
            <div className="row container-sent-email">
                <div className="logo-container-sent-email">
                    <img 
                        className="logo-sent-email"
                        src={require('../../../assets/images/company-logo_2.png')} 
                        alt="Sample Bank" 
                        // style={{width: 100, height: 50}}
                    />
                </div>
                <div className="col-4 col-xm-5 col-xs-6 col-md-6 col-sm-10 card-sent-email">
                    <img 
                        className="img-verification-sent-email"
                        src={require('../../../assets/images/sent-email.png')}
                        alt="Verification Email"
                    />
                    <div className="title-sent-email">Email Berhasil Terkirim</div>
                    <span className="subtitle-sent-email">Anda akan menerima tautan pemulihan kata sandi di alamat email Anda.</span>
                    
                    <span className="resend-label-sent-email">Belum menerima Email?</span>
                    <span className="resend-button-sent-email">Kirim Ulang</span>
                </div>
            </div>
        )
    }
}

export default SentEmail;