import React, {Component} from 'react';
import './input-atm.css';
import Loader from '../../../components/loader/Loader';
import Alert from '../../../components/alert/Alert';
import Card from '../../../components/card/Card';
import TextInput from '../../../components/textInput/TextInput';
import Button from '../../../components/button/Button';
import {connect} from 'react-redux';
import {
  craeateAtmDenominator,
  createATM,
  createATMKey,
  detailATM,
  editATM,
  editAtmDenominator,
  findAtmDenominator,
} from '../../../redux/actions/atm';
import { formatRupiah } from '../../../helpers/functionFormat';
import Dropdown from "../../../components/dropdown/Dropdown";
import {getAllBranch} from "../../../redux/actions";
import {getDenominasiList} from "../../../redux/actions/denominasi";

class InputATM extends Component {
  state = {
    id: null,
    name: '',
    location: '',
    description: '',
    merk: '',
    denominatorId: null,
    type1: '',
    type2: '',
    type3: '',
    type4: '',
    norek: '',
    // jenis: 'ncr',
    key: '',
    label: '',
    branch: '',
    loading: false,
    elementWidth: 300,
    alert: {
      show: false,
      message: '',
      isError: false,
      onBtnPress: null,
      onBackPress: null,
    },
    tableConfig: {
      search: '',
      page: 1,
      sort: 'atm_id',
      limit: 10
    },
  };

  componentDidMount() {
    if (this.props.location && this.props.location.state) {
      this.setState({
        id: this.props.location.state.id,
        name: this.props.location.state.name,
        location: this.props.location.state.location,
        merk: this.props.location.state.merk,
        norek: this.props.location.state.norek,
        description: this.props.location.state.description,
        key: this.props.location.state.atm_key,
        label: this.props.location.state.atm_label,
      });
    }
    this.setState({elementWidth: this.textInputRef.current.offsetWidth})
    this.loadBranchList()
    this.loadDenominatorsList()
  }

  textInputRef = React.createRef();

  componentDidUpdate(prevProps, prevState) {
    if (prevState.id !== this.state.id) {
      this.onLoadATM(this.state.id);
      this.onFindAtmDenominator(this.state.id);
    }
  }

  onLoadATM = async (id) => {
    this.setState({ loading: true });
    try {
      const response = await this.props.onGetATM(id);
      // console.log('GET ATM', response?.data);
      this.setState({
        id: response.data.atm_id,
        name: response.data.name,
        branch: {
          value: response.data.Branch.branch_id,
          name: response.data.Branch.name
        },
        location: response.data.location,
        merk: response.data.merk,
        norek: response.data.account_no, // here norek
        key: response.data.atm_key,
        label: response.data.atm_label,
        description: response.data.description,
        loading: false,
      });
    } catch (error) {
      this.setState({
        loading: false,
        alert: {
          ...alert,
          show: true,
          message: error.message,
          isError: true,
          onBtnPress: null,
          onBackPress: null,
        },
      });
    }
  };

  loadBranchList = async () => {
    // if(this._isMounted){
    //     this.setState({loading: true});
    // }
    try{
      await this.props.getAllBranch();
      if(this._isMounted){
        this.setState({loading: false})
      }
    }catch(error){
      if(this._isMounted){
        this.setState({
          loading: false,
          alert: {
            ...alert,
            show: true,
            message: error.message,
            isError: true
          }
        })
      }
    }
  }

  loadDenominatorsList = async () => {
    // if(this._isMounted){
    //     this.setState({loading: true});
    // }
    try{
      await this.props.getDenominasi();
      if(this._isMounted){
        this.setState({loading: false})
      }
    }catch(error){
      if(this._isMounted){
        this.setState({
          loading: false,
          alert: {
            ...alert,
            show: true,
            message: error.message,
            isError: true
          }
        })
      }
    }
  }

  onCreateAtmDenominators = async (atmId) => {
    this.setState({ loading: true });
    try {
      const response = await this.props.onCreateAtmDenominators(
        atmId,
        this.state.type1,
        this.state.type2,
        this.state.type3,
        this.state.type4,
      );
      this.setState({
        name: '',
        location: '',
        description: '',
        merk: '',
        loading: false,
        alert: {
          ...alert,
          show: true,
          message: response.message,
          isError: false,
          onBtnPress: () => this.props.history.goBack(),
          onBackPress: () => this.props.history.goBack(),
        },
      });
    } catch (error) {
      this.setState({
        loading: false,
        alert: {
          ...alert,
          show: true,
          message: error.message,
          isError: true,
          onBtnPress: null,
          onBackPress: null,
        },
      });
    }
  }

  onEditAtmDenominators = async () => {
    this.setState({ loading: true });
    try {
      const response = await this.props.onEditAtmDenominators(
        this.state.denominatorId,
        this.state.id,
        this.state.type1,
        this.state.type2,
        this.state.type3,
        this.state.type4,
      );
      this.setState({
        name: '',
        location: '',
        description: '',
        merk: '',
        loading: false,
        alert: {
          ...alert,
          show: true,
          message: response.message,
          isError: false,
          onBtnPress: () => this.props.history.goBack(),
          onBackPress: () => this.props.history.goBack(),
        },
      });
    } catch (error) {
      this.setState({
        loading: false,
        alert: {
          ...alert,
          show: true,
          message: error.message,
          isError: true,
          onBtnPress: null,
          onBackPress: null,
        },
      });
    }
  }

  onFindAtmDenominator = async (id) => {
    this.setState({ loading: true });
    try {
      const response = await this.props.onFindAtmDenominator(id);
      const {data} = response;
      this.setState({
        loading: false,
        denominatorId:  data?.atm_denominator_id > 1 ? data?.atm_denominator_id : null,
        type1: data?.denominator_value_1,
        type2: data?.denominator_value_2,
        type3: data?.denominator_value_3,
        type4: data?.denominator_value_4,
      });
    } catch (error) {
      this.setState({
        loading: false,
        alert: {
          ...alert,
          show: true,
          message: error.message,
          isError: true,
          onBtnPress: null,
          onBackPress: null,
        },
      });
    }
  }

  onCreateATM = async () => {
    this.setState({ loading: true });
    try {
      const response = await this.props.onCreateATM(
        this.state.name,
        this.state.location,
        this.state.description,
        this.state.merk,
        this.state.norek,
        this.state.key,
        this.state.label,
        this.state.branch,
      );
      await this.onCreateAtmDenominators(response?.id);
      // await this.props.onCreateATMKey();
      this.setState({
        name: '',
        location: '',
        description: '',
        merk: '',
        loading: false,
        alert: {
          ...alert,
          show: true,
          message: response.message,
          isError: false,
          onBtnPress: () => this.props.history.goBack(),
          onBackPress: () => this.props.history.goBack(),
        },
      });
    } catch (error) {
      this.setState({
        loading: false,
        alert: {
          ...alert,
          show: true,
          message: error.message,
          isError: true,
          onBtnPress: null,
          onBackPress: null,
        },
      });
    }
  };

  onEditATM = async () => {
    this.setState({ loading: true });
    try {
      const response = await this.props.onEditATM(
        this.state.id,
        this.state.name,
        this.state.location,
        this.state.description,
        this.state.merk,
        this.state.norek,
        this.state.key,
        this.state.label,
        this.state.branch
      );
      if(this.state.denominatorId) {
        await this.onEditAtmDenominators();
      } else {
        await this.onCreateAtmDenominators(this.state.id);
      }

      this.setState({
        loading: false,
        alert: {
          ...alert,
          show: true,
          message: response.message,
          isError: false,
          onBtnPress: () => this.props.history.goBack(),
          onBackPress: () => this.props.history.goBack(),
        },
      });
    } catch (error) {
      this.setState({
        loading: false,
        alert: {
          ...alert,
          show: true,
          message: error.message,
          isError: true,
        },
      });
    }
  };

  validateIPaddress = (ipaddress) => {
    if (
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
        ipaddress
      )
    ) {
      return true;
    }
    return false;
  };


  formatDropdownDenominasi = () => {
    const list = this?.props?.denominasiData?.denominasi.map(item => {
      return {
        value: item?.value,
        name: formatRupiah(item.value, '.')
        // name: `${formatRupiah(item.value, '.')} (${item?.description})`
      }
    });
    list.unshift({value: '', name: 'Pilih Denominator'})
    return list;
  }

  getValue = (value) => {
    if(value) {
      return {value: value, name: formatRupiah(value, '.')}
    } else {
      return {value: '', name: ''}
    }
  }

  render() {
    const branchData = this.props.branchData?.branchAll?.map(({ name, id}) => ({value: id, name: name}))
    return (
      <div className='col-12' style={{ marginBottom: 20 }}>
        <Loader show={this.state.loading} />
        <Alert
          show={this.state.alert.show}
          message={this.state.alert.message}
          isError={this.state.alert.isError}
          modalClosed={() =>
            this.setState({ alert: { ...alert, show: false } })
          }
          onBtnPress={this.state.alert.onBtnPress}
          onBackPress={this.state.alert.onBackPress}
        />
        <div className='row'>
          <div className='col-8'>
            <h4 className='title-input-atm'>
              {this.state.id ? 'Edit ATM' : 'Tambah ATM'}
            </h4>
            <Card>
              <div className='col-12' ref={this.textInputRef}>
                <TextInput
                  className='input_namaAtm'
                  label='Nama ATM'
                  placeholder='Nama ATM'
                  value={this.state.name}
                  onChange={(e) => this.setState({ name: e.target.value })}
                  style={{
                    fontSize: '16px',
                    paddingTop: '2px',
                    paddingBottom: '2px',
                  }}
                />
                <div style={{ marginBottom: 20 }}></div>
                <label className="textinput-label">Cabang</label>
                <div style={{marginTop: '5px'}}>
                  <Dropdown
                      placeholder='Pilih Cabang'
                      className="input_peran"
                      style={{width: this.state.elementWidth - 30}}
                      containerStyle={{width: '100%'}}
                      styleValue={{padding: '8px 10px'}}
                      value={this.state.branch}
                      options={branchData}
                      onChange={(value) => this.setState({ branch: value })}
                  />
                </div>
                <div style={{ marginBottom: 20 }}></div>
                <TextInput
                  className='input_lokasi'
                  label='Lokasi'
                  placeholder='Lokasi'
                  value={this.state.location}
                  onChange={(e) => this.setState({ location: e.target.value })}
                  style={{
                    fontSize: '16px',
                    paddingTop: '2px',
                    paddingBottom: '2px',
                  }}
                />
                <div style={{ marginBottom: 20 }}></div>
                <TextInput
                  className='input_labelAtm'
                  label='Terminal ID'
                  placeholder='Terminal ID'
                  value={this.state.label}
                  onChange={(e) => this.setState({ label: e.target.value })}
                  style={{
                    fontSize: '16px',
                    paddingTop: '2px',
                    paddingBottom: '2px',
                  }}
                />
                <div style={{ marginBottom: 20 }}></div>
                {/*<TextInput*/}
                {/*  className='input_merkAtm'*/}
                {/*  label='Merek ATM'*/}
                {/*  placeholder='Merek ATM'*/}
                {/*  value={this.state.merk}*/}
                {/*  onChange={(e) => this.setState({ merk: e.target.value })}*/}
                {/*  style={{*/}
                {/*    fontSize: '16px',*/}
                {/*    paddingTop: '2px',*/}
                {/*    paddingBottom: '2px',*/}
                {/*  }}*/}
                {/*/>*/}
                <div style={{ marginBottom: 20 }}></div>
                <label className="textinput-label">Denominator Type</label>
                <Card style={{padding: 10, marginTop: 10}}>
                  <div className="textinput-label textinput-label-input-user">Type 1</div>
                  <Dropdown
                    containerStyle={{width: '100%'}}
                    style={{width: this.state.elementWidth - 54}}
                    styleValue={{padding: '8px 10px'}}
                    value={this.getValue(this.state.type1)}
                    placeholder="Pilih Denominator Type 1"
                    options={this.formatDropdownDenominasi()}
                    onChange={(value) => this.setState({type1: value?.value})}
                  />
                  <div style={{ marginBottom: 15 }}></div>
                  <div className="textinput-label textinput-label-input-user">Type 2</div>
                  <Dropdown
                    containerStyle={{width: '100%'}}
                    style={{width: this.state.elementWidth - 54}}
                    styleValue={{padding: '8px 10px'}}
                    value={this.getValue(this.state.type2)}
                    placeholder="Pilih Denominator Type 2"
                    options={this.formatDropdownDenominasi()}
                    onChange={(value) => this.setState({type2: value?.value})}
                  />
                  <div style={{ marginBottom: 15 }}></div>
                  <div className="textinput-label textinput-label-input-user">Type 3</div>
                  <Dropdown
                    containerStyle={{width: '100%'}}
                    style={{width: this.state.elementWidth - 54}}
                    styleValue={{padding: '8px 10px'}}
                    value={this.getValue(this.state.type3)}
                    placeholder="Pilih Denominator Type 3"
                    options={this.formatDropdownDenominasi()}
                    onChange={(value) => this.setState({type3: value?.value})}
                  />
                  <div style={{ marginBottom: 15 }}></div>
                  <div className="textinput-label textinput-label-input-user">Type 4</div>
                  <Dropdown
                    containerStyle={{width: '100%'}}
                    style={{width: this.state.elementWidth - 54}}
                    styleValue={{padding: '8px 10px'}}
                    placeholder="Pilih Denominator Type 4"
                    value={this.getValue(this.state.type4)}
                    options={this.formatDropdownDenominasi()}
                    onChange={(value) => this.setState({type4: value?.value})}
                  />
                </Card>
                <div style={{ marginBottom: 20 }}></div>
                <TextInput
                  className='input_norek'
                  label='Nomor Rekening'
                  placeholder='Nomor Rekening'
                  value={this.state.norek}
                  onChange={(e) => this.setState({ norek: e.target.value })}
                  style={{
                    fontSize: '16px',
                    paddingTop: '2px',
                    paddingBottom: '2px',
                  }}
                />
                <div style={{ marginBottom: 20 }}></div>
                <label className='textinput-label'>Merek ATM</label>
                <div style={{ marginTop: '10px', display: 'flex' }}>
                  <div className='col-6'>
                    <input
                      type='radio'
                      name='merk'
                      value='ncr'
                      checked={this.state.merk === 'ncr'}
                      onChange={(e) => this.setState({ merk: 'ncr' })}
                    />
                    <label style={{ marginLeft: '10px' }} htmlFor='ncr'>
                      NCR
                    </label>
                  </div>
                  <div className='col-6'>
                    <input
                      type='radio'
                      name='merk'
                      value='hitachi'
                      checked={this.state.merk === 'hitachi'}
                      onChange={(e) => this.setState({ merk: 'hitachi' })}
                    />
                    <label style={{ marginLeft: '10px' }} htmlFor='hitachi'>
                      Hitachi
                    </label>
                  </div>
                  <div className='col-6'>
                    <input
                      type='radio'
                      name='merk'
                      value='hyosung'
                      checked={this.state.merk === 'hyosung'}
                      onChange={(e) => this.setState({merk: 'hyosung'})}
                    />
                    <label style={{marginLeft: '10px'}} htmlFor='hyosung'>
                      Hyosung
                    </label>
                  </div>
                </div>
                {this.state.merk === 'hitachi' && (
                  <div style={{ marginBottom: 20 }}></div>
                )}
                {this.state.merk === 'hitachi' && (
                  <TextInput
                    className='input_keyAtm'
                    label='IP Address ATM'
                    placeholder='IP Address ATM'
                    value={this.state.key}
                    onChange={(e) => this.setState({ key: e.target.value })}
                    style={{
                      fontSize: '16px',
                      paddingTop: '2px',
                      paddingBottom: '2px',
                    }}
                  />
                )}
                <div style={{ marginBottom: 20 }}></div>
                <TextInput
                  className='input_deskripsi'
                  label='Deskripsi'
                  placeholder='Deskripsi'
                  type='textarea'
                  value={this.state.description}
                  onChange={(e) =>
                    this.setState({ description: e.target.value })
                  }
                  style={{
                    fontSize: '16px',
                    paddingTop: '2px',
                    paddingBottom: '2px',
                  }}
                />
                <div style={{ marginBottom: 20 }}></div>
                <div style={{ borderBottom: '1px solid #E4E7EB' }} />
                <div className='col-12 btn-container-input-atm'>
                  <Button
                    disabled={
                      !this.state.name ||
                      !this.state.location ||
                      !this.state.merk ||
                      (this.state.merk === 'hitachi' &&
                        !this.validateIPaddress(this.state.key))
                    }
                    className='btn_tambahkan'
                    title={this.state.id ? 'Simpan Perubahan' : 'Tambahkan'}
                    onClick={this.state.id ? this.onEditATM : this.onCreateATM}
                  />
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    denominasiData: state.denominasi,
    branchData: state.branch
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetATM: (id) => dispatch(detailATM(id)),
    onEditATM: (id, name, location, desc, merk, norek, key, label, branch) =>
      dispatch(editATM(id, name, location, desc, merk, norek, key, label, branch)),
    onCreateATM: (name, location, desc, merk, norek, key, label, branch) =>
      dispatch(createATM(name, location, desc, merk, norek, key, label, branch)),
    onCreateATMKey: (id) => dispatch(createATMKey(id)),
    onFindAtmDenominator: (id) => dispatch(findAtmDenominator(id)),
    onCreateAtmDenominators: (id, type1, type2, type3, type4) => dispatch(craeateAtmDenominator(id, type1, type2, type3, type4)),
    onEditAtmDenominators: (denominatorId, id, type1, type2, type3, type4) => dispatch(editAtmDenominator(denominatorId, id, type1, type2, type3, type4)),
    getAllBranch: () => dispatch(getAllBranch()),
    getDenominasi: () => dispatch(getDenominasiList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InputATM);
