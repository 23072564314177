import axios from "axios";
import * as actionTypes from "../actionTypes";
import {logout} from "./auth";

export const getDenominasiList = () => {
  // console.log('BRANCH ID', branchId);
  return async (dispatch, getState) => {
    try {
      const url = getState().config.url;
      const token = getState().auth.token;

      const compare = ( a, b ) => {
        if ( a.value < b.value ){
          return -1;
        }
        if ( a.value > b.value ){
          return 1;
        }
        return 0;
      }

      const response = await axios.get(
        `${url}/denominators/list`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      );
      const resData = response.data.data.map((item) => {
        return {
          denominator_id: item?.denominator_id,
          value: item?.value,
          description: '',
        };
      });
      // console.log('DENOMINATOR LIST', JSON.stringify(resData, null, 4));
      await dispatch({
        type: actionTypes.GET_LIST_DENOMINASI,
        denominasi: resData.sort(compare),
        meta: {
          pagination: Math.ceil(
            response.data.meta.total / response.data.meta.limit
          ),
          total: response.data.meta.total,
          limit: response.data.meta.limit,
          page: response.data.meta.page,
        },
      });
    } catch (error) {
      let message = 'Internal Server Error';
      if (error.response) {
        if (error.response.data.errors) {
          if (error.response.data.errors[0].code === 1009) {
            dispatch(logout());
          }
          message = error.response.data.errors[0].message;
        }
      } else {
        message = error.message;
      }
      throw new Error(message);
    }
  };
};
