import React from 'react';
import './catridge-form.css';
import TextInput from '../textInput/TextInput';
import Card from '../card/Card';
import { formatRupiah } from '../../helpers/functionFormat';
import denominasiList from '../../assets/JsonData/denominasi-list.json';

const CatridgeForm = props => {

    const { index, showDelete, onDelete, item, onChange, isReport } = props;

    return (
        <div className="row container-catridge-form">
            <div className="col-12">
                <Card className="card-catridge-form">
                    <div className="header-catridge-form">
                        <h4>Catridge: {index + 1}</h4>
                        {showDelete && !isReport? <label className="btn-del-catridge-form" onClick={onDelete}>Hapus</label> : <div />}
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <TextInput 
                                className="input_nomorSari"
                                label="Nomor Seri"
                                labelstyle={{fontSize: '14px', fontWeight: '500', color: '#1A3650'}}
                                style={{fontSize: '14px'}}
                                value={item.nomorSeri}
                                onChange={(e) => onChange('nomorSeri', e.target.value)}
                                readOnly={isReport}
                            />
                        </div>
                        <div className="col-6">
                            <div className="label-catridge-form">Denominasi</div>
                            {denominasiList.map(deno => (
                                <div className="row" style={{alignItems: 'center'}} key={deno.denominator_id}>
                                    <div className="col-5">
                                        <label className="label-catridge-form">{formatRupiah(deno.value, '.')} x</label>
                                    </div>
                                    <div className="col-7">
                                        <TextInput 
                                            className={deno.classInput}
                                            style={{width: '100%', fontSize: '14px'}}
                                            righticon={(
                                                <span className="lembar-span-catridgeform">Lembar</span>
                                            )}
                                            type="number"
                                            value={item['deno_'+deno.denominator_id]? item['deno_'+deno.denominator_id].value : '0'}
                                            onChange={(e) => onChange('deno_'+deno.denominator_id, e.target.value < 0? '0' : e.target.value)}
                                            onBlur={() => {
                                                if(item['deno_'+deno.denominator_id]){
                                                    if(!item['deno_'+deno.denominator_id].value){
                                                        onChange('deno_'+deno.denominator_id, '0')
                                                    }
                                                    if (item['deno_'+deno.denominator_id].value < 0) {
                                                        onChange('deno_'+deno.denominator_id, "0");
                                                    }
                                                    else{
                                                        onChange(item['deno_'+deno.denominator_id].value.split("-").join(""));
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            ))}
                            
                            {/* <div className="row" style={{alignItems: 'center'}}>
                                <div className="col-5">
                                    <label className="value-catridge-form">Rp 50.000 x</label>
                                </div>
                                <div className="col-7">
                                     <TextInput 
                                        style={{width: '100%', fontSize: '14px'}}
                                        righticon={(
                                            <span className="lembar-span-catridgeform">Lembar</span>
                                        )}
                                        type="number"
                                        value={item.deno50}
                                        onChange={(e) => onChange('deno50', e.target.value)}
                                    />
                                </div>
                            </div> */}
                            {/* <div className="label-catridge-form" style={{marginTop: 15}}>Ditolak</div>
                            {denominasiList.map(deno => (
                                <div className="row" style={{alignItems: 'center'}} key={deno.denominator_id}>
                                    <div className="col-5">
                                        <label className="value-catridge-form">{formatRupiah(deno.value, '.')} x</label>
                                    </div>
                                    <div className="col-7">
                                        <TextInput 
                                            style={{width: '100%', fontSize: '14px'}}
                                            righticon={(
                                                <span className="lembar-span-catridgeform">Lembar</span>
                                            )}
                                            type="number"
                                            value={item['reject_'+deno.denominator_id].value}
                                            onChange={(e) => onChange('reject_'+deno.denominator_id, e.target.value)}
                                        />
                                    </div>
                                </div>
                            ))} */}
                            
                            <div className="hr-catridge-form" />
                            <div className="row">
                                <div className="col-4">
                                    <label className="value-catridge-form">Subtotal</label>
                                </div>
                                <div className="col-8" style={{textAlign: 'right'}}>
                                    <label className="label-catridge-form">{formatRupiah(item.total, '.')}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    )
}

export default CatridgeForm;