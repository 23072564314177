import axios from 'axios';
import * as actionTypes from '../actionTypes';
import { logout } from './auth';

export const getDashboardData = (branchId) => {
    return async (dispatch, getState) => {
        try{
            const url = getState().config.url;
            const token = getState().auth.token;

            if(branchId) {
                const response = await axios.get(
                  `${url}/dashboard?branch_ids=${branchId}`,
                  {
                      headers: {
                          'Content-Type': 'application/json',
                          Authorization: 'Bearer ' + token
                      }
                  }
                );
                await dispatch({
                    type: actionTypes.GET_DASHBOARD,
                    atm: response.data.data.total_atm,
                    iniasiUlang: response.data.data.total_initiation_replenishment,
                    rekonsiliasi: response.data.data.total_reconciliation_replenishment,
                    selisih: response.data.data.total_different_replenishment,
                    inactiveAtm: response.data.data.total_inactive_atm,
                })
            } else {
                await dispatch({
                    type: actionTypes.GET_DASHBOARD,
                    atm: 0,
                    iniasiUlang: 0,
                    rekonsiliasi: 0,
                    selisih: 0
                })
            }
        }catch(error){
            let message = 'Internal Server Error';
            if(error.response){
                if(error.response.data.errors){
                    if(error.response.data.errors[0].code === 1009){
                        dispatch(logout());
                    }
                    message = error.response.data.errors[0].message;
                }
            }else{
                message = error.message;
            }
            throw new Error(message);
        }
    }
}
