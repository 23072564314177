import * as actionTypes from '../actionTypes';

export const initialState = {
    user: {
        data: [],
        meta: {
            pagination: 1,
            total: 0,
            limit: 10,
            page: 1
        }
    },
    audit: {
        data: [],
        meta: {
            pagination: 1,
            total: 0,
            limit: 10,
            page: 1
        }
    },
    role: {
        data: [],
        meta: {
            pagination: 1,
            total: 0,
            limit: 10,
            page: 1
        }
    }
}

const userReducer = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.GET_LIST_AUDIT: {
            return {
                ...state,
                audit: {
                    ...state.all,
                    data: action.data,
                    meta: action.meta
                }
            }
        }
        case actionTypes.GET_LIST_USER: {
            return {
                ...state,
                user: {
                    ...state.rekonsiliasi,
                    data: action.data,
                    meta: action.meta
                }
            }
        }
        case actionTypes.GET_LIST_ROLE: {
            return {
                ...state,
                role: {
                    ...state.rekonsiliasi,
                    data: action.data,
                    meta: action.meta
                }
            }
        }
        default: {
            return state;
        }
    }
}

export default userReducer;