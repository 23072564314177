import axios from 'axios';
import {getProfile, logout} from './auth';
import * as actionTypes from "../actionTypes";
import {formatRequestDateOnly} from "../../helpers/functionFormat";

export const uploadFile = (file) => {
    return async (dispatch, getState) => {
        try{
            const url = getState().config.url;
            const token = getState().auth.token;

            const formData = new FormData();
            formData.append("image", file);

            const response = await axios.post(
                `${url}/upload`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: 'Bearer ' + token
                    }
                }
            );

            return {
                meta: response.data.meta.http_status,
                file: response.data.data.data.image_url
            }
        }catch(error){
            let message = 'Internal Server Error';
            if(error.response){
                if(error.response.data.errors){
                    if(error.response.data.errors[0].code === 1009){
                        dispatch(logout());
                    }
                    message = error.response.data.errors[0].message;
                }
            }else{
                message = error.message;
            }
            throw new Error(message);
        }
    }
}

export const uploadCoreStatement = (file, isSyariah) => {
    return async (dispatch, getState) => {
        try{
            const url = getState().config.url;
            const token = getState().auth.token;

            const formData = new FormData();
            formData.append("file", file);
            formData.append("is_syariah", isSyariah);
            // formData.append("date", date)

            // for (const pair of formData.entries()) {
            //     console.log(pair[0]+ ', ' + pair[1]);
            // }

            const response = await axios.post(
                `${url}/core-statement/upload`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: 'Bearer ' + token
                    }
                }
            );

            return {
                meta: response.data.meta.http_status,
                file: response.data.data.file_url,
                message: 'Unggah Core Statement Berhasil!',
            }
        }catch(error){
            let message = 'Internal Server Error';
            if(error.response){
                if(error.response.data.errors){
                    if(error.response.data.errors[0].code === 1009){
                        dispatch(logout());
                    }
                    message = error.response.data.errors[0].message;
                }
            }else{
                message = error.message;
            }
            throw new Error(message);
        }
    }
}

export const uploadSwitchingStatement = (file, startDate, endDate) => {
    return async (dispatch, getState) => {
        try{
            const url = getState().config.url;
            const token = getState().auth.token;

            const formData = new FormData();
            formData.append("file", file);
            formData.append("start_date", startDate);
            formData.append("end_date", endDate);


            const response = await axios.post(
                `${url}/switching-statement/upload`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: 'Bearer ' + token
                    }
                }
            );

            return {
                meta: response.data.meta.http_status,
                file: response.data.data.file_url,
                message: 'Unggah Switching Statement Berhasil!',
            }
        }catch(error){
            let message = 'Internal Server Error';
            if(error.response){
                if(error.response.data.errors){
                    if(error.response.data.errors[0].code === 1009){
                        dispatch(logout());
                    }
                    message = error.response.data.errors[0].message;
                }
            }else{
                message = error.message;
            }
            throw new Error(message);
        }
    }
}

export const downloadStatement = (data) => {
    return async (dispatch, getState) => {
        try{
            const url = getState().config.url;
            const token = getState().auth.token;

            let params = {};
            if (data?.type === 'core') {
                params.type_statement = data?.type;
                params.tanggal = data?.tanggal;
                params.is_syariah = data?.isSyariah;
                params.no_rekening = data?.atm?.account_no;
                params.override = data?.override;
            } else if (data?.type === 'switching') {
                params.type_statement = data?.type;
                params.tanggal = data?.tanggal;
                params.id_mesin = data?.atm?.value;
                params.override = data?.override;
            } else {
                throw new Error('Params tidak valid');
            }

            const response = await axios.post(
              `${url}/agregator-statement`,
              params,
              {
                  headers: {
                      'Content-Type': 'application/json',
                      Authorization: 'Bearer ' + token
                  }
              }
            );

            return {
                meta: response.data.meta.http_status,
                message: 'Ambil data Statement Berhasil!',
            }
        }catch(error){
            let message = 'Internal Server Error';
            if(error.response){
                if(error.response.data.errors){
                    if(error.response.data.errors[0].code === 1009){
                        dispatch(logout());
                    }
                    message = error.response.data.errors[0].message;
                }
            }else{
                message = error.message;
            }
            throw new Error(message);
        }
    }
}

export const getAllATMList = () => {
    // console.log('BRANCH ID', branchId);
    return async (dispatch, getState) => {
        try {
            const url = getState().config.url;
            const token = getState().auth.token;
            const response = await axios.get(
              `${url}/atm/list`,
              {
                  headers: {
                      'Content-Type': 'application/json',
                      Authorization: 'Bearer ' + token,
                  },
              }
            );
            // console.log('ATM LIST', JSON.stringify(response.data.data, null, 4));
            const resData = response.data.data.map((item) => {
                return {
                    id: item.atm_id,
                    name: item.name,
                    location: item.location,
                    merk: item.merk,
                    atm_label: item.atm_label,
                    description: item.description,
                    saldo: item.balance,
                    account_no: item?.account_no,
                    createdAt: item.created_at,
                    updatedAt: item.updated_at,
                };
            });
            await dispatch({
                type: actionTypes.GET_LIST_ATM,
                atm: resData,
                meta: {
                    pagination: Math.ceil(
                      response.data.meta.total / response.data.meta.limit
                    ),
                    total: response.data.meta.total,
                    limit: response.data.meta.limit,
                    page: response.data.meta.page,
                },
            });
        } catch (error) {
            let message = 'Internal Server Error';
            if (error.response) {
                if (error.response.data.errors) {
                    if (error.response.data.errors[0].code === 1009) {
                        dispatch(logout());
                    }
                    message = error.response.data.errors[0].message;
                }
            } else {
                message = error.message;
            }
            throw new Error(message);
        }
    };
};

export const setImportFeatures = (value) => {
    return async (dispatch, getState) => {
        try {
            const url = getState().config.url;
            const token = getState().auth.token;
            const body = {
                env_value: value.toString()
            }
            const response = await axios.put(
              `${url}/com-env-settings/FEATURE_IMPORT_STATEMENT`,
              body,
              {
                  headers: {
                      'Content-Type': 'application/json',
                      Authorization: 'Bearer ' + token,
                  },
              }
            );
            if (response) {
                await dispatch(getProfile(token))
            }
            return {
                meta: response.data.meta.http_status,
                message: response.data.meta.message,
            }
        } catch (error) {
            let message = 'Internal Server Error';
            if (error.response) {
                if (error.response.data.errors) {
                    if (error.response.data.errors[0].code === 1009) {
                        dispatch(logout());
                    }
                    message = error.response.data.errors[0].message;
                }
            } else {
                message = error.message;
            }
            throw new Error(message);
        }
    };
};

export const setIntegrateFeatures = (value) => {
    return async (dispatch, getState) => {
        try {
            const url = getState().config.url;
            const token = getState().auth.token;
            const body = {
                env_value: value.toString()
            }
            const response = await axios.put(
              `${url}/com-env-settings/FEATURE_INTEGRATE_STATEMENT_BY_API`,
              body,
              {
                  headers: {
                      'Content-Type': 'application/json',
                      Authorization: 'Bearer ' + token,
                  },
              }
            );
            if (response) {
                await dispatch(getProfile(token))
            }
            return {
                meta: response.data.meta.http_status,
                message: response.data.meta.message,
            }
        } catch (error) {
            let message = 'Internal Server Error';
            if (error.response) {
                if (error.response.data.errors) {
                    if (error.response.data.errors[0].code === 1009) {
                        dispatch(logout());
                    }
                    message = error.response.data.errors[0].message;
                }
            } else {
                message = error.message;
            }
            throw new Error(message);
        }
    };
};

export const uploadLogEJ = (file, atm) => {
    return async (dispatch, getState) => {
        try{
            const url = getState().config.url;
            const token = getState().auth.token;

            const formData = new FormData();
            formData.append("file", file);
            formData.append("engine", atm?.merk);
            formData.append("atm_id", atm?.id);


            const response = await axios.post(
              `${url}/atm-transactions/parse`,
              formData,
              {
                  headers: {
                      'Content-Type': 'multipart/form-data',
                      Authorization: 'Bearer ' + token
                  }
              }
            );

            console.log(response);
            if(response?.data?.errors?.length > 0) {
                throw new Error(response?.data?.errors[0]?.message);
            } else {
                return {
                    meta: 200,
                    file: '',
                    message: 'Unggah Log EJ Berhasil!',
                }
            }
        } catch(error) {
            let message = 'Internal Server Error';
            if(error.response){
                if(error.response.data.errors){
                    if(error.response.data.errors[0].code === 1009){
                        dispatch(logout());
                    }
                    message = error.response.data.errors[0].message;
                }
            }else{
                message = error.message;
            }
            throw new Error(message);
        }
    }
}

export const exportLogEJ = (date, atm) => {
    const FileDownload = require('js-file-download');
    return async (dispatch, getState) => {
        try{
            const url = getState().config.url;
            const token = getState().auth.token;

            const response = await axios.get(
              `${url}/current-logs/export?atm_id=${atm?.id}&date=${formatRequestDateOnly(date)}`,
              // `${url}/current-logs/export?atm_id=38&date=2023-09-06`,
              {
                  headers: {
                      'Content-Type': 'application/json',
                      Authorization: 'Bearer ' + token
                  },
                  responseType: 'blob',
              }
            );

            FileDownload(response.data, `${atm?.name} (${formatRequestDateOnly(date)}).txt`);

            return {
                meta: 200,
                message: 'Export Log EJ Berhasil!',
            }
        } catch(error) {
            let message = 'File not found';
            if(error.response) {
                if (error.response.data.errors) {
                    if (error.response.data.errors[0].code === 1009) {
                        dispatch(logout());
                    }
                    message = error.response.data.errors[0].message;
                }
            }else{
                message = error.message;
            }
            throw new Error(message);
        }
    }
}
