const validationInput = (value, rules, equalObject) => {
    let isValid = true;
    let message = '';

    if(rules.isRequired) {
        isValid = value.trim() !== '' && isValid;
        if(!(value.trim() !== '')) {
            message = 'Harus diisi';
        }
    }

    if(rules.requiredObject && isValid){
        isValid = value.id.trim() !== '' && isValid;
        if(!(value.id.trim() !== '')) {
            message = 'Harus diisi';
        }
    }

    if(rules.minLength && isValid) {
        isValid = value.length >= rules.minLength && isValid;
        if(!(value.length >= rules.minLength)) {
            message = 'Panjang karakter minimal ' + rules.minLength + ' karakter';
        }
    }

    if(rules.maxLength && isValid) {
        isValid = value.length <= rules.maxLength && isValid;
        if(!(value.length <= rules.maxLength)) {
            message = 'Panjang karakter maksimal ' + rules.maxLength + ' karakter';
        }
    }

    if(rules.valueMinLength && isValid) {
        isValid = value >= rules.valueMinLength && isValid;
        if(!(value >= rules.valueMinLength)) {
            message = 'Minimal ' + rules.valueMinLength;
        }
    }

    if(rules.valueMaxLength && isValid) {
        isValid = value <= rules.valueMaxLength && isValid;
        if(!(value <= rules.valueMaxLength)) {
            message = 'Maksimal ' + rules.valueMaxLength;
        }
    }

    if(rules.equalTo && isValid) {
        isValid = value === equalObject.value && isValid;
        if(!(value === equalObject.value)) {
            message = 'Input harus sama dengan ' + equalObject.label;
        }
    }

    if(rules.isEmail && isValid) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test(value) && isValid;
        if(!(pattern.test(value)) && value !== '') {
            message = 'Email tidak sesuai';
        }
    }

    if(rules.isNumeric && isValid) {
        const pattern = /^\d+$/;
        isValid = pattern.test(value) && isValid;
        if(!(pattern.test(value)) && value !== '') {
            message = 'Harus diisi dengan angka';
        }
    }

    if(rules.isUsername && isValid) {
        const pattern = /^[A-Za-z0-9_-]+(?:[_-][A-Za-z0-9]+)*$/;
        isValid = pattern.test(value) && isValid;
        if(!(pattern.test(value)) && value !== ''){ 
            message = 'Tidak boleh ada karakter selain huruf atau angka';
        }
    }

    if(rules.isVerify && isValid) {
        isValid = equalObject && isValid;
        if(!equalObject && value !== ''){
            message = 'Belum terverifikasi!'
        }
    }

    // if(rules.upperCaseRequired && isValid){
    //     const pattern = /(?=.*[A-Z])/;
    //     isValid = pattern.test(value) && isValid;
    //     if(!pattern.test(value) && value !== ''){
    //         message = 'Input harus terdapat huruf besar';
    //     }
    // }

    // if(rules.lowerCaseRequired && isValid){
    //     const pattern = /(?=.*[a-z])/;
    //     isValid = pattern.test(value) && isValid;
    //     if(!pattern.test(value) && value !== ''){
    //         message = 'Input harus terdapat huruf kecil';
    //     }
    // }

    // if(rules.numberRequired && isValid){
    //     const pattern = /(?=.*\d)/;
    //     isValid = pattern.test(value) && isValid;
    //     if(!pattern.test(value) && value !== ''){
    //         message = 'Input harus terdapat angka';
    //     }
    // }

    // if(rules.symbolRequired && isValid){
    //     const pattern = /(?=.*\W)/;
    //     isValid = pattern.test(value) && isValid;
    //     if(!pattern.test(value) && value !== ''){
    //         message = 'Input harus terdapat simbol';
    //     }
    // }

    if(rules.upperCaseRequired && rules.lowerCaseRequired && rules.numberRequired && rules.symbolRequired && isValid){
        let pattern = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/;
        let patternUpper = /(?=.*[A-Z])/;
        let patternLower = /(?=.*[a-z])/;
        let patternNumber = /(?=.*\d)/;
        let patternSymbol = /(?=.*\W)/;

        isValid = pattern.test(value) && isValid;

        message = 'Input harus terdiri dari ';
        let arrayError = [];

        if(!patternUpper.test(value)) {
            arrayError.push("huruf besar");
        }
        if(!patternLower.test(value)){
            arrayError.push("huruf kecil");
        }
        if(!patternNumber.test(value)){
            arrayError.push("angka");
        }
        if(!patternSymbol.test(value)){
            arrayError.push("simbol");
        }
        let lastMessage = '';
        if(arrayError.length > 1){
            lastMessage = " dan " + arrayError[arrayError.length - 1];
            arrayError.pop();
        }
        let arrayMessage = arrayError.join(", ");
        message = message + arrayMessage + lastMessage;
        // let pattern = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/;
        // isValid = pattern.test(value) && isValid;
        // if(!pattern.test(value) && value !== ''){
        //     message = 'Input harus terdiri huruf besar, huruf kecil, angka dan simbol'
        // }
    }

    return {
        valid: isValid,
        messageError: message
    };
}

export default validationInput;