import React, { Component } from 'react';
import './detail-replenishment.css';
import Card from '../../../components/card/Card';
import TextInput from '../../../components/textInput/TextInput';
import Button from '../../../components/button/Button';
import CatridgeForm from '../../../components/catridge/CatridgeForm';
import { formatRupiah, formatRequestDateOnly } from '../../../helpers/functionFormat';
import denominasiList from '../../../assets/JsonData/denominasi-list.json';
import Loader from '../../../components/loader/Loader';
import Alert from '../../../components/alert/Alert';
import { connect } from 'react-redux';
import { createReplenishment } from '../../../redux/actions';
import DatePicker from '../../../components/datepicker/DatePicker';

class FormInisiasiUlang extends Component {

    state = {
        atm: {
            id: null,
            name: '',
            location: '',
            saldo: 0
        },
        date: null,
        // dateTime: '',
        catridges: [],
        total: '0',
        loading: false,
        alert: {
            show: false,
            message: '',
            isError: false
        }
    }

    componentDidMount(){
        if(this.props.location && this.props.location.state){
            this.setState({
                atm: {
                    id: this.props.location.state.atmId,
                    name: this.props.location.state.atmName,
                    location: this.props.location.state.atmLocation,
                    saldo: this.props.location.state.saldo
                }
            })
        }
        this.onAddCatridge();
    }

    onAddCatridge = () => {
        let denoList = {};
        let rejectList = {};
        for(let i=0; i<denominasiList.length; i++){
            denoList = {
                ...denoList,
                [`deno_${denominasiList[i].denominator_id}`]: {
                    id: denominasiList[i].denominator_id,
                    value: '0'
                }
            }
            rejectList = {
                ...rejectList,
                [`reject_${denominasiList[i].denominator_id}`]: {
                    id: denominasiList[i].denominator_id,
                    value: '0'
                }
            }
        }

        let catridge = {
            nomorSeri: '',
            ...denoList,
            ...rejectList,
            total: '0'
        }
        let catridges = this.state.catridges;
        catridges.push(catridge);
        this.setState({catridges: catridges});
    }

    onDeleteCatridge = (index) => {
        let catridges = this.state.catridges;
        catridges.splice(index, 1);
        let totalAll = 0;
        for(let i=0; i<catridges.length; i++){
            totalAll += parseInt(catridges[i].total);
        }
        this.setState({catridges: catridges, total: totalAll})
    }

    onChangeInputCatridge = (index, identifier, value) => {
        let catridges = this.state.catridges;
        let catridge = this.state.catridges[index];

        if(typeof catridge[identifier] === "object"){
            catridge = {
                ...catridge,
                [identifier]: {
                    ...catridge[identifier],
                    value: isNaN(value)? 0 : value
                }
            }
        }else{
            catridge = {
                ...catridge,
                [identifier]: value
            }
        }

        let total = 0;

        for(let i=0; i<denominasiList.length; i++){
            total = total + (denominasiList[i].value * parseInt(catridge[`deno_${denominasiList[i].denominator_id}`].value));
            total = total - (denominasiList[i].value * parseInt(catridge[`reject_${denominasiList[i].denominator_id}`].value));
        }
        total = isNaN(total)? '0' : total;
        catridge = {
            ...catridge,
            total: total
        }
        catridges[index] = catridge;
        this.setState({catridges: catridges})
        let totalAll = 0;
        for(let i=0; i<this.state.catridges.length; i++){
            totalAll += parseInt(this.state.catridges[i].total);
        }
        this.setState({total: totalAll});
    }

    onSubmitHandler = async () => {
        this.setState({loading: true})
        try{
            if(!this.state.date){
                throw new Error("Tanggal Isi Ulang tidak boleh kosong!")
            }
            let catridges = this.state.catridges.map(item => {
                let details = [];
                for(let i=0; i<denominasiList.length; i++){
                    details.push({
                        denominator_id: parseInt(item[`deno_${denominasiList[i].denominator_id}`].id),
                        value: parseInt(item[`deno_${denominasiList[i].denominator_id}`].value)
                    })
                }
                return {
                    serial_number: item?.nomorSeri,
                    sub_total: item?.total,
                    type: 'IN',
                    replenishment_cartridge_details: details
                }
            })
            const response = await this.props.onCreateReplenishment(
                this.state.atm.id,
                formatRequestDateOnly(this.state.date),
                this.state.total,
                catridges
            );
            this.setState({
                loading: false,
                alert: {
                    ...alert,
                    show: true,
                    message: response.message,
                    isError: false,
                    onBtnPress: () => this.props.history.goBack()
                    // onBackPress: () => this.props.history.goBack()
                }
            })
        }catch(error){
            this.setState({
                loading: false,
                alert: {
                    ...alert,
                    show: true,
                    message: error.message,
                    isError: true
                }
            })
        }
    }

    render() {
        return (
            <div>
                <Loader show={this.state.loading}/>
                <Alert
                    show={this.state.alert.show}
                    message={this.state.alert.message}
                    isError={this.state.alert.isError}
                    modalClosed={() => this.setState({alert: {...alert, show: false}})}
                />
                <h2 className="page-header header-detail-replenishment">Inisiasi Isi Ulang</h2>
                <div className="row container-detail-replenishment">
                    <div className="col-8">
                        <Card className="card-detail-replenishment">
                            <div className="row info-detail-replenishment">
                                <div className="col-12" style={{marginBottom: 10}}>
                                    <TextInput
                                        label="ATM"
                                        readOnly
                                        labelstyle={{fontSize: '14px', fontWeight: 'bold', color: '#1A3650'}}
                                        style={{fontSize: '16px'}}
                                        value={this.state.atm.id + " - " + this.state.atm.name}
                                    />
                                </div>
                                <div className="col-6">
                                    <TextInput
                                        label="Penanggung Jawab"
                                        readOnly
                                        labelstyle={{fontSize: '14px', fontWeight: 'bold', color: '#1A3650'}}
                                        style={{fontSize: '16px'}}
                                        value={this.props.user.name}
                                    />
                                </div>
                                <div className="col-6">
                                    <TextInput
                                        label="Saldo Awal"
                                        readOnly
                                        labelstyle={{fontSize: '14px', fontWeight: 'bold', color: '#1A3650'}}
                                        style={{fontSize: '16px'}}
                                        value={formatRupiah(this.state.atm.saldo, ".")}
                                    />
                                </div>
                            </div>
                            <div className="hr-detail-replenishment" />
                            <div className="row info-detail-replenishment">
                                <div className="col-12">
                                    <h4 style={{marginBottom: '10px'}}>Tanggal Isi Ulang</h4>
                                    <div style={{width: 180}}>
                                        <DatePicker
                                            label="Tanggal Isi Ulang"
                                            date={this.state.date}
                                            onchangeDate={date => this.setState({date: date})}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="hr-detail-replenishment" />
                            <div className="row info-detail-replenishment">
                                <div className="col-12">
                                    <h4>Isi Ulang</h4>
                                </div>
                            </div>
                            {this.state.catridges.map((item, index) => (
                                <CatridgeForm
                                    key={index.toString()}
                                    index={index}
                                    item={item}
                                    showDelete={this.state.catridges.length > 1}
                                    onDelete={() => this.onDeleteCatridge(index)}
                                    onChange={(identifier, value) => this.onChangeInputCatridge(index, identifier, value)}
                                />
                            ))}
                            <Button
                                className="btn-default"
                                title="+  Tambah Catridge"
                                style={{width: '100%', marginBottom: 20}}
                                onClick={this.onAddCatridge}
                            />
                            <div className="row container-detail-replenishment">
                                <div className="col-6">
                                    <label className="total-detail-replanishment">Total Isi Ulang</label>
                                </div>
                                <div className="col-6" style={{textAlign: 'right'}}>
                                    <label className="total-detail-replanishment">{formatRupiah(this.state.total, '.')}</label>
                                </div>
                            </div>
                            <div className="hr-detail-replenishment" />
                            <div className="row container-detail-replenishment">
                                <div className="col-12" style={{ alignItems: 'flex-end'}}>
                                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                        <Button
                                            className="submit"
                                            title="Submit"
                                            onClick={this.onSubmitHandler}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="col-4"></div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.profile
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onCreateReplenishment: (atmId, dateTime, balance, catridges) => dispatch(createReplenishment(atmId, dateTime, balance, catridges))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormInisiasiUlang);
