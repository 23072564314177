import React, {useEffect, useState} from "react";
import TextInput from '../../../components/textInput/TextInput';
import './InputBranch.css';
import Button from "../../../components/button/Button";

const ModalUpdate = ({type = 'Tambah', onClose, onCreateBranch, item, onUpdateBranch}) => {
  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [location, setLocation] = useState('');
  const [rootId, setRootId] = useState(null);

  const onSubmit = () => {
    const value = {
      code,
      name,
      location,
      rootId
    }
    if(type === 'Tambah') {
      onCreateBranch(value);
      setCode('');
      setName('');
      setLocation('');
      setRootId(null);
    } else {
      value.id = item?.branch_id;
      onUpdateBranch(value);
      setCode('');
      setName('');
      setLocation('');
      setRootId(null);
    }
  }

  useEffect(() => {
    if(type === 'Edit') {
      setCode(item?.code);
      setName(item?.name);
      setLocation(item?.location);
      setRootId(parseInt(item?.root_id) > 0 && item?.root_id !== '' && item?.root_id? item?.root_id : null);
    } else {
      setCode('');
      setName('');
      setLocation('');
      setRootId(item?.branch_id ?? null);
    }
  }, [item, type])

  return (
    <div style={{padding: 10}}>
      <h2>{type} Cabang</h2>
      <div style={{marginTop: 40}}>
        <TextInput
          label='Kode Cabang'
          placeholder='Kode Cabang'
          value={code}
          onChange={(e) => setCode(e.target.value)}
          style={{
            fontSize: '16px',
            paddingTop: '2px',
            paddingBottom: '2px',
          }}
        />
        <div style={{height: 20}} />
        <TextInput
          label='Nama Cabang'
          placeholder='Nama Cabang'
          value={name}
          onChange={(e) => setName(e.target.value)}
          style={{
            fontSize: '16px',
            paddingTop: '2px',
            paddingBottom: '2px',
          }}
        />
        <div style={{height: 20}} />
        <TextInput
          label='Keterangan Lokasi'
          placeholder='Keterangan Lokasi'
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          style={{
            fontSize: '16px',
            paddingTop: '2px',
            paddingBottom: '2px',
          }}
        />
      </div>
      <div className="bottom-content padding-vertical-10">
        <Button className="btn-default" title={<span style={{color: '#667AB3'}}>Batal</span>} onClick={onClose} />
        <Button style={{marginLeft: 20}} title="Simpan" onClick={onSubmit} />
      </div>
    </div>
  )
}

export default ModalUpdate;
