import React, { useState } from 'react';
import './layout.css';
import TopNav from './topnav/TopNav';
import Sidebar from './sidebar/Sidebar';
import { BrowserRouter, Route } from 'react-router-dom';

const Layout = props => {

    const [showDrawer, setShowDrawer] = useState(false);

    const { children, profile } = props;

    return (
        <BrowserRouter>
            <Route render={(props) => (
                <div className={`layout theme-mode-light theme-color-blue`}>
                    <Sidebar 
                        {...props} 
                        profile={profile} 
                        showDrawer={showDrawer} 
                        closeDrawer={() => setShowDrawer(false)} 
                    />
                    <div className="layout__content" onClick={showDrawer? () => setShowDrawer(false) : () => {}}>
                        <TopNav profile={profile} onToggleDrawer={() => setShowDrawer(!showDrawer)}/>
                        <div className="layout__content-main">
                            {children}
                        </div>
                    </div>
                </div>
            )}/>
        </BrowserRouter>
    )
}

export default Layout;
