import React, { Component } from 'react';
import Table from '../../../components/table/Table';
// import Dropdown from '../../../components/dropdown/Dropdown';
import { connect } from 'react-redux';
import { getAuditList } from '../../../redux/actions';
import { formatDisplayDate } from '../../../helpers/functionFormat';
import Loader from '../../../components/loader/Loader';
import Alert from '../../../components/alert/Alert';

class Audit extends Component {

    _isMounted;

    state = {
        tableConfig: {
            search: '',
            page: 1,
            sort: 'name',
            limit: 10
        },
        loading: false,
        alert: {
            show: false,
            message: '',
            isError: false
        }
    }

    componentDidMount(){
        this.onGetAuditList(
            this.state.tableConfig.page,
            this.state.tableConfig.limit,
            this.state.tableConfig.search,
            this.state.tableConfig.sort
        )
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.tableConfig !== this.state.tableConfig) {
            this.onGetAuditList(
                this.state.tableConfig.page,
                this.state.tableConfig.limit,
                this.state.tableConfig.search,
                this.state.tableConfig.sort
            )
        }
    }

    onGetAuditList = async (page, limit, search, sort) => {
        // this.setState({loading: true});
        try{
            await this.props.onGetAuditList(page, limit, search, sort);
            this.setState({loading: false});
        }catch(error){
            this.setState({loading: false});
            this.setState({
                alert: {
                    ...alert,
                    show: true,
                    message: error.message,
                    isError: true
                }
            })
        }
    }

    formatTable = (data) => {
        if(!data){
            return [];
        }
        const newAuditList = data.map(item => {
            return {
                id: item.com_log_detail_id,
                user: (
                    <div>
                        <div>{item.user.name}</div>
                        <div>{item.user.email}</div>
                    </div>
                ),
                aksi: item.endpoint? item.endpoint : "Inisiasi Ulang",
                timestamp: (
                    <div style={{flex: 'wrap', whiteSpace: 'initial'}}>{formatDisplayDate(item.created_at)}</div>
                ),
                userAgent: <div style={{flex: 'wrap', whiteSpace: 'initial'}}>{item.user_agent}</div>,
                ipAddress: item.ip_address? item.ip_address : "127.0.0.1"
            }
        })

        return newAuditList;

    }

    onChangeTable = (identifier, value) => {
        this.setState(prevState => {
            let page = identifier === 'page'? value : prevState.tableConfig.page;
            page = identifier === 'search'? 1 : page;
            return {
                ...prevState,
                tableConfig: {
                    ...prevState.tableConfig,
                    [identifier]: value,
                    page: page
                }
            }
        })
    }

    render(){
        const columns = [{
            name: 'User',
            style: { width: 200 }
        }, {
            name: 'Aksi',
            style: { width: 100 }
        }, {
            name: 'Timestamp',
            style: { width: 200 }
        }, {
            name: 'User Agent',
            style: { width: 600 }
        }, {
            name: 'IP Address',
            style: { width: 100 }
        }]

        return (
            <div className="col-12" style={{minHeight: '100vh', marginBottom: '150px'}}>
                <Loader show={this.state.loading}/>
                <Alert 
                    show={this.state.alert.show}
                    message={this.state.alert.message}
                    isError={this.state.alert.isError}
                    modalClosed={() => this.setState({alert: {...alert, show: false}})}
                />
                <div className="row">
                    <div className="col-12">
                        <Table 
                            columns={columns}
                            data={this.formatTable(this.props.auditData.data)}
                            useHeader
                            useFooter
                            // rightHeaderComponent={(
                            //     <div style={{display: 'flex', alignItems: 'center'}}>
                            //         <div>
                            //             <Dropdown 
                            //                 style={{width: '150px'}}
                            //                 value={{ value: 'all', name: 'User: Semua'}}
                            //                 options={[{ value: 'all', name: 'User: Semua'}]}
                            //                 onChange={() => {}}
                            //             />
                            //         </div>
                            //         <div style={{marginLeft: 10, marginRight: 10}}>
                            //             <Dropdown 
                            //                 style={{width: '150px'}}
                            //                 value={{ value: 'all', name: 'Aksi: Semua'}}
                            //                 options={[{ value: 'all', name: 'Aksi: Semua'}]}
                            //                 onChange={() => {}}
                            //             />
                            //         </div>
                            //         <div>
                            //             <Dropdown 
                            //                 style={{width: '170px'}}
                            //                 value={{ value: 'all', name: '7 Hari Terakhir'}}
                            //                 options={[{ value: 'all', name: '7 Hari Terakhir'}]}
                            //                 onChange={() => {}}
                            //             />
                            //         </div>
                            //     </div>
                            // )}
                            tableConfig={this.state.tableConfig}
                            onChange={this.onChangeTable}
                            meta={this.props.auditData.meta}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auditData: state.user.audit
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onGetAuditList: (page, limit, keyword, sort) => dispatch(getAuditList(page, limit, keyword, sort))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Audit);