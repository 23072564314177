import React from 'react';

import './statuscard.css';

const StatusCard = props => {

    const active = props.active? 'active' : '';

    return (
        <div className={`status-card ${active} ${props.classExtra}`}>
            <div className="status-card-info">
                <div className="status-card-title-container">
                    <span className={`status-card-title ${active}`}>{props.title}</span>
                </div>
                <h4 className={`${active}`}>{props.count}</h4>
            </div>
        </div>
    )
}

export default StatusCard
