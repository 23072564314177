import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { ReactComponent as GridSvg } from '../../assets/icons/grid.svg';
import { ReactComponent as SettingSvg } from '../../assets/icons/settings.svg';
import { ReactComponent as DatabaseSvg } from '../../assets/icons/database.svg';
import { ReactComponent as FolderSvg } from '../../assets/icons/folder.svg';
import { ReactComponent as FileTextSvg } from '../../assets/icons/file-text2.svg';
import { ReactComponent as UploadSVG } from '../../assets/icons/cloud-upload2.svg';
import { ReactComponent as UploadIconSVG } from '../../assets/icons/upload.svg';
import { ReactComponent as TrendingSVG } from '../../assets/icons/trending.svg';

import './sidebar.css';
import SidebarItem from './SidebarItem';
import { setDefaultPage } from '../../redux/actions';

const Sidebar = props => {

    const dispatch = useDispatch();

    // let sidebarItems = [
    //     {
    //         "display_name": "Dashboard",
    //         "route": "/",
    //         "Icon": GridSvg,
    //         multiple: ['dashboard', 'replenishment', 'kasus-selisih', 'inisiasi-ulang']
    //     },
    //     {
    //         "display_name": "Konfigurasi",
    //         "route": "/configuration",
    //         "Icon": SettingSvg
    //     },
    //     {
    //         "display_name": "Master",
    //         "route": "/master",
    //         "Icon": DatabaseSvg,
    //         multiple: ['atm', 'denominasi', 'user', 'add-atm', 'edit-atm']
    //     },
    //     {
    //         "display_name": "Rekonsiliasi",
    //         "route": "/rekonsiliasi",
    //         "Icon": FolderSvg
    //     }
    // ]

    let sidebarItems = [
        {
            "display_name": "Dashboard",
            "route": "/",
            "Icon": GridSvg,
            "class": "sidebar_dashboard",
            multiple: ['dashboard', 'replenishment', 'kasus-selisih', 'inisiasi-ulang', 'atm', 'add-notes', 'rekonsiliasi-document', 'detail-file-rekon']
        },
        {
            "display_name": "Rekonsiliasi",
            "route": "/rekonsiliasi",
            "Icon": FolderSvg,
            "class": "sidebar_rekonsiliasi",
            multiple: ['rekonsiliasi']
        },
        {
            "display_name": "Rekonsiliasi Dokumen",
            "route": "/rekonsiliasi-dokumen",
            "Icon": FileTextSvg,
            "class": "sidebar_rekonsiliasi",
            multiple: ['rekonsiliasi', 'rekonsiliasi-dokumen-fail']
        },
        {
            "display_name": "Unggah Statement",
            "route": "/unggah-statement",
            "Icon": UploadSVG,
            "class": "sidebar_unggah_statement",
            multiple: ['unggah-statement']
        },
        {
            "display_name": "Export Log EJ",
            "route": "/export-log-ej",
            "Icon": UploadIconSVG,
            "class": "sidebar_unggah_statement",
            multiple: ['export-log-ej']
        }
    ]

    if(props.profile.roleId === 2){
        sidebarItems = [
            {
                "display_name": "Dashboard",
                "route": "/",
                "Icon": GridSvg,
                "class": "sidebar_dashboard",
                multiple: ['dashboard', 'replenishment', 'kasus-selisih', 'inisiasi-ulang', 'atm', 'add-notes', 'rekonsiliasi-document', 'detail-file-rekon']
            },
            {
                "display_name": "Rekonsiliasi",
                "route": "/rekonsiliasi",
                "Icon": FolderSvg,
                "class": "sidebar_rekonsiliasi",
                multiple: ['rekonsiliasi', 'rekonsiliasi-dokumen-fail']
            },
            {
                "display_name": "Unggah Statement",
                "route": "/unggah-statement",
                "Icon": UploadSVG,
                "class": "sidebar_unggah_statement",
                multiple: ['unggah-statement']
            },
            {
                "display_name": "Export Log EJ",
                "route": "/export-log-ej",
                "Icon": UploadIconSVG,
                "class": "sidebar_unggah_statement",
                multiple: ['export-log-ej']
            }
        ]
    } else if(props.profile.roleId === 3){
        sidebarItems = [
            {
                "display_name": "Konfigurasi",
                "route": "/",
                "Icon": SettingSvg,
                "class": "sidebar_konfigurasi",
                multiple: ['configuration']
            },
            {
                "display_name": "Master",
                "route": "/master",
                "Icon": DatabaseSvg,
                "class": "sidebar_master",
                multiple: ['atm', 'denominasi', 'user', 'add-atm', 'edit-atm', 'add-user', 'add-branch']
            },
            {
                "display_name": "Monitoring",
                "route": "/monitoring",
                "Icon": TrendingSVG,
                "class": "sidebar_monitoring",
                multiple: ['monitoring']
            },
            {
                "display_name": "Unggah EJ Log",
                "route": "/unggah-log-ej",
                "Icon": UploadIconSVG,
                "class": "sidebar_master",
                multiple: ['unggah-log-ej']
            }
        ]
    }

    function checkActiveItem(sidebarItems) {
        const pathname = props.location.pathname.split("/");
        return sidebarItems.findIndex(item => {
            const route = item.route.split("/");
            return route[1] === pathname[1] || (item.multiple && handleCheck(item.multiple, pathname[1]))
        });
    }

    function handleCheck(array, val) {
        return array.some(item => item === val);
    }

    const activeItem = checkActiveItem(sidebarItems);

    const sidebarDrawer = props.showDrawer? "sidebar-show" : "sidebar-hide";

    const onClickSidebar = () => {
        props.closeDrawer();
        dispatch(setDefaultPage());
    }

    return (
        <div className={`sidebar ${sidebarDrawer}`}>
            <div className="sidebar-logo">
                <img src={require('../../assets/images/company-logo_2.png')} alt="Sample Bank" />
            </div>
            {
                sidebarItems.map((item, index) => (
                    <Link to={item.route} key={index} onClick={onClickSidebar} className={item.class}>
                        <SidebarItem
                            title={item.display_name}
                            icon={<item.Icon stroke={index === activeItem? '#FFFFFF' : '#687083'}/>}
                            active={index === activeItem}
                        />
                    </Link>
                ))
            }
        </div>
    )
}

export default Sidebar
