import React, { Component } from "react";
import "./rekonsiliasi-dokumen.css";

import { BlobProvider, Document, Page, Text, View } from "@react-pdf/renderer";
import { connect } from "react-redux";
import { ReactComponent as ArrowLeftSvg } from "../../../assets/icons/arrow-left.svg";
import { ReactComponent as ArrowRightSvg } from "../../../assets/icons/arrow-right.svg";
import { ReactComponent as CheckSvg } from "../../../assets/icons/check.svg";
import { ReactComponent as EyeSvg } from "../../../assets/icons/eye-line.svg";
import { ReactComponent as PrintSvg } from "../../../assets/icons/print.svg";
import Alert from "../../../components/alert/Alert";
import Button from "../../../components/button/Button";
import Card from "../../../components/card/Card";
import Dropdown from "../../../components/dropdown/Dropdown";
import Loader from "../../../components/loader/Loader";
import Modal from "../../../components/modal/Modal";
import Table from "../../../components/table/Table";
import Warning from "../../../components/warning/Warning";
import { tableFunction } from "../../../helpers/functionEJ";
import {
  formatRequestDateReconUTC,
  formatRequestDateTimeUTC,
  formatRupiah,
  formatUTCDisplayDate,
} from "../../../helpers/functionFormat";
import { getReconDetail, getReconDocByATM } from "../../../redux/actions";
import { checkRecon } from "../../../redux/actions/reconsiliation";

class RekonsiliasiDokumenATM extends Component {
  state = {
    date: new Date(),
    atmId: null,
    atmName: null,
    sourceType: { value: "SEMUA", name: "Semua Dokumen" },
    sourceType2: { value: "SEMUA", name: "Semua Dokumen" },
    sourceList: [
      { value: "SEMUA", name: "Semua Dokumen" },
      { value: "ej", name: "EJ" },
      { value: "switching", name: "Switching" },
      { value: "core", name: "Core Banking" },
    ],
    sourceList2: [
      { value: "SEMUA", name: "Semua Dokumen" },
      { value: "ej", name: "EJ" },
      { value: "switching", name: "Switching" },
      { value: "core", name: "Core Banking" },
    ],
    listDoc: [
      { value: "SEMUA", name: "Semua Dokumen" },
      { value: "ej", name: "EJ" },
      { value: "switching", name: "Switching" },
      { value: "core", name: "Core Banking" },
    ],
    filterBy: { value: "ALL", name: "All" },
    filterList: [
      { value: "ALL", name: "All" },
      { value: "MATCH", name: "Sukses" },
      { value: "UNMATCH", name: "Mismatch" },
    ],
    sortBy: { value: "resi_number", name: "No. Resi Terkecil" },
    sortList: [
      { value: "resi_number", name: "No. Resi Terkecil" },
      { value: "-resi_number", name: "No. Resi Terbesar" },
      { value: "datetime", name: "Tanggal Terlama" },
      { value: "-datetime", name: "Tanggal Terbaru" },
    ],
    documents: [],
    loading: false,
    modal: {
      show: false,
      body: null,
    },
    meta: null,
    tableConfig: {
      // search: '',
      page: 1,
      sort: "resi_number",
      limit: 10,
      filter: "ALL",
    },
    modalCheck: {
      show: false,
      message1: "Apakah anda yakin sudah mengecek transaksi ini ?",
      message2: "",
      data: null,
    },
    alert: {
      show: false,
      message: "",
      isError: false,
      onBtnPress: null,
      onBackPress: null,
    },
  };

  componentDidMount() {
    if (this.props.location && this.props.location.state) {
      this.setState({
        atmId: this.props.location.state.atmId,
        atmName: this.props.location.state.atmName,
        date: this.props.location.state.date,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.tableConfig !== this.state.tableConfig ||
      prevState.date !== this.state.date ||
      prevState.sourceType.value !== this.state.sourceType.value ||
      prevState.sourceType2.value !== this.state.sourceType2.value ||
      prevState.atmId !== this.state.atmId
    ) {
      this.loadDocuments(
        this.state.date,
        this.state.atmId,
        this.state.sourceType.value,
        this.state.sourceType2.value,

        this.state.tableConfig.page,
        this.state.tableConfig.limit,
        // this.state.tableConfig.search,
        this.state.tableConfig.sort,
        this.state.tableConfig.filter
      );
    }
  }

  formatTable = (data) => {
    let newInisiasiList = [];
    if (data) {
      newInisiasiList = data.map((item) => {
        // console.log('pembeda', item.pembeda?.[0]);
        return {
          id: item.transaction_id,
          cardNumber: item.card_number,
          // namaNasabah: item.core_customer_name,
          rekNumber: item.account_number,
          resiNumber: item.resi_number,
          tanggal: formatUTCDisplayDate(item.date_time),
          Jumlah: formatRupiah(item.total, "."),
          totalSelish: formatRupiah(item.total_different, "."),
          type: item.type,
          fileId: "#" + item.transaction_id,
          pembeda: tableFunction.checkingPembeda(item.pembeda),
          alasan: (
            <div
              className="rekon-atm-alasan"
              onClick={() =>
                this.onGetReconDetail(item.transaction_id, item.source)
              }
            >
              {item.alasan}
            </div>
          ),
          status: item?.status,
          aksi: (
            <div>
              <Button
                style={{ padding: "2px 4px 1px 4px" }}
                title={<EyeSvg />}
                onClick={() =>
                  this.onGetReconDetail(item.transaction_id, item.source)
                }
              />
              <Button
                disabled={item?.status === "Checked"}
                style={{ padding: "2px 4px 1px 4px", marginLeft: 10 }}
                title={<CheckSvg />}
                onClick={() =>
                  this.setState({
                    modalCheck: {
                      ...this.state.modalCheck,
                      show: true,
                      data: item,
                    },
                  })
                }
              />
            </div>
          ),
        };
      });
    }

    return newInisiasiList;
  };

  loadDocuments = async (
    date,
    atmId,
    sourceType,
    sourceType2,
    page,
    limit,
    sort,
    filter
  ) => {
    this.setState({ loading: true });
    try {
      const data = await this.props.onGetDocReconsiliation(
        date,
        atmId,
        sourceType,
        sourceType2,
        page,
        limit,
        sort,
        filter
      );

      this.setState({
        loading: false,
        documents: data.data.transactions,
        meta: {
          ...data.meta,
          pagination: Math.ceil(data.meta.total / data.meta.limit),
        },
      });
    } catch (error) {
      this.setState({ loading: false });
    }
  };

  onChangeDropdown = (item, listSource, sourceType, listOther, other) => {
    let tableConfig = this.state.tableConfig;
    if (sourceType === "sortBy")
      tableConfig = { ...tableConfig, sort: item.value };

    if (sourceType === "filterBy")
      tableConfig = { ...tableConfig, filter: item.value };

    this.setState({
      [sourceType]: item,
      tableConfig,
    });
  };

  onGetReconDetail = async (fileId, source) => {
    // console.log('source', source);
    this.setState({ loading: true });
    try {
      const data = await this.props.onGetReconDetail(
        fileId,
        source
        // this.state.sourceType.value
      );
      this.setState({ loading: false });
      this.onShowModal(data, false);
    } catch (error) {
      this.setState({ loading: false });
    }
  };

  onChangeTable = (identifier, value) => {
    this.setState((prevState) => {
      let page = identifier === "page" ? value : prevState.tableConfig.page;
      page = identifier === "search" ? 1 : page;
      return {
        ...prevState,
        tableConfig: {
          ...prevState.tableConfig,
          [identifier]: value,
          page: page,
        },
      };
    });
  };

  onShowModal = (data, isError) => {
    let pembeda = [];
    pembeda.push(data.source);
    if (data.pembeda !== null) {
      pembeda = [...pembeda, ...data.pembeda];
    }
    const notes = data.notes.replaceAll(/",|{|}/g, '"\n');
    const notesClean = notes.replaceAll('"', "");
    this.setState({
      modal: {
        show: true,
        body: (
          <div className="row">
            <div className="col-12" style={{ marginBottom: 10 }}>
              <div className="row">
                <div className="col-3">Nomor Resi</div>
                <div className="col-1">:</div>
                <div className="col-8">{data.resi_number}</div>
              </div>
            </div>
            <div className="col-12" style={{ marginBottom: 10 }}>
              <div className="row">
                <div className="col-3">Tanggal</div>
                <div className="col-1">:</div>
                <div className="col-8">
                  {formatRequestDateReconUTC(data.date_time)}
                </div>
              </div>
            </div>
            <div className="col-12" style={{ marginBottom: 10 }}>
              <div className="row">
                <div className="col-3">Jumlah</div>
                <div className="col-1">:</div>
                <div className="col-8">{formatRupiah(data.total, ".")}</div>
              </div>
            </div>
            <div className="col-12" style={{ marginBottom: 10 }}>
              <div className="row">
                <div className="col-3">Tipe Transaksi</div>
                <div className="col-1">:</div>
                <div className="col-8">{data.type}</div>
              </div>
            </div>
            <div className="col-12" style={{ marginBottom: 10 }}>
              <div className="row">
                <div className="col-3">File ID</div>
                <div className="col-1">:</div>
                <div className="col-8">#{data.transaction_id}</div>
              </div>
            </div>
            <div className="col-12" style={{ marginBottom: 20 }}>
              <div className="row">
                <div className="col-3">Pembeda</div>
                <div className="col-1">:</div>
                <div className="col-8">
                  {tableFunction.checkingPembeda(pembeda, {
                    display: "flex",
                  })}
                </div>
              </div>
            </div>
            <div className="col-12" style={{ marginBottom: 10 }}>
              <div
                className="title-failed-trx"
                style={{
                  position: "static",
                  display: "flex",
                  justifyItems: "center",
                  marginTop: 10,
                  marginBottom: 10,
                }}
                onClick={() => {
                  this.props.history.push(
                    `/rekonsiliasi-dokumen-fail/${formatRequestDateTimeUTC(
                      data.date_time
                    )}/${this.props.location.state.atmId}&with-datetime=true`
                  );
                }}
              >
                <p>Lihat Transaksi Gagal </p>
                <ArrowRightSvg
                  className="arrow-back-rekon-doc"
                  stroke={"#DE5242"}
                  width={20}
                  height={20}
                />
              </div>
            </div>
            {isError && (
              <div
                className="col-12"
                style={{
                  border: "1px solid #ccc",
                  margin: "0px 15px 0px 15px",
                  borderRadius: 10,
                  padding: 10,
                  marginBottom: 20,
                }}
              >
                <div
                  style={{
                    marginBottom: 5,
                    fontWeight: "bold",
                  }}
                >
                  Error
                </div>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Sapien, nulla scelerisque vulputate aliquet amet tristique. Eu
                  etiam urna imperdiet quam ullamcorper ornare. Neque, nisi,
                  ultrices duis amet, porttitor commodo. Enim ut sed ultricies
                  euismod congue vitae, arcu commodo.
                </p>
              </div>
            )}
            <div
              className="col-12"
              style={{
                border: "1px solid #ccc",
                margin: "0px 15px 0px 15px",
                borderRadius: 10,
                padding: 10,
                overflow: "auto",
              }}
            >
              <div
                className="row col-12"
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ marginBottom: 10, fontWeight: "bold" }}>
                  Catatan
                </div>
                <div style={{ marginRight: -20 }}>
                  <BlobProvider document={this.renderPDF(notesClean)}>
                    {({ url }) => (
                      <Button
                        style={{ padding: "2px 4px 1px 4px", marginLeft: 10 }}
                        title={<PrintSvg style={{ height: 20, width: 20 }} />}
                        onClick={async () => {
                          const win = window.open(url, "_blank");
                          win.focus();
                        }}
                      />
                    )}
                  </BlobProvider>
                </div>
              </div>
              <pre>{notesClean}</pre>
            </div>
          </div>
        ),
      },
    });
  };

  renderPDF = (data) => {
    const notes = data.replaceAll(/",|{|}|&/g, '"\n');
    const notesClean = notes.replaceAll('"', "");
    return (
      <Document>
        <Page
          size="A4"
          style={{
            display: "flex",
            paddingVertical: 25,
            paddingHorizontal: 30,
          }}
        >
          <View>
            <Text style={{ fontSize: 9 }}>{notesClean}</Text>
          </View>
        </Page>
      </Document>
    );
  };

  onChecked = async (data) => {
    try {
      this.setState({ loading: true });
      const response = await this?.props?.onCheckRecon(
        data?.transaction_id,
        data?.header_core_statement_id,
        data?.atm_id,
        true
      );
      if (response?.meta?.http_status === 200) {
        await this.loadDocuments(
          this.state.date,
          this.state.atmId,
          this.state.sourceType.value,
          this.state.sourceType2.value,

          this.state.tableConfig.page,
          this.state.tableConfig.limit,
          // this.state.tableConfig.search,
          this.state.tableConfig.sort,
          this.state.tableConfig.filter
        );
      } else {
        this.setState({ loading: false });
      }
    } catch (error) {
      this.setState({
        loading: false,
        alert: {
          ...alert,
          show: true,
          message: error.message,
          isError: true,
          onBtnPress: null,
          onBackPress: null,
        },
      });
    }
  };

  render() {
    const columns = [
      {
        name: "No Kartu",
        style: { width: "15%" },
      },
      // {
      //   name: 'Nama Nasabah',
      //   style: { width: '15%' },
      // },
      {
        name: "No Rekening",
        style: { width: "15%" },
      },
      {
        name: "Nomor Resi",
        style: { width: "15%" },
      },
      {
        name: "Tanggal",
        style: { width: "15%" },
      },
      {
        name: "Jumlah",
        style: { width: 200 },
      },
      {
        name: "Total Selisih",
        style: { width: 200 },
      },
      {
        name: "Tipe Transaksi",
        style: { width: "15%" },
      },
      {
        name: "File ID",
        style: { width: "15%" },
      },
      {
        name: "Log Missmatch",
        style: { width: "20%", textAlign: "center" },
      },
      {
        name: "Alasan",
        style: { width: "15%" },
      },
      {
        name: "Status",
        style: { width: "15%" },
      },
      {
        name: "Aksi",
        style: { width: "10%", textAlign: "center" },
      },
    ];

    // const SortingComponent = () => (
    //   <ArrowLeftSvg
    //     className='arrow-back-rekon-doc'
    //     stroke={'#667AB3'}
    //     width={20}
    //     height={15}
    //     style={{
    //       transform: 'rotate(-90deg)',
    //       padding: '2px',
    //     }}
    //   />
    // );

    return (
      <div>
        <Modal
          show={this.state.modal.show}
          useHeader={true}
          style={{ maxHeight: "90%" }}
          styleHeader={{ border: "none", marginBottom: 0 }}
          modalClosed={() =>
            this.setState((prevState) => {
              return {
                ...prevState,
                modal: {
                  ...prevState.modal,
                  show: false,
                },
              };
            })
          }
          body={this.state.modal.body}
        />
        <Warning
          show={this.state.modalCheck.show}
          message1={this.state.modalCheck.message1}
          message2={this.state.modalCheck.message2}
          modalClosed={() =>
            this.setState({
              modalCheck: { ...this.state.modalCheck, show: false, data: null },
            })
          }
          onBtnPress={() => {
            this.onChecked(this.state.modalCheck.data);
            this.setState({
              modalCheck: { ...this.state.modalCheck, show: false, data: null },
            });
          }}
          buttonText2="Tidak"
          buttonText="Ya"
        />
        <Alert
          show={this.state.alert.show}
          message={this.state.alert.message}
          isError={this.state.alert.isError}
          modalClosed={() =>
            this.setState({ alert: { ...alert, show: false } })
          }
          onBtnPress={this.state.alert.onBtnPress}
          onBackPress={this.state.alert.onBackPress}
        />
        <Loader show={this.state.loading} />
        <div
          className="back-rekon-doc"
          onClick={() => this.props.history.goBack()}
        >
          <ArrowLeftSvg
            className="arrow-back-rekon-doc"
            stroke={"#667AB3"}
            width={20}
            height={20}
          />
          <div className="label-back-rekon-doc">Kembali</div>
        </div>
        <div className="row container-detail-rekonsiliasi">
          <div className="col-8 col-sm-12">
            <Card className="card-detail-rekonsiliasi">
              <div className="row info-detail-rekonsiliasi">
                <div className="col-4 col-md-12 col-sm-12">
                  <label className="label-detail-rekonsiliasi">ATM</label>
                </div>
                <div className="col-8 col-md-12 col-sm-12">
                  <label className="value-detail-rekonsiliasi">{`${this.state.atmId} - ${this.state.atmName}`}</label>
                </div>
              </div>
            </Card>
          </div>
          <div className="col-12" style={{ display: "flex" }}>
            <div style={{ marginRight: 20 }}>
              <div
                className="label-detail-rekonsiliasi"
                style={{ marginBottom: 5 }}
              >
                Dokumen Utama
              </div>
              <Dropdown
                style={{ width: "200px" }}
                styleValue={{ paddingTop: 8, paddingBottom: 8 }}
                value={this.state.sourceType}
                options={this.state.sourceList}
                onChange={(item) =>
                  this.onChangeDropdown(
                    item,
                    "sourceList",
                    "sourceType",
                    "sourceList2",
                    "sourceType2"
                  )
                }
                // onChange={item => this.setState({sourceType: item})}
              />
            </div>
            <div style={{ marginRight: 20 }}>
              <div
                className="label-detail-rekonsiliasi"
                style={{ marginBottom: 5 }}
              >
                Dokumen Pendamping
              </div>
              <Dropdown
                style={{ width: "200px" }}
                styleValue={{ paddingTop: 8, paddingBottom: 8 }}
                value={this.state.sourceType2}
                options={this.state.sourceList2}
                onChange={(item) =>
                  this.onChangeDropdown(
                    item,
                    "sourceList2",
                    "sourceType2",
                    "sourceList",
                    "sourceType"
                  )
                }
                // onChange={item => this.setState({sourceType2: item})}
              />
            </div>
          </div>
          <div className="col-12">
            <div className="row" style={{ margin: "15px 0" }}>
              <div>
                <h4
                  className="title-rekon-doc"
                  style={{ marginTop: 15, marginBottom: 15 }}
                >
                  Tidak Sesuai
                </h4>
                <p>
                  Berikut ini adalah daftar transaksi yg telah berhasil
                  dilakukan
                </p>
              </div>
              <div
                className="row title-failed-trx"
                onClick={() => {
                  this.props.history.push(
                    `/rekonsiliasi-dokumen-fail/${this.props.location.state.date}/${this.props.location.state.atmId}`
                  );
                }}
              >
                <p>Lihat Transaksi Gagal </p>
                <ArrowRightSvg
                  className="arrow-back-rekon-doc"
                  stroke={"#DE5242"}
                  width={20}
                  height={20}
                />
              </div>
            </div>
            <div className="row action-table">
              <div className="row">
                <p>Filter by :</p>
                <Dropdown
                  style={{ width: "200px" }}
                  styleValue={{ paddingTop: 8, paddingBottom: 8 }}
                  value={this.state.filterBy}
                  options={this.state.filterList}
                  onChange={(item) =>
                    this.onChangeDropdown(item, "", "filterBy", "", "")
                  }
                  // onChange={item => this.setState({sourceType: item})}
                />
              </div>

              <div className="row">
                <p>Sort by :</p>
                <Dropdown
                  style={{ width: "200px" }}
                  styleValue={{ paddingTop: 8, paddingBottom: 8 }}
                  value={this.state.sortBy}
                  options={this.state.sortList}
                  onChange={(item) =>
                    this.onChangeDropdown(item, "", "sortBy", "", "")
                  }
                  // onChange={item => this.setState({sourceType: item})}
                />
              </div>
            </div>
            <Table
              columns={columns}
              data={this.formatTable(this.state.documents)}
              tableConfig={this.state.tableConfig}
              onChange={this.onChangeTable}
              useFooter
              meta={
                this.state.meta
                  ? this.state.meta.total
                    ? this.state.meta
                    : null
                  : null
              }
              // SortingComponent={SortingComponent}
              // data={this.formatTable(this.props.inisiasiData.data)}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onGetDocReconsiliation: (
      date,
      atmId,
      sourceType,
      sourceType2,
      page,
      limit,
      sort,
      filter
    ) =>
      dispatch(
        getReconDocByATM(
          date,
          atmId,
          sourceType,
          sourceType2,
          page,
          limit,
          sort,
          filter
        )
      ),
    onGetReconDetail: (fileId, source) =>
      dispatch(getReconDetail(fileId, source)),
    onCheckRecon: (
      transactionId,
      headerCoreStatementId,
      atmId,
      isCheckedFlag
    ) =>
      dispatch(
        checkRecon(transactionId, headerCoreStatementId, atmId, isCheckedFlag)
      ),
  };
};

export default connect(null, mapDispatchToProps)(RekonsiliasiDokumenATM);
