import React from 'react';
import './catridge-rekonsiliasi.css';
import Card from '../card/Card';
import { formatRupiah } from '../../helpers/functionFormat';

const CatridgeRekonsiliasi = props => {

    const { index, item, isLast } = props;

    return (
        <>
            <h4 className="header-detail-rekonsiliasi">Catridge: {index} </h4>
            <div className="row">
                <div className="col-6 col-sm-12 container-catridge-rekonsiliasi">
                    <Card
                        className="card-detail-rekonsiliasi"
                        useHeader
                        headerTitle={"Jurnal Elektronik"}
                        color="primary"
                    >
                        <div className="row">
                            <div className="col-5 col-md-12 nomorseri-catridge-rekonsiliasi">
                                <div className="label-detail-rekonsiliasi">Nomor Seri</div>
                                <div className="value-detail-rekonsiliasi">{item["IN"]?.serial_number}</div>
                            </div>
                            <div className="col-7 col-md-12">
                                <div className="label-detail-rekonsiliasi">Denominasi</div>
                                {item["IN"]?.replenishment_cartridge_details?.map((deno, idx) => (
                                    <div className="row" key={idx.toString()}>
                                        <div className="col-6">
                                            <label className="value-detail-rekonsiliasi">{formatRupiah(deno.denoValue, '.')} x</label>
                                        </div>
                                        <div className="col-6" style={{textAlign: 'right'}}>
                                            <label className="label-detail-rekonsiliasi">{deno.value} Lembar</label>
                                        </div>
                                    </div>
                                ))}
                                {/* <div className="label-detail-rekonsiliasi" style={{marginTop: 10}}>Ditolak</div>
                                <div className="row">
                                    <div className="col-6">
                                        <label className="value-detail-rekonsiliasi">Rp 100.000 x</label>
                                    </div>
                                    <div className="col-6" style={{textAlign: 'right'}}>
                                        <label className="label-detail-rekonsiliasi">248 Lembar</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <label className="value-detail-rekonsiliasi">Rp 50.000 x</label>
                                    </div>
                                    <div className="col-6" style={{textAlign: 'right'}}>
                                        <label className="label-detail-rekonsiliasi">0 Lembar</label>
                                    </div>
                                </div> */}
                                <div className="hr-detail-rekonsiliasi" />
                                <div className="row">
                                    <div className="col-4">
                                        <label className="value-detail-rekonsiliasi">Subtotal</label>
                                    </div>
                                    <div className="col-8" style={{textAlign: 'right'}}>
                                        <label className="label-detail-rekonsiliasi">{item["IN"]?.sub_total ? formatRupiah(item["IN"]?.sub_total, ".") : 0}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
                <div className="col-6 col-sm-12 container-catridge-rekonsiliasi">
                    {item["OUT"] && (
                    <Card
                        className="card-detail-rekonsiliasi"
                        useHeader
                        headerTitle={"Saldo Akhir Cek Fisik"}
                        color="success"
                    >

                        <div className="row">
                            <div className="col-5 col-md-12 nomorseri-catridge-rekonsiliasi">
                                <div className="label-detail-rekonsiliasi">Nomor Seri</div>
                                <div className="value-detail-rekonsiliasi">{item["OUT"]?.serial_number}</div>
                            </div>
                            <div className="col-7 col-md-12">
                                <div className="label-detail-rekonsiliasi">Denominasi</div>
                                {item["OUT"]?.replenishment_cartridge_details?.map((deno, idx) => (
                                    <div className="row" key={idx.toString()}>
                                        <div className="col-6">
                                            <label className="value-detail-rekonsiliasi">{formatRupiah(deno.denoValue, '.')} x</label>
                                        </div>
                                        <div className="col-6" style={{textAlign: 'right'}}>
                                            <label className="label-detail-rekonsiliasi">{deno.value} Lembar</label>
                                        </div>
                                    </div>
                                ))}
                                {/* <div className="label-detail-rekonsiliasi" style={{marginTop: 10}}>Ditolak</div>
                                <div className="row">
                                    <div className="col-6">
                                        <label className="value-detail-rekonsiliasi">Rp 100.000 x</label>
                                    </div>
                                    <div className="col-6" style={{textAlign: 'right'}}>
                                        <label className="label-detail-rekonsiliasi">248 Lembar</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <label className="value-detail-rekonsiliasi">Rp 50.000 x</label>
                                    </div>
                                    <div className="col-6" style={{textAlign: 'right'}}>
                                        <label className="label-detail-rekonsiliasi">0 Lembar</label>
                                    </div>
                                </div> */}
                                <div className="hr-detail-rekonsiliasi" />
                                <div className="row">
                                    <div className="col-4">
                                        <label className="value-detail-rekonsiliasi">Subtotal</label>
                                    </div>
                                    <div className="col-8" style={{textAlign: 'right'}}>
                                        <label className="label-detail-rekonsiliasi">{item["OUT"]?.sub_total ? formatRupiah(item["OUT"]?.sub_total, ".") : 0}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                    )}
                </div>
            </div>
            {!isLast && <div className="hr-catridge-rekonsiliasi" />}
        </>
    )
}

export default CatridgeRekonsiliasi;
