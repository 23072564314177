import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import { CSVLink } from 'react-csv';

import Table from '../../../components/table/Table';
import Dropdown from '../../../components/dropdown/Dropdown';
import Button from '../../../components/button/Button';
import Modal from '../../../components/modal/Modal';
import DatePicker from '../../../components/datepicker/DatePicker';
import { getListRekonsiliasi, downloadReplenishment } from '../../../redux/actions';
import { formatRupiah, formatDisplayDate, formatRequestDownloadDate } from '../../../helpers/functionFormat';
import { tableFunction, modalDateFunction } from '../../../helpers/functionEJ';
import Loader from "../../../components/loader/Loader";

class Rekonsiliasi extends Component {
    _isMounted = true;
    state = {
        month: this.props.listMonth()[new Date().getMonth()],
        year: {
            value: new Date().getFullYear().toString(),
            name: new Date().getFullYear().toString()
        },
        tableConfig: {
            search: '',
            page: 1,
            sort: 'name',
            limit: 10
        },
        alert: {
            show: false,
            message: '',
            isError: false
        },
        modalDate: {
            show: false,
            useHeader: false,
            title: "Unduh Laporan",
            dateRange: [null, null]
        },
        downloadData: [],
        loading: true,
    }

    componentDidMount(){
        // let date = new Date();
        // this.setState({
        //     // month: this.props.listMonth()[date.getMonth() + 1],
        //     month: this.props.listMonth()[date.getMonth()],
        //     year: {
        //         value: date.getFullYear().toString(),
        //         name: date.getFullYear().toString()
        //     }
        // })
    }

    componentDidUpdate(prevProps, prevState){
        if (prevState.tableConfig !== this.state.tableConfig
            || prevState.month.value !== this.state.month.value
            || prevState.year.value !== this.state.year.value
        ) {
            this.loadRekonsiliasiList(
              this.state.tableConfig.page,
              this.state.tableConfig.limit,
              this.state.tableConfig.search,
              this.state.tableConfig.sort,
              this.state.month.value,
              this.state.year.value
            )
        }
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    loadRekonsiliasiList = async (page, limit, search, sort, month, year) => {
        this.setState({loading: true});
        try{
            const {branchId} = this.props?.profile;
            await this.props.onGetListRekonsiliasi(page, limit, search, sort, month, year, null, branchId);
            this.setState({loading: false});
        }catch(error){
            this.setState({loading: false});
            this.setState({
                alert: {
                    ...alert,
                    show: true,
                    message: error.message,
                    isError: true
                }
            })
        }
    }

    onDownloadReplenishment = async () => {
        this.setState({modalDate: { ...this.state.modalDate, show: false}, loading: true})
        try{
            const response = await this.props.onDownloadReplenishment(
                '',
                'replenishment_id',
                'REKONSILIASI',
                this.state.modalDate.dateRange[0]? formatRequestDownloadDate(this.state.modalDate.dateRange[0]) : null,
                this.state.modalDate.dateRange[1]? formatRequestDownloadDate(this.state.modalDate.dateRange[1]) : null,
                null
            )
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', 'Laporan Rekonsiliasi.xlsx');
            document.body.appendChild(fileLink);
            fileLink.click();
            fileLink.remove();
            // const data = response.data.map(item => {
            //     return {
            //         id: item.replenishment_id,
            //         atm: item.atm.name,
            //         status: item.status_desc.toLocaleUpperCase(),
            //         // tglInisiasi: formatDisplayDate(item.replenishment_datetime),
            //         tglLapor: formatDisplayDate(item.updated_at),
            //         saldoAwal: formatRupiah(item.beginning_balance, "."),
            //         saldoIsi: formatRupiah(item.replenishment_balance, "."),
            //         saldoEJ: formatRupiah(item.ej_balance, "."),
            //         saldoAkhir: formatRupiah(item.return_balance, "."),
            //         selisih: formatRupiah(item.difference_balance, ".")
            //     }
            // })

            // this.setState({loading: false, downloadData: data});
            // this.csvLink.link.click();
        }catch(error){

            this.setState({
                loading: false
            })
        }
    }

    formatTable = (data) => {
        const newRekonsiliasiList = data.map(item => {
            return {
                id: item.replenishment_id,
                idComponent: (
                    <Link to={`/rekonsiliasi/${item.replenishment_id}`}>
                        <div style={{color: '#4991F2'}}>
                            {item.replenishment_id}
                        </div>
                    </Link>
                ),
                terminalId: (
                  <div style={{color: '#4991F2'}}>
                      {item?.atm?.atm_label}
                  </div>
                ),
                atm: item.atm.name,
                status: tableFunction.checkingStatus(item.status),
                tgl: formatDisplayDate(item.updated_at),
                saldoAwal: formatRupiah(item.beginning_balance, '.'),
                saldoIsi: formatRupiah(item.replenishment_balance, '.'),
                saldoEJ: formatRupiah(item.ej_balance, '.'),
                saldoAkhir: formatRupiah(item.return_balance, '.'),
                selisih: (
                    <div className="selisih-dashboard">
                        {formatRupiah(item.difference_balance, '.')}
                    </div>
                )
            }
        })
        return newRekonsiliasiList;
    }

    render(){
        const columns = [{
            name: "ID Replenishment",
            style: { width: 100 }
        }, {
            name: "Terminal ID",
            style: { width: 100 }
        },{
            name: "ATM",
            style: { width: 400 }
        }, {
            name: "Status Rekonsiliasi",
            style: { width: 200 }
        }, {
            name: "Tgl. Lapor",
            style: { width: 150 }
        }, {
            name: "Saldo Awal",
            style: { width: 100 }
        }, {
            name: "Saldo Isi Ulang",
            style: { width: 100 }
        }, {
            name: "Saldo  EJ",
            style: { width: 100 }
        }, {
            name: "Saldo Akhir",
            style: { width: 100 }
        }, {
            name: "Selisih",
            style: { width: 100 }
        }]

        return (
            <div className="row">
                <Loader show={this.state.loading}/>
                <Modal
                    useHeader={this.state.modalDate.useHeader}
                    show={this.state.modalDate.show}
                    modalClosed={() => modalDateFunction.closeModalDate(this, 'modalDate')}
                    title={this.state.modalDate.title}
                    style={{top: 100, overflow: 'visible'}}
                    className="modal-content-date col-25 col-md-4 col-sm-6 col-xm-4 col-xs-4"
                >
                    <div className="row">
                        <div className="col-12">
                            <DatePicker
                                selectsRange={true}
                                date={this.state.modalDate.dateRange}
                                onchangeDate={(date) => modalDateFunction.onchangeRangeDate(this, 'modalDate', date)}
                                label="Semua Tanggal"
                            />
                            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                <Button
                                    title={this.state.loading? "Mengunduh..." : "Unduh"}
                                    style={{background: '#111827', marginTop: '20px'}}
                                    onClick={this.onDownloadReplenishment}
                                />
                            </div>
                        </div>
                    </div>
                </Modal>
                {/* <CSVLink
                    ref={ref => this.csvLink = ref}
                    style={{display: 'none'}}
                    data={this.state.downloadData}
                    headers={[
                        { label: "ID Replenishment", key: "id" },
                        { label: "ATM", key: "atm" },
                        { label: "Status Rekonsiliasi", key: "status" },
                        // { label: "Tanggal Inisiasi Ulang", key: "tglInisiasi" },
                        { label: "Tanggal Lapor", key: "tglLapor" },
                        { label: "Saldo Awal", key: "saldoAwal" },
                        { label: "Saldo Isi Ulang", key: "saldoIsi" },
                        { label: "Saldo EJ", key: "saldoEJ" },
                        { label: "Saldo Akhir", key: "saldoAkhir" },
                        { label: "Selisih", key: "selisih" }
                    ]}
                    filename={"Laporan Rekonsiliasi.csv"}
                /> */}
                <div className="col-12">
                    <h3 className="title-dashboard">Rekonsiliasi</h3>
                    <Table
                        columns={columns}
                        data={this.formatTable(this.props.rekonsiliasiData.data)}
                        useHeader={true}
                        useFooter={true}
                        rightHeaderComponent={(
                            <div style={{display: 'flex'}}>
                                <div>
                                    <Dropdown
                                        style={{width: '140px'}}
                                        value={this.state.month}
                                        options={this.props.listMonth()}
                                        onChange={(month) => this.setState({month: month})}
                                    />
                                </div>
                                <div style={{marginRight: '10px', marginLeft: '10px'}}>
                                    <Dropdown
                                        style={{width: '100px'}}
                                        value={this.state.year}
                                        options={this.props.listYear()}
                                        onChange={(year) => this.setState({year: year})}
                                    />
                                </div>
                                <div>
                                    <Button
                                        className="action_unduhLaporan"
                                        style={{padding: '12px 20px'}}
                                        title="Unduh Laporan"
                                        onClick={() => modalDateFunction.openModalDate(this, 'modalDate')}
                                    />
                                </div>
                            </div>
                        )}
                        tableConfig={this.state.tableConfig}
                        onChange={(identifier, value) => tableFunction.onChangeTable(this, identifier, value)}
                        meta={this.props.rekonsiliasiData.meta}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        rekonsiliasiData: state.replenish.rekonsiliasi,
        profile: state.auth.profile
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onGetListRekonsiliasi: (page, limit, keyword, sort, month, year, atmId, branchId) => dispatch(getListRekonsiliasi(page, limit, keyword, sort, month, year, atmId, branchId)),
        onDownloadReplenishment: (keyword, sort, category, startDate, endDate, atmId) => dispatch(downloadReplenishment(keyword, sort, category, startDate, endDate, atmId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Rekonsiliasi);
