import axios from 'axios';
import * as actionTypes from '../actionTypes';
import { uploadFile } from './other';
import { logout } from './auth';

export const getListReplenishment = (page, limit, keyword, sort, category, month, year, atmId, branchId) => {
    // console.log('BRANCH ID', branchId);
    return async (dispatch, getState) => {
        try{
            const url = getState().config.url;
            const token = getState().auth.token;

            let params = `?offset=0&page=${page}&limit=${limit}&keyword=${keyword}&sort=${sort}&branch_ids=${branchId}`;

            if(category){
                params += `&category=${category}`;
            }

            if(month){
                params += `&month=${month}`;
            }

            if(year){
                params += `&year=${year}`;
            }

            if(atmId){
                params += `&atm_id=${atmId}`;
            }

            // console.log(`${url}/replenishments${params}`);

            const response = await axios.get(
                `${url}/replenishments${params}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            );

            if(!category){
                await dispatch({
                    type: actionTypes.GET_LIST_ALL_REPLENISHMENT,
                    data: response.data.data ?? [],
                    meta: {
                        pagination: Math.ceil(response.data.meta.total/response.data.meta.limit),
                        total: response.data.meta.total,
                        limit: response.data.meta.limit,
                        page: response.data.meta.page
                    }
                })
            }
            return response;
        }catch(error){
            let message = 'Internal Server Error';
            if(error.response){
                if(error.response.data.errors){
                    if(error.response.data.errors[0].code === 1009){
                        dispatch(logout());
                    }
                    message = error.response.data.errors[0].message;
                }
            }else{
                message = error.message;
            }
            throw new Error(message);
        }
    }
}

export const getListRekonsiliasi = (page, limit, keyword, sort, month, year, atmId, branchId) => {
    return async (dispatch) => {
        try{
            const response = await dispatch(getListReplenishment(page, limit, keyword, sort, 'REKONSILIASI', month, year, atmId, branchId));
            await dispatch({
                type: actionTypes.GET_LIST_REKONSILIASI,
                data: response.data.data ?? [],
                meta: {
                    pagination: Math.ceil(response.data.meta.total/response.data.meta.limit),
                    total: response.data.meta.total,
                    limit: response.data.meta.limit,
                    page: response.data.meta.page
                }
            })
        } catch (e) {
            await dispatch({
                type: actionTypes.GET_LIST_REKONSILIASI,
                data: [],
                meta: {
                    pagination: 1,
                    total: 0,
                    limit: 10,
                    page: 1
                }
            })
        }
    }
}

export const getListSelisih = (page, limit, keyword, sort, month, year, atmId, branchId) => {
    return async (dispatch) => {
        try {
            const response = await dispatch(getListReplenishment(page, limit, keyword, sort, 'SELISIH', month, year, atmId, branchId));
            await dispatch({
                type: actionTypes.GET_LIST_SELISIH,
                data: response.data.data ?? [],
                meta: {
                    pagination: Math.ceil(response.data.meta.total/response.data.meta.limit),
                    total: response.data.meta.total,
                    limit: response.data.meta.limit,
                    page: response.data.meta.page
                }
            })
        } catch (e) {
            await dispatch({
                type: actionTypes.GET_LIST_SELISIH,
                data: [],
                meta: {
                    pagination: 1,
                    total: 0,
                    limit: 10,
                    page: 1
                }
            })
        }
    }
}

export const getListInisiasi = (page, limit, keyword, sort, month, year, atmId, branchId) => {
    return async (dispatch) => {
        try {
            const response = await dispatch(getListReplenishment(page, limit, keyword, sort, 'INISIASI', month, year, atmId, branchId));
            await dispatch({
                type: actionTypes.GET_LIST_INISIASIULANG,
                data: response.data.data ?? [],
                meta: {
                    pagination: Math.ceil(response.data.meta.total/response.data.meta.limit),
                    total: response.data.meta.total,
                    limit: response.data.meta.limit,
                    page: response.data.meta.page
                }
            })
        } catch (e) {
            await dispatch({
                type: actionTypes.GET_LIST_INISIASIULANG,
                data: [],
                meta: {
                    pagination: 1,
                    total: 0,
                    limit: 10,
                    page: 1
                }
            })
        }
    }
}

export const createReplenishment = (atmId, datetime, balance, catridges) => {
    return async (dispatch, getState) => {
        try{
            const url = getState().config.url;
            const token = getState().auth.token;

            const response = await axios.post(
                `${url}/replenishments`,
                {
                    atm_id: atmId,
                    replenishment_datetime: datetime,
                    replenishment_balance: balance,
                    replenishment_cartridges: catridges
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            );

            return {
                meta: response.data.meta.http_status,
                message: 'Inisiasi Isi Ulang Berhasil!',
                data: response.data.data
            }
        }catch(error){
            let message = 'Internal Server Error';
            if(error.response){
                if(error.response.data.errors){
                    if(error.response.data.errors[0].code === 1009){
                        dispatch(logout());
                    }
                    message = error.response.data.errors[0].message;
                }
            }else{
                message = error.message;
            }
            throw new Error(message);
        }
    }
}

export const approvalReplenisment = (replenishmentId) => {
    return async (dispatch, getState) => {
        try{
            const url = getState().config.url;
            const token = getState().auth.token;

            const response = await axios.post(
                `${url}/replenishments/approve`,
                {
                    replenishment_id: replenishmentId
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            );

            return {
                meta: response.data.meta.http_status,
                message: 'Persetujuan Berhasil!',
                data: response.data.data
            }
        }catch(error){
            let message = 'Internal Server Error';
            if(error.response){
                if(error.response.data.errors){
                    if(error.response.data.errors[0].code === 1009){
                        dispatch(logout());
                    }
                    message = error.response.data.errors[0].message;
                }
            }else{
                message = error.message;
            }
            throw new Error(message);
        }
    }
}

export const rejectReplenishment = (replenishmentId) => {
    return async (dispatch, getState) => {
        try{
            const url = getState().config.url;
            const token = getState().auth.token;

            const response = await axios.put(
                `${url}/replenishments/reject`,
                {
                    replenishment_id: replenishmentId
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            );

            return {
                meta: response.data.meta.http_status,
                message: 'Berhasil Ditolak!',
                data: response.data.data
            }
        }catch(error){
            let message = 'Internal Server Error';
            if(error.response){
                if(error.response.data.errors){
                    if(error.response.data.errors[0].code === 1009){
                        dispatch(logout());
                    }
                    message = error.response.data.errors[0].message;
                }
            }else{
                message = error.message;
            }
            throw new Error(message);
        }
    }
}

export const returnReplenishment = (replenishmentId, total, fotoBukti, fotoKerja, catridges) => {
    return async (dispatch, getState) => {
        try{
            const url = getState().config.url;
            const token = getState().auth.token;

            const imageBukti = await dispatch(uploadFile(fotoBukti));
            const imageKerja = await dispatch(uploadFile(fotoKerja));

            const response = await axios.post(
                `${url}/replenishments/return`,
                {
                    replenishment_id: replenishmentId,
                    total: total,
                    bukti_foto_layar_image_url: imageBukti.file,
                    surat_kerja_image_url: imageKerja.file,
                    replenishment_cartridges: catridges
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            );

            return {
                meta: response.data.meta.http_status,
                message: 'Laporan Berhasil!',
                data: response.data.data
            }
        }catch(error){
            let message = 'Internal Server Error';
            if(error.response){
                if(error.response.data.errors){
                    if(error.response.data.errors[0].code === 1009){
                        dispatch(logout());
                    }
                    message = error.response.data.errors[0].message;
                }
            }else{
                message = error.message;
            }
            throw new Error(message);
        }
    }
}

export const addNotesReplenishment = (replenishmentId, notes) => {
    return async (dispatch, getState) => {
        try{
            const url = getState().config.url;
            const token = getState().auth.token;

            const response = await axios.post(
                `${url}/replenishments/notes`,
                {
                    replenishment_id: replenishmentId,
                    notes: notes
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            );

            return {
                meta: response.data.meta.http_status,
                message: 'Laporan Berhasil!',
                data: response.data.data
            }
        }catch(error){
            let message = 'Internal Server Error';
            if(error.response){
                if(error.response.data.errors){
                    if(error.response.data.errors[0].code === 1009){
                        dispatch(logout());
                    }
                    message = error.response.data.errors[0].message;
                }
            }else{
                message = error.message;
            }
            throw new Error(message);
        }
    }
}

export const detailReplenishment = (replenishmentId) => {
    return async (dispatch, getState) => {
        try{
            const url = getState().config.url;
            const token = getState().auth.token;

            const response = await axios.get(
                `${url}/replenishments/${replenishmentId}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            );

            return {
                meta: response.data.meta.http_status,
                data: response.data.data
            }
        }catch(error){
            let message = 'Internal Server Error';
            if(error.response){
                if(error.response.data.errors){
                    if(error.response.data.errors[0].code === 1009){
                        dispatch(logout());
                    }
                    message = error.response.data.errors[0].message;
                }
            }else{
                message = error.message;
            }
            throw new Error(message);
        }
    }
}

export const markDoneReplenishment = (replenishmentId) => {
    return async (dispatch, getState) => {
        try{
            const url = getState().config.url;
            const token = getState().auth.token;

            const response = await axios.put(
                `${url}/replenishments/${replenishmentId}/done`,
                {},
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            );

            return {
                meta: response.data.meta.http_status,
                message: 'Rekonsiliasi Selesai!'
            }
        }catch(error){
            let message = 'Internal Server Error';
            if(error.response){
                if(error.response.data.errors){
                    if(error.response.data.errors[0].code === 1009){
                        dispatch(logout());
                    }
                    message = error.response.data.errors[0].message;
                }
            }else{
                message = error.message;
            }
            throw new Error(message);
        }
    }
}

export const approveReconsiliasi = (replenishmentId) => {
    return async (dispatch, getState) => {
        try{
            const url = getState().config.url;
            const token = getState().auth.token;

            const response = await axios.put(
                `${url}/replenishments/${replenishmentId}/approve-reconciliation`,
                {},
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            );

            return {
                meta: response.data.meta.http_status,
                message: 'Rekonsiliasi Berhasil Disetujui!'
            }
        }catch(error){
            let message = 'Internal Server Error';
            if(error.response){
                if(error.response.data.errors){
                    if(error.response.data.errors[0].code === 1009){
                        dispatch(logout());
                    }
                    message = error.response.data.errors[0].message;
                }
            }else{
                message = error.message;
            }
            throw new Error(message);
        }
    }
}

export const downloadReplenishment = (keyword, sort, category, startDate, endDate, atmId) => {
    return async (dispatch, getState) => {
        try{
            const url = getState().config.url;
            const token = getState().auth.token;

            let params = `?keyword=${keyword}&sort=${sort}`;

            if(category){
                params += `&category=${category}`;
            }

            if(startDate){
                params += `&start_date=${startDate}`;
                if(endDate){
                    params += `&end_date=${endDate}`;
                }else{
                    params += `&end_date=${startDate}`;
                }
            }

            if(atmId){
                params += `&atm_id=${atmId}`;
            }

            const response = await axios.get(
                `${url}/replenishments/download-v2${params}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token,

                    },
                    responseType: 'blob'
                }
            );

            return response;

        }catch(error){
            let message = 'Internal Server Error';
            if(error.response){
                if(error.response.data.errors){
                    if(error.response.data.errors[0].code === 1009){
                        dispatch(logout());
                    }
                    message = error.response.data.errors[0].message;
                }
            }else{
                message = error.message;
            }
            throw new Error(message);
        }
    }
}
