import React, {Component} from 'react';
import Table from '../../../components/table/Table';
import {connect} from 'react-redux';
import {getATMList} from '../../../redux/actions';
import {tableFunction} from '../../../helpers/functionEJ';
import Loader from "../../../components/loader/Loader";


class TidakMengirimLog extends Component {

  _isMounted;

  state = {
    tableConfig: {
      search: '',
      page: 1,
      sort: 'name',
      limit: 10
    },
    alert: {
      show: false,
      message: '',
      isError: false
    },
    loading: true,
  }

  componentDidMount(){
    // this.loadATMList(
    //     this.state.tableConfig.page,
    //     this.state.tableConfig.limit,
    //     this.state.tableConfig.search,
    //     this.state.tableConfig.sort
    // )
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.tableConfig !== this.state.tableConfig) {
      this.loadATMList(
        this.state.tableConfig.page,
        this.state.tableConfig.limit,
        this.state.tableConfig.search,
        this.state.tableConfig.sort
      )
    }
  }

  loadATMList = async (page, limit, search, sort) => {
    this.setState({loading: true});
    try{
      const {branchId} = this.props?.profile;
      await this.props.getATMList(page, limit, search, sort, branchId, 'inactive');
      this.setState({loading: false});
    }catch(error){
      this.setState({loading: false});
      this.setState({
        alert: {
          ...alert,
          show: true,
          message: error.message,
          isError: true
        }
      })
    }
  }

  formatTable = (data) => {
    return data.map(item => {
      return {
        id: item.id,
        idComponent: (
          <label
            className="id-dashboard"
            onClick={() => this.props.history.push({
              key: `atm-${item.id}`,
              pathname: `/atm/${item.id}`,
              state: {...item}
            })}
          >
            <div style={{color: '#4991F2'}}>
              {item.id}
            </div>
          </label>
        ),
        terminalId: (
          <div style={{color: '#4991F2'}}>
            {item?.atm_label}
          </div>
        ),
        name: item.name,
      }

    });
  }

  render(){
    let columns = [];
    if(this.props.profile.roleId === 2){
      columns.push({
        name: "Aksi",
        style: { width: 100, textAlign: 'center' }
      })
    }

    columns = [...[{
      name: "ID ATM",
      style: { width: 100 },
    }, {
      name: "Terminal ID",
      style: { width: 100 }
    }, {
      name: "ATM",
      style: { width: '30%' }
    }]]

    return (
      <div className="row">
        <Loader show={this.state.loading}/>
        <div className="col-12">
          <h3 className="title-dashboard">Tidak Mengirim Log</h3>
          <Table
            columns={columns}
            data={this.formatTable(this.props.atmData.atm)}
            useHeader={true}
            useFooter={true}
            tableConfig={this.state.tableConfig}
            onChange={(identifier, value) => tableFunction.onChangeTable(this, identifier, value)}
            meta={this.props.atmData.meta}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    atmData: state.atm,
    profile: state.auth.profile
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getATMList: (page, limit, keyword, sort, branchId, state) => dispatch(getATMList(page, limit, keyword, sort, branchId, state))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TidakMengirimLog);
