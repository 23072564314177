import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Layout from '../layout/Layout';
import Login from '../pages/auth/login/Login';
import Confirmation from '../pages/auth/confirmation/Confirmation';
import ForgotPassword from '../pages/auth/forgot-password/ForgotPassword';
import NewPassword from '../pages/auth/new-password/NewPassword';
// import Rekonsiliasi from "../pages/main/rekonsiliasi/Rekonsiliasi";
// import ATM from "../pages/main/atm/ATM";
// import InisiasiUlang from "../pages/main/inisiasi-ulang/InisiasiUlang";
// import KasusSelisih from "../pages/main/kasus-selisih/KasusSelisih";
import SentEmail from '../pages/auth/sent-email/SentEmail';
import Logout from '../pages/auth/Logout';
import Page404 from '../pages/other/Page404/Page404';
import Dashboard from '../pages/main/dashboard/Dashboard';
import Konfigurasi from '../pages/main/konfigurasi/Konfigurasi';
import Master from '../pages/main/master/Master';
import Rekonsiliasi from '../pages/main/rekonsiliasi/Rekonsiliasi';
import DetailATM from '../pages/main/atm/DetailATM';
import DetailRekonsiliasi from '../pages/main/rekonsiliasi/DetailRekonsiliasi';
import DetailReplenishment from '../pages/main/inisiasi-ulang/DetailReplenishment';
import FormInisiasiUlang from '../pages/main/inisiasi-ulang/FormInisiasiUlang';
import LaporanInisiasiUlang from '../pages/main/inisiasi-ulang/LaporInisasiUlang';
import InputATM from '../pages/main/atm/InputATM';
import InformasiAkun from '../pages/main/konfigurasi/InformasiAkun';
import InputUser from '../pages/main/user/InputUser';
import AddNotesReplenisment from '../pages/main/kasus-selisih/AddNotesReplenisment';
import DokumenRekonsiliasi from '../pages/main/rekonsiliasi/DokumenRekonsiliasi';
import DetailFileRekon from '../pages/main/rekonsiliasi/DetailFileRekon';
import UnggahStatement from '../pages/main/unggah/UnggahStatement';
import RekonsiliasiDokumen from '../pages/main/rekonsiliasi/RekonsiliasiDokumen';
import RekonsiliasiDokumenTgl from '../pages/main/rekonsiliasi/RekonsiliasiDokumenTgl';
import RekonsiliasiDokumenATM from '../pages/main/rekonsiliasi/RekonsiliasiDokumenATM';
import RekonsiliasiDokumenFail from '../pages/main/rekonsiliasi/RekonsiliasiDokumenFail';
import InputBranch from '../pages/main/branch/InputBranch';
import UnggahLogEJ from '../pages/main/logEJ/UnggahLogEJ';
import ExportLogEJ from '../pages/main/logEJ/ExportLogEJ';
import Monitoring from '../pages/main/monitoring/Monitoring';

const Router = (props) => {
  const token = useSelector((state) => state.auth.token);
  const isFirstLoggedIn = useSelector((state) => state.auth.isFirstLoggedIn);
  const profile = useSelector((state) => state.auth.profile);

  if (!token || !profile) {
    return (
      <Switch>
        <Route path='/' exact component={Login} />
        <Route path='/login' exact component={Login} />
        <Route path='/confirmation' exact component={Confirmation} />
        <Route path='/forgot-password' exact component={ForgotPassword} />
        <Route path='/new-password' exact component={NewPassword} />
        <Route path='/sent-email' exact component={SentEmail} />
        {!isFirstLoggedIn && window.location.pathname === '/login' ? (
          <Redirect to='/' />
        ) : null}
        <Route path='*' component={Page404} />
      </Switch>
    );
  }

  return (
    <Layout profile={profile}>
      {profile.roleId === 3 ? (
        <Switch>
          <Route path='/' exact component={Konfigurasi} />
          {/* <Route path="/dashboard" exact component={Dashboard} /> */}
          <Route path='/configuration' exact component={Konfigurasi} />
          <Route path='/master' exact component={Master} />
          <Route path='/unggah-log-ej' exact component={UnggahLogEJ} />
          <Route path='/monitoring' exact component={Monitoring} />
          {/* <Route path="/rekonsiliasi" exact component={Rekonsiliasi} /> */}
          <Route path='/atm/:id' exact component={DetailATM} />
          <Route path='/add-atm' exact component={InputATM} />
          <Route path='/edit-atm/:id' exact component={InputATM} />
          <Route path='/user/:id' exact component={DetailATM} />
          <Route path='/add-user' exact component={InputUser} />
          <Route path='/edit-user' exact component={InputUser} />
          <Route path='/edit-user/:id' exact component={InputUser} />
          <Route
            path='/rekonsiliasi/:id'
            exact
            component={DetailRekonsiliasi}
          />
          <Route
            path='/replenishment/:id'
            exact
            component={DetailReplenishment}
          />
          <Route
            path='/inisiasi-ulang/form-inisiasi-ulang'
            exact
            component={FormInisiasiUlang}
          />
          <Route
            path='/inisiasi-ulang/lapor-inisiasi-ulang'
            exact
            component={LaporanInisiasiUlang}
          />
          <Route path='/logout' exact component={Logout} />
          <Route path='/add-branch' exact component={InputBranch} />
          {isFirstLoggedIn ? <Redirect to='/' /> : null}
          <Route path='*' component={Page404} />
        </Switch>
      ) : (
        <Switch>
          <Route path='/' exact component={Dashboard} />
          <Route path='/dashboard' exact component={Dashboard} />
          <Route path='/profile' exact component={InformasiAkun} />
          <Route path='/rekonsiliasi' exact component={Rekonsiliasi} />
          <Route path='/atm/:id' exact component={DetailATM} />
          <Route path='/add-atm' exact component={InputATM} />
          <Route path='/edit-atm/:id' exact component={InputATM} />
          <Route path='/export-log-ej' exact component={ExportLogEJ} />
          <Route
            path='/rekonsiliasi/:id'
            exact
            component={DetailRekonsiliasi}
          />
          <Route
            path='/rekonsiliasi-document/:id'
            exact
            component={DokumenRekonsiliasi}
          />
          <Route
            path='/detail-file-rekon/:id'
            exact
            component={DetailFileRekon}
          />
          <Route
            path='/replenishment/:id'
            exact
            component={DetailReplenishment}
          />
          <Route
            path='/rekonsiliasi-dokumen'
            exact
            component={RekonsiliasiDokumen}
          />
          <Route
            path='/rekonsiliasi-dokumen/:tgl'
            exact
            component={RekonsiliasiDokumenTgl}
          />
          <Route
            path='/rekonsiliasi-dokumen/:tgl/:id'
            exact
            component={RekonsiliasiDokumenATM}
          />
          <Route
            path='/rekonsiliasi-dokumen-fail/:tgl/:id'
            exact
            component={RekonsiliasiDokumenFail}
          />
          <Route path='/unggah-statement' exact component={UnggahStatement} />
          <Route path='/add-notes/:id' exact component={AddNotesReplenisment} />
          <Route
            path='/inisiasi-ulang/form-inisiasi-ulang'
            exact
            component={FormInisiasiUlang}
          />
          <Route
            path='/inisiasi-ulang/lapor-inisiasi-ulang'
            exact
            component={LaporanInisiasiUlang}
          />
          <Route path='/logout' exact component={Logout} />
          {isFirstLoggedIn ? <Redirect to='/' /> : null}
          <Route path='*' component={Page404} />
        </Switch>
      )}
    </Layout>
  );
};

export default Router;
