import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { CSVLink } from 'react-csv';
import './detail-atm.css';
import Card from '../../../components/card/Card';
import Table from '../../../components/table/Table';
import Button from '../../../components/button/Button';
// import Dropdown from '../../../components/dropdown/Dropdown';
// import StatusLabel from '../../../components/statuslabel/StatusLabel';
import Loader from '../../../components/loader/Loader';
import Alert from '../../../components/alert/Alert';
import {
  deleteATM,
  detailATM,
  getListReplenishment,
  downloadReplenishment,
} from '../../../redux/actions';
import {
  formatRupiah,
  formatDisplayDate,
  formatRequestDownloadDate,
} from '../../../helpers/functionFormat';
import { tableFunction, modalDateFunction } from '../../../helpers/functionEJ';
import Modal from '../../../components/modal/Modal';
import DatePicker from '../../../components/datepicker/DatePicker';
import {findAtmDenominator} from "../../../redux/actions/atm";

class DetailATM extends Component {
  state = {
    id: '',
    name: '',
    branch: {},
    location: '',
    merk: '',
    type1: '',
    type2: '',
    type3: '',
    type4: '',
    norek: '',
    description: '-',
    saldoAktif: '',
    lastIsiUlang: '',
    saldo: 0,
    key: '',
    label: '',
    // atmKey: null,
    loading: false,
    alert: {
      show: false,
      message: '',
      isError: false,
      onBtnPress: null,
      onBackPress: null,
    },
    tableConfig: {
      search: '',
      page: 1,
      sort: '-replenishment_id',
      limit: 10,
    },
    modalDate: {
      show: false,
      useHeader: false,
      title: 'Unduh Laporan',
      dateRange: [null, null],
    },
    downloadData: [],
  };

  async componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      if (this.props.isFirstLoggedIn) {
        this.props.onChangeFirstLoggedIn();
      }
    }
    if (this.props.location && this.props.location.state) {
      this.setState({
        id: this.props.location.state.id,
        name: this.props.location.state.name,
        location: this.props.location.state.location,
        merk: this.props.location.state.merk
          ? this.props.location.state.merk
          : '-',
        norek: this.props.location.norek ? this.props.location.norek : '-',
        label: this.props.location.label ? this.props.location.label : '-',
        description: this.props.location.state.description,
        saldoAktif: this.props.location.state.saldoAktif
          ? this.props.location.state.saldoAktif
          : '-',
        lastIsiUlang: '-',
      });
      await this.loadDetailATM(this.props.location.state.id);
      await this.findAtmDenominator(this.props.location.state.id);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.tableConfig !== this.state.tableConfig &&
      this.props.location &&
      this.props.location.state
    ) {
      this.loadReplenismentList(
        this.state.tableConfig.page,
        this.state.tableConfig.limit,
        this.state.tableConfig.search,
        this.state.tableConfig.sort,
        this.props.location.state.id
      );
    }
  }

  loadDetailATM = async (id) => {
    try {
      const atm = await this.props.onDetailATM(id);
      this.setState({
        id: atm.data.atm_id,
        name: atm.data.name,
        branch: atm.data.Branch,
        location: atm.data.location ? atm.data.location : '-',
        merk: atm.data.merk ? atm.data.merk : '-',
        norek: atm.data.account_no ? atm.data.account_no : '-',
        description: atm.data.description ? atm.data.description : '-',
        saldoAktif: atm.data.balance
          ? formatRupiah(atm.data.balance, '.')
          : 'Rp. 0',
        lastIsiUlang: atm.data.updated_at
          ? formatDisplayDate(atm.data.updated_at)
          : '-',
        saldo: atm.data.balance ? atm.data.balance : 0,
        key: atm.data.atm_key,
        label: atm.data.atm_label ? atm.data.atm_label : '-',
        // atmKey: atm.data.atm_key.atm_key_id === 0? null : atm.data.atm_key.public_key
      });
      await this.loadReplenismentList(
        this.state.tableConfig.page,
        this.state.tableConfig.limit,
        this.state.tableConfig.search,
        this.state.tableConfig.sort,
        id
      );
    } catch (error) {
      this.setState({
        loading: false,
        alert: {
          ...alert,
          show: true,
          message: error.message,
          isError: true,
        },
      });
    }
  };

  findAtmDenominator = async (id) => {
    try {
      const response = await this.props.onFindAtmDenominator(id);
      const {data} = response;
      this.setState({
        type1: data?.denominator_value_1,
        type2: data?.denominator_value_2,
        type3: data?.denominator_value_3,
        type4: data?.denominator_value_4,
      });
    } catch (error) {
      this.setState({
        loading: false,
        alert: {
          ...alert,
          show: true,
          message: error.message,
          isError: true,
        },
      });
    }
  };

  loadReplenismentList = async (page, limit, keyword, sort, atmId) => {
    if (this.props.profile.roleId !== 3) {
      this.setState({ loading: true });
      try {
        const branchId = this.props?.profile?.branchId;
        await this.props.onGetListReplenishment(
          page,
          limit,
          keyword,
          sort,
          null,
          null,
          null,
          atmId,
          branchId
        );
        this.setState({ loading: false });
      } catch (error) {
        this.setState({
          loading: false,
          alert: {
            ...alert,
            show: true,
            message: error.message,
            isError: true,
          },
        });
      }
    }
  };

  onDownloadReplenishment = async () => {
    this.setState({
      modalDate: { ...this.state.modalDate, show: false },
      loading: true,
    });
    try {
      const response = await this.props.onDownloadReplenishment(
        '',
        'replenishment_id',
        null,
        this.state.modalDate.dateRange[0]
          ? formatRequestDownloadDate(this.state.modalDate.dateRange[0])
          : null,
        this.state.modalDate.dateRange[1]
          ? formatRequestDownloadDate(this.state.modalDate.dateRange[1])
          : null,
        this.props.location.state.id
      );
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', 'Laporan Isi Ulang.xlsx');
      document.body.appendChild(fileLink);
      fileLink.click();
      fileLink.remove();
      // const data = response.data.map(item => {
      //     return {
      //         id: item.replenishment_id,
      //         atm: item.atm.name,
      //         status: item.status_desc.toLocaleUpperCase(),
      //         // tglInisiasi: formatDisplayDate(item.replenishment_datetime),
      //         tglLapor: formatDisplayDate(item.updated_at),
      //         saldoAwal: formatRupiah(item.beginning_balance, "."),
      //         saldoIsi: formatRupiah(item.replenishment_balance, "."),
      //         saldoEJ: formatRupiah(item.ej_balance, "."),
      //         saldoAkhir: formatRupiah(item.return_balance, "."),
      //         selisih: formatRupiah(item.difference_balance, ".")
      //     }
      // })

      // this.setState({loading: false, downloadData: data});
      // this.csvLink.link.click();
    } catch (error) {
      this.setState({
        loading: false,
      });
    }
  };

  onChangeTable = (identifier, value) => {
    this.setState((prevState) => {
      let page = identifier === 'page' ? value : prevState.tableConfig.page;
      page = identifier === 'search' ? 1 : page;
      return {
        ...prevState,
        tableConfig: {
          ...prevState.tableConfig,
          [identifier]: value,
          page: page,
        },
      };
    });
  };

  onDeleteATM = async () => {
    this.setState({ loading: true });
    try {
      if (!this.props.match.params || !this.props.match.params.id) {
        throw new Error('Internal Server Error');
      }
      const response = await this.props.onDeleteATM(this.props.match.params.id);
      this.setState({
        loading: false,
        alert: {
          ...alert,
          show: true,
          message: response.message,
          isError: false,
          onBtnPress: () => this.props.history.goBack(),
          onBackPress: () => this.props.history.goBack(),
        },
      });
    } catch (error) {
      this.setState({
        loading: false,
        alert: {
          ...alert,
          show: true,
          message: error.message,
          isError: true,
          onBtnPress: null,
          onBackPress: null,
        },
      });
    }
  };

  onGenerateATMKey = async () => {
    this.setState({ loading: true });
    try {
      if (!this.props.match.params || !this.props.match.params.id) {
        throw new Error('Internal Server Error');
      }
      const response = await this.props.onGenerateATMKey(
        this.props.match.params.id
      );

      this.setState({
        loading: false,
        alert: {
          ...alert,
          show: true,
          message: response.message,
          isError: false,
          onBtnPress: () => this.loadDetailATM(this.props.location.state.id),
          onBackPress: () => this.loadDetailATM(this.props.location.state.id),
        },
      });
    } catch (error) {
      this.setState({
        loading: false,
        alert: {
          ...alert,
          show: true,
          message: error.message,
          isError: true,
          onBtnPress: null,
          onBackPress: null,
        },
      });
    }
  };

  // checkingStatus = (status) => {
  //     let data = status;
  //     switch(status.toUpperCase()){
  //         case 'REPLENISHMENT_ST_01': {
  //             data = <StatusLabel type="process" label={"Inisiasi"}/>;
  //             break;
  //         }
  //         case 'REPLENISHMENT_ST_02': {
  //             data = <StatusLabel type="success" label={"Inisiasi Disetujui"}/>;
  //             break;
  //         }
  //         case 'REPLENISHMENT_ST_03': {
  //             data = <StatusLabel type="process" label={"Rekonsiliasi"}/>;
  //             break;
  //         }
  //         case 'REPLENISHMENT_ST_04': {
  //             data = <StatusLabel type="process" label={"Rekonsiliasi Disetujui"}/>;
  //             break;
  //         }
  //         case 'REPLENISHMENT_ST_05': {
  //             data = <StatusLabel type="success" label={"Rekonsiliasi Sukses"}/>;
  //             break;
  //         }
  //         case 'REPLENISHMENT_ST_06': {
  //             data = <StatusLabel type="error" label={"Kasus Selisih"}/>
  //             break;
  //         }
  //         case 'REPLENISHMENT_ST_07': {
  //             data = <StatusLabel type="success" label={"Selisih Selesai"}/>
  //             break;
  //         }
  //         default: {
  //             data = status;
  //             break;
  //         }
  //     }
  //     return data;
  // }

  formatTable = (data) => {
    const newInisiasiList = data.map((item) => {
      return {
        id: item.replenishment_id,
        idComponent: (
          <label
            className='id-replenisment-detail-atm'
            onClick={() =>
              this.props.history.push({
                key: `replenishment-${item.replenishment_id}`,
                pathname: `/replenishment/${item.replenishment_id}`,
                state: { ...item },
              })
            }
          >
            {item.replenishment_id}
          </label>
        ),
        name: item.atm.name,
        status: tableFunction.checkingStatus(item.status),
        tglLapor: formatDisplayDate(item.updated_at),
        saldoAwal: formatRupiah(item.beginning_balance, '.'),
        saldoIsi: formatRupiah(item.replenishment_balance, '.'),
        saldoEJ: formatRupiah(item.ej_balance, '.'),
        saldoAkhir: formatRupiah(item.return_balance, '.'),
        selisih: (
          <div className='selisih-replenisment-detail-atm'>
            {formatRupiah(item.difference_balance, '.')}
          </div>
        ),
      };
    });

    return newInisiasiList;
  };

  render() {
    const columns = [
      {
        name: 'ID Replenishment',
        style: { width: 100, fontSize: '14px' },
      },
      {
        name: 'ATM',
        style: { width: 300, fontSize: '14px' },
      },
      {
        name: 'Status Rekonsiliasi',
        style: { width: 250, fontSize: '14px' },
      },
      {
        name: 'Tgl. Lapor',
        style: { width: 200, fontSize: '14px' },
      },
      {
        name: 'Saldo Awal',
        style: { width: 100, fontSize: '14px' },
      },
      {
        name: 'Saldo Isi Ulang',
        style: { width: 100, fontSize: '14px' },
      },
      {
        name: 'Saldo  EJ',
        style: { width: 100, fontSize: '14px' },
      },
      {
        name: 'Saldo Akhir',
        style: { width: 100, fontSize: '14px' },
      },
      {
        name: 'Selisih',
        style: { width: 100, fontSize: '14px' },
      },
    ];

    return (
      <div className='detail-atm'>
        <h2 className='page-header header-detail-atm'>Detail ATM</h2>
        <Loader show={this.state.loading} />
        <Alert
          show={this.state.alert.show}
          message={this.state.alert.message}
          isError={this.state.alert.isError}
          modalClosed={() =>
            this.setState({ alert: { ...alert, show: false } })
          }
          onBtnPress={this.state.alert.onBtnPress}
          onBackPress={this.state.alert.onBackPress}
        />
        <Modal
          useHeader={this.state.modalDate.useHeader}
          show={this.state.modalDate.show}
          modalClosed={() =>
            modalDateFunction.closeModalDate(this, 'modalDate')
          }
          title={this.state.modalDate.title}
          style={{ top: 100, overflow: 'visible' }}
          className='modal-content-date col-25 col-md-4 col-sm-6 col-xm-4 col-xs-4'
        >
          <div className='row'>
            <div className='col-12'>
              <DatePicker
                selectsRange={true}
                date={this.state.modalDate.dateRange}
                onchangeDate={(date) =>
                  modalDateFunction.onchangeRangeDate(this, 'modalDate', date)
                }
                label='Semua Tanggal'
              />
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  title={this.state.loading ? 'Mengunduh...' : 'Unduh'}
                  style={{ background: '#111827', marginTop: '20px' }}
                  onClick={this.onDownloadReplenishment}
                />
              </div>
            </div>
          </div>
        </Modal>
        {/* <CSVLink
                    ref={ref => this.csvLink = ref}
                    style={{display: 'none'}}
                    data={this.state.downloadData}
                    headers={[
                        { label: "ID Replenishment", key: "id" },
                        { label: "ATM", key: "atm" },
                        { label: "Status Rekonsiliasi", key: "status" },
                        // { label: "Tanggal Inisiasi Ulang", key: "tglInisiasi" },
                        { label: "Tanggal Lapor", key: "tglLapor" },
                        { label: "Saldo Awal", key: "saldoAwal" },
                        { label: "Saldo Isi Ulang", key: "saldoIsi" },
                        { label: "Saldo EJ", key: "saldoEJ" },
                        { label: "Saldo Akhir", key: "saldoAkhir" },
                        { label: "Selisih", key: "selisih" }
                    ]}
                    filename={"Riwayat Isi Ulang.csv"}
                /> */}
        <div className='row container-detail-atm'>
          <div className='col-8'>
            <Card className='card-detail-atm'>
              <h4 className='header-detail-atm'>Informasi ATM</h4>
              <div className='row info-detail-atm'>
                <div className='col-4'>
                  <label className='label-detail-atm'>Terminal ID</label>
                </div>
                <div className='col-8'>
                  <label className='value-detail-atm'>{this.state.id}</label>
                </div>
              </div>
              {this.state.key && (
                <div className='row info-detail-atm'>
                  <div className='col-4'>
                    <label className='label-detail-atm'>IP Address ATM</label>
                  </div>
                  <div className='col-8'>
                    <label className='value-detail-atm'>{this.state.key}</label>
                  </div>
                </div>
              )}
              <div className='row info-detail-atm'>
                <div className='col-4'>
                  <label className='label-detail-atm'>ATM Label</label>
                </div>
                <div className='col-8'>
                  <label className='value-detail-atm'>{this.state.label}</label>
                </div>
              </div>
              <div className='row info-detail-atm'>
                <div className='col-4'>
                  <label className='label-detail-atm'>Nama ATM</label>
                </div>
                <div className='col-8'>
                  <label className='value-detail-atm'>{this.state.name}</label>
                </div>
              </div>
              <div className='row info-detail-atm'>
                <div className='col-4'>
                  <label className='label-detail-atm'>Cabang</label>
                </div>
                <div className='col-8'>
                  <label className='value-detail-atm'>{this.state.branch.name}</label>
                </div>
              </div>
              <div className='row info-detail-atm'>
                <div className='col-4'>
                  <label className='label-detail-atm'>Lokasi</label>
                </div>
                <div className='col-8'>
                  <label className='value-detail-atm'>
                    {this.state.location}
                  </label>
                </div>
              </div>
              <div className='row info-detail-atm'>
                <div className='col-4'>
                  <label className='label-detail-atm'>Merek ATM</label>
                </div>
                <div className='col-8'>
                  <label className='value-detail-atm'>{this.state.merk}</label>
                </div>
              </div>
              {/*{this.props.profile.roleId === 3 && (*/}
                <div className='row info-detail-atm'>
                  <div className='col-4'>
                    <label className='label-detail-atm'>Denominator Type</label>
                  </div>
                  <div className='col-8'>
                    <div style={{marginBottom: 10}}>
                      <label className='label-detail-atm'>Type 1</label>
                      <p style={{marginTop: 5}}><label className='value-detail-atm'>{this.state.type1 ? formatRupiah(this.state.type1, '.', '') : '-'}</label></p>
                    </div>
                    <div style={{marginBottom: 10}}>
                      <label className='label-detail-atm'>Type 2</label>
                      <p style={{marginTop: 5}}><label className='value-detail-atm'>{this.state.type2 ? formatRupiah(this.state.type2, '.', '') : '-'}</label></p>
                    </div>
                    <div style={{marginBottom: 10}}>
                      <label className='label-detail-atm'>Type 3</label>
                      <p style={{marginTop: 5}}><label className='value-detail-atm'>{this.state.type3 ? formatRupiah(this.state.type3, '.', '') : '-'}</label></p>
                    </div>
                    <div style={{marginBottom: 10}}>
                      <label className='label-detail-atm'>Type 4</label>
                      <p style={{marginTop: 5}}><label className='value-detail-atm'>{this.state.type4 ? formatRupiah(this.state.type4, '.', '') : '-'}</label></p>
                    </div>
                  </div>
                </div>
                {/*)}*/}
              <div className='row info-detail-atm'>
                <div className='col-4'>
                  <label className='label-detail-atm'>No. Rekening</label>
                </div>
                <div className='col-8'>
                  <label className='value-detail-atm'>{this.state.norek}</label>
                </div>
              </div>
              <div className='row info-detail-atm'>
                <div className='col-4'>
                  <label className='label-detail-atm'>Deskripsi</label>
                </div>
                <div className='col-8'>
                  <label className='value-detail-atm'>
                    {this.state.description}
                  </label>
                </div>
              </div>
              <div className='row info-detail-atm'>
                <div className='col-4'>
                  <label className='label-detail-atm'>Saldo Aktif</label>
                </div>
                <div className='col-8'>
                  <label className='value-detail-atm'>
                    {this.state.saldoAktif}
                  </label>
                </div>
              </div>
            </Card>
          </div>
          <div className='col-4'>
            <Card className='card-detail-atm'>
              <div className='label-detail-atm'>Terakhir diisi ulang</div>
              <div className='value-detail-atm'>{this.state.lastIsiUlang}</div>
            </Card>
            {this.props.profile.roleId === 2 && (
              <Button
                style={{
                  width: '100%',
                  marginTop: 20,
                  padding: '12px 20px 12px 20px',
                }}
                title='Inisiasi Isi Ulang'
                onClick={() =>
                  this.props.history.push({
                    key: 'inisiasi-ulang-' + this.state.id,
                    pathname: `/inisiasi-ulang/form-inisiasi-ulang`,
                    state: {
                      atmId: this.state.id,
                      atmName: this.state.name,
                      atmLocation: this.state.location,
                      saldo: this.state.saldo,
                    },
                    // state: {
                    //     id: this.state.id,
                    //     name: this.state.name,
                    //     location: this.state.location,
                    //     merk: this.state.merk,
                    //     description: this.state.description,
                    //     saldo: this.state.saldo
                    // }
                  })
                }
              />
            )}
            {this.props.profile.roleId === 3 && (
              <div className='row'>
                <div className='col-6 col-xs-12'>
                  <Button
                    className='btn-default btn-detail-update'
                    title='Edit'
                    onClick={() =>
                      this.props.history.push({
                        key: 'editatm-' + this.state.id,
                        pathname: `/edit-atm/${this.state.id}`,
                        state: {
                          id: this.state.id,
                          name: this.state.name,
                          location: this.state.location,
                          merk: this.state.merk,
                          norek: this.state.norek,
                          description: this.state.description,
                        },
                      })
                    }
                  />
                </div>
                <div className='col-6 col-xs-12'>
                  <Button
                    className='btn-default btn-detail-update'
                    title='Hapus'
                    onClick={this.onDeleteATM}
                  />
                </div>
                {/* {!this.state.atmKey && <div className="col-12">
                                <Button
                                    className="btn-detail-update"
                                    title="Generate Key"
                                    onClick={this.onGenerateATMKey}
                                />
                            </div>} */}
              </div>
            )}
          </div>
        </div>
        {this.props.profile.roleId !== 3 && (
          <div className='row container-detail-atm'>
            <div className='col-12'>
              <h3 className='header-detail-atm'>Riwayat Isi Ulang</h3>
              <Table
                columns={columns}
                data={this.formatTable(this.props.replenismentData.data)}
                useHeader
                useFooter
                stickyLeft={[0]}
                stickyBodyLeft={['idComponent']}
                rightHeaderComponent={
                  <div className='header-table-detail-atm'>
                    {/* <div className="header-dropdown-detail-atm">
                                        <Dropdown
                                            style={{width: '170px'}}
                                            value={{ value: 'all', name: '7 hari terakhir'}}
                                            options={[{ value: 'all', name: '7 hari terakhir'}]}
                                            onChange={() => {}}
                                        />
                                    </div> */}
                    <Button
                      className='action_unduhLaporan'
                      style={{ padding: '12px 20px 12px 20px' }}
                      title='Unduh Laporan'
                      onClick={() =>
                        modalDateFunction.openModalDate(this, 'modalDate')
                      }
                    />
                  </div>
                }
                tableConfig={this.state.tableConfig}
                onChange={this.onChangeTable}
                meta={this.props.replenismentData.meta}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.auth.profile,
    replenismentData: state.replenish.all,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDetailATM: (id) => dispatch(detailATM(id)),
    onDeleteATM: (id) => dispatch(deleteATM(id)),
    onGetListReplenishment: (page, limit, keyword, sort, category, month, year, atmId, branchId) =>
      dispatch(getListReplenishment(page, limit, keyword, sort, category, month, year, atmId, branchId)),
    onDownloadReplenishment: (keyword, sort, category, startDate, endDate, atmId) =>
      dispatch(downloadReplenishment(keyword, sort, category, startDate, endDate, atmId)),
    onFindAtmDenominator: (id) => dispatch(findAtmDenominator(id)),
    // onGenerateATMKey: (id) => dispatch(createATMKey(id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailATM);
