import * as actionTypes from '../actionTypes';

export const initialState = {
    inisiasi: {
        data: [],
        meta: {
            pagination: 1,
            total: 0,
            limit: 10,
            page: 1
        }
    },
    rekonsiliasi: {
        data: [],
        meta: {
            pagination: 1,
            total: 0,
            limit: 10,
            page: 1
        }
    },
    selisih: {
        data: [],
        meta: {
            pagination: 1,
            total: 0,
            limit: 10,
            page: 1
        }
    },
    all: {
        data: [],
        meta: {
            pagination: 1,
            total: 0,
            limit: 10,
            page: 1
        }
    }
}

const replenishReducer = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.GET_LIST_ALL_REPLENISHMENT: {
            return {
                ...state,
                all: {
                    ...state.all,
                    data: action.data,
                    meta: action.meta
                }
            }
        }
        case actionTypes.GET_LIST_REKONSILIASI: {
            return {
                ...state,
                rekonsiliasi: {
                    ...state.rekonsiliasi,
                    data: action.data,
                    meta: action.meta
                }
            }
        }
        case actionTypes.GET_LIST_INISIASIULANG: {
            return {
                ...state,
                inisiasi: {
                    ...state.inisiasi,
                    data: action.data,
                    meta: action.meta
                }
            }
        }
        case actionTypes.GET_LIST_SELISIH: {
            return {
                ...state,
                selisih: {
                    ...state.selisih,
                    data: action.data,
                    meta: action.meta
                }
            }
        }
        default: {
            return state;
        }
    }
}

export default replenishReducer;
