import React, { Component } from "react";

import { connect } from "react-redux";
import { ReactComponent as CheckCircle } from "../../../assets/icons/check-circle.svg";
import Card from "../../../components/card/Card";
import DatePicker from "../../../components/datepicker/DatePicker";
import Dropdown from "../../../components/dropdown/Dropdown";
import Loader from "../../../components/loader/Loader";
import Table from "../../../components/table/Table";
import {
  formatRequestDateOnly,
  formatRupiah,
  formatUTCDisplayDate,
} from "../../../helpers/functionFormat";
import { getReconsiliationDocument } from "../../../redux/actions";

class DokumenRekonsiliasi extends Component {
  state = {
    date: new Date(),
    sourceType: { value: "EJ", name: "EJ" },
    sourceType2: { value: "EJ", name: "EJ" },
    atmId: null,
    atmName: null,
    // replenishmentId: null,
    documents: {
      transactions: [],
      is_matched: false,
      message: "Data Tidak Tersedia",
    },
    loading: false,
  };

  componentDidMount() {
    if (this.props.location && this.props.location.state) {
      this.setState({
        // replenishmentId: this.props.location.state.id,
        atmId: this.props.location.state.atmId,
        atmName: this.props.location.state.atmName,
        date: new Date(),
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.date !== this.state.date ||
      prevState.sourceType.value !== this.state.sourceType.value ||
      prevState.sourceType2.value !== this.state.sourceType2.value ||
      prevState.atmId !== this.state.atmId
    ) {
      this.loadDocuments(
        this.state.atmId,
        this.state.sourceType.value,
        this.state.sourceType2.value,
        this.state.date
      );
    }
  }

  formatTable = (data) => {
    let newInisiasiList = [];
    if (data && data.transactions) {
      newInisiasiList = data.transactions.map((item) => {
        return {
          id: item.transaction_id,
          nomorResi: item.resi_number,
          dateTime: formatUTCDisplayDate(item.date_time),
          total: formatRupiah(item.total, "."),
          type: item.type,
          fileId: "#" + item.transaction_id,
          lineNumber: <div style={{ textAlign: "center" }}>16</div>,
        };
      });
    }

    return newInisiasiList;
  };

  loadDocuments = async (id, sourceType, sourceType2, date) => {
    this.setState({ loading: true });
    try {
      const data = await this.props.onGetDocReconsiliation(
        id,
        sourceType,
        sourceType2,
        formatRequestDateOnly(date)
      );
      this.setState({ documents: data ? data : [], loading: false });
    } catch (error) {
      this.setState({ loading: false });
    }
  };

  render() {
    const columns = [
      {
        name: "Nomor Resi",
        style: { width: "18%" },
      },
      {
        name: "Tanggal",
        style: { width: "16%" },
      },
      {
        name: "Jumlah",
        style: { width: "20%" },
      },
      {
        name: "Tipe Transaksi",
        style: { width: "16%" },
      },
      {
        name: "File ID",
        style: { width: "18%" },
      },
      {
        name: "Line Number",
        style: { width: 200, textAlign: "center" },
      },
    ];

    return (
      <div>
        <Loader show={this.state.loading} />
        <h2 className="page-header header-detail-rekonsiliasi">
          Rekonsiliasi Dokumen
        </h2>
        <div className="row container-detail-rekonsiliasi">
          <div className="col-8 col-sm-12">
            <Card className="card-detail-rekonsiliasi">
              {/* <div className="row info-detail-rekonsiliasi">
                                <div className="col-4 col-md-12">
                                    <label className="label-detail-rekonsiliasi">ID Replenishment</label>
                                </div>
                                <div className="col-8 col-md-12 col-sm-12">
                                    <label className="value-detail-rekonsiliasi">{this.state.replenishmentId}</label>
                                </div>
                            </div> */}
              <div className="row info-detail-rekonsiliasi">
                <div className="col-4 col-md-12 col-sm-12">
                  <label className="label-detail-rekonsiliasi">ATM</label>
                </div>
                <div className="col-8 col-md-12 col-sm-12">
                  <label className="value-detail-rekonsiliasi">{`${this.state.atmId} - ${this.state.atmName}`}</label>
                </div>
              </div>
            </Card>
          </div>
        </div>
        <div className="row">
          <div
            className="col-12"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div className="col-8" style={{ display: "flex" }}>
              <div style={{ marginRight: 20 }}>
                <div
                  className="label-detail-rekonsiliasi"
                  style={{ marginBottom: 5 }}
                >
                  Dokumen Utama
                </div>
                <Dropdown
                  style={{ width: "200px" }}
                  styleValue={{ paddingTop: 8, paddingBottom: 8 }}
                  value={this.state.sourceType}
                  options={[
                    { value: "ej", name: "EJ" },
                    { value: "switching", name: "Switching" },
                    { value: "core", name: "Core Banking" },
                  ]}
                  onChange={(item) => this.setState({ sourceType: item })}
                />
              </div>
              <div style={{ marginRight: 20 }}>
                <div
                  className="label-detail-rekonsiliasi"
                  style={{ marginBottom: 5 }}
                >
                  Dokumen Pendamping
                </div>
                <Dropdown
                  style={{ width: "200px" }}
                  styleValue={{ paddingTop: 8, paddingBottom: 8 }}
                  value={this.state.sourceType2}
                  options={[
                    { value: "ej", name: "EJ" },
                    { value: "switching", name: "Switching" },
                    { value: "core", name: "Core Banking" },
                  ]}
                  onChange={(item) => this.setState({ sourceType2: item })}
                />
              </div>
            </div>
            <div
              className="col-4"
              style={{ justifyContent: "flex-end", display: "flex" }}
            >
              <div>
                <div
                  className="label-detail-rekonsiliasi"
                  style={{ marginBottom: 5 }}
                >
                  Tanggal
                </div>
                <div style={{ width: "220px" }}>
                  <DatePicker
                    label="Tanggal Isi Ulang"
                    date={this.state.date}
                    onchangeDate={(date) => this.setState({ date: date })}
                    customInputStyle={{
                      paddingTop: "8px",
                      paddingBottom: "8px",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.documents.is_matched ? (
          <div
            className="col-12"
            style={{
              background: "#EFFCF4",
              paddingTop: "10px",
              paddingBottom: "10px",
              textAlign: "center",
              borderRadius: "8px",
              marginTop: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CheckCircle />
              <div
                style={{
                  fontWeight: "bold",
                  color: "#34774C",
                  marginLeft: "10px",
                }}
              >
                Data Sudah Sesuai
              </div>
            </div>
          </div>
        ) : this.state.documents.transactions &&
          this.state.documents.transactions.length > 0 ? (
          <div className="col-12">
            <h4
              className="page-header header-detail-rekonsiliasi"
              style={{ marginTop: 15, marginBottom: 15 }}
            >
              Tidak Sesuai
            </h4>
            <Table
              columns={columns}
              data={this.formatTable(this.state.documents)}
              // data={this.formatTable(this.props.inisiasiData.data)}
              // tableConfig={this.state.tableConfig}
              // onChange={(identifier, value) => tableFunction.onChangeTable(this, identifier, value)}
              // meta={this.props.inisiasiData.meta}
            />
          </div>
        ) : (
          <div
            className="col-12"
            style={{
              background: "#fcf0ef",
              paddingTop: "10px",
              paddingBottom: "10px",
              textAlign: "center",
              borderRadius: "8px",
              marginTop: "20px",
            }}
          >
            <div style={{ fontWeight: "bold", color: "#773434" }}>
              Data Tidak Ada
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onGetDocReconsiliation: (atmId, sourceType, soureType2, date) =>
      dispatch(getReconsiliationDocument(atmId, sourceType, soureType2, date)),
  };
};

export default connect(null, mapDispatchToProps)(DokumenRekonsiliasi);
