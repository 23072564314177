import React, {Component} from "react";
import {connect} from "react-redux";
import Loader from "../../../components/loader/Loader";
import DatePicker from "../../../components/datepicker/DatePicker";
import Table from "../../../components/table/Table";
import {monitoring} from "../../../redux/actions/reconsiliation";
import {formatRupiah} from "../../../helpers/functionFormat";

class Monitoring extends Component {
  state = {
    loading: false,
    date: new Date(),
    totalAmount: 'Rp. 0',
    totalMissMatch: 0,
    atmList: [],
    meta: {
      pagination: 1,
      total: 0,
      limit: 10,
      page: 1,
    },
    tableConfig: {
      search: '',
      page: 1,
      sort: 'ASC',
      limit: 10,
    },
  }

  getData = async () => {
    const {branchId} = this?.props?.profile;
    this.setState({ loading: true });
    try {
      const response = await this?.props?.onGetData(
        this?.state?.tableConfig?.limit,
        this?.state?.tableConfig?.page,
        this?.state?.tableConfig?.sort,
        this?.state?.date,
        this?.state?.tableConfig?.search,
        branchId
      );
      this.setState({
        loading: false,
        totalAmount: formatRupiah(response?.data?.amount_missmatch, '.'),
        totalMissMatch: response?.data?.total_missmatch,
        atmList: response?.data?.atm_list,
        meta: {
          pagination: response.meta.total === 0 ? 1 :Math.ceil(
            response.data.total / response.meta.limit
          ),
          total: response.meta.total,
          limit: response.meta.limit,
          page: response.meta.page,
        }
      })
      // console.log(response);
    } catch (error) {
      this.setState({
        loading: false,
        alert: {
          ...alert,
          show: true,
          message: error.message,
          isError: true,
        },
      });
    }
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.tableConfig !== this.state.tableConfig ||
      prevState.date !== this.state.date
    ) {
      this.getData();
    }
  }

  onChangeTable = (identifier, value) => {
    this.setState((prevState) => {
      let page = identifier === 'page' ? value : prevState.tableConfig.page;
      page = identifier === 'search' ? 1 : page;
      return {
        ...prevState,
        tableConfig: {
          ...prevState.tableConfig,
          [identifier]: value,
          page: page,
        },
      };
    });
  };

  formatTable = (data) => {
    return data?.map(item => {
      // console.log(item)
      return {
        name: item?.atm_name,
        total: item.total_missmatch,
        totalChecked: item.total_checked_missmatch,
      }
    });
  }

  render() {
    const columns = [
      {
        name: 'Nama ATM',
        style: { width: 100, fontSize: '14px' },
      },
      {
        name: 'Jumlah Missmatch',
        style: { width: 300, fontSize: '14px' },
      },
      {
        name: 'Jumlah Missmatch Yang Sudah Dicek',
        style: { width: 250, fontSize: '14px' },
      },
    ];
    return (
      <div>
        <h2 className="page-header">Monitoring</h2>
        <Loader show={this.state.loading}/>
        <div className="row container-detail-rekonsiliasi">
          <div className="col-8 col-md-12 col-sm-12">
            <div className="col-4 col-md-12 col-sm-12" style={{marginBottom: '20px'}}>
              <label className="label-detail-rekonsiliasi">Tanggal</label>
              <div style={{width: '100%', marginTop: '5px'}}>
                <DatePicker
                  selectsRange={this.state.document === "SWITCHING"}
                  date={this.state.date}
                  onchangeDate={date => {
                    this.setState({date: date})
                  }}
                  customInputStyle={{paddingTop: '8px', paddingBottom: '8px'}}
                  label="Pilih Tanggal"
                />
              </div>
            </div>
          </div>
        </div>
        <div className='col-12' style={{ display: 'flex' }}>
          <div style={{ marginRight: 20 }}>
            <div className='card-info-rekon-doc'>
              <div className='title-info-rekon-doc'>Total Missmatch</div>
              <div className='value-info-rekon-doc'>
                {this?.state?.totalMissMatch}
              </div>
            </div>
          </div>
          <div style={{ marginRight: 20 }}>
            <div className='card-info-rekon-doc'>
              <div className='title-info-rekon-doc'>Amount Missmatch</div>
              <div
                className='amount-info-rekon-doc'
                style={{
                  fontSize:
                    this.state?.totalAmount?.length > 10
                      ? (
                      (242 / this.state?.totalAmount?.length) *
                      1.4
                    ).toString() + 'px'
                      : '25px',
                }}
              >
                {this?.state?.totalAmount}
              </div>
            </div>
          </div>
        </div>
        <div className="col-8 col-md-12 col-sm-12" style={{marginTop: '20px'}}>
          <h3 style={{marginBottom: 20}}>Data Missmatch per hari</h3>
        </div>
        <Table
          columns={columns}
          data={this?.formatTable(this?.state?.atmList ?? [])}
          useHeader
          useFooter
          stickyLeft={[0]}
          stickyBodyLeft={['idComponent']}
          tableConfig={this.state.tableConfig}
          onChange={this.onChangeTable}
          meta={this?.state?.meta}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.auth.profile
  };
};

const mapDispatchToProps = (dispatch) => {
  return{
    onGetData: (limit, page, sort, date, search, branchId) => dispatch(monitoring(limit, page, sort, date, search, branchId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Monitoring);
