import React, {useRef, useState} from 'react';
import { ReactComponent as ChevronDown } from '../../assets/icons/chevron-down.svg';
import './dropdown.css';

const clickOutsideRef = (content_ref, toggle_ref, onChangeOpen, isOpen) => {
    document.addEventListener('mousedown', (e) => {
        if (toggle_ref.current && toggle_ref.current.contains(e.target)) {
            onChangeOpen(!isOpen)
        } else {
            if (content_ref.current && !content_ref.current.contains(e.target)) {
                onChangeOpen(!isOpen)
            }
        }
    }, isOpen)
}


const Dropdown = props => {

    const { value, options, onChange, style, styleValue, containerStyle, disabledClick, placeholder = '' } = props;

    const dropdown_toggle_el = useRef(null)
    const dropdown_content_el = useRef(null)

    const [isOpen, setOpen] = useState(false);

    clickOutsideRef(dropdown_content_el, dropdown_toggle_el, setOpen, isOpen);

    const onSelected = (value) => {
        if(onChange) onChange(value);
        setOpen(false);
    }

    return (
        <div className="dropdown">
            <div ref={disabledClick? null : dropdown_toggle_el} className="dropdown-header" style={containerStyle? containerStyle : style}>
                {value?.name ? (<div className="dropdown-value" style={styleValue}>{value?.name}</div>) : (
                  <div className="dropdown-value" style={styleValue}><span style={{color: "lightgray"}}>{placeholder}</span></div>
                )}
                <div className="dropdown-chevron">
                    <ChevronDown />
                </div>
            </div>
            {isOpen && (
                <div ref={dropdown_content_el} className="dropdown-list" style={style}>
                    {options.map(item => (
                        <div
                            className="dropdown-listitem"
                            onClick={() => onSelected(item)}
                            key={item.value}
                        ><span style={{color: item.value ? 'black' : 'grey'}}>{item.name}</span></div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default Dropdown
