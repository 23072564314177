import React, {Component} from 'react';
import {connect} from 'react-redux';
import './input-user.css';
import Loader from '../../../components/loader/Loader';
import Alert from '../../../components/alert/Alert';
import Card from '../../../components/card/Card';
import TextInput from '../../../components/textInput/TextInput';
import Button from '../../../components/button/Button';
import Dropdown from '../../../components/dropdown/Dropdown';
import {assignRoleUser, createUser, getAllBranch, getListRole} from '../../../redux/actions';
import validationInput from '../../../helpers/validationInput';
import {DropdownAutoComplete} from '../../../components/dropdownAutoComplete';
import {editUser, findUser, getRoleUserId, updateRoleUser} from "../../../redux/actions/user";

class InputUser extends Component {

    state = {
        id: this.props?.history?.location?.state?.data?.user_id || null,
        name: {
            value: '',
            touched: false,
            valid: !!this.props?.history?.location?.state?.data?.name,
            validation: {
                isRequired: true
            },
            error: ''
        },
        // name: '',
        role: { value: 1, name: 'Manajer' },
        useRoleId: null,
        email: {
            value: '',
            touched: false,
            valid: !!this.props?.history?.location?.state?.data?.email,
            validation: {
                isRequired: true,
                isEmail: true
            },
            error: ''
        },
        phone: {
            value: '',
            touched: false,
            valid: !!this.props?.history?.location?.state?.data?.phone,
            validation: {
                isRequired: true
            },
            error: ''
        },
        password: {
            value: '',
            label: 'Password',
            touched: false,
            valid: !!this.props?.history?.location?.state?.data?.user_id,
            isShow: false,
            validation: {
                isRequired: true,
                minLength: 8,
                upperCaseRequired: true,
                lowerCaseRequired: true,
                numberRequired: true,
                symbolRequired: true
            },
            error: ''
        },
        confirmPassword: {
            value: '',
            touched: false,
            valid: !!this.props?.history?.location?.state?.data?.user_id,
            isShow: false,
            validation: {
                isRequired: true,
                equalTo: true
            },
            error: ''
        },
        loading: false,
        alert: {
            show: false,
            message: '',
            isError: false,
            onBtnPress: null,
            onBackPress: null
        },
        branch_id: [],
        tableConfig: {
            search: '',
            page: 1,
            sort: 'value',
            limit: 10
        },
    }

    textInputRef = React.createRef();

    componentDidMount(){
        this.getListRole();
        if(this.props.location && this.props.location.state && this.props.roleData.data.length > 0){
            this.setState({
                // id: this.props.location.state.id,
                // name: this.props.location.state.name,
                // location: this.props.location.state.location,
                // merk: this.props.location.state.merk,
                // description: this.props.location.state.description
            })
        }
        if(this.state.id) {
            this.onLoadUser(this.state.id);
        }
        this.loadBranchList();
    }

    componentDidUpdate(prevProps, prevState){
        if(prevState.id !== this.state.id ){
            this.onLoadUser(this.state.id);
        }
    }

    getListRole = async () => {
        this.setState({loading: true});
        try{
            await this.props.onGetListRole();
            this.setState({loading: false});
        }catch(error){
            this.setState({
                loading: false,
                alert: {
                    ...alert,
                    show: true,
                    message: error.message,
                    isError: true,
                    onBtnPress: null,
                    onBackPress: null
                }
            })
        }
    }

    loadBranchList = async () => {
        // if(this._isMounted){
        //     this.setState({loading: true});
        // }
        try{
            await this.props.getAllBranch();
            if(this._isMounted){
                this.setState({loading: false})
            }
        }catch(error){
            if(this._isMounted){
                this.setState({
                    loading: false,
                    alert: {
                        ...alert,
                        show: true,
                        message: error.message,
                        isError: true
                    }
                })
            }
        }
    }

    getUserRoleId = async (id) => {
        try {
            const response = await this.props.onGetUserRoleId(id);
            if(response?.data?.length > 0) {
                this.setState({useRoleId: response?.data[0]?.role_user_id})
            }
        } catch (error) {
            console.log(error?.message)
            // this.setState({
            //     loading: false,
            //     alert: {
            //         ...alert,
            //         show: true,
            //         message: error.message,
            //         isError: true,
            //         onBtnPress: null,
            //         onBackPress: null
            //     }
            // })
        }
    }

    onLoadUser = async (id) => {
        this.setState({loading: true});
        try{
            const response = await this.props.onFindUser(id);
            if(response?.meta === 200) {
                let branchArr = []
                console.log("[LOG] => (InputUser.jsx:167) response", JSON.stringify(response?.data, null, 4));
                if(response?.data?.branches) {
                    branchArr = response?.data?.branches?.map(function (obj) {
                        return {value: obj?.branch_id, name: obj?.name};
                    })
                }
                this.setState({
                    name: {...this.state.name, value: response?.data?.name, valid: true},
                    email: {...this.state.name, value: response?.data?.email, valid: true},
                    phone: {...this.state.name, value: response?.data?.phone, valid: true},
                    role: { value: response?.data?.role?.role_id ?? 1, name: response?.data?.role?.name ?? 'Manajer'},
                    branch_id: branchArr,
                })
                this.getUserRoleId(response?.data?.user_id);
            }
        }catch(error){
            this.setState({
                loading: false,
                alert: {
                    ...alert,
                    show: true,
                    message: error.message,
                    isError: true,
                    onBtnPress: null,
                    onBackPress: null
                }
            })
        }
    }

    onCreateUser = async () => {
        this.setState({loading: true});
        try{
            const response = await this.props.onCreateUser(
                this.state.email.value,
                this.state.name.value,
                this.state.phone.value,
                this.state.password.value,
                this.state.branch_id.map(({ value }) => value)
            );
            if(response.data){
                await this.props.onAssignRole(response.data.id, this.state.role.value);
            }
            this.setState({
                email: {
                    value: '',
                    touched: false,
                    valid: false,
                    validation: {
                        isRequired: true,
                        isEmail: true
                    },
                    error: ''
                },
                name: {
                    value: '',
                    touched: false,
                    valid: false,
                    validation: {
                        isRequired: true
                    }
                },
                phone: {
                    value: '',
                    touched: false,
                    valid: false,
                    validation: {
                        isRequired: true
                    },
                    error: ''
                },
                password: {
                    value: '',
                    touched: false,
                    valid: false,
                    isShow: false,
                    validation: {
                        isRequired: true,
                        minLength: 8,
                        upperCaseRequired: true,
                        lowerCaseRequired: true,
                        numberRequired: true,
                        symbolRequired: true
                    },
                    error: ''
                },
                confirmPassword: {
                    value: '',
                    touched: false,
                    valid: false,
                    isShow: false,
                    validation: {
                        isRequired: true,
                        equalTo: true
                    },
                    error: ''
                },
                loading: false,
                alert: {
                    ...alert,
                    show: true,
                    message: response.message,
                    isError: false,
                    onBtnPress: () => this.props.history.goBack(),
                    onBackPress: () => this.props.history.goBack()
                }
            })
        }catch(error){
            this.setState({
                loading: false,
                alert: {
                    ...alert,
                    show: true,
                    message: error.message,
                    isError: true,
                    onBtnPress: null,
                    onBackPress: null
                }
            })
        }
    }

    onEditUser = async () => {
        this.setState({loading: true});
        console.log(this.state.branch_id.map(({ value }) => value));
        try{
            const response = await this.props.onEditUser(
              this.state.id,
              this.state.email.value,
              this.state.name.value,
              this.state.phone.value,
              this.state.password.value,
              this.state.branch_id.map(({ value }) => value)
            );
            if(response?.meta === 202 && this.state.useRoleId) {
                await this.props.onUpdateRole(this.state.useRoleId, this.state.id, this.state.role.value);
            }
            this.setState({
                loading: false,
                alert: {
                    ...alert,
                    show: true,
                    message: response.message,
                    isError: false,
                    onBtnPress: () => this.props.history.goBack(),
                    onBackPress: () => this.props.history.goBack()
                }});
            this.setState({loading: false});
        }catch(error){
            this.setState({
                loading: false,
                alert: {
                    ...alert,
                    show: true,
                    message: error.message,
                    isError: true
                }
            })
        }
    }

    formatDropdownRole = (data) => {
        if(!data){
            return [{ value: 1, name: 'Manajer'}];
        }
        return data.map(role => {
            return {
                value: role.role_id,
                name: role.name
            }
        });
    }

    onChangeInput = (value, identifier, equalObject, targetEqualId) => {
        const validation = validationInput(value, this.state[identifier].validation, equalObject);
        this.setState({
            [identifier]: {
                ...this.state[identifier],
                valid: validation.valid,
                touched: true,
                error: validation.messageError,
                value: value
            }
        })
        if(targetEqualId && this.state[targetEqualId].touched){
            let object = {
                ...this.state[identifier],
                value: value
            }
            const validationTargetEqual = validationInput(
                this.state[targetEqualId].value,
                this.state[targetEqualId].validation,
                object
            );
            this.setState({
                [targetEqualId]: {
                    ...this.state[targetEqualId],
                    valid: validationTargetEqual.valid,
                    error: validationTargetEqual.messageError
                }
            })
        }
    }

    render() {
        const branchData = this.props.branchData.branchAll.map(({ name, id}) => ({value: id, name: name}));
        return (
            <div className="col-12" style={{marginBottom: 20}}>
                <Loader show={this.state.loading}/>
                <Alert
                    show={this.state.alert.show}
                    message={this.state.alert.message}
                    isError={this.state.alert.isError}
                    modalClosed={() => this.setState({alert: {...alert, show: false}})}
                    onBtnPress={this.state.alert.onBtnPress}
                    onBackPress={this.state.alert.onBackPress}
                />
                <div className="row">
                    <div className="col-8">
                        <h4 className="title-input-user">{this.state.id? 'Edit User' : "Tambah User"}</h4>
                        <Card>
                            <div className="col-12">
                                <div className="row" style={{marginBottom: 20}}>
                                    <div className="col-6" ref={this.textInputRef}>
                                        <TextInput
                                            className="input_nama"
                                            label="Nama User"
                                            placeholder="Nama User"
                                            value={this.state.name.value}
                                            valid={this.state.name.valid}
                                            touched={this.state.name.touched}
                                            messageerror={this.state.name.error}
                                            onChange={(e) => this.onChangeInput(e.target.value, 'name', null)}
                                            style={{fontSize: '16px', paddingTop: '2px', paddingBottom: '2px'}}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <div className="textinput-label textinput-label-input-user">Peran</div>
                                        <Dropdown
                                            className="input_peran"
                                            containerStyle={{width: '100%'}}
                                            style={{width: this.textInputRef.current? this.textInputRef.current.clientWidth - 30 : 182}}
                                            styleValue={{padding: '8px 10px'}}
                                            value={this.state.role}
                                            options={this.formatDropdownRole(this.props.roleData.data)}
                                            onChange={(value) => this.setState({role: value})}
                                        />
                                    </div>
                                </div>
                                <div className="row" style={{marginBottom: 20}}>
                                    <div className="col-6">
                                        <TextInput
                                            className="input_email"
                                            label="Email"
                                            placeholder="Email User"
                                            type="email"
                                            value={this.state.email.value}
                                            valid={this.state.email.valid}
                                            touched={this.state.email.touched}
                                            messageerror={this.state.email.error}
                                            onChange={(e) => this.onChangeInput(e.target.value, 'email', null)}
                                            style={{fontSize: '16px', paddingTop: '2px', paddingBottom: '2px'}}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <TextInput
                                            className="input_noTelp"
                                            label="Nomor Telepon"
                                            placeholder="Nomor Telepon"
                                            type="number"
                                            value={this.state.phone.value}
                                            valid={this.state.phone.valid}
                                            touched={this.state.phone.touched}
                                            messageerror={this.state.phone.error}
                                            onChange={(e) => this.onChangeInput(e.target.value, 'phone', null)}
                                            style={{fontSize: '16px', paddingTop: '2px', paddingBottom: '2px'}}
                                        />
                                    </div>
                                </div>
                                <div className="row" style={{marginBottom: 20}}>
                                    <div className="col-6">
                                        <TextInput
                                            className="input_password"
                                            label="Password"
                                            placeholder="Password"
                                            type={this.state.password.isShow? "default" : "password"}
                                            value={this.state.password.value}
                                            valid={this.state.password.valid}
                                            touched={this.state.password.touched}
                                            messageerror={this.state.password.error}
                                            onChange={(e) => {
                                                this.onChangeInput(e.target.value, 'password', null, 'confirmPassword');
                                                if(this.props?.history?.location?.state?.data?.user_id) {
                                                    if(e.target.value === '') {
                                                        this.setState({
                                                            password: {
                                                                ...this.state.password,
                                                                touched: false,
                                                                value: '',
                                                                valid: true,
                                                            }
                                                        })
                                                        if (this.state.confirmPassword.value === '') {
                                                            this.setState({
                                                                confirmPassword: {
                                                                    ...this.state.confirmPassword,
                                                                    touched: false,
                                                                    value: '',
                                                                    valid: true,
                                                                }
                                                            })
                                                        }
                                                    } else {
                                                        if(this.state.confirmPassword.value === '') {
                                                            this.setState({
                                                                confirmPassword: {
                                                                    ...this.state.confirmPassword,
                                                                    touched: false,
                                                                    value: '',
                                                                    valid: false,
                                                                }
                                                            })
                                                        }
                                                    }
                                                }
                                            }}
                                            style={{fontSize: '16px', paddingTop: '2px', paddingBottom: '2px'}}
                                            righticon={(
                                                <div
                                                    className="textinput-hide-show-btn"
                                                    onClick={() => this.setState({password: {
                                                        ...this.state.password,
                                                        isShow: !this.state.password.isShow
                                                    }})}>
                                                    {this.state.password.isShow? 'hide' : 'show'}
                                                </div>
                                            )}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <TextInput
                                            className="input_konfirmasiPassword"
                                            label="Konfirmasi Password"
                                            placeholder="Konfirmasi Password"
                                            type={this.state.confirmPassword.isShow? "default" : "password"}
                                            value={this.state.confirmPassword.value}
                                            valid={this.state.confirmPassword.valid}
                                            touched={this.state.confirmPassword.touched}
                                            messageerror={this.state.confirmPassword.error}
                                            onChange={(e) => {
                                                this.onChangeInput(e.target.value, 'confirmPassword', this.state.password);
                                                if(this.props?.history?.location?.state?.data?.user_id) {
                                                    if (e.target.value === '') {
                                                        if (this.state.password.value === '') {
                                                            this.setState({
                                                                confirmPassword: {
                                                                    ...this.state.confirmPassword,
                                                                    touched: false,
                                                                    value: '',
                                                                    valid: true,
                                                                }
                                                            })
                                                        } else {
                                                            this.setState({
                                                                confirmPassword: {
                                                                    ...this.state.confirmPassword,
                                                                    touched: true,
                                                                    value: '',
                                                                    valid: false,
                                                                }
                                                            })
                                                        }
                                                    }
                                                }
                                            }}
                                            style={{fontSize: '16px', paddingTop: '2px', paddingBottom: '2px'}}
                                            righticon={(
                                                <div
                                                    className="textinput-hide-show-btn"
                                                    onClick={() => this.setState({confirmPassword: {
                                                        ...this.state.confirmPassword,
                                                        isShow: !this.state.confirmPassword.isShow
                                                    }})}>
                                                    {this.state.confirmPassword.isShow? 'hide' : 'show'}
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="row" style={{marginBottom: 20}}>
                                    <div className="col-6">
                                        <div className="textinput-label textinput-label-input-user">Cabang</div>
                                        <DropdownAutoComplete
                                            onChange={(val) => this.setState({ branch_id: val })}
                                            option={branchData}
                                            multiple
                                            value={this.state.branch_id}
                                            placeholder='Pilih Cabang'
                                        />
                                    </div>
                                </div>
                                <div style={{borderBottom: '1px solid #E4E7EB'}}/>
                                <div className="col-12 btn-container-input-user">
                                    <Button
                                        disabled={
                                          !this.state.name.valid ||
                                          !this.state.email.valid ||
                                          !this.state.password.valid ||
                                          !this.state.phone.valid ||
                                          !this.state.confirmPassword.valid
                                        }
                                        title={this.state.id? "Simpan Perubahan" : "Tambahkan"}
                                        onClick={this.state.id? this.onEditUser : this.onCreateUser}
                                    />
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        roleData: state.user.role,
        branchData: state.branch
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onEditUser: (id, email, name, phone, password, branch_id) => dispatch(editUser(id, email, name, phone, password, branch_id)),
        onFindUser: (id) => dispatch(findUser(id)),
        onCreateUser: (email, name, phone, password, branch_id) => dispatch(createUser(email, name, phone, password, branch_id)),
        onGetListRole: () => dispatch(getListRole()),
        onAssignRole: (userId, roleId) =>  dispatch(assignRoleUser(userId, roleId)),
        onUpdateRole: (id, userId, roleId) =>  dispatch(updateRoleUser(id, userId, roleId)),
        onGetUserRoleId: (userId) =>  dispatch(getRoleUserId(userId)),
        getAllBranch: () => dispatch(getAllBranch()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InputUser);
