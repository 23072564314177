import React, { Component } from 'react';
import './dashboard.css';
import { connect } from 'react-redux';
import ATM from './ATM';
import KasusSelisih from './KasusSelisih';
import InisiasiUlang from './InisiasiUlang';
import Rekonsiliasi from './Rekonsiliasi';
import TidakMengirimLog from './TidakMengirimLog';
import StatusCard from '../../../components/statuscard/StatusCard';
// import Dropdown from '../../../components/dropdown/Dropdown';
import { changeFirstLoggedIn, getDashboardData, changePage } from '../../../redux/actions';
import Loader from '../../../components/loader/Loader';
import Alert from '../../../components/alert/Alert';

class Dashboard extends Component {
    _isMounted;
    state = {
        // month: { value: '', name: 'Semua'},
        // year: { value: '', name: 'Semua'},
        loading: false,
        alert: {
            show: false,
            message: '',
            isError: false
        }
    }

    componentDidMount(){
        this._isMounted = true;
        if(this._isMounted){
            if(this.props.isFirstLoggedIn){
                this.props.onChangeFirstLoggedIn();
            }
        }
        this.onGetDashboardData();
        // let date = new Date();
        // this.setState({
        //     month: this.listMonth()[date.getMonth() + 1],
        //     year: {
        //         value: date.getFullYear().toString(),
        //         name: date.getFullYear().toString()
        //     }
        // })
    }

    // componentDidUpdate(prevProps, prevState){
    //     if(prevState.month.value !== this.state.month.value || prevState.year.value !== this.state.year.value){
    //         this.onGetDashboardData();
    //     }
    // }

    onGetDashboardData = async () => {
        this.setState({loading: true});
        try{
            const branchId = this.props?.profile?.branchId;
            await this.props.onGetDashboard(branchId);
            this.setState({ loading: false })
        }catch(error){
            this.setState({
                loading: false,
                alert: {
                    ...alert,
                    show: true,
                    message: error.message,
                    isError: true
                }
            })
        }
    }

    getScreenComponent = (screen) => {
        switch(screen){
            case "atm": {
                return <ATM
                    {...this.props}
                    onLoading={(value) => this.setState({loading: value})}
                />
            }
            case "inisiasi": {
                return <InisiasiUlang
                    {...this.props}
                    listMonth={this.listMonth}
                    listYear={this.listYear}
                    onLoading={(value) => this.setState({loading: value})}
                />
            }
            case "rekonsiliasi": {
                return <Rekonsiliasi
                    {...this.props}
                    listMonth={this.listMonth}
                    listYear={this.listYear}
                    onLoading={(value) => this.setState({loading: value})}
                />
            }
            case "kasus-selisih": {
                return <KasusSelisih
                    {...this.props}
                    listMonth={this.listMonth}
                    listYear={this.listYear}
                    onLoading={(value) => this.setState({loading: value})}
                />
            }
            case "tidak-mengirim-log": {
                return <TidakMengirimLog
                  {...this.props}
                  onLoading={(value) => this.setState({loading: value})}
                />
            }
            default: {
                return <ATM
                    {...this.props}
                    // month={this.state.month.value}
                    // year={this.state.year.value}
                    onLoading={(value) => this.setState({loading: value})}
                />
            }
        }
    }

    listMonth = () => {
        return [
            // { value: '', name: 'Semua' },
            { value: '01', name: 'Januari' },
            { value: '02', name: 'Februari' },
            { value: '03', name: 'Maret' },
            { value: '04', name: 'April' },
            { value: '05', name: 'Mei' },
            { value: '06', name: 'Juni' },
            { value: '07', name: 'Juli' },
            { value: '08', name: 'Agustus' },
            { value: '09', name: 'September' },
            { value: '10', name: 'Oktober' },
            { value: '11', name: 'November' },
            { value: '12', name: 'Desember' }
        ]
    }

    listYear = () => {
        let year = new Date().getFullYear();
        let firstYear = year - 3;
        let years = [];
        years.push({value: '', name: 'Semua'});
        for(let i=firstYear; i <= year; i++){
            years.push({
                value: i.toString(),
                name: i.toString()
            })
        }
        return years;
    }

    render(){
        return (
            <div className="page-dashboard">
                <Loader show={this.state.loading}/>
                <Alert
                    show={this.state.alert.show}
                    message={this.state.alert.message}
                    isError={this.state.alert.isError}
                    modalClosed={() => this.setState({alert: {...alert, show: false}})}
                />
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start'}}>
                    <h2 className="page-header">Dashboard</h2>
                    {/* <div style={{display: 'flex'}}>
                        <div style={{marginRight: '10px'}}>
                            <Dropdown
                                style={{width: '140px'}}
                                value={this.state.month}
                                options={this.listMonth()}
                                onChange={(month) => this.setState({month: month})}
                            />
                        </div>
                        <div>
                            <Dropdown
                                style={{width: '100px'}}
                                value={this.state.year}
                                options={this.listYear()}
                                onChange={(year) => this.setState({year: year})}
                            />
                        </div>
                    </div> */}
                </div>
                <div className="row d-flex">
                    <div className="col-25 col-sm-6 col-xs-6">
                        {/* <div className="btn-status" onClick={() => this.setState({screen: 'atm'})}> */}
                        <div className="btn-status" onClick={() => this.props.onChangePage('dashboard', 'atm')}>
                            <StatusCard
                                title="Total ATM"
                                count={this.props.dashboard.atm}
                                active={this.props.currentScreen === 'atm'}
                                classExtra="menu_totalAtm"
                            />
                        </div>
                    </div>
                    <div className="col-25 col-sm-6 col-xs-6">
                        <div className="btn-status" onClick={() => this.props.onChangePage('dashboard', 'inisiasi')}>
                            <StatusCard
                                title="Inisiasi Isi Ulang"
                                count={this.props.dashboard.iniasiUlang}
                                active={this.props.currentScreen === 'inisiasi'}
                                classExtra="menu_inisiasiUlang"
                            />
                        </div>
                    </div>
                    <div className="col-25 col-sm-6 col-xs-6">
                        <div className="btn-status" onClick={() => this.props.onChangePage('dashboard', 'rekonsiliasi')}>
                            <StatusCard
                                title="Rekonsiliasi"
                                count={this.props.dashboard.rekonsiliasi}
                                active={this.props.currentScreen === 'rekonsiliasi'}
                                classExtra="menu_rekonsilasi"
                            />
                        </div>
                    </div>
                    <div className="col-25 col-sm-6 col-xs-6">
                        <div className="btn-status" onClick={() => this.props.onChangePage('dashboard', 'kasus-selisih')}>
                            <StatusCard
                                title="Kasus Selisih"
                                count={this.props.dashboard.selisih}
                                active={this.props.currentScreen === 'kasus-selisih'}
                                classExtra="menu_kasusSelisih"
                            />
                        </div>
                    </div>
                    <div className="col-25 col-sm-6 col-xs-6">
                        <div className="btn-status" onClick={() => this.props.onChangePage('dashboard', 'tidak-mengirim-log')}>
                            <StatusCard
                              title="Tidak Mengirim Log"
                              count={this.props.dashboard.inactiveAtm}
                              active={this.props.currentScreen === 'tidak-mengirim-log'}
                              classExtra="menu_tidakMengirimLog"
                            />
                        </div>
                    </div>
                </div>
                {this.getScreenComponent(this.props.currentScreen)}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        dashboard: state.dashboard,
        isFirstLoggedIn: state.auth.isFirstLoggedIn,
        currentScreen: state.config.pages.dashboard,
        profile: state.auth.profile
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onChangePage: (key, page) => dispatch(changePage(key, page)),
        onGetDashboard: (branchId) => dispatch(getDashboardData(branchId)),
        onChangeFirstLoggedIn: () => dispatch(changeFirstLoggedIn(false))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
