import axios from 'axios';
import * as actionTypes from '../actionTypes';
import { logout } from './auth';

export const getAuditList = (page, limit, keyword, sort) => {
    return async (dispatch, getState) => {
        try {
            const url = getState().config.url;
            const token = getState().auth.token;

            const response = await axios.get(
                `${url}/audit-trail?page=${page}&limit=${limit}&keyword=${keyword}&sort=${sort}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token,
                    },
                }
            );

            await dispatch({
                type: actionTypes.GET_LIST_AUDIT,
                data: response.data.data,
                meta: {
                    pagination: Math.ceil(
                        response.data.meta.total / response.data.meta.limit
                    ),
                    total: response.data.meta.total,
                    limit: response.data.meta.limit,
                    page: response.data.meta.page,
                },
            });
            return response;
        } catch (error) {
            let message = 'Internal Server Error';
            if (error.response) {
                if (error.response.data.errors) {
                    if (error.response.data.errors[0].code === 1009) {
                        dispatch(logout());
                    }
                    message = error.response.data.errors[0].message;
                }
            } else {
                message = error.message;
            }
            throw new Error(message);
        }
    };
};

export const getUserList = (page, limit, keyword, sort, roleId) => {
    return async (dispatch, getState) => {
        try {
            const url = getState().config.url;
            const token = getState().auth.token;

            let role = '';
            if (roleId) {
                role += `&role_id=${roleId}`;
            }

            const response = await axios.get(
                `${url}/super-admin/users?page=${page}&limit=${limit}&keyword=${keyword}&sort=${sort}${role}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token,
                    },
                }
            );

            await dispatch({
                type: actionTypes.GET_LIST_USER,
                data: response.data.data,
                meta: {
                    pagination: Math.ceil(
                        response.data.meta.total / response.data.meta.limit
                    ),
                    total: response.data.meta.total,
                    limit: response.data.meta.limit,
                    page: response.data.meta.page,
                },
            });
            return response;
        } catch (error) {
            let message = 'Internal Server Error';
            if (error.response) {
                if (error.response.data.errors) {
                    if (error.response.data.errors[0].code === 1009) {
                        dispatch(logout());
                    }
                    message = error.response.data.errors[0].message;
                }
            } else {
                message = error.message;
            }
            throw new Error(message);
        }
    };
};

export const getListRole = () => {
    return async (dispatch, getState) => {
        try {
            const url = getState().config.url;
            const token = getState().auth.token;

            const response = await axios.get(`${url}/super-admin/roles`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
            });

            await dispatch({
                type: actionTypes.GET_LIST_ROLE,
                data: response.data.data,
                meta: {
                    pagination: Math.ceil(
                        response.data.meta.total / response.data.meta.limit
                    ),
                    total: response.data.meta.total,
                    limit: response.data.meta.limit,
                    page: response.data.meta.page,
                },
            });
            return response;
        } catch (error) {
            let message = 'Internal Server Error';
            if (error.response) {
                if (error.response.data.errors) {
                    if (error.response.data.errors[0].code === 1009) {
                        dispatch(logout());
                    }
                    message = error.response.data.errors[0].message;
                }
            } else {
                message = error.message;
            }
            throw new Error(message);
        }
    };
};

export const createUser = (email, name, phone, password, branch_id) => {
    return async (dispatch, getState) => {
        try {
            const url = getState().config.url;
            const token = getState().auth.token;

            const response = await axios.post(
                `${url}/super-admin/users`,
                {
                    email: email,
                    name: name,
                    phone: phone,
                    password: password,
                    is_active_flag: true,
                    branch_ids: branch_id,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token,
                    },
                }
            );

            return {
                meta: response.data.meta.http_status,
                message: 'Tambah User Berhasil!',
                data: response.data.data,
            };
        } catch (error) {
            let message = 'Internal Server Error';
            if (error.response) {
                if (error.response.data.errors) {
                    if (error.response.data.errors[0].code === 1009) {
                        dispatch(logout());
                    }
                    message = error.response.data.errors[0].message;
                }
            } else {
                message = error.message;
            }
            throw new Error(message);
        }
    };
};

export const findUser = (id) => {
    return async (dispatch, getState) => {
        try {
            const url = getState().config.url;
            const token = getState().auth.token;

            const response = await axios.get(
              `${url}/super-admin/users/${id}`,
              {
                  headers: {
                      'Content-Type': 'application/json',
                      Authorization: 'Bearer ' + token,
                  },
              }
            );

            return {
                meta: response.data.meta.http_status,
                message: 'Success Find User',
                data: response.data.data,
            };
        } catch (error) {
            let message = 'Internal Server Error';
            if (error.response) {
                if (error.response.data.errors) {
                    if (error.response.data.errors[0].code === 1009) {
                        dispatch(logout());
                    }
                    message = error.response.data.errors[0].message;
                }
            } else {
                message = error.message;
            }
            throw new Error(message);
        }
    };
};

export const editUser = (id, email, name, phone, password, branch_id) => {
    return async (dispatch, getState) => {
        try {
            const url = getState().config.url;
            const token = getState().auth.token;

            const response = await axios.put(
              `${url}/super-admin/users/${id}`,
              {
                  email: email,
                  name: name,
                  phone: phone,
                  password: password,
                  is_active_flag: true,
                  branch_ids: branch_id,
              },
              {
                  headers: {
                      'Content-Type': 'application/json',
                      Authorization: 'Bearer ' + token,
                  },
              }
            );

            return {
                meta: response.data.meta.http_status,
                message: 'Update User Berhasil!',
                data: response.data.data,
            };
        } catch (error) {
            let message = 'Internal Server Error';
            if (error.response) {
                if (error.response.data.errors) {
                    if (error.response.data.errors[0].code === 1009) {
                        dispatch(logout());
                    }
                    message = error.response.data.errors[0].message;
                }
            } else {
                message = error.message;
            }
            throw new Error(message);
        }
    };
};

export const deleteUser = (UID) => {
    return async (dispatch, getState) => {
        try {
            const url = getState().config.url;
            const token = getState().auth.token;

            const response = await axios.delete(
                `${url}/super-admin/users/${UID}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token,
                    },
                }
            );

            return {
                meta: response.data.meta.http_status,
                message: 'Hapus User Berhasil!',
                data: response.data.message,
            };
        } catch (error) {
            let message = 'Internal Server Error';
            if (error.response) {
                if (error.response.data.errors) {
                    if (error.response.data.errors[0].code === 1009) {
                        dispatch(logout());
                    }
                    message = error.response.data.errors[0].message;
                }
            } else {
                message = error.message;
            }
            throw new Error(message);
        }
    };
};

export const assignRoleUser = (userId, roleId) => {
    return async (dispatch, getState) => {
        try {
            const url = getState().config.url;
            const token = getState().auth.token;

            const response = await axios.post(
                `${url}/super-admin/role-users`,
                {
                    role_id: roleId,
                    user_id: userId,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token,
                    },
                }
            );

            return {
                meta: response.data.meta.http_status,
                message: 'Set Role User Berhasil!',
                data: response.data.data,
            };
        } catch (error) {
            let message = 'Internal Server Error';
            if (error.response) {
                if (error.response.data.errors) {
                    if (error.response.data.errors[0].code === 1009) {
                        dispatch(logout());
                    }
                    message = error.response.data.errors[0].message;
                }
            } else {
                message = error.message;
            }
            throw new Error(message);
        }
    };
};

export const getRoleUserId = (userId) => {
    return async (dispatch, getState) => {
        try {
            const url = getState().config.url;
            const token = getState().auth.token;

            const response = await axios.get(
              `${url}/super-admin/role-users/list?user_id=${userId}`,
              {
                  headers: {
                      'Content-Type': 'application/json',
                      Authorization: 'Bearer ' + token,
                  },
              }
            );

            return {
                meta: response.data.meta.http_status,
                message: 'Set Role User Berhasil!',
                data: response.data.data,
            };
        } catch (error) {
            let message = 'Internal Server Error';
            if (error.response) {
                if (error.response.data.errors) {
                    if (error.response.data.errors[0].code === 1009) {
                        dispatch(logout());
                    }
                    message = error.response.data.errors[0].message;
                }
            } else {
                message = error.message;
            }
            throw new Error(message);
        }
    };
};

export const updateRoleUser = (id, userId, roleId) => {
    return async (dispatch, getState) => {
        try {
            const url = getState().config.url;
            const token = getState().auth.token;

            const response = await axios.put(
              `${url}/super-admin/role-users/${id}`,
              {
                  role_id: roleId,
                  user_id: userId,
              },
              {
                  headers: {
                      'Content-Type': 'application/json',
                      Authorization: 'Bearer ' + token,
                  },
              }
            );

            return {
                meta: response.data.meta.http_status,
                message: 'Get User Role ID Berhasil!',
                data: response.data.data,
            };
        } catch (error) {
            let message = 'Internal Server Error';
            if (error.response) {
                if (error.response.data.errors) {
                    if (error.response.data.errors[0].code === 1009) {
                        dispatch(logout());
                    }
                    message = error.response.data.errors[0].message;
                }
            } else {
                message = error.message;
            }
            throw new Error(message);
        }
    };
};

