import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonTab from '../../../components/buttontab/ButtonTab';
import UserList from './User';
import ATM from './ATM';
import Branch from "./Branch";
import Denominasi from './Denominasi';
import { changePage } from '../../../redux/actions';


const Master = props => {

    const dispatch = useDispatch();
    const screen = useSelector(state => state.config.pages.master);

    let page = <UserList {...props} />;
    switch(screen){
        case 'user': {
            page = <UserList {...props} />;
            break;
        }
        case 'atm': {
            page = <ATM {...props} />;
            break;
        }
        case 'denominasi': {
            page = <Denominasi {...props} />;
            break;
        }
        case 'branch': {
            page = <Branch {...props} />;
            break;
        }
        default: {
            page = <UserList {...props} />;
            break;
        }
    }

    return (
        <div style={{minHeight: '100vh', marginBottom: '150px'}}>
            <h2 className="page-header" style={{marginBottom: 20}}>Master</h2>
            <div className="row">
                <div className="col-12">
                    <div style={{paddingBottom: 10, borderBottom: '1px solid #E4E7EB', marginBottom: 20}}>
                        <ButtonTab
                            className="menu_user"
                            style={{marginRight: 10}}
                            title="User"
                            active={screen === 'user'}
                            onPress={() => dispatch(changePage('master', 'user'))}
                        />
                        <ButtonTab
                          className="menu_branch"
                          style={{marginRight: 10}}
                          title="Cabang"
                          active={screen === 'branch'}
                          onPress={() => dispatch(changePage('master', 'branch'))}
                        />
                        <ButtonTab
                            className="menu_atm"
                            style={{marginRight: 10}}
                            title="ATM"
                            active={screen === 'atm'}
                            onPress={() => dispatch(changePage('master', 'atm'))}
                        />
                        <ButtonTab
                            className="menu_denomisasi"
                            style={{marginRight: 10}}
                            title="Denominasi"
                            active={screen === 'denominasi'}
                            onPress={() => dispatch(changePage('master', 'denominasi'))}
                        />
                    </div>
                </div>
                {page}
            </div>
        </div>
    )
}

export default Master;
