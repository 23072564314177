import React from 'react';
import './text-input.css';

const TextInput = props => {

    const { lefticon, righticon, label, labelstyle, containerstyle, iconStyle, type } = props;

    const classReadOnly = props.readOnly? 'textinput-read-only' : '';
    const classError = !props.valid && props.touched? 'textinput-container-error' : '';

    const newProps = { ...props };

    delete newProps.lefticon;
    delete newProps.righticon;
    delete newProps.label;
    delete newProps.labelstyle;
    delete newProps.containerstyle;
    delete newProps.iconStyle;

    if(type && type === 'textarea'){
        return (
            <div>
            {label && <label className="textinput-label" style={labelstyle}>{label}</label>}
                <div className={`textinput-container ${classReadOnly} ${props.containerclassname} ${classError}`} style={containerstyle}>
                    {lefticon && (
                        <div className="textinput-lefticon" style={iconStyle}>
                            {lefticon}
                        </div>
                    )}
                    <textarea 
                        {...props}
                        valid={null}
                        touched={null}
                        messageerror={null}
                        className={`textinput ${classReadOnly} ${props.className}`}
                    />
                    {righticon && (
                        <div className="textinput-righticon" style={iconStyle}>
                            {righticon}
                        </div>
                    )}
                </div>
            </div>
        )
    }

    return (
        <div>
            {label && <label className="textinput-label" style={labelstyle}>{label}</label>}
            <div className={`textinput-container ${classReadOnly} ${props.containerclassname} ${classError}`} style={containerstyle}>
                {lefticon && (
                    <div className="textinput-lefticon" style={iconStyle}>
                        {lefticon}
                    </div>
                )}
                <input 
                    {...props}
                    valid={null}
                    touched={null}
                    messageerror={null}
                    className={`textinput ${classReadOnly} ${props.className}`}
                />
                {righticon && (
                    <div className="textinput-righticon" style={iconStyle}>
                        {righticon}
                    </div>
                )}
            </div>
            {!props.valid && props.touched && <div className="textinput-message-error">{props.messageerror}</div>}
        </div>
    )
}

export default TextInput;