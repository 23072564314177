import React, { Component } from 'react';
import { connect } from 'react-redux';

import Card from '../../../components/card/Card';
import DatePicker from '../../../components/datepicker/DatePicker';
import DropZoneDoc from '../../../components/dropzone/DropzoneDoc';
import Button from '../../../components/button/Button';
import {uploadSwitchingStatement, uploadCoreStatement, getAllATMList} from '../../../redux/actions';
import Loader from '../../../components/loader/Loader';
import { formatRequestDateOnly } from '../../../helpers/functionFormat';
import Alert from '../../../components/alert/Alert';
import {DropdownSelect} from '../../../components/dropdownAutoComplete';
import Select from '@mui/material/Select';
import {MenuItem} from "@mui/material";
import {KeyboardArrowDown} from "@mui/icons-material";
import {downloadStatement} from "../../../redux/actions/other";
import Warning from "../../../components/warning/Warning";

class UnggahStatement extends Component {

    state = {
        document: 'SWITCHING',
        isSyariah: false,
        date: new Date(),
        dateRange: [null, null],
        loading: false,
        file: null,
        alert: {
            show: false,
            message: '',
            isError: false
        },
        warning: {
            show: false,
            message1: '',
            message2: '',
        },
        downloadDocument: 'SWITCHING',
        downloadDate: null,
        downloadDateRange: [null, null],
        downloadAtm: null,
        downloadOverride: false,
    }

    componentDidMount(){
        this.loadATMList(1, 10, '', 'atm_id');
    }

    loadATMList = async (page, limit, search, sort) => {
        try{
            await this.props.onGetATMList(page, limit, search, sort);
            if(this._isMounted){
                this.setState({loading: false})
            }
        }catch(error){
            if(this._isMounted){
                this.setState({
                    loading: false,
                    alert: {
                        ...alert,
                        show: true,
                        message: error.message,
                        isError: true
                    }
                })
            }
        }
    }

    onSubmit = async () => {
        this.setState({loading: true});
        try{
            let response;
            if(this.state.document === "SWITCHING"){
                response = await this.props.onUploadSwitching(
                    this.state.file,
                    formatRequestDateOnly(this.state.dateRange[0]),
                    formatRequestDateOnly(this.state.dateRange[1])
                )
            }else{
                response = await this.props.onUploadCore(
                    this.state.file,
                    this.state.isSyariah,
                    // ,formatRequestDateOnly(this.state.date)
                )
            }

            this.setState({
                loading: false,
                alert: {
                    ...alert,
                    show: true,
                    message: response.message,
                    isError: false
                },
                document: 'SWITCHING',
                date: new Date(),
                dateRange: [null, null],
                file: null
            });
        }catch(error){
            this.setState({
                loading: false,
                alert: {
                    ...alert,
                    show: true,
                    message: error.message,
                    isError: true
                }
            })
        }
    }

    onDownload = async () => {
        this.setState({loading: true});
        try {
            const params = {
                type: this.state.downloadDocument.toLowerCase(),
                atm: this.state.downloadAtm,
                tanggal: formatRequestDateOnly(this.state.downloadDate),
                override: this.state.downloadOverride,
            }
            await this.props.onDownloadStatement(params);
            this.setState({
                loading: false,
                alert: {...alert, show: true, message: 'Sukses', isError: false},
                downloadOverride: false,
            });
        } catch (error) {
            if(error.message === 'Laporan Sudah Pernah di Ambil') {
                this.setState({
                    loading: false,
                    downloadOverride: true,
                    warning: {
                        show: true,
                        message1: 'Laporan Sudah Pernah di Ambil',
                        message2: 'Override Data ?',
                    }
                })
            } else {
                this.setState({
                    loading: false,
                    alert: {
                        ...alert,
                        show: true,
                        message: error.message,
                        isError: true
                    }
                })
            }
        }
    }

    render(){
        const atmList = this.props?.atmData?.atm?.map(({ id, account_no, atm_label, name}) => ({id, account_no, value: atm_label, name: `${atm_label} - ${name.trim()}`}));
        const {features} = this.props?.profile;
        const statusImport = features.find(x => x.name === 'FEATURE_IMPORT_STATEMENT');
        const statusIntegrate = features.find(x => x.name === 'FEATURE_INTEGRATE_STATEMENT_BY_API');
        return (
            <div>
                <Loader show={this.state.loading}/>
                <Alert
                    show={this.state.alert.show}
                    message={this.state.alert.message}
                    isError={this.state.alert.isError}
                    modalClosed={() => this.setState({alert: {...alert, show: false}})}
                />
                <Warning
                  show={this.state.warning.show}
                  message1={this.state.warning.message1}
                  message2={this.state.warning.message2}
                  modalClosed={() => this.setState({warning: {...alert, show: false}, downloadOverride: false})}
                  onBtnPress={() => {
                      this.onDownload();
                      this.setState({warning: {...alert, show: false}, downloadOverride: false})
                  }}
                  buttonText2="Cancel"
                />
                {statusImport && (
                  <div>
                      <h2 className="page-header header-detail-rekonsiliasi">Unggah Statement</h2>
                      <div className="row container-detail-rekonsiliasi">
                          <div className="col-8 col-md-12 col-sm-12">
                              <Card className="card-detail-rekonsiliasi">
                                  <h3 style={{marginBottom: 20}}>Informasi Statement</h3>
                                  <div className="row info-detail-rekonsiliasi">
                                      <div className="col-6 col-md-12 col-sm-12" style={{marginBottom: '20px'}}>
                                          <label className="label-detail-rekonsiliasi">Tipe Dokumen</label>
                                          <div style={{width: '100%', marginTop: '5px'}}>
                                              <Select
                                                IconComponent={KeyboardArrowDown}
                                                labelId="documentTypeLabel"
                                                id="documentType"
                                                value={this.state.document}
                                                style={{width: '100%'}}
                                                name='documentType'
                                                size="small"
                                                onChange={e => this.setState({document: e.target.value})}
                                              >
                                                  <MenuItem value='SWITCHING'>Switching</MenuItem>
                                                  <MenuItem value='CORE'>Core Banking</MenuItem>
                                              </Select>
                                          </div>
                                          {this.state.document === 'CORE' && (
                                            <div style={{alignItems: 'center', marginTop: 20, marginBottom: 10}}>
                                                <label className="label-detail-rekonsiliasi" style={{marginRight: 10}}>Syariah</label>
                                                {/*<div style={{width: '280px', marginTop: '5px'}}>*/}
                                                <input checked={this.state.isSyariah} type="checkbox" name="syariah" onChange={() => this.setState({isSyariah: !this.state.isSyariah})} />
                                                {/*</div>*/}
                                            </div>
                                          )}
                                      </div>
                                      {this.state.document === 'SWITCHING' && (<div className="col-6 col-md-12 col-sm-12">
                                          <label className="label-detail-rekonsiliasi">Tanggal</label>
                                          <div style={{width: '100%', marginTop: '5px'}}>
                                              <DatePicker
                                                selectsRange={this.state.document === "SWITCHING"}
                                                // date={this.state.date}
                                                // onchangeDate={date => this.setState({date: date})}
                                                date={this.state.document === "SWITCHING"? this.state.dateRange : this.state.date}
                                                onchangeDate={date => {
                                                    if(this.state.document === "SWITCHING"){
                                                        this.setState({dateRange: date})
                                                    }else{
                                                        this.setState({date: date})
                                                    }
                                                }}
                                                customInputStyle={{paddingTop: '8px', paddingBottom: '8px'}}
                                                label="Pilih Tanggal"
                                              />
                                          </div>
                                      </div>)}
                                  </div>
                                  <div className="row info-detail-rekonsiliasi">
                                      <div className="col-12" style={{marginTop: '10px'}}>
                                          <label className="label-detail-rekonsiliasi">Lampirkan Dokumen</label>
                                          <DropZoneDoc
                                            file={this.state.file}
                                            onChangeFile={(file) => this.setState({file: file})}
                                          />
                                      </div>
                                      {this.state.document === "CORE" && <div style={{marginLeft: '15px', marginTop: '5px', fontSize: '14px', color: 'red'}}>Format file harus .xlsx</div>}
                                  </div>
                                  <div className="hr-detail-replenishment" />
                                  <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                      <Button
                                        disabled={this.state.document === "SWITCHING"? !this.state.dateRange[0] : !this.state.date || !this.state.file}
                                        title="Unggah"
                                        onClick={this.onSubmit}
                                      />
                                  </div>
                              </Card>
                          </div>
                      </div>
                  </div>
                )}
                {/*------------------------- Download -------------------------*/}
                {statusIntegrate && (
                  <div>
                      <h2 className="page-header header-detail-rekonsiliasi">Ambil Data Statement</h2>
                      <div className="row container-detail-rekonsiliasi">
                          <div className="col-8 col-md-12 col-sm-12">
                              <Card className="card-detail-rekonsiliasi">
                                  <h3 style={{marginBottom: 20}}>Informasi Statement</h3>
                                  <div className="row info-detail-rekonsiliasi">
                                      <div className="col-4 col-md-12 col-sm-12" style={{marginBottom: '20px'}}>
                                          <label className="label-detail-rekonsiliasi">Tipe Dokumen</label>
                                          <div style={{width: '100%', marginTop: '5px'}}>
                                              <Select
                                                IconComponent={KeyboardArrowDown}
                                                labelId="downloadDocumentTypeLabel"
                                                id="downloadDocumentType"
                                                value={this.state.downloadDocument}
                                                style={{width: '100%'}}
                                                name='downloadDocumentType'
                                                size="small"
                                                onChange={e => this.setState({downloadDocument: e.target.value})}
                                              >
                                                  <MenuItem value='SWITCHING'>Switching</MenuItem>
                                                  <MenuItem value='CORE'>Core Banking</MenuItem>
                                              </Select>
                                          </div>
                                      </div>
                                      <div className="col-4 col-md-12 col-sm-12 ">
                                          <label className="label-detail-rekonsiliasi">Tanggal</label>
                                          <div style={{width: '100%', marginTop: '5px', marginBottom: '20px'}}>
                                              <DatePicker
                                                // selectsRange={this.state.downloadDocument === "SWITCHING"}
                                                // date={this.state.date}
                                                // onchangeDate={date => this.setState({date: date})}
                                                date={this.state.downloadDate}
                                                onchangeDate={date => {
                                                    this.setState({downloadDate: date})
                                                }}
                                                customInputStyle={{paddingTop: '8px', paddingBottom: '8px'}}
                                                label="Pilih Tanggal"
                                              />
                                          </div>
                                      </div>
                                      <div className="col-4 col-md-12 col-sm-12">
                                          <label className="label-detail-rekonsiliasi">ATM</label>
                                          <div style={{width: '100%', marginTop: '5px'}}>
                                              <DropdownSelect
                                                style={{width: '280px'}}
                                                styleValue={{paddingTop: 8, paddingBottom: 8}}
                                                value={this.state.downloadAtm}
                                                options={atmList}
                                                isOptionEqualToValue={(option, value) => option.value === value.value} // eslint-disable-line no-shadow
                                                placeholder="Pilih ATM"
                                                noOptionsText="ATM tidak ditemukan"
                                                onChange={(e, v) => this.setState({downloadAtm: v})}
                                              />
                                          </div>
                                      </div>
                                  </div>
                                  <div className="hr-detail-replenishment" />
                                  <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                      <Button
                                        disabled={!this.state.downloadDate || !this.state.downloadAtm}
                                        title="Ambil Data"
                                        onClick={this.onDownload}
                                      />
                                  </div>
                              </Card>
                          </div>
                      </div>
                  </div>
                )}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        atmData: state.atm,
        profile: state.auth.profile
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onUploadSwitching: (file, startDate, endDate) => dispatch(uploadSwitchingStatement(file, startDate, endDate)),
        onUploadCore: (file, isSyariah) => dispatch(uploadCoreStatement(file, isSyariah)),
        onGetATMList: () => dispatch(getAllATMList()),
        onDownloadStatement: (data) => dispatch(downloadStatement(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UnggahStatement);
