import React, { Component } from "react";
import { connect } from "react-redux";
import { ReactComponent as ArrowLeftSvg } from "../../../assets/icons/arrow-left.svg";
import Button from "../../../components/button/Button";
import Loader from "../../../components/loader/Loader";
import Table from "../../../components/table/Table";
import {
  formatRequestDateReconUTC,
  formatRupiah,
} from "../../../helpers/functionFormat";
import { getReconDocByDate } from "../../../redux/actions";
import "./rekonsiliasi-dokumen.css";

class RekonsiliasiDokumenTgl extends Component {
  state = {
    date: new Date(),
    documents: [],
    loading: false,
    atmLabel: "",
  };

  componentDidMount() {
    if (this.props.location && this.props.location.state) {
      this.setState({
        date: this.props.location.state.date,
        atmLabel: this.props.location.state.atmLabel,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.date !== this.state.date ||
      prevState.atmLabel !== this.state.atmLabel
    ) {
      this.loadDocuments(this.state.date, this.state.atmLabel);
    }
  }

  formatTable = (data) => {
    let newInisiasiList = [];
    if (data) {
      newInisiasiList = data.map((item) => {
        return {
          id: item.atm_id,
          aksi: (
            <Button
              title="Detail"
              onClick={() =>
                this.props.history.push({
                  key: `rekonsiliasi-dokumen-${this.state.date}-${item.atm_id}`,
                  pathname: `/rekonsiliasi-dokumen/${this.state.date}/${item.atm_id}`,
                  state: {
                    date: this.state.date,
                    atmId: item.atm_id,
                    atmName: `${item.atm_name} (${item.atm_label})`,
                  },
                })
              }
              style={{ width: "100%" }}
            />
          ),
          idComponent: (
            <label
              className="id-dashboard"
              onClick={() =>
                this.props.history.push({
                  key: `atm-${item.atm_id}`,
                  pathname: `/atm/${item.atm_id}`,
                  state: { ...item, id: item.atm_id },
                })
              }
            >
              <div style={{ color: "#4991F2" }}>{item.atm_id}</div>
            </label>
          ),
          atm_label: item.atm_label,
          atmName: `${item.atm_name} (${item.atm_label})`,
          missMatch: formatRupiah(item.amount, "."),
        };
      });
    }

    return newInisiasiList;
  };

  loadDocuments = async (date, atmLabel) => {
    this.setState({ loading: true });
    try {
      const { branchId } = this.props?.profile;
      const data = await this.props.onGetDocReconsiliation(
        date,
        branchId,
        atmLabel
      );
      this.setState({
        loading: false,
        documents: data,
      });
    } catch (error) {
      this.setState({ loading: false });
    }
  };

  render() {
    const columns = [
      {
        name: "Aksi",
        style: { width: "15%" },
      },
      {
        name: "ID ATM",
        style: { width: "15%" },
      },
      {
        name: "Terminal ID",
        style: { width: "15%" },
      },
      {
        name: "ATM",
        style: { width: 450 },
      },
      {
        name: "Amount Missmatch",
        style: { width: 300 },
      },
    ];

    return (
      <div>
        <Loader show={this.state.loading} />
        <div
          className="back-rekon-doc"
          onClick={() => this.props.history.goBack()}
        >
          <ArrowLeftSvg
            className="arrow-back-rekon-doc"
            stroke={"#667AB3"}
            width={20}
            height={20}
          />
          <div className="label-back-rekon-doc">Kembali</div>
        </div>
        <h3
          className="page-header header-detail-rekonsiliasi"
          style={{ color: "#092540" }}
        >
          {formatRequestDateReconUTC(this.state.date)}
        </h3>
        <div className="row" style={{ marginTop: "20px" }}>
          <div className="col-12">
            <h4
              className="title-rekon-doc"
              style={{ marginTop: 15, marginBottom: 15 }}
            >
              Tidak Sesuai
            </h4>
            <Table
              columns={columns}
              data={this.formatTable(this.state.documents)}
              // data={this.formatTable(this.props.inisiasiData.data)}
              // tableConfig={this.state.tableConfig}
              // onChange={(identifier, value) => tableFunction.onChangeTable(this, identifier, value)}
              // meta={this.props.inisiasiData.meta}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.auth.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetDocReconsiliation: (date, branchId, atmLabel) =>
      dispatch(getReconDocByDate(date, branchId, atmLabel)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RekonsiliasiDokumenTgl);
