import React from 'react';
import './status-label.css';

const StatusLabel = props => {

    const { type, label, style } = props;

    return (
        <div className={`status-label status-label-${type}`} style={style}>
            <div className={`status-label-circle status-label-circle-${type}`}/>
            <div className={`status-label-text status-label-text-${type}`}>{label}</div>
        </div>
    )
}

export default StatusLabel;