import axios from 'axios';

import * as actionTypes from '../actionTypes';
import { logout } from './auth';

export const getATMList = (page, limit, keyword, sort, branchId, state = '') => {
  // console.log('BRANCH ID', branchId);
  return async (dispatch, getState) => {
    try {
      const url = getState().config.url;
      const token = getState().auth.token;
      const response = await axios.get(
        `${url}/atm?limit=${limit}&keyword=${keyword}&sort=${sort}&page=${page}&branch_ids=${branchId}&state=${state}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      );
      // console.log('ATM LIST', JSON.stringify(response.data.data, null, 4));
      const resData = response.data.data.map((item) => {
        return {
          id: item.atm_id,
          name: item.name,
          location: item.location,
          merk: item.merk,
          atm_label: item.atm_label,
          description: item.description,
          saldo: item.balance,
          createdAt: item.created_at,
          updatedAt: item.updated_at,
        };
      });
      await dispatch({
        type: actionTypes.GET_LIST_ATM,
        atm: resData,
        meta: {
          pagination: Math.ceil(
            response.data.meta.total / response.data.meta.limit
          ),
          total: response.data.meta.total,
          limit: response.data.meta.limit,
          page: response.data.meta.page,
        },
      });
    } catch (error) {
      let message = 'Internal Server Error';
      if (error.response) {
        if (error.response.data.errors) {
          if (error.response.data.errors[0].code === 1009) {
            dispatch(logout());
          }
          message = error.response.data.errors[0].message;
        }
      } else {
        message = error.message;
      }
      throw new Error(message);
    }
  };
};

export const createATM = (name, location, description, merk, norek, key, label, branch) => {
  return async (dispatch, getState) => {
    try {
      const url = getState().config.url;
      const token = getState().auth.token;

      const response = await axios.post(
        `${url}/atm`,
        {
          name: name,
          location: location,
          description: description,
          merk: merk,
          account_no: norek,
          atm_label: label,
          atm_key: key,
          branch_id: branch.value,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return {
        id: response.data.data.id,
        meta: response.data.meta.http_status,
        message: 'ATM Berhasil Ditambahkan!',
      };
    } catch (error) {
      let message = 'Internal Server Error';
      if (error.response) {
        if (error.response.data.errors) {
          if (error.response.data.errors[0].code === 1009) {
            dispatch(logout());
          }
          message = error.response.data.errors[0].message;
        }
      } else {
        message = error.message;
      }
      throw new Error(message);
    }
  };
};

export const editATM = (id, name, location, description, merk, norek, key, label, branch ) => {
  return async (dispatch, getState) => {
    try {
      const url = getState().config.url;
      const token = getState().auth.token;

      const response = await axios.put(
        `${url}/atm/${id}`,
        {
          name: name,
          location: location,
          description: description,
          merk: merk,
          account_no: norek,
          atm_label: label,
          atm_key: key,
          branch_id: branch.value,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return {
        meta: response.data.meta.http_status,
        message: 'ATM Berhasil Disimpan!',
      };
    } catch (error) {
      let message = 'Internal Server Error';
      if (error.response) {
        if (error.response.data.errors) {
          if (error.response.data.errors[0].code === 1009) {
            dispatch(logout());
          }
          message = error.response.data.errors[0].message;
        }
      } else {
        message = error.message;
      }
      throw new Error(message);
    }
  };
};

export const deleteATM = (id) => {
  return async (dispatch, getState) => {
    try {
      const url = getState().config.url;
      const token = getState().auth.token;

      const response = await axios.delete(`${url}/atm/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      });
      return {
        meta: response.data.meta.http_status,
        message: 'ATM Berhasil Dihapus!',
      };
    } catch (error) {
      let message = 'Internal Server Error';
      if (error.response) {
        if (error.response.data.errors) {
          if (error.response.data.errors[0].code === 1009) {
            dispatch(logout());
          }
          message = error.response.data.errors[0].message;
        }
      } else {
        message = error.message;
      }
      throw new Error(message);
    }
  };
};

export const detailATM = (id) => {
  return async (dispatch, getState) => {
    try {
      const url = getState().config.url;
      const token = getState().auth.token;

      const response = await axios.get(`${url}/atm/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      });
      return {
        meta: response.data.meta.http_status,
        data: response.data.data,
      };
    } catch (error) {
      let message = 'Internal Server Error';
      if (error.response) {
        if (error.response.data.errors) {
          if (error.response.data.errors[0].code === 1009) {
            dispatch(logout());
          }
          message = error.response.data.errors[0].message;
        }
      } else {
        message = error.message;
      }
      throw new Error(message);
    }
  };
};

export const createATMKey = (id) => {
  return async (dispatch, getState) => {
    try {
      const url = getState().config.url;
      const token = getState().auth.token;

      const response = await axios.post(
        `${url}/super-admin/atm-keys`,
        {
          atm_id: id,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return {
        meta: response.data.meta.http_status,
        message: 'ATM Key Berhasil Ditambahkan!',
      };
    } catch (error) {
      let message = 'Internal Server Error';
      if (error.response) {
        if (error.response.data.errors) {
          if (error.response.data.errors[0].code === 1009) {
            dispatch(logout());
          }
          message = error.response.data.errors[0].message;
        }
      } else {
        message = error.message;
      }
      throw new Error(message);
    }
  };
};

export const getListAtmTransactions = (atmId, sourceType, date) => {
  return async (dispatch, getState) => {
    try {
      const url = getState().config.url;
      const token = getState().auth.token;

      const response = await axios.get(
        `${url}/atm-transactions?atm_id=${atmId}&source_type=${sourceType.toLocaleUpperCase()}&date=${date}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data.data;
      // await dispatch({
      //     type: actionTypes.GET_LIST_ATM_TRANSACTIONS,
      //     data: response.data.data
      // })
    } catch (error) {
      let message = 'Internal Server Error';
      if (error.response) {
        if (error.response.data.errors) {
          if (error.response.data.errors[0].code === 1009) {
            dispatch(logout());
          }
          message = error.response.data.errors[0].message;
        }
      } else {
        message = error.message;
      }
      throw new Error(message);
    }
  };
};

export const getReconsiliationDocument = (atmId, sourceType, sourceType2, date) => {
  return async (dispatch, getState) => {
    try {
      const url = getState().config.url;
      const token = getState().auth.token;

      const response = await axios.get(
        `${url}/reconciliation?atm_id=${atmId}&main_document=${sourceType.toLocaleUpperCase()}&second_document=${sourceType2.toLocaleUpperCase()}&date=${date}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data.data;
      // await dispatch({
      //     type: actionTypes.GET_LIST_ATM_TRANSACTIONS,
      //     data: response.data.data
      // })
    } catch (error) {
      let message = 'Internal Server Error';
      if (error.response) {
        if (error.response.data.errors) {
          if (error.response.data.errors[0].code === 1009) {
            dispatch(logout());
          }
          message = error.response.data.errors[0].message;
        }
      } else {
        message = error.message;
      }
      throw new Error(message);
    }
  };
};

export const findAtmDenominator = (atmAtm) => {
  return async (dispatch, getState) => {
    try {
      const url = getState().config.url;
      const token = getState().auth.token;

      const response = await axios.get(
        `${url}/atm/${atmAtm}/atm-denominator`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response?.data;
    } catch (error) {
      let message = 'Internal Server Error';
      if (error.response) {
        if (error.response.data.errors) {
          if (error.response.data.errors[0].code === 1009) {
            dispatch(logout());
          }
          message = error.response.data.errors[0].message;
        }
      } else {
        message = error.message;
      }
      throw new Error(message);
    }
  };
};

export const craeateAtmDenominator = (atmId, type1, type2, type3, type4 ) => {
  return async (dispatch, getState) => {
    try {
      const url = getState().config.url;
      const token = getState().auth.token;

      const response = await axios.post(
        `${url}/atm-denominators`,
        {
          atm_id: atmId,
          denominator_value_1: parseInt(type1),
          denominator_value_2: parseInt(type2),
          denominator_value_3: parseInt(type3),
          denominator_value_4: parseInt(type4),
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return {
        meta: response.data.meta.http_status,
        message: 'ATM Berhasil Ditambahkan!',
      };
    } catch (error) {
      let message = 'Internal Server Error';
      if (error.response) {
        if (error.response.data.errors) {
          if (error.response.data.errors[0].code === 1009) {
            dispatch(logout());
          }
          message = error.response.data.errors[0].message;
        }
      } else {
        message = error.message;
      }
      throw new Error(message);
    }
  }
}

export const editAtmDenominator = (denominatorId, atmId, type1, type2, type3, type4 ) => {
  return async (dispatch, getState) => {
    try {
      const url = getState().config.url;
      const token = getState().auth.token;

      const response = await axios.put(
        `${url}/atm-denominators/${denominatorId}`,
        {
          atm_id: atmId,
          denominator_value_1: parseInt(type1),
          denominator_value_2: parseInt(type2),
          denominator_value_3: parseInt(type3),
          denominator_value_4: parseInt(type4),
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return {
        meta: response.data.meta.http_status,
        message: 'ATM Berhasil Disimpan!',
      };
    } catch (error) {
      let message = 'Internal Server Error';
      if (error.response) {
        if (error.response.data.errors) {
          if (error.response.data.errors[0].code === 1009) {
            dispatch(logout());
          }
          message = error.response.data.errors[0].message;
        }
      } else {
        message = error.message;
      }
      throw new Error(message);
    }
  }
}

export const deleteAtmDenominator = (denominatorId ) => {
  return async (dispatch, getState) => {
    try {
      const url = getState().config.url;
      const token = getState().auth.token;

      const response = await axios.delete(`${url}/atm-denominators/${denominatorId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      });
      return {
        meta: response.data.meta.http_status,
        message: 'ATM Berhasil Dihapus!',
      };
    } catch (error) {
      let message = 'Internal Server Error';
      if (error.response) {
        if (error.response.data.errors) {
          if (error.response.data.errors[0].code === 1009) {
            dispatch(logout());
          }
          message = error.response.data.errors[0].message;
        }
      } else {
        message = error.message;
      }
      throw new Error(message);
    }
  }
}
