import React, { Component, createRef } from 'react';
import Chart from 'chart.js/auto';
import './charts.css';

class BarChart extends Component {

    constructor(props){
        super(props);
        this.barChartRef = createRef();
        this.barChart = null;
    }

    componentDidMount(){
        if(this.barChartRef.current){
            this.barChart = new Chart(this.barChartRef.current, {
                type: 'bar',
                options: {
                    responsive: true,
                    interaction: {
                        intersect: false,
                        mode: 'index',
                    },
                    plugins: {
                        tooltip: {
                            callbacks: {
                                title: (tooltipItems) => {
                                    let label = "";
                                    tooltipItems.forEach(function(tooltipItem) {
                                        label += tooltipItem.label;
                                    });
                                    return this.props.tooltipLabel(label);
                                }
                            }
                        }
                    },  
                                    
                    onClick: this.props.onClick,
                    onHover: (e, el) => {
                        const section = el[0];
                        const currentStyle = e.native.target.style;
                        currentStyle.cursor = section? 'pointer' : 'default';
                    }
                },
                data: {
                    labels: this.props.data.map(item => item.label),
                    datasets: [{
                        label: this.props.labelX,
                        data: this.props.data.map(item => item.value),
                        backgroundColor: this.props.color
                    }]
                }
                
            })
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.data !== this.props.data){
            if(this.barChart){
                this.barChartRef.current = null;
                this.barChart.data.labels = this.props.data.map(item => item.label);
                this.barChart.data.datasets[0].data = this.props.data.map(item => item.value);
                this.barChart.data.datasets[0].label = this.props.labelX;
                this.barChart.data.datasets[0].backgroundColor = this.props.color;
                this.barChart.update();
            }
        }
    }

    render(){
        return (
            <div>
                <div className="title-charts">{this.props.title}</div>
                <canvas 
                    ref={this.barChartRef}
                />
                <div className="date-title-charts">Tanggal</div>
            </div>
        )
    }
}

export default BarChart;