import React, { Component } from 'react';
import Table from '../../../components/table/Table';
// import Button from '../../../components/button/Button';
// import denominasi from '../../../assets/JsonData/denominasi-list.json';
import { formatRupiah } from '../../../helpers/functionFormat';
import {connect} from "react-redux";
import {getDenominasiList} from "../../../redux/actions/denominasi";
import Loader from "../../../components/loader/Loader";

class Denominasi extends Component {
    _isMounted;
    state = {
        denominasiList: [],
        data: [],
        loading: true,
        tableConfig: {
            search: '',
            page: 1,
            sort: 'value',
            limit: 10
        },
        meta: {
            pagination: Math.ceil(this?.props?.denominasiData?.denominasi.length/10),
            total: this?.props?.denominasiData?.denominasi.length,
            limit: 10,
            page: 1
        }
    }

    componentDidMount(){
        this.loadDenominasiList(
            this.state.tableConfig.page,
            this.state.tableConfig.search,
            this.state.tableConfig.limit,
            this.state.tableConfig.sort
        )
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.tableConfig !== this.state.tableConfig) {
            this.loadDenominasiList(
                this.state.tableConfig.page,
                this.state.tableConfig.search,
                this.state.tableConfig.limit,
                this.state.tableConfig.sort
            )
        }
    }

    loadDenominasiList = async (page, search, limit, sort) => {
        try{
            await this.props.getDenominasiList(page, limit, search, sort);
            if(this._isMounted){
                this.setState({loading: true})
            }
            function compare(a, b) {
                if(sort[0] === '-'){
                    if ( a[sort] < b[sort] ){
                        return 1;
                    }
                    if ( a[sort] > b[sort] ){
                        return -1;
                    }
                    return 0;
                }else{
                    if ( a[sort] < b[sort] ){
                        return -1;
                    }
                    if ( a[sort] > b[sort] ){
                        return 1;
                    }
                    return 0;
                }

            }
            let dataResult = this?.props?.denominasiData?.denominasi.filter(item =>
                item.value.toString().includes(search)
                || item.description.toLowerCase().includes(search.toLowerCase())
                || formatRupiah(item.value, '.').toLowerCase().includes(search.toLowerCase())
            )
            dataResult.sort(compare);

            let newDenominasiList = dataResult.slice((page - 1) * limit, limit * page).map(item => {
                return {
                    id: item.denominator_id,
                    nilai: formatRupiah(item.value, '.'),
                    keterangan: item.description,
                    aksi: (
                        <div />
                        // <Button
                        //     className="btn-default"
                        //     title="Atur"
                        // />
                    )
                }
            })

            this.setState({
                denominasiList: newDenominasiList,
                meta: {
                    pagination: Math.ceil(dataResult.length/limit),
                    total: dataResult.length,
                    limit: limit,
                    page: page
                },
                loading: false,
            })
        }catch(error){
            if(this._isMounted){
                this.setState({
                    loading: false,
                    alert: {
                        ...alert,
                        show: true,
                        message: error.message,
                        isError: true
                    }
                })
            }
        }
    }

    onChangeTable = (identifier, value) => {
        this.setState(prevState => {
            let page = identifier === 'page'? value : prevState.tableConfig.page;
            page = identifier === 'search' || identifier === 'limit'? 1 : page;
            return {
                ...prevState,
                tableConfig: {
                    ...prevState.tableConfig,
                    [identifier]: value,
                    page: page
                }
            }
        })
    }

    render(){
        const columns = [{
            name: 'Nilai',
            style: { width: 450 }
        }, {
            name: 'Keterangan',
            style: { width: 600 }
        }, {
            name: ' ',
            style: { width: 100 }
        }]

        return (
            <div className="col-12">
                <Loader show={this.state.loading}/>
                <div className="row">
                    <div className="col-12">
                        <Table
                            columns={columns}
                            data={this.state.denominasiList}
                            useHeader
                            useFooter
                            tableConfig={this.state.tableConfig}
                            onChange={this.onChangeTable}
                            meta={this.state.meta}
                            // rightHeaderComponent={(
                            //     <div>
                            //         <Button
                            //             style={{padding: '12px 20px 12px 20px'}}
                            //             title="Tambah Denominasi"
                            //         />
                            //     </div>
                            // )}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        denominasiData: state.denominasi
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getDenominasiList: () => dispatch(getDenominasiList())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Denominasi);

