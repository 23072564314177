import axios from 'axios';

import * as actionTypes from "../actionTypes";

export const authenticate = (userId, token) => {
    return {
        type: actionTypes.AUTHENTICATE,
        userId: userId,
        token: token
    }
}

const setProfile = (userId, email, name, phone, roleId, role, photo, branches, branchId, features) => {
    return  {
        type: actionTypes.UPDATE_PROFILE,
        profile: {
            userId: userId,
            email: email,
            name: name,
            phone: phone,
            roleId: roleId,
            role: role,
            photo: photo,
            branches: branches,
            branchId: branchId,
            features: features,
        }
    }
}

export const logout = () => {
    return {
        type: actionTypes.LOGOUT
    }
}

export const changeFirstLoggedIn = (isFirstLoggedIn) => {
    return {
        type: actionTypes.CHANGE_FIRST_LOGGEDIN,
        isFirstLoggedIn: isFirstLoggedIn
    }
}

export const login = (email, password, rememberMe) => {
    return async (dispatch, getState) => {
        try{
            const url = getState().config.url;

            const response = await axios.post(
                `${url}/auth/login`,
                {
                    email: email,
                    password: password,
                    is_remembered_flag: rememberMe
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );

            const profile = await dispatch(getProfile(response.data.data.access_token));
            await dispatch(authenticate(response.data.data.user.user_id, response.data.data.access_token));
            return profile;
        }catch(error){
            let message = 'Internal Server Error';
            if(error.response){
                if(error.response.data.errors){
                    message = error.response.data.errors[0].message;
                }
            }else{
                message = error.message;
            }
            throw new Error(message);
        }
    }
}

export const getProfile = (authToken) => {
    return async (dispatch, getState) => {
        try{
            const url = getState().config.url;
            const token = authToken? authToken : getState().auth.token;

            const response = await axios.get(
                `${url}/me/profile`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            );
            let branchId = '';
            if (response?.data?.data?.branches?.length > 0) {
                branchId = String(response.data.data.branches.map(a => a.branch_id));
            }
            await dispatch(setProfile(
                response.data.data.user_id,
                response.data.data.email,
                response.data.data.name,
                response.data.data.phone,
                response.data.data.role.role_id,
                response.data.data.role.name,
                response.data.data.display_picture_url,
                response.data.data.branches,
                branchId,
                response.data?.data?.features
            ))

            return {
                userId: response.data.data.user_id,
                email: response.data.data.email,
                name: response.data.data.name,
                phone: response.data.data.phone,
                roleId: response.data.data.role.role_id,
                role: response.data.data.role.name,
                photo: response.data.data.display_picture_url,
                branches: response.data.data.branches,
                branchId,
                features: response.data.data.features,

            }
        }catch(error){
            let message = 'Internal Server Error';
            if(error.response){
                if(error.response.data.errors){
                    if(error.response.data.errors[0].code === 1009){
                        dispatch(logout());
                    }
                    message = error.response.data.errors[0].message;
                }
            }else{
                message = error.message;
            }
            throw new Error(message);
        }
    }
}

export const changePassword = (oldPassword, password, confirmPassword) => {
    return async (dispatch, getState) => {
        try{
            const url = getState().config.url;
            const token = getState().auth.token;

            const response = await axios.post(
                `${url}/me/change-password`,
                {
                    old_password: oldPassword,
                    password: password,
                    password_confirmation: confirmPassword
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            );
            return {
                meta: response.data.meta.http_status,
                message: 'Password Berhasil Diganti!'
            }
        }catch(error){
            let message = 'Internal Server Error';
            if(error.response){
                if(error.response.data.errors){
                    if(error.response.data.errors[0].code === 1009){
                        dispatch(logout());
                    }
                    message = error.response.data.errors[0].message;
                }
            }else{
                message = error.message;
            }
            throw new Error(message);
        }
    }
}

export const changeProfile = (name, email, phone) => {
    return async (dispatch, getState) => {
        try{
            const url = getState().config.url;
            const token = getState().auth.token;
            const profile = getState().auth.profile;

            const response = await axios.put(
                `${url}/me/profile`,
                {
                    name: name,
                    email: email,
                    phone: phone
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            );
            await dispatch(setProfile(
                profile.userId,
                email,
                name,
                phone,
                profile.roleId,
                profile.role,
                profile.photo
            ))
            return {
                meta: response.data.meta.http_status,
                message: 'Informasil Akun Berhasil Diganti!'
            }
        }catch(error){
            let message = 'Internal Server Error';
            if(error.response){
                if(error.response.data.errors){
                    if(error.response.data.errors[0].code === 1009){
                        dispatch(logout());
                    }
                    message = error.response.data.errors[0].message;
                }
            }else{
                message = error.message;
            }
            throw new Error(message);
        }
    }
}
