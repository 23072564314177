import React, { Component } from 'react';
import './new-password.css';
import Button from '../../../components/button/Button';

class NewPassword extends Component {
    render() {
        return (
            <div className="row container-new-password">
                <div className="logo-container-new-password">
                    <img 
                        className="logo-new-password"
                        src={require('../../../assets/images/company-logo_2.png')} 
                        alt="Sample Bank" 
                    />
                </div>
                <div className="col-4 col-xm-5 col-xs-6 col-md-6 col-sm-10 card-login">
                    <div className="title-new-password">Buat Kata Sandi Baru</div>
                    <span className="subtitle-new-password">Kata sandi baru Anda harus berbeda dengan kata sandi Anda sebelumnya. Segera ganti kata sandi Anda sebelum <strong style={{color: 'black'}}>16 Des 2021, 09:30</strong></span>
                    <label className="label-input-new-password">Kata Sandi</label>
                    <div className="input-container-new-password">
                        <input 
                            className="input-text-new-password"
                            placeholder="Kata Sandi"
                            type="password"
                        />
                        <div className="icon-new-password" />
                    </div>
                    <label className="label-input-new-password">Konfirmasi Kata Sandi</label>
                    <div className="input-container-new-password">
                        <input 
                            className="input-text-new-password"
                            placeholder="Konfirmasi Kata Sandi"
                            type="password"
                        />
                        <div className="icon-new-password" />
                    </div>
                    <Button 
                        style={{background: '#111827'}}
                        className="btn-new-password"
                        title="Ubah Kata Sandi"
                    />
                </div>
            </div>
        )
    }
}

export default NewPassword;