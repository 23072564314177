import React from 'react';
import './button.css';

const Button = (props) => {
    const { title, style, className, onClick, disabled } = props;

    const classDisabled = disabled ? 'button-disabled' : '';

    return (
        <button
            className={`btn-container ${className} ${classDisabled}`}
            style={style}
            onClick={onClick}
            disabled={disabled}
        >
            {title}
        </button>
    );
};

export default Button;
