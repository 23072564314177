import axios from 'axios';

import * as actionTypes from '../actionTypes';
import { logout } from './auth';

export const getBranchList = (page, limit, keyword, sort) => {
  return async (dispatch, getState) => {
    try {
      const url = getState().config.url;
      const token = getState().auth.token;

      const response = await axios.get(
        `${url}/branches?limit=${limit}&page=${page}&keyword=${keyword}&sort=${sort}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      );
      let resData = [];
      if(response.data.data?.length > 0) {
        resData = response.data.data.map((item) => {
          return {
            id: item?.branch_id,
            code: item?.code,
            users: item?.Users,
            name: item?.name,
            location: item.location,
            createdBy: item.created_by,
            updatedBy: item.updated_by,
            createdAt: item.created_at,
            updatedAt: item.updated_at,
          };
        });
      }
      await dispatch({
        type: actionTypes.GET_LIST_BRANCH,
        branch: resData,
        meta: {
          pagination: Math.ceil(
            response.data.meta.total / response.data.meta.limit
          ),
          total: response.data.meta.total,
          limit: response.data.meta.limit,
          page: response.data.meta.page,
        },
      });
    } catch (error) {
      let message = 'Internal Server Error';
      if (error.response) {
        if (error.response.data.errors) {
          if (error.response.data.errors[0].code === 1009) {
            dispatch(logout());
          }
          message = error.response.data.errors[0].message;
        }
      } else {
        message = error.message;
      }
      throw new Error(message);
    }
  };
};

export const getAllBranch = (page, limit, keyword, sort) => {
  return async (dispatch, getState) => {
    try {
      const url = getState().config.url;
      const token = getState().auth.token;

      const response = await axios.get(
        `${url}/branches?sort=name}&is_group_by_root=false}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      );
      let resData = [];
      if(response.data.data?.length > 0) {
        // console.log('ALL BRANCH', JSON.stringify(response.data.data, null, 4));
        resData = response?.data?.data?.map((item) => {
          return {
            id: item?.branch_id,
            code: item?.code,
            name: item?.name,
            location: item.location,
            rootId: item.root_id,
            createdBy: item.created_by,
            updatedBy: item.updated_by,
            createdAt: item.created_at,
            updatedAt: item.updated_at,
          };
        });
      }
      await dispatch({
        type: actionTypes.GET_ALL_BRANCH,
        branchAll: resData,
      });
    } catch (error) {
      let message = 'Internal Server Error';
      if (error.response) {
        if (error.response.data.errors) {
          if (error.response.data.errors[0].code === 1009) {
            dispatch(logout());
          }
          message = error.response.data.errors[0].message;
        }
      } else {
        message = error.message;
      }
      throw new Error(message);
    }
  };
};

export const getBranchTree = (sort, isGroup) => {
  return async (dispatch, getState) => {
    try {
      const url = getState().config.url;
      const token = getState().auth.token;

      const response = await axios.get(
        `${url}/branches?sort=${sort}&is_group_by_root=${isGroup}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      );
      await dispatch({
        type: actionTypes.GET_LIST_BRANCH_TREE,
        branchTree: response.data.data,
      });
    } catch (error) {
      let message = 'Internal Server Error';
      if (error.response) {
        if (error.response.data.errors) {
          if (error.response.data.errors[0].code === 1009) {
            dispatch(logout());
          }
          message = error.response.data.errors[0].message;
        }
      } else {
        message = error.message;
      }
      throw new Error(message);
    }
  };
};

export const createBranch = (code, name, location, rootId) => {
  return async (dispatch, getState) => {
    try {
      const url = getState().config.url;
      const token = getState().auth.token;

      const response = await axios.post(
        `${url}/branches`,
        {
          code: code,
          name: name,
          location: location,
          root_id: rootId ?? null,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return {
        id: response.data.data.id,
        meta: response.data.meta.http_status,
        message: 'Cabang Berhasil Ditambahkan!',
      };
    } catch (error) {
      let message = 'Internal Server Error';
      if (error.response) {
        if (error.response.data.errors) {
          if (error.response.data.errors[0].code === 1009) {
            dispatch(logout());
          }
          message = error.response.data.errors[0].message;
        }
      } else {
        message = error.message;
      }
      throw new Error(message);
    }
  };
};

export const updateBranch = (id, code, name, location, rootId) => {
  return async (dispatch, getState) => {
    try {
      const url = getState().config.url;
      const token = getState().auth.token;

      const response = await axios.put(
        `${url}/branches/${id}`,
        {
          code: code,
          name: name,
          location: location,
          root_id: rootId ?? null,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return {
        id: response.data.data?.id,
        meta: response.data.meta.http_status,
        message: 'Cabang Berhasil Ditambahkan!',
      };
    } catch (error) {
      let message = 'Internal Server Error';
      if (error.response) {
        if (error.response.data.errors) {
          if (error.response.data.errors[0].code === 1009) {
            dispatch(logout());
          }
          message = error.response.data.errors[0].message;
        }
      } else {
        message = error.message;
      }
      throw new Error(message);
    }
  };
};

export const findBranch = (id) => {
  return async (dispatch, getState) => {
    try {
      const url = getState().config.url;
      const token = getState().auth.token;

      const response = await axios.get(
        `${url}/branches/${id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response?.data;
    } catch (error) {
      let message = 'Internal Server Error';
      if (error.response) {
        if (error.response.data.errors) {
          if (error.response.data.errors[0].code === 1009) {
            dispatch(logout());
          }
          message = error.response.data.errors[0].message;
        }
      } else {
        message = error.message;
      }
      throw new Error(message);
    }
  };
};

export const deleteBranch = (id) => {
  return async (dispatch, getState) => {
    try {
      const url = getState().config.url;
      const token = getState().auth.token;

      const response = await axios.delete(`${url}/branches/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      });
      return {
        meta: response.data.meta.http_status,
        message: 'Cabang Berhasil Dihapus!',
      };
    } catch (error) {
      let message = 'Internal Server Error';
      if (error.response) {
        if (error.response.data.errors) {
          if (error.response.data.errors[0].code === 1009) {
            dispatch(logout());
          }
          message = error.response.data.errors[0].message;
        }
      } else {
        message = error.message;
      }
      throw new Error(message);
    }
  };
};
