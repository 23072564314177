import React from 'react';
import './alert.css';
import Button from '../button/Button';

const Alert = (props) => {
    const {
        show,
        modalClosed,
        message,
        buttonText,
        buttonText2,
        onBtnPress,
        isError,
        onBackPress,
        noImg,
    } = props;

    const handleOnBackPress = (event) => {
        event.preventDefault();
        modalClosed();
    };

    return (
        <div
            onClick={onBackPress ? onBackPress : handleOnBackPress}
            className={`alert-container ${show ? 'active' : ''}`}
        >
            <div onClick={(e) => e.stopPropagation()} className='alert-content'>
                {!noImg && (
                    <img
                        className='alert-image'
                        alt='Alert Info'
                        src={
                            isError
                                ? require('../../assets/images/cross-image.png')
                                : require('../../assets/images/checked-image.png')
                        }
                    />
                )}
                <div className='alert-message'>{message}</div>
                <div className='alert-button'>
                    {onBtnPress && modalClosed && buttonText2 && (
                        <Button
                            className='btn-default'
                            title={buttonText2 ? buttonText2 : 'Cancel'}
                            onClick={modalClosed}
                        />
                    )}
                    <Button
                        title={buttonText ? buttonText : 'OK'}
                        onClick={onBtnPress ? onBtnPress : modalClosed}
                    />
                </div>
            </div>
        </div>
    );
};

export default Alert;
