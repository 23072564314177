import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Autocomplete, Checkbox, TextField } from "@mui/material";
import React from "react";

export const DropdownAutoComplete = ({
  multiple = false,
  defaultValue = [],
  option = [],
  placeholder = "",
  onChange,
  value,
}) => {
  return (
    <Autocomplete
      multiple={multiple}
      size="small"
      limitTags={2}
      id="multiple-limit-tags"
      options={option}
      onChange={(a, b) => {
        onChange(b);
        const result = b?.reduce(function (r, a) {
          r[a.value] = r[a.value] || [];
          r[a.value].push(a);
          return r;
        }, Object.create(null));
        Object.keys(result).forEach((val) => {
          if (result[val].length > 1) {
            onChange(b.filter(({ value }) => value !== +val));
          }
        });
      }}
      getOptionLabel={(option) => option.name}
      defaultValue={defaultValue}
      value={value}
      renderOption={(props, option) => {
        const selected = !!value?.find((val) => val.value === option.value);
        return (
          <li style={{ padding: "0px" }} {...props}>
            <Checkbox style={{ marginRight: 8 }} checked={selected} />
            {option.name}
          </li>
        );
      }}
      sx={{
        border: "none!important",
        "& .MuiInputBase-root": {
          paddingTop: "4px!important",
          paddingBottom: "4px!important",
          border: "none",
        },
      }}
      renderInput={(params) => (
        <TextField {...params} placeholder={placeholder} />
      )}
    />
  );
};

export const DropdownSelect = ({
  options,
  defaultValue,
  disabled = false,
  noOptionsText,
  placeholder,
  disableClearable,
  fullWidth = false,
  values,
  onChange,
  isOptionEqualToValue,
  multiple = false,
  autoSelect,
}) => {
  return (
    <Autocomplete
      multiple={multiple}
      fullWidth={fullWidth}
      disabled={disabled}
      filterSelectedOptions={false}
      options={options}
      isOptionEqualToValue={isOptionEqualToValue}
      disableClearable={disableClearable}
      size="small"
      getOptionLabel={(option) => option.name}
      onChange={onChange}
      autoSelect={autoSelect}
      popupIcon={
        <KeyboardArrowDownIcon
          style={{ marginTop: 2, width: 20, height: 20 }}
        />
      }
      sx={{
        border: "none!important",
        "& .MuiInputBase-root": {
          paddingTop: "4px!important",
          paddingBottom: "4px!important",
          border: "none",
        },
      }}
      defaultValue={defaultValue}
      value={values}
      noOptionsText={noOptionsText}
      renderInput={(params) => (
        <TextField {...params} placeholder={placeholder} />
      )}
    />
  );
};
