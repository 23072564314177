import React, { Component } from 'react';
import './InputBranch.css';
import Loader from '../../../components/loader/Loader';
import Alert from '../../../components/alert/Alert';
import Card from '../../../components/card/Card';
import Button from '../../../components/button/Button';
import { connect } from 'react-redux';
import {createBranch, deleteBranch, getBranchTree, updateBranch} from "../../../redux/actions";
import Collapsible from "../../../components/Collapsible";
import { ReactComponent as Plus } from '../../../assets/icons/plus.svg';
import Modal from '../../../components/modal/Modal';
import ModalUpdate from "./ModalUpdate";

class InputBranch extends Component {
  _isMounted = true;
  state = {
    isEdit: false,
    loading: false,
    tableConfig: {
      search: '',
      sort: 'name',
      isGroup: true,
    },
    alert: {
      show: false,
      message: '',
      isError: false,
      onBtnPress: null,
      onBackPress: null,
    },
    modal: {
      show: false,
      type: 'Tambah',
      body: null,
    },
    list: [
      {
        name: 'Cabang Malang',
        child: [
          {name: 'Cabang Kedungkandang'},
          {name: 'Cabang Lowokwaru'},
          {name: 'Cabang Sukun'},
          {name: 'Cabang Klojen'},
        ]
      },
      {name: 'Cabang Blitar'},
      {name: 'Cabang Surabaya'},
    ]
  }

  openModal = (type, item) => {
    this.setState({modal: {show: true, type, body: item}})
  }

  closeModal = () => {
    this.setState({modal: {show: false}})
  }

  closeAlert = () => {
    this.setState({
      loading: false,
      alert: {
        show: false,
        message: '',
        isError: false,
        onBtnPress: null,
        onBackPress: null,
      },
    });
  }

  onCreateBranch = async (value) => {
    this.setState({ loading: true });
    this.closeModal();
    try {
      const response = await this.props.onCreateBranch(
        value.code,
        value.name,
        value.location,
        value.rootId ?? null,
      );
      this.setState({
        loading: false,
        alert: {
          ...alert,
          show: true,
          message: response.message,
          isError: false,
          onBtnPress: this.closeAlert,
          onBackPress: this.closeAlert,
        },
      });
      this.loadBranchList(
        this.state.tableConfig.sort,
        this.state.tableConfig.isGroup,
      )
    } catch (error) {
      this.setState({
        loading: false,
        alert: {
          ...alert,
          show: true,
          message: error.message,
          isError: true,
          onBtnPress: null,
          onBackPress: null,
        },
      });
    }
  };

  onDeleteBranch = async () => {
    // console.log(this.state.modal.body);
    this.setState({loading: true});
    try {
      const response = await this.props.onDeleteBranch(this.state?.modal?.body?.branch_id);
      this.setState({
        loading: false,
        alert: {
          ...alert,
          show: true,
          message: response.message,
          isError: false,
          onBtnPress: this.closeAlert,
          onBackPress: this.closeAlert,
        },
      });
      this.setState({modal: {show: false, body: null}})
      this.loadBranchList(
        this.state.tableConfig.sort,
        this.state.tableConfig.isGroup,
      )
    } catch (error) {
      this.setState({modal: {show: false, body: null}})
      this.setState({
        loading: false,
        alert: {
          ...alert,
          show: true,
          message: error.message,
          isError: true,
          onBtnPress: null,
          onBackPress: null,
        },
      });
    }
  }

  onUpdateBranch = async (value) => {
    this.setState({ loading: true });
    this.closeModal();
    try {
      const response = await this.props.onUpdateBranch(
        value.id,
        value.code,
        value.name,
        value.location,
        value.rootId ?? null,
      );
      this.setState({
        loading: false,
        alert: {
          ...alert,
          show: true,
          message: response.message,
          isError: false,
          onBtnPress: this.closeAlert,
          onBackPress: this.closeAlert,
        },
      });
      this.loadBranchList(
        // this.state.tableConfig.page,
        // this.state.tableConfig.limit,
        this.state.tableConfig.sort,
        this.state.tableConfig.isGroup,
      )
    } catch (error) {
      console.log('ERROR', error);
      this.setState({
        loading: false,
        alert: {
          ...alert,
          show: true,
          message: error.message,
          isError: true,
          onBtnPress: null,
          onBackPress: null,
        },
      });
    }
  }

  loadBranchList = async (search, sort, isGroup = true) => {
    // if(this._isMounted){
    //     this.setState({loading: true});
    // }
    try{
      await this.props.getBranchTree(sort, isGroup);
      if(this._isMounted){
        this.setState({loading: false})
      }
    }catch(error){
      if(this._isMounted){
        this.setState({
          loading: false,
          alert: {
            ...alert,
            show: true,
            message: error.message,
            isError: true
          }
        })
      }
    }
  }

  componentDidMount(){
    if (this.props.location?.state?.type === 'Edit') {
      this.setState({isEdit: true});
    }
    this.loadBranchList(
      this.state.tableConfig.sort,
      this.state.tableConfig.isGroup,
    )
  }

  render() {
    return(
      <div className='col-12' style={{ marginBottom: 20 }}>
        <Loader show={this.state.loading} />
        <Alert
          show={this.state.alert.show}
          message={this.state.alert.message}
          isError={this.state.alert.isError}
          modalClosed={() =>
            this.setState({ alert: { ...alert, show: false } })
          }
          onBtnPress={this.state.alert.onBtnPress}
          onBackPress={this.state.alert.onBackPress}
        />
        <h2 className="title-input-branch">
          {this.props.location?.state?.type ?? 'Tambah'} Cabang
        </h2>
        <div className='row'>
          <div className='col-8'>
            <Card>
              <div className='col-12'>
                <div className="row row-between padding-vertical-20" style={{paddingBottom: 20}}>
                  <h3>Struktur Cabang</h3>
                  {!this.state.isEdit && (
                    <Button className="btn-default" title={<span style={{color: '#667AB3'}}>Edit</span>} onClick={() => this.setState({isEdit: true})} />
                  )}
                </div>
                {this.state.isEdit && (
                  <Button
                    style={{marginBottom: 20}}
                    title={
                      <div style={{flexDirection: 'row'}}>
                        <Plus color="white"/>
                        <span style={{marginLeft: 5}}>Tambah Cabang Baru</span>
                      </div>
                    }
                    onClick={() => this.openModal('Tambah')}
                  />
                )}

                {/*TreeView*/}
                {this.props.branchData?.map((v, i) => {
                  return (
                    <Collapsible key={i} isEdit={this.state.isEdit} item={v} header={v?.name} child={v?.child} openModal={this.openModal} />
                  )
                })}

                <div style={{ borderBottom: '1px solid #E4E7EB' }} />
                <div className="bottom-content padding-vertical-10">
                  <Button className="btn-default" title={<span style={{color: '#667AB3'}}>Batal</span>} onClick={() => this.props.history.goBack()} />
                  <Button style={{marginLeft: 20}} title="Simpan" />
                </div>
              </div>
            </Card>
          </div>
        </div>

        {/*Modal add*/}
        <Modal
          show={this.state.modal.show}
          useHeader={false}
          style={{ maxHeight: '550px' }}
          styleHeader={{ border: 'none', marginBottom: 0 }}
          modalClosed={this.closeModal}
        >
          {this.state.modal.type === 'Delete' ? (
            <div style={{paddingTop: 40, paddingBottom: 30, textAlign: 'center'}}>
              <h3>Apakah anda yakin akan menghapus Cabang ini ?</h3>
              <div style={{ marginTop: 50, marginBottom: 50 }} />
              <Button className="btn-default" title={<span style={{color: '#667AB3'}}>Batal</span>} onClick={() => this.setState({modal: {show: false, body: null}})} />
              <Button style={{marginLeft: 20}} title="Hapus" onClick={this.onDeleteBranch} />
            </div>
          ) : (
            <ModalUpdate type={this.state.modal.type ?? 'Tambah'} item={this.state.modal.body} onClose={this.closeModal} onCreateBranch={this.onCreateBranch} onUpdateBranch={this.onUpdateBranch} />
          )}
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    branchData: state?.branch?.branchTree
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBranchTree: (sort, isGroup) => dispatch(getBranchTree(sort, isGroup)),
    onCreateBranch: (code, name, location, rootId) => dispatch(createBranch(code, name, location, rootId)),
    onUpdateBranch: (id, code, name, location, rootId) => dispatch(updateBranch(id, code, name, location, rootId)),
    onDeleteBranch: (id) => dispatch(deleteBranch(id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InputBranch);
