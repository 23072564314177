import * as actionTypes from '../actionTypes';

export const changePage = (key, page) => {
    return {
        type: actionTypes.CHANGE_PAGE,
        key: key,
        page: page
    }
}

export const setDefaultPage = () => {
    return {
        type: actionTypes.DEFAULT_PAGE
    }
}