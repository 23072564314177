import React, { Component } from 'react';
import './detail-rekonsiliasi.css';
import Card from '../../../components/card/Card';
import StatusLabel from '../../../components/statuslabel/StatusLabel';
import Modal from '../../../components/modal/Modal';
import { connect } from 'react-redux';
import CatridgeRekonsiliasi from '../../../components/catridge/CatridgeRekonsiliasi';
import Loader from '../../../components/loader/Loader';
import { detailReplenishment, addNotesReplenishment, markDoneReplenishment } from '../../../redux/actions';
import denominasiList from '../../../assets/JsonData/denominasi-list.json';
import { formatDisplayDate, formatRupiah } from '../../../helpers/functionFormat';
import CatridgeModal from '../../../components/catridge/CatridgeModal';
import { tableFunction } from '../../../helpers/functionEJ';
import TextInput from '../../../components/textInput/TextInput';
import Button from '../../../components/button/Button';

class AddNotesReplenisment extends Component {

    state = {
        id: '',
        atm: {
            id: '',
            name: '',
            location: ''
        },
        userResponsible: {
            id: '',
            name: '-'
        },
        userChecked: {
            id: '',
            name: '-'
        },
        tglInisiasi: new Date(),
        tglLapor: new Date(),
        saldoAwal: '0',
        saldoIsi: '0',
        saldoEJ: '0',
        saldoAkhir: '0',
        catridges: [],
        total: '0',
        status: '',
        catatan: '-',
        fotoBukti: null,
        fotoKerja: null,

        loading: false,
        alert: {
            show: false,
            message: '',
            isError: false
        },
        showModal: false,
        modal: {
            show: false,
            useHeader: false,
            title: null,
            body: null
        }
    }

    componentDidMount(){
        this.loadDetailReplenishment(this.props.match.params.id)
    }

    loadDetailReplenishment = async (id) => {
        this.setState({loading: true});
        try{
            let deno = {};
            for(let i=0; i< denominasiList.length; i++){
                deno = {
                    ...deno,
                    [denominasiList[i].denominator_id]: {
                        ...denominasiList[i]
                    }
                }
            }
            const response = await this.props.onGetReplenishment(id);
            const responseCatridges = response.data.replenishment_cartridges;

            let catridge = {};
            for(let i=0; i<responseCatridges.length; i++){
                let denoList = [];
                for(let d=0; d<responseCatridges[i]?.replenishment_cartridge_details?.length; d++){
                    denoList.push({
                        ...responseCatridges[i]?.replenishment_cartridge_details[d],
                        denoValue: deno[responseCatridges[i]?.replenishment_cartridge_details[d]?.denominator_id].value
                    })
                }
                catridge = {
                    ...catridge,
                    [responseCatridges[i]?.serial_number]: {
                        ...catridge[responseCatridges[i]?.serial_number],
                        [responseCatridges[i]?.type]: {
                            ...responseCatridges[i],
                            replenishment_cartridge_details: denoList
                        }
                    }
                }
            }

            let catridges = Object.keys(catridge).map(item => {
                return catridge[item]
            })

            this.setState({
                id: response.data.replenishment_id,
                atm: {
                    id: response.data.atm.atm_id,
                    name: response.data.atm.name,
                    location: response.data.atm.location
                },
                userResponsible: {
                    id: response.data.atm.updated_by,
                    name: response.data.pic.name
                },
                userChecked: {
                    id: response.data.pic.created_by,
                    name: response.data.pic.name
                },
                tglInisiasi: response.data.created_at,
                tglLapor: response.data.updated_at,
                saldoAwal: response.data.beginning_balance,
                saldoIsi: response.data.replenishment_balance,
                saldoEJ: response.data.ej_balance,
                saldoAkhir: response.data.return_balance,
                catridges: catridges,
                status: response.data.status,
                catatan: response.data.notes,
                fotoBukti: response.data.bukti_foto_layar_image_url,
                fotoKerja: response.data.surat_kerja_image_url,
                loading: false
            });
        }catch(error){
            alert(error.message);
            this.setState({loading: false});
        }
    }

    onAddNotesReplenishment = async () => {
        this.setState({loading: true});
        try{
            await this.props.onAddNotesReplenishment(
                this.state.id,
                this.state.catatan
            );
            const newReponse = await this.props.onMarkDoneReplenishment(this.state.id);
            this.setState({
                loading: false,
                alert: {
                    ...alert,
                    show: true,
                    message: newReponse.message,
                    isError: false,
                    onBtnPress: () => this.props.history.goBack(),
                    onBackPress: () => this.props.history.goBack()
                }
            })
        }catch(error){
            this.setState({
                loading: false,
                alert: {
                    ...alert,
                    show: true,
                    message: error.message,
                    isError: true
                }
            })
        }
    }

    openModalRingkasan = () => {
        const body = (
            <div className="row">
                <div className="col-12">
                    {this.state.catridges.map((catridge, idx) => (
                        <CatridgeModal
                            key={idx.toString()}
                            item={catridge["IN"]}
                        />
                    ))}
                </div>
            </div>
        )

        this.setState({
            modal: {
                ...this.state.modal,
                show: true,
                useHeader: true,
                title: 'Rincian Inisiasi Isi Ulang',
                body: body
            }
        })
    }

    openModalPhoto = (altName, image) => {
        const body = (
            <img
                alt={altName}
                style={{width: '100%', height: '100%'}}
                src={image? image : require('../../../assets/images/example-photo.png')}
            />
        );
        this.setState({
            modal: {
                ...this.state.modal,
                show: true,
                useHeader: false,
                title: null,
                body: body
            }
        })
    }

    render() {
        return (
            <div>
                <Loader show={this.state.loading}/>
                <Modal
                    useHeader={this.state.modal.useHeader}
                    show={this.state.modal.show}
                    modalClosed={() => this.setState(prevState => { return {...prevState.modal, modal: false}})}
                    title={this.state.modal.title}
                    body={this.state.modal.body}
                />
                <h2 className="page-header header-detail-rekonsiliasi">Detail Rekonsiliasi</h2>
                <div className="row container-detail-rekonsiliasi">
                    <div className="col-8 col-sm-12">
                        <Card className="card-detail-rekonsiliasi">
                            <div className="row info-detail-rekonsiliasi">
                                <div className="col-4 col-md-12">
                                    <label className="label-detail-rekonsiliasi">ID Replenishment</label>
                                </div>
                                <div className="col-8 col-md-12 col-sm-12">
                                    <label className="value-detail-rekonsiliasi">{this.state.id}</label>
                                </div>
                            </div>
                            <div className="row info-detail-rekonsiliasi">
                                <div className="col-4 col-md-12 col-sm-12">
                                    <label className="label-detail-rekonsiliasi">Tgl. Inisiasi</label>
                                </div>
                                <div className="col-8 col-md-12 col-sm-12">
                                    <label className="value-detail-rekonsiliasi">{formatDisplayDate(this.state.tglInisiasi)}</label>
                                </div>
                            </div>
                            <div className="row info-detail-rekonsiliasi">
                                <div className="col-4 col-md-12 col-sm-12">
                                    <label className="label-detail-rekonsiliasi">Penanggung Jawab</label>
                                </div>
                                <div className="col-8 col-md-12 col-sm-12">
                                    <label className="value-detail-rekonsiliasi">{this.state.userResponsible.name}</label>
                                </div>
                            </div>
                            <div className="row info-detail-rekonsiliasi">
                                <div className="col-4 col-md-12 col-sm-12">
                                    <label className="label-detail-rekonsiliasi">ATM</label>
                                </div>
                                <div className="col-8 col-md-12 col-sm-12">
                                    <label className="value-detail-rekonsiliasi">{this.state.atm.id} - {this.state.atm.name}</label>
                                </div>
                            </div>
                            <div className="hr-detail-rekonsiliasi" />
                            <div className="row info-detail-rekonsiliasi">
                                <div className="col-4 col-md-12 col-sm-12">
                                    <label className="label-detail-rekonsiliasi">Saldo Awal</label>
                                </div>
                                <div className="col-8 col-md-12 col-sm-12">
                                    <label className="value-detail-rekonsiliasi">{formatRupiah(this.state.saldoAwal, '.')}</label>
                                </div>
                            </div>
                            <div className="row info-detail-rekonsiliasi">
                                <div className="col-4 col-md-12 col-sm-12">
                                    <label className="label-detail-rekonsiliasi">Saldo Isi Ulang</label>
                                </div>
                                <div className="col-8 col-md-12 col-sm-12">
                                    <label className="value-detail-rekonsiliasi">{formatRupiah(this.state.saldoIsi, '.')}</label>
                                    <label
                                        onClick={this.openModalRingkasan}
                                        className="value-detail-rekonsiliasi a-label-detail-rekonsiliasi"
                                    >
                                        Lihat Ringkasan
                                    </label>
                                </div>
                            </div>
                            <div className="row info-detail-rekonsiliasi">
                                <div className="col-4 col-md-12 col-sm-12">
                                    <label className="label-detail-rekonsiliasi">Saldo Jurnal Elektronik</label>
                                </div>
                                <div className="col-8 col-md-12 col-sm-12">
                                    <label className="value-detail-rekonsiliasi">{formatRupiah(this.state.saldoEJ, '.')}</label>
                                </div>
                            </div>
                            <div className="row info-detail-rekonsiliasi">
                                <div className="col-4 col-md-12 col-sm-12">
                                    <label className="label-detail-rekonsiliasi">Saldo Akhir Cek Fisik</label>
                                </div>
                                <div className="col-8 col-md-12 col-sm-12">
                                    <label className="value-detail-rekonsiliasi">{formatRupiah(this.state.saldoAkhir, '.')}</label>
                                </div>
                            </div>
                            <div className="row info-detail-rekonsiliasi">
                                <div className="col-4 col-md-12 col-sm-12">
                                    <label className="label-detail-rekonsiliasi">Selisih</label>
                                </div>
                                <div className="col-8 col-md-12 col-sm-12">
                                    <label className="value-detail-rekonsiliasi text-red-detail-rekonsiliasi">{formatRupiah(0, '.')}</label>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="col-4 col-sm-12">
                        <Card className="card-detail-rekonsiliasi">
                            <div className="info-right-detail-rekonsiliasi">
                                <div className="label-detail-rekonsiliasi">Status Rekonsiliasi</div>
                                {this.state.status? tableFunction.checkingStatus(this.state.status)  : <StatusLabel type="process" label="Diperiksa"/>}
                            </div>
                            <div className="info-right-detail-rekonsiliasi">
                                <div className="label-detail-rekonsiliasi">Tanggal Lapor</div>
                                <div className="value-detail-rekonsiliasi">{formatDisplayDate(this.state.tglLapor)}</div>
                            </div>
                            <div className="info-right-detail-rekonsiliasi">
                                <div className="label-detail-rekonsiliasi">Diperiksa Oleh</div>
                                <div className="value-detail-rekonsiliasi">{this.state.userChecked.name}</div>
                            </div>
                        </Card>
                    </div>
                </div>

                <div className="row container-detail-rekonsiliasi">
                    <div className="col-12">
                        <Card className="card-detail-rekonsiliasi">
                            {this.state.catridges.map((item, idx) => (
                                <CatridgeRekonsiliasi
                                    key={idx.toString()}
                                    item={item}
                                    index={idx + 1}
                                    isLast={this.state.catridges.length === idx + 1}
                                />
                            ))}
                        </Card>
                    </div>
                </div>
                <div className="row container-detail-rekonsiliasi">
                    <div className="col-12">
                        <Card className="card-detail-rekonsiliasi">
                            <h4 className="header-detail-rekonsiliasi">Dokumen</h4>

                            <div className="row" style={{marginBottom: 10}}>
                                <div className="col-3 col-sm-12">
                                    <label className="label-detail-rekonsiliasi">Bukti Foto Layar ATM</label>
                                </div>
                                <div className="col-9 col-sm-12">
                                    <label className="value-detail-rekonsiliasi">bukti_foto_layar_atm.jpg</label>
                                    <label
                                        className="value-detail-rekonsiliasi a-label-detail-rekonsiliasi"
                                        onClick={() => this.openModalPhoto("bukti_layar_atm", this.state.fotoBukti)}
                                    >
                                        Lihat
                                    </label>
                                </div>
                            </div>
                            <div className="row" style={{marginBottom: 10}}>
                                <div className="col-3 col-sm-12">
                                    <label className="label-detail-rekonsiliasi">Foto Surat Kerja</label>
                                </div>
                                <div className="col-9 col-sm-12">
                                    <label className="value-detail-rekonsiliasi">foto-surat-kerja_narto-hidayat.jpg</label>
                                    <label
                                        className="value-detail-rekonsiliasi a-label-detail-rekonsiliasi"
                                        onClick={() => this.openModalPhoto("foto_surat_kerja", this.state.fotoKerja)}
                                    >
                                        Lihat
                                    </label>
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
                <div className="row container-detail-rekonsiliasi">
                    <div className="col-12">
                        <Card className="card-detail-rekonsiliasi">
                            <div className="row">
                                <div className="col-12">
                                    <div className="label-detail-rekonsiliasi">Catatan</div>
                                </div>
                                <div className="col-12">
                                    <div className="value-detail-rekonsiliasi">
                                        <TextInput
                                            type="textarea"
                                            value={this.state.catatan}
                                            onChange={(e) => this.setState({catatan: e.target.value})}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Card>
                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button
                                style={{marginRight: 10}}
                                className="btn-default"
                                title="Tolak"
                                onClick={() => this.props.history.goBack()}
                            />
                            <Button
                                title="Kirim"
                                onClick={() => this.props.history.goBack()}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onGetReplenishment: (id) => dispatch(detailReplenishment(id)),
        onAddNotesReplenishment: (id, notes) => dispatch(addNotesReplenishment(id, notes)),
        onMarkDoneReplenishment: (id) => dispatch(markDoneReplenishment(id))
    }
}

export default connect(null, mapDispatchToProps)(AddNotesReplenisment);
