import React from 'react';

import './buttontab.css';

const ButtonTab = props => {

    const active = props.active? 'active' : '';

    return (
        <div className="button-tab-container" style={props.style}>
            <div onClick={props.onPress} className={`button-tab ${active} ${props.className}`}>
                <div className="button-tab-info">
                    <span className={`button-tab-label ${active}`}>{props.title}</span>
                </div>
            </div>
        </div>
    )
}

export default ButtonTab;
