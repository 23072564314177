import React, { Component } from 'react';
import './confirmation.css';

class Confirmation extends Component {
    render() {
        return (
            <div className="row container-confirmation">
                <div style={{position: 'absolute', top: 10, left: 20}}>Sample Bank</div>
                <div className="col-4 col-xm-5 col-xs-6 col-md-6 col-sm-10 card-login">
                    <label className="title">Login ke akun Anda</label>
                    <span className="subtitle">Selamat Datang! Silakan lengkapi data Anda</span>
                    <div className="input-container">
                        <div className="icon" />
                        <input
                            className="input-text"
                            placeholder="Email"
                            type="email"
                        />
                    </div>
                    <div className="input-container">
                        <div className="icon" />
                        <input
                            className="input-text"
                            placeholder="Password"
                            type="password"
                        />
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 15, marginTop: 10}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <input className="input-check" type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                            <span className="checkmark"></span>
                        </div>
                        <label htmlFor="vehicle1" style={{marginLeft: 5, fontSize: 12}}>  Ingat Saya</label>
                    </div>
                    <span onClick={() => alert("asds")} className="forgot-password">Lupa Password?</span>
                    <button style={{width: '85%', marginTop: '15px', paddingBottom: 12, paddingTop: 12, background: '#667AB3', borderRadius: 6, color: 'white'}}>Masuk</button>
                </div>
            </div>
        )
    }
}

export default Confirmation;
