import React, { Component } from 'react';
import TextInput from '../../../components/textInput/TextInput';
import Button from '../../../components/button/Button';
import Card from '../../../components/card/Card';

class PengaturanNotifikasi extends Component {

    state = {
        pengisianKembali: {
            value: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit et pharetra facilisis pulvinar vulputate mauris. Sit est feugiat tempus nulla egestas fames. Dolor turpis adipiscing amet, augue ac diam nullam ipsum. Risus, enim tincidunt mattis suscipit urna habitasse. Lorem pellentesque id amet habitasse dolor. Rhoncus erat viverra aenean vulputate feugiat at euismod porttitor. Mi est auctor rhoncus mauris proin metus tristique hendrerit. Aliquam auctor sed volutpat venenatis velit cursus proin et. Et sapien eu massa laoreet. Nunc magna at sodales eget. Risus, malesuada volutpat nullam vel massa sapien. Ligula habitasse nec auctor nisl venenatis eget enim.",
            readOnly: true
        },
        isiUlang: {
            value: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit et pharetra facilisis pulvinar vulputate mauris. Sit est feugiat tempus nulla egestas fames. Dolor turpis adipiscing amet, augue ac diam nullam ipsum. Risus, enim tincidunt mattis suscipit urna habitasse. Lorem pellentesque id amet habitasse dolor. Rhoncus erat viverra aenean vulputate feugiat at euismod porttitor. Mi est auctor rhoncus mauris proin metus tristique hendrerit. Aliquam auctor sed volutpat venenatis velit cursus proin et. Et sapien eu massa laoreet. Nunc magna at sodales eget. Risus, malesuada volutpat nullam vel massa sapien. Ligula habitasse nec auctor nisl venenatis eget enim.",
            readOnly: true
        }
    }

    render(){
        return (
            <div className="col-12" style={{marginBottom: 20}}>
                <div className="row">
                    <div className="col-8 col-sm-12">
                        <Card>
                            <div className="col-12">
                                <h4 style={{marginBottom: 20}}>Notifikasi ATM Perlu Pengisian Kembali</h4>
                                <TextInput 
                                    style={{resize: 'vertical', minHeight: '100px', fontSize: '14px'}}
                                    label="Isi"
                                    type="textarea"
                                    readOnly={this.state.pengisianKembali.readOnly}
                                    value={this.state.pengisianKembali.value}
                                    onChange={(e) => this.setState(prevState => { 
                                        return {
                                            pengisianKembali: { 
                                                ...prevState.pengisianKembali, 
                                                value: e.target.value
                                            }
                                        }
                                    })}
                                />
                                <Button 
                                    style={{marginBottom: 20, marginTop: 10}}
                                    title={this.state.pengisianKembali.readOnly? "Ubah Isi" : "Simpan"}
                                    onClick={() => this.setState(prevState => { 
                                        return {
                                            pengisianKembali: { 
                                                ...prevState.pengisianKembali, 
                                                readOnly: !prevState.pengisianKembali.readOnly
                                            }
                                        }
                                    })}
                                />
                                <div style={{borderBottom: '1px solid #E4E7EB'}}/>
                            </div>
                            <div className="col-12">
                                <h4 style={{marginBottom: 20, marginTop: 20}}>Notifikasi ATM Telah Diisi Ulang</h4>
                                <TextInput 
                                    style={{resize: 'vertical', minHeight: '100px', fontSize: '14px'}}
                                    label="Isi"
                                    type="textarea"
                                    readOnly={this.state.isiUlang.readOnly}
                                    value={this.state.isiUlang.value}
                                    onChange={(e) => this.setState(prevState => { 
                                        return {
                                            isiUlang: { 
                                                ...prevState.isiUlang, 
                                                value: e.target.value
                                            }
                                        }
                                    })}
                                />
                                <Button 
                                    style={{marginBottom: 20, marginTop: 10}}
                                    title={this.state.isiUlang.readOnly? "Ubah Isi" : "Simpan"}
                                    onClick={() => this.setState(prevState => { 
                                        return {
                                            isiUlang: { 
                                                ...prevState.isiUlang, 
                                                readOnly: !prevState.isiUlang.readOnly
                                            }
                                        }
                                    })}
                                />
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        )
    }
}

export default PengaturanNotifikasi;