import storage from 'redux-persist/lib/storage';
import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';

import rootReducer from './reducers';

const persistConfig = {
	// Root
	key: 'bank_niaga_ejv1',
	// Storage Method (React Native)
	storage: storage,
	timeout: null,
	// Whitelist (Save Specific Reducers)
	whitelist: ['auth'],
	// blacklist: ['homeReducer']
};
// Middleware: Redux Persist Persisted Reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);
// Redux: Store
const store = createStore(
    persistedReducer,
    compose(
        applyMiddleware(thunk),
        window.devToolsExtension ? window.devToolsExtension() : function (f) { return f; }
    )
);
// Middleware: Redux Persist Persister
let persistor = persistStore(store);
// Exports
export { store, persistor };