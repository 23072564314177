import axios from "axios";
import { formatRequestDateOnly } from "../../helpers/functionFormat";
import { logout } from "./auth";

export const getReconDoc = (month, year, branchId) => {
  return async (dispatch, getState) => {
    try {
      const url = getState().config.url;
      const token = getState().auth.token;
      const response = await axios.get(
        `${url}/reconciliation?month=${month}&year=${year}&branch_ids=${branchId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      let message = "Internal Server Error";
      if (error.response) {
        if (error.response.data.errors) {
          if (error.response.data.errors[0].code === 1009) {
            dispatch(logout());
          }
          message = error.response.data.errors[0].message;
        }
      } else {
        message = error.message;
      }
      throw new Error(message);
    }
  };
};

// export const getReconDocByDateRange = (
//   startDate,
//   endDate,
//   branchId,
//   atmLabel
// ) => {
//   return async (dispatch, getState) => {
//     try {
//       const url = getState().config.url;
//       const token = getState().auth.token;
//       const response = await axios.get(
//         `${url}/reconciliation-v2?start_date=${startDate}&end_date=${endDate}&branch_ids=${branchId}&atm_label=${atmLabel}`,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: "Bearer " + token,
//           },
//         }
//       );
//       return response.data.data;
//     } catch (error) {
//       let message = "Internal Server Error";
//       if (error.response) {
//         if (error.response.data.errors) {
//           if (error.response.data.errors[0].code === 1009) {
//             dispatch(logout());
//           }
//           message = error.response.data.errors[0].message;
//         }
//       } else {
//         message = error.message;
//       }
//       throw new Error(message);
//     }
//   };
// };

// export const getReconDocByDateRange = (
//   startDate,
//   endDate,
//   branchId,
//   atmLabel
// ) => {
//   return async (dispatch, getState) => {
//     try {
//       const url = getState().config.url;
//       const token = getState().auth.token;
//       const response = await axios.get(
//         `${url}/reconciliation-v2?start_date=${startDate}&end_date=${endDate}&branch_ids=${branchId}&atm_label=${atmLabel}`,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: "Bearer " + token,
//           },
//         }
//       );
//       return response.data.data;
//     } catch (error) {
//       let message = "Internal Server Error";
//       if (error.response) {
//         if (error.response.data.errors) {
//           if (error.response.data.errors[0].code === 1009) {
//             dispatch(logout());
//           }
//           message = error.response.data.errors[0].message;
//         }
//       } else {
//         message = error.message;
//       }
//       throw new Error(message);
//     }
//   };
// };

// new

export const getReconDocByDateRange = (
  startDate,
  endDate,
  branchId,
  atmLabel
) => {
  return async (dispatch, getState) => {
    try {
      const url = getState().config.url;
      const token = getState().auth.token;
      const response = await axios.get(
        `${url}/reconciliation-v2?start_date=${startDate}&end_date=${endDate}&branch_ids=${branchId}&atm_label=${atmLabel}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      let message = "Internal Server Error";
      if (error.response) {
        if (error.response.data.errors) {
          if (error.response.data.errors[0].code === 1009) {
            dispatch(logout());
          }
          message = error.response.data.errors[0].message;
        }
      } else {
        message = error.message;
      }
      throw new Error(message);
    }
  };
};

export const getReconDocByDate = (date, branchId, atmLabel) => {
  return async (dispatch, getState) => {
    try {
      const url = getState().config.url;
      const token = getState().auth.token;
      const response = await axios.get(
        `${url}/reconciliation/day?date=${date}&branch_ids=${branchId}&atm_label=${atmLabel}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      let message = "Internal Server Error";
      if (error.response) {
        if (error.response.data.errors) {
          if (error.response.data.errors[0].code === 1009) {
            dispatch(logout());
          }
          message = error.response.data.errors[0].message;
        }
      } else {
        message = error.message;
      }
      throw new Error(message);
    }
  };
};

export const getReconDocByATM = (
  date,
  atmId,
  sourceType,
  sourceType2,
  page,
  limit,
  sort,
  filter
) => {
  return async (dispatch, getState) => {
    try {
      const url = getState().config.url;
      const token = getState().auth.token;
      const response = await axios.get(
        `${url}/reconciliation/day/atm?date=${date}&atm_id=${atmId}&main_document=${sourceType.toUpperCase()}&second_document=${sourceType2.toUpperCase()}&limit=${limit}&page=${page}&sort=${sort}&type=${filter}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      let postData = response.data;
      return postData;
    } catch (error) {
      let message = "Internal Server Error";
      if (error.response) {
        if (error.response.data.errors) {
          if (error.response.data.errors[0].code === 1009) {
            dispatch(logout());
          }
          message = error.response.data.errors[0].message;
        }
      } else {
        message = error.message;
      }
      throw new Error(message);
    }
  };
};

export const getReconDetail = (fileId, source) => {
  return async (dispatch, getState) => {
    try {
      const url = getState().config.url;
      const token = getState().auth.token;
      const response = await axios.get(
        `${url}/reconciliation/${fileId}?source=${source.toUpperCase()}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      let message = "Internal Server Error";
      if (error.response) {
        if (error.response.data.errors) {
          if (error.response.data.errors[0].code === 1009) {
            dispatch(logout());
          }
          message = error.response.data.errors[0].message;
        }
      } else {
        message = error.message;
      }
      throw new Error(message);
    }
  };
};

export const getReconFailed = (date, atmId, page) => {
  return async (dispatch, getState) => {
    try {
      const url = getState().config.url;
      const token = getState().auth.token;
      const response = await axios.get(
        `${url}/reconciliation/day/atm?date=${date}&atm_id=${atmId}&accepted_transaction=false&page=${page}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      return response.data;
    } catch (error) {
      let message = "Internal Server Error";
      if (error.response) {
        if (error.response.data.errors) {
          if (error.response.data.errors[0].code === 1009) {
            dispatch(logout());
          }
          message = error.response.data.errors[0].message;
        }
      } else {
        message = error.message;
      }
      throw new Error(message);
    }
  };
};

export const getReconFailedByDatetime = (
  datetime,
  atmId,
  page,
  limit,
  sort
) => {
  return async (dispatch, getState) => {
    try {
      const url = getState().config.url;
      const token = getState().auth.token;
      const response = await axios.get(
        `${url}/reconciliation/atm/${atmId}?datetime=${datetime}&page=${page}&limit=${limit}&sort=desc`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      return response.data;
    } catch (error) {
      let message = "Internal Server Error";
      if (error.response) {
        if (error.response.data.errors) {
          if (error.response.data.errors[0].code === 1009) {
            dispatch(logout());
          }
          message = error.response.data.errors[0].message;
        }
      } else {
        message = error.message;
      }
      throw new Error(message);
    }
  };
};

export const checkRecon = (
  transactionId,
  headerCoreStatementId,
  atmId,
  isCheckedFlag
) => {
  return async (dispatch, getState) => {
    try {
      const url = getState().config.url;
      const token = getState().auth.token;
      const params = {
        transaction_id: transactionId,
        header_core_statement_id: headerCoreStatementId,
        atm_id: atmId,
        is_checked_flag: isCheckedFlag,
      };
      const response = await axios.put(
        `${url}/reconciliation/day/atm`,
        params,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      return response.data;
    } catch (error) {
      let message = "Internal Server Error";
      if (error.response) {
        if (error.response.data.errors) {
          if (error.response.data.errors[0].code === 1009) {
            dispatch(logout());
          }
          message = error.response.data.errors[0].message;
        }
      } else {
        message = error.message;
      }
      throw new Error(message);
    }
  };
};

export const monitoring = (limit, page, sort, date, search, branchId) => {
  return async (dispatch, getState) => {
    try {
      const url = getState().config.url;
      const token = getState().auth.token;
      const response = await axios.get(
        `${url}/monitoring/reconciliation/day?limit=${limit}&p=${page}&sort=${sort}&date=${formatRequestDateOnly(
          date
        )}&search=${search}&branch_ids=${branchId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      return response.data;
    } catch (error) {
      let message = "Internal Server Error";
      if (error.response) {
        if (error.response.data.errors) {
          if (error.response.data.errors[0].code === 1009) {
            dispatch(logout());
          }
          message = error.response.data.errors[0].message;
        }
      } else {
        message = error.message;
      }
      throw new Error(message);
    }
  };
};
