import React, { Component } from 'react';
import './lapor-inisiasiulang.css';
import Card from '../../../components/card/Card';
// import TextInput from '../../../components/textInput/TextInput';
import Button from '../../../components/button/Button';
import DropZone from '../../../components/dropzone/DropZone';
import { formatDisplayDate, formatRupiah } from '../../../helpers/functionFormat';
import { connect } from 'react-redux';
import Loader from '../../../components/loader/Loader';
import Alert from '../../../components/alert/Alert';
import { detailReplenishment, returnReplenishment, approvalReplenisment } from '../../../redux/actions';
import denominasiList from '../../../assets/JsonData/denominasi-list.json';
import CatridgeForm from '../../../components/catridge/CatridgeForm';

class LaporanInisiasiUlang extends Component {

    state = {
        id: '',
        atm: {
            id: '',
            name: '',
            location: ''
        },
        userResponsible: {
            id: '',
            name: '-'
        },
        userChecked: {
            id: '',
            name: '-'
        },
        tglInisiasi: new Date(),
        saldoAwal: '0',
        saldoIsi: '0',
        total: 0,
        catridges: [],
        fileBukti: null,
        fileKerja: null,
        loading: false,
        alert: {
            show: false,
            message: '',
            isError: false
        }
    }

    componentDidMount(){
        if(this.props.location && this.props.location.state){
            this.setState({
                id: this.props.location.state.replenishment_id,
                atm: {
                    id: this.props.location.state.atm.atm_id,
                    name: this.props.location.state.atm.name,
                    location: this.props.location.state.atm.location
                },
                userResponsible: {
                    id: this.props.location.state.atm.updated_by,
                    name: '-'
                },
                userChecked: {
                    id: '',
                    name: '-'
                },
                tglInisiasi: this.props.location.state.created_at,
                saldoAwal: this.props.location.state.beginning_balance,
                saldoIsi: this.props.location.state.replenishment_balance,
                catridges: [],
                status: '',
                catatan: '-',
                // total: this.props.location.state.replenishment_balance
                total: 0
            })
            this.loadDetailReplenishment(this.props.location.state.replenishment_id);
        }
    }

    loadDetailReplenishment = async (id) => {
        this.setState({loading: true});
        try{
            const response = await this.props.onGetReplenishment(id);
            let catridges = response.data.replenishment_cartridges.map(catridge => {

                let detail = {};
                for(let i=0; i<catridge?.replenishment_cartridge_details?.length; i++){
                    detail = {
                        ...detail,
                        [`deno_${catridge?.replenishment_cartridge_details[i]?.denominator_id}`]: {
                            id: catridge?.replenishment_cartridge_details[i]?.denominator_id,
                            value: 0
                            // value: catridge.replenishment_cartridge_details[i].value? catridge.replenishment_cartridge_details[i].value : null
                        }
                    }
                }

                return {
                    id: catridge.replenishment_cartridge_id,
                    nomorSeri: catridge?.serial_number,
                    type: 'OUT',
                    total: 0,
                    // total: catridge.sub_total,
                    ...detail
                }
            })
            this.setState({
                loading: false,
                id: response.data.replenishment_id,
                atm: {
                    id: response.data.atm.atm_id,
                    name: response.data.atm.name,
                    location: response.data.atm.location
                },
                userResponsible: {
                    id: response.data.atm.updated_by,
                    name: response.data.pic.name
                },
                userChecked: {
                    id: response.data.pic.created_by,
                    name: response.data.pic.name
                },
                tglInisiasi: response.data.created_at,
                saldoAwal: response.data.beginning_balance,
                saldoIsi: response.data.replenishment_balance,
                catridges: catridges,
                status: response.data.status,
                catatan: response.data.notes,

            });
        }catch(error){
            this.setState({loading: false});
        }
    }

    onChangeInputCatridge = (index, identifier, value) => {
        let catridges = this.state.catridges;
        let catridge = this.state.catridges[index];

        if(typeof catridge[identifier] === "object"){
            catridge = {
                ...catridge,
                [identifier]: {
                    ...catridge[identifier],
                    value: isNaN(value)? 0 : value
                }
            }
        }else{
            catridge = {
                ...catridge,
                [identifier]: value
            }
        }

        let total = 0;

        for(let i=0; i<denominasiList.length; i++){
            total = total + (denominasiList[i].value * parseInt(catridge[`deno_${denominasiList[i].denominator_id}`].value));
        }
        total = isNaN(total)? '0' : total;
        catridge = {
            ...catridge,
            total: total
        }
        catridges[index] = catridge;
        this.setState({catridges: catridges})
        let totalAll = 0;
        for(let i=0; i<this.state.catridges.length; i++){
            totalAll += parseInt(this.state.catridges[i].total);
        }
        this.setState({total: totalAll});
    }

    onSubmitHandler = async () => {
        this.setState({loading: true})
        try{
            let catridges = this.state.catridges.map(item => {
                let details = [];
                for(let i=0; i<denominasiList.length; i++){
                    if(item[`deno_${denominasiList[i].denominator_id}`]){
                        details.push({
                            denominator_id: parseInt(item[`deno_${denominasiList[i].denominator_id}`].id),
                            value: parseInt(item[`deno_${denominasiList[i].denominator_id}`].value)
                        })
                    }
                }
                return {
                    serial_number: item?.nomorSeri,
                    sub_total: item?.total,
                    type: 'OUT',
                    replenishment_cartridge_details: details
                }
            })
            const response = await this.props.onReturnReplenishment(
                this.state.id,
                this.state.total,
                this.state.fileBukti,
                this.state.fileKerja,
                catridges
            );
            this.setState({
                loading: false,
                alert: {
                    ...alert,
                    show: true,
                    message: response.message,
                    isError: false,
                    onBtnPress: () => this.props.history.goBack()
                    // onBackPress: () => this.props.history.goBack()
                }
            })
        }catch(error){
            this.setState({
                loading: false,
                alert: {
                    ...alert,
                    show: true,
                    message: error.message,
                    isError: true
                }
            })
        }
    }

    render() {
        return (
            <div>
                <Loader show={this.state.loading}/>
                <Alert
                    show={this.state.alert.show}
                    message={this.state.alert.message}
                    isError={this.state.alert.isError}
                    modalClosed={() => this.setState({alert: {...alert, show: false}})}
                />
                <h2 className="page-header header-lapor-inisiasiulang">Lapor Pengembalian Saldo Akhir</h2>
                <div className="row container-lapor-inisiasiulang">
                    <div className="col-8 col-md-12">
                        <Card className="card-detail-replenishment">
                            <div className="row info-detail-replenishment">
                                <div className="col-4">
                                    <label className="label-lapor-inisiasiulang">ID Replenishment</label>
                                </div>
                                <div className="col-8">
                                    <label className="value-lapor-inisiasiulang">{this.state.id}</label>
                                </div>
                            </div>
                            <div className="row info-detail-replenishment">
                                <div className="col-4">
                                    <label className="label-lapor-inisiasiulang">Tgl. Inisiasi</label>
                                </div>
                                <div className="col-8">
                                    <label className="value-lapor-inisiasiulang">{formatDisplayDate(this.state.tglInisiasi)}</label>
                                </div>
                            </div>
                            <div className="row info-detail-replenishment">
                                <div className="col-4">
                                    <label className="label-lapor-inisiasiulang">ATM</label>
                                </div>
                                <div className="col-8">
                                    <label className="value-lapor-inisiasiulang">{this.state.atm.id} - {this.state.atm.name}</label>
                                </div>
                            </div>
                            <div className="row info-detail-replenishment">
                                <div className="col-4">
                                    <label className="label-lapor-inisiasiulang">Penanggung Jawab</label>
                                </div>
                                <div className="col-8">
                                    <label className="value-lapor-inisiasiulang">Narto Hidayat</label>
                                </div>
                            </div>
                            <div className="hr-detail-replenishment" />
                            <div className="row info-detail-replenishment">
                                <div className="col-4">
                                    <label className="label-lapor-inisiasiulang">Saldo Awal</label>
                                </div>
                                <div className="col-8">
                                    <label className="value-lapor-inisiasiulang">{formatRupiah(this.state.saldoAwal, '.')}</label>
                                </div>
                            </div>
                            <div className="row info-detail-replenishment">
                                <div className="col-4">
                                    <label className="label-lapor-inisiasiulang">Saldo Isi Ulang</label>
                                </div>
                                <div className="col-8">
                                    <label className="value-lapor-inisiasiulang">{formatRupiah(this.state.saldoIsi, '.')}</label>
                                </div>
                            </div>
                            <div className="hr-detail-replenishment" />
                            {this.state.catridges.map((item, index) => (
                                <CatridgeForm
                                    key={index.toString()}
                                    index={index}
                                    item={item}
                                    showDelete={this.state.catridges.length > 1}
                                    onDelete={() => this.onDeleteCatridge(index)}
                                    onChange={(identifier, value) => this.onChangeInputCatridge(index, identifier, value)}
                                    // isReport={true}
                                />
                                // <div className="row container-lapor-inisiasiulang">
                                //     <div className="col-12">
                                //         {index === 0 && <h4 className="header-lapor-inisiasiulang">Pengembalian Saldo Akhir Cek Fisik</h4>}
                                //         <Card className="card-detail-replenishment">
                                //             <h4 className="header-lapor-inisiasiulang">Catridge: {index + 1}</h4>
                                //             <div className="row">
                                //                 <div className="col-6 col-sm-12">
                                //                     <TextInput
                                //                         label="Nomor Seri"
                                //                         labelstyle={{fontSize: '14px', fontWeight: '500', color: '#1A3650'}}
                                //                         style={{fontSize: '14px'}}
                                //                         readonly
                                //                         // defaultValue="1284-1234-242-1292"
                                //                         containerstyle={{marginBottom: '20px'}}
                                //                         value={catridge.serialNumber}
                                //                     />
                                //                 </div>
                                //                 <div className="col-6 col-sm-12">
                                //                     <div className="label-lapor-inisiasiulang">Denominasi</div>
                                //                     {denominasiList.map(deno => (
                                //                         <div className="row" style={{alignItems: 'center'}} key={deno.denominator_id}>
                                //                             <div className="col-5">
                                //                                 <label className="label-catridge-form">{formatRupiah(deno.value, '.')} x</label>
                                //                             </div>
                                //                             <div className="col-7">
                                //                                 <TextInput
                                //                                     style={{width: '100%', fontSize: '14px'}}
                                //                                     righticon={(
                                //                                         <span className="lembar-span-catridgeform">Lembar</span>
                                //                                     )}
                                //                                     type="number"
                                //                                     value={catridge['deno_'+deno.denominator_id].value}
                                //                                     onChange={(e) => onChange('deno_'+deno.denominator_id, e.target.value)}
                                //                                 />
                                //                             </div>
                                //                         </div>
                                //                     ))}
                                //                     <div className="row" style={{alignItems: 'center'}}>
                                //                         <div className="col-6">
                                //                             <label className="value-lapor-inisiasiulang">Rp 100.000 x</label>
                                //                         </div>
                                //                         <div className="col-6">
                                //                             <TextInput
                                //                                 style={{width: '100%', fontSize: '14px'}}
                                //                                 righticon={(
                                //                                     <span style={{fontSize: '12px', color: '#9AA2B1'}}>Lembar</span>
                                //                                 )}
                                //                                 type="number"
                                //                             />
                                //                         </div>
                                //                     </div>
                                //                     <div className="row" style={{alignItems: 'center'}}>
                                //                         <div className="col-6">
                                //                             <label className="value-lapor-inisiasiulang">Rp 50.000 x</label>
                                //                         </div>
                                //                         <div className="col-6">
                                //                             <TextInput
                                //                                 style={{width: '100%', fontSize: '14px'}}
                                //                                 righticon={(
                                //                                     <span style={{fontSize: '12px', color: '#9AA2B1'}}>Lembar</span>
                                //                                 )}
                                //                                 type="number"
                                //                             />
                                //                         </div>
                                //                     </div>
                                //                     {/* <div className="label-lapor-inisiasiulang" style={{marginTop: 10}}>Ditolak</div>
                                //                     <div className="row" style={{alignItems: 'center'}}>
                                //                         <div className="col-6">
                                //                             <label className="value-lapor-inisiasiulang">Rp 100.000 x</label>
                                //                         </div>
                                //                         <div className="col-6">
                                //                             <TextInput
                                //                                 style={{width: '100%', fontSize: '14px'}}
                                //                                 righticon={(
                                //                                     <span style={{fontSize: '12px', color: '#9AA2B1'}}>Lembar</span>
                                //                                 )}
                                //                                 type="number"
                                //                             />
                                //                         </div>
                                //                     </div>
                                //                     <div className="row" style={{alignItems: 'center'}}>
                                //                         <div className="col-6">
                                //                             <label className="value-lapor-inisiasiulang">Rp 50.000 x</label>
                                //                         </div>
                                //                         <div className="col-6">
                                //                             <TextInput
                                //                                 style={{width: '100%', fontSize: '14px'}}
                                //                                 righticon={(
                                //                                     <span style={{fontSize: '12px', color: '#9AA2B1'}}>Lembar</span>
                                //                                 )}
                                //                                 type="number"
                                //                             />
                                //                         </div>
                                //                     </div> */}
                                //                     <div className="hr-detail-replenishment" />
                                //                     <div className="row">
                                //                         <div className="col-4">
                                //                             <label className="value-lapor-inisiasiulang">Subtotal</label>
                                //                         </div>
                                //                         <div className="col-8" style={{textAlign: 'right'}}>
                                //                             <label className="label-lapor-inisiasiulang">{formatRupiah(catridge.total, '.')}</label>
                                //                         </div>
                                //                     </div>
                                //                 </div>
                                //             </div>
                                //         </Card>
                                //     </div>
                                // </div>
                            ))}
                            {/* <div className="row container-lapor-inisiasiulang">
                                <div className="col-12">
                                    <Card className="card-detail-replenishment">
                                        <h4 className="header-lapor-inisiasiulang">Catridge: 2</h4>
                                        <div className="row">
                                            <div className="col-6 col-sm-12">
                                                <TextInput
                                                    label="Nomor Seri"
                                                    labelstyle={{fontSize: '14px', fontWeight: '500', color: '#1A3650'}}
                                                    style={{fontSize: '14px'}}
                                                    defaultValue="1284-1234-242-1294"
                                                    containerstyle={{marginBottom: '20px'}}

                                                />
                                            </div>
                                            <div className="col-6 col-sm-12">
                                                <div className="label-lapor-inisiasiulang">Denominasi</div>
                                                <div className="row" style={{alignItems: 'center'}}>
                                                    <div className="col-6">
                                                        <label className="value-lapor-inisiasiulang">Rp 100.000 x</label>
                                                    </div>
                                                    <div className="col-6">
                                                        <TextInput
                                                            style={{width: '100%', fontSize: '14px'}}
                                                            righticon={(
                                                                <span style={{fontSize: '12px', color: '#9AA2B1'}}>Lembar</span>
                                                            )}
                                                            type="number"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row" style={{alignItems: 'center'}}>
                                                    <div className="col-6">
                                                        <label className="value-lapor-inisiasiulang">Rp 50.000 x</label>
                                                    </div>
                                                    <div className="col-6">
                                                        <TextInput
                                                            style={{width: '100%', fontSize: '14px'}}
                                                            righticon={(
                                                                <span style={{fontSize: '12px', color: '#9AA2B1'}}>Lembar</span>
                                                            )}
                                                            type="number"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="label-lapor-inisiasiulang" style={{marginTop: 10}}>Ditolak</div>
                                                <div className="row" style={{alignItems: 'center'}}>
                                                    <div className="col-6">
                                                        <label className="value-lapor-inisiasiulang">Rp 100.000 x</label>
                                                    </div>
                                                    <div className="col-6">
                                                        <TextInput
                                                            style={{width: '100%', fontSize: '14px'}}
                                                            righticon={(
                                                                <span style={{fontSize: '12px', color: '#9AA2B1'}}>Lembar</span>
                                                            )}
                                                            type="number"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row" style={{alignItems: 'center'}}>
                                                    <div className="col-6">
                                                        <label className="value-lapor-inisiasiulang">Rp 50.000 x</label>
                                                    </div>
                                                    <div className="col-6">
                                                        <TextInput
                                                            style={{width: '100%', fontSize: '14px'}}
                                                            righticon={(
                                                                <span style={{fontSize: '12px', color: '#9AA2B1'}}>Lembar</span>
                                                            )}
                                                            type="number"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="hr-detail-replenishment" />
                                                <div className="row">
                                                    <div className="col-4">
                                                        <label className="value-lapor-inisiasiulang">Subtotal</label>
                                                    </div>
                                                    <div className="col-8" style={{textAlign: 'right'}}>
                                                        <label className="label-lapor-inisiasiulang">Rp 25.000.000</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                            </div> */}
                            <div className="row container-lapor-inisiasiulang">
                                <div className="col-6">
                                    <label className="total-lapor-inisiasiulang">Total Isi Ulang</label>
                                </div>
                                <div className="col-6" style={{textAlign: 'right'}}>
                                    <label className="total-lapor-inisiasiulang">{formatRupiah(this.state.total, '.')}</label>
                                </div>
                            </div>
                            <div className="hr-detail-replenishment" />
                            <div className="row container-lapor-inisiasiulang">
                                <div className="col-12">
                                    <h4 className="header-lapor-inisiasiulang">Dokumen Pendukung</h4>
                                    <div className="row" style={{alignItems: 'flex-end'}}>
                                        <div className="col-6">
                                            <DropZone
                                                className="bukti_fotoAtm"
                                                label="Bukti Foto Layar ATM"
                                                file={this.state.fileBukti}
                                                onChangeFile={(file) => this.setState({fileBukti: file})}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <DropZone
                                                className="bukti_suratKerja"
                                                label="Foto Surat Kerja"
                                                file={this.state.fileKerja}
                                                onChangeFile={(file) => this.setState({fileKerja: file})}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="hr-detail-replenishment" />
                            <div className="row container-lapor-inisiasiulang">
                                <div className="col-12" style={{ alignItems: 'flex-end'}}>
                                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                        <Button
                                            className="send_report"
                                            title="Kirim Laporan"
                                            onClick={this.onSubmitHandler}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="col-4" />
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onGetReplenishment: (id) => dispatch(detailReplenishment(id)),
        onReturnReplenishment: (id, total, fotoBukti, fotoKerja, catridges) => dispatch(returnReplenishment(id, total, fotoBukti, fotoKerja, catridges)),
        onApproveReplenishment: (id) => dispatch(approvalReplenisment(id))
    }
}

export default connect(null, mapDispatchToProps)(LaporanInisiasiUlang);
